import { put, select, takeLatest, takeEvery } from "redux-saga/effects";
import { delay } from "redux-saga";

import {
  TUTOR_INIT_APP,
  getESchoolApiKey,
  getESchoolAPIBase,
  getUserType,
  USER_TYPES,
  getRecordingPutUrl,
  getRecordingGetUrl,
  WELCOME_PACKET_RECIEVED
} from "../app/reducer/reducer";
import {
  RT_ARCHIVE_STARTED,
  RT_STREAM_ADDED,
  rtGetStreams,
  RT_END_SESSION,
  RT_DISCONNECTED,
  RT_SIGNAL_RECEIVED
} from "../realtime/realtime-reducer";
import {
  isRecordingArchive,
  getArchiveId,
  recordingsLoaded,
  getEventLog,
  MANUAL_SAVE_EVENTS,
  hasUnsavedEvents,
  onEventsSaved,
  onEventsLoaded,
  getArchiveStartEvents,
  eventOccured
} from "./archive-reducer";
import { startRecording, stopRecording, getRecordings } from "../app/services/eschool";
import { logMinorError } from "../errorlogging/errorlogging-reducer";
import { uploadEventLog, downloadEventLog } from "./archive-service";
import { eventsToChatHistory, whiteboardEvent } from "./archive-events";
import { chatHistoryLoaded } from "../chat/chat-reducer";
import { wbGetTools } from "../whiteboard/whiteboard-reducer";

function* loadEventLog() {
  const userType = yield select(getUserType);
  if (userType === USER_TYPES.LEARNER) {
    return;
  }

  const url = yield select(getRecordingGetUrl);
  try {
    const events = yield downloadEventLog(url);
    yield put(onEventsLoaded(events));
    const chatHistory = eventsToChatHistory(events);
    yield put(chatHistoryLoaded(chatHistory));
  } catch (e) {
    // This is normal since you won't have previous events most of the time.
  }
}

function* saveEventLog() {
  const userType = yield select(getUserType);
  if (userType !== USER_TYPES.COACH) {
    return;
  }

  const events = yield select(getEventLog);
  const hasUnsaved = yield select(hasUnsavedEvents);
  if (!hasUnsaved) {
    return;
  }
  console.log(`Saving ${events.length} events to event log`);
  const url = yield select(getRecordingPutUrl);
  try {
    yield uploadEventLog(url, events);
    yield put(onEventsSaved(events));
  } catch (e) {
    yield put(logMinorError(e, "Could not upload event log"));
  }
}

function* loadPreviousRecording() {
  const isLearner = (yield select(getUserType)) === USER_TYPES.LEARNER;
  if (isLearner) return;

  const apiKey = yield select(getESchoolApiKey);
  const baseUrl = yield select(getESchoolAPIBase);
  const archiveStartEvents = (yield select(getArchiveStartEvents)) || [];
  const archiveStartTimes = archiveStartEvents.reduce((acc, curr) => {
    acc[curr["id"]] = curr["timestamp"];
    return acc;
  }, {});

  try {
    const recordings = (yield getRecordings(apiKey, baseUrl)) || [];
    // The recordings don't come back in timestamp order from the API.
    // We're going to sort they by what we think the timestamp was, but there
    // is a possibility that we didn't catch the beginning of the recording in an
    // archiveStart event, and the ordering is off. Hopefully this is rare.
    const sortedRecordings =
      recordings && recordings.sort
        ? recordings.sort((a, b) => {
            const aTimestamp = archiveStartTimes[a.archive_id] || 0;
            const bTimestamp = archiveStartTimes[b.archive_id] || 0;
            return aTimestamp - bTimestamp;
          })
        : [];
    yield put(recordingsLoaded(sortedRecordings));
  } catch (e) {
    console.warn("Could not load recordings", e);
  }
}

function* initArchive() {
  yield loadEventLog();
  yield loadPreviousRecording();
}

function* onSessionEnd() {
  const userType = yield select(getUserType);
  if (userType !== USER_TYPES.COACH) return;

  yield saveEventLog();

  const isRecording = yield select(isRecordingArchive);
  if (!isRecording) return;
  const apiKey = yield select(getESchoolApiKey);
  const baseUrl = yield select(getESchoolAPIBase);
  const archiveId = yield select(getArchiveId);

  try {
    yield stopRecording(apiKey, baseUrl, archiveId);
  } catch (e) {
    console.warn("Could not stop recording.", e);
  }
}

function* onStreamConnected() {
  const isCoach = (yield select(getUserType)) === USER_TYPES.COACH;
  const streamCount = (yield select(rtGetStreams)).length;
  const isRecording = yield select(isRecordingArchive);

  if (!isCoach || streamCount === 0 || isRecording) {
    return;
  }

  const apiKey = yield select(getESchoolApiKey);
  const baseUrl = yield select(getESchoolAPIBase);

  try {
    const archvieId = yield startRecording(apiKey, baseUrl);
    console.log("Started recording", archvieId);
  } catch (e) {
    console.warn("Could not start recording", e);
  }
}

const sharedStateSignals = ["stateset", "statemerge", "stateappend"];
const isWhiteboardSignal = signal =>
  signal &&
  signal.type &&
  sharedStateSignals.includes(signal.type) &&
  signal.data &&
  signal.data.key.substr(0, 21) === "sharedData.whiteboard";

function* onRecordSignal(action) {
  /*
   For the whiteboard, we're going to save the sate of the tools in an archiveEvent whenever it changes.
   That will let us fast forward/rewind to a specific point in time without having to rebuild the history
   of events that it took us to get there.

   We couldn't do this in the reducer like other signals are (see RT_SIGNAL_RECEIVED case in archive-reducer) because
   we needed to be able to retrieve the state.sharedState.whiteboard object and the reducer didn't have access there.
  */
  if (isWhiteboardSignal(action.payload.signal)) {
    const whiteboardData = yield select(wbGetTools);
    yield put(eventOccured(whiteboardEvent(whiteboardData)));
  }
}

function* onSignalRecv() {
  // We takeLatest on this... so we'll bail out during this delay if multiple signals come in.
  // This lets us rate-limit the number of saves to one every 10 sec really easily
  yield delay(10000);
  yield saveEventLog();
}

export function* archiveSagas() {
  yield takeLatest(TUTOR_INIT_APP, loadPreviousRecording);
  yield takeLatest(WELCOME_PACKET_RECIEVED, initArchive);

  yield takeLatest(RT_STREAM_ADDED, onStreamConnected);
  yield takeLatest(RT_END_SESSION, onSessionEnd);
  yield takeLatest(RT_DISCONNECTED, onSessionEnd);

  yield takeLatest(RT_SIGNAL_RECEIVED, onSignalRecv);
  yield takeEvery(RT_SIGNAL_RECEIVED, onRecordSignal);

  yield takeLatest(RT_ARCHIVE_STARTED, saveEventLog);
  yield takeLatest(MANUAL_SAVE_EVENTS, saveEventLog);

  yield takeLatest(WELCOME_PACKET_RECIEVED, loadEventLog);
}
