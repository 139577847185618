export const componentIdFromUri = uri => {
  if (uri.substr(0, 30) === "rsus://metadata_xml_component/") {
    return uri.substr(30);
  }

  if (uri.substr(0, 7) === "rsus://") {
    return uri.substr(7);
  }
  return uri;
};

/**
 * Contacts the update service and grabs the puddle resource ID for a given
 * @param {} baseUrl
 * @param {*} classResource
 */
export /* istanbul ignore next */ const getResourceIDfromComponentID = (baseUrl, componentUri) => {
  const componentId = componentIdFromUri(componentUri);
  const isMetadata = componentUri.indexOf("metadata_xml_component") === 7;
  const apiPath = isMetadata ? "highest_metadata_xml_release" : "highest_full_release";
  const parser = isMetadata
    ? response => {
        // Parser for the highest_metadata_xml_release respone
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(response, "text/xml");
        const metadata = xmlDoc.getElementsByTagName("metadata_xml")[0].textContent;
        const metaDoc = parser.parseFromString(metadata, "text/xml");
        return metaDoc.getElementsByTagName("resource")[0].textContent;
      }
    : response => {
        // Parser for the highest_full_release respone
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(response, "text/xml");
        const url = xmlDoc.getElementsByTagName("download_url")[0].textContent;
        return url.split("/").pop();
      };

  return fetch(baseUrl + "/api/rs3/" + apiPath, {
    method: "POST",
    headers: { "Content-Type": "application/xml" },
    body: `<request client_name="tutor" client_version="1.0" protocol_version="2">
    <configuration>
      <locale>null</locale>
    </configuration>
    <component>
      <identifier>${componentId}</identifier>
      <platform>All</platform>
    </component>
  </request>
 `
  })
    .then(r => r.text())
    .then(parser);
};
