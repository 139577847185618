import convert from "xml-js";
import { parseNonCompactXML } from "../topic/xmlutil";

function encodeEntities(v) {
  return v
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&apos;");
}

export const saveCoachNote = (noteText, classId, slideIndex, teacherId, noteType, guid, accessToken, baseUrl) => {
  const cleanedNoteText = encodeEntities(noteText);
  const body = `<request access_key="${accessToken}">
  <record>
    <tags>
      <tag>type:studio_note</tag>
      <tag>class_id:${classId}</tag>
      <tag>slide_index:${slideIndex}</tag>
      <tag>teacher_id:${teacherId}</tag>
      <tag>note_type:${noteType}</tag>
      <tag>guid:${guid}</tag>
    </tags>
    <value dataType="String">${cleanedNoteText}</value>
  </record>
</request>`;

  return fetch(`${baseUrl}/taggable_records/save`, {
    method: "POST",
    body: body,
    headers: new Headers({ "Content-Type": "text/xml" })
  });
};

export const deleteNote = (tags, accessToken, baseUrl) => {
  const tagLines = Object.keys(tags)
    .map(key => `<tag>${key}:${tags[key]}</tag>`)
    .join("\n");
  const body = `<request access_key="${accessToken}">
    <tags>
      ${tagLines}
    </tags>
</request>`;

  return fetch(`${baseUrl}/taggable_records/delete`, {
    method: "POST",
    body: body,
    headers: new Headers({ "Content-Type": "text/xml" })
  });
};

const parseTags = tags =>
  tags.reduce((acc, curr) => {
    const [tag, value] = curr.text.split(":");
    acc[tag] = value;
    return acc;
  }, {});

export const parseNoteResult = resultText => {
  try {
    const xml = convert.xml2js(resultText, { compact: false, nativeType: true, addParent: false, alwaysArray: true });
    const noteData = parseNonCompactXML(xml, ["tag", "record"], []).response.records.record || [];
    noteData.forEach(note => (note.tags = parseTags(note.tags.tag)));
    return noteData;
  } catch (e) {
    console.log("Could not parse notes", e);
    return [];
  }
};

export const getNote = (classId, slideIndex, teacherId, accessToken, baseUrl) => {
  const body = `<request access_key="${accessToken}">
  <searchParameters>
    <tag>type:studio_note</tag>
    <tag>class_id:${classId}</tag>
    <tag>slide_index:${slideIndex}</tag>
    <tag>teacher_id:${teacherId}</tag>
  </searchParameters>
</request>`;
  return fetch(`${baseUrl}/taggable_records/search`, {
    method: "POST",
    body: body,
    headers: new Headers({ "Content-Type": "text/xml" })
  })
    .then(result => result.text())
    .then(parseNoteResult);
};
