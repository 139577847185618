import React from "react";
import exact from "prop-types-exact";

export const NoVideoIcon = props => (
  <svg width={90} height={90} fill="none" {...props}>
    <circle opacity="0.1" cx="45.0001" cy={45} r="44.4444" fill="#4195D3" />
    <path
      d="M61.667 36.815a1 1 0 0 0-1.6-.8l-5.808 4.356v-4.63a1.857 1.857 0 0 0-1.852-1.852H40.963l20.704 20.704V36.815zM28.833 26.482l-2.352 2.352 5.056 5.055h-1.352a1.857 1.857 0 0 0-1.852 1.852V54.26c0 1.018.834 1.851 1.852 1.851h22.222c.39 0 .723-.148 1-.333l5.908 5.889 2.352-2.352-32.834-32.833z"
      fill="#4195D3"
    />
  </svg>
);
NoVideoIcon.propTypes = exact({});
NoVideoIcon.defaultProps = {};
export default NoVideoIcon;
