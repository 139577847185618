import React, { PureComponent } from "react";
import { Redirect } from "react-router";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { PreSession } from "./PreSession";
import DeviceLister from "./DeviceLister";

import {
  rtMicSelected,
  rtCameraSelected,
  rtSpeakerSelected,
  rtGetCurrentMic,
  rtGetCurrentCamera,
  rtGetCurrentSpeaker,
  rtStartSelfTest,
  rtIsSelfTestExecuting,
  rtGetSelfTestPercent,
  rtStopBroadcast,
  rtStartBroadcast
} from "../../realtime/realtime-reducer";
import LearnerAuth from "../LearnerAuth";
import ConnectionTestResult from "./ConnectionTestResult";
import {
  isAttendGroupSession,
  attendEnterPreSession,
  attendEnterSession,
  getAttendSession
} from "../attend/attendance-reducer";
import { setSessionFromProps } from "../learner-session";
import { lionSelectors } from "@rosetta/react-lion";
import { isRecordingAllowed, isScheduledSessionGroup } from "../scheduler/scheduler-reducer";
import { appError } from "../../app/reducer/reducer";
import { SUPPORTED_LEVEL, isBrowserSupported } from "../../app/services/user-agent-util";
import { BrowserSupportMessage } from "../scheduler/BrowserSupportMessage";

class StandAloneSystemCheck extends PureComponent {
  state = {
    done: false,
    sessionId: null,
    browserWarningDismissed: false,
    browserSupport: SUPPORTED_LEVEL.SUPPORTED
  };
  static propTypes = {
    syscheck: PropTypes.bool, // Is this a system check? (vs. a pre-session)
    isGroupSession: PropTypes.bool,
    rtMicSelected: PropTypes.func,
    rtCameraSelected: PropTypes.func,
    rtSpeakerSelected: PropTypes.func,
    rtStartSelfTest: PropTypes.func,
    attendEnterSession: PropTypes.func,
    attendEnterPreSession: PropTypes.func,
    selectedMic: PropTypes.string,
    selectedCamera: PropTypes.string,
    selectedSpeaker: PropTypes.string,

    stubCredentials: PropTypes.bool,

    selfTestExecuting: PropTypes.bool,
    selfTestPercent: PropTypes.number,

    // From router:
    match: PropTypes.any,
    location: PropTypes.any,
    history: PropTypes.any,
    staticContext: PropTypes.any,

    cameraNoneLabel: PropTypes.any,
    rtStopBroadcast: PropTypes.func,

    rtStartBroadcast: PropTypes.func,
    isRecordingAllowed: PropTypes.bool,
    loaded: PropTypes.bool
  };

  static defaultProps = {
    syscheck: false
  };

  sessionId = () => this.state.sessionId;
  backToScheduler = () => this.setState({ done: "/schedule" });
  joinSession = () => {
    if (!window.OT) {
      return;
    }
    window.OT.unblockAudio();
    this.setState({ done: `/learner/session/${this.sessionId()}` });
  };

  componentDidMount() {
    const sessionId = setSessionFromProps(this.props);
    this.setState({
      sessionId
    });
    this.props.attendEnterPreSession(sessionId, this.props.isGroupSession);

    try {
      this.setState({
        browserSupport: isBrowserSupported()
      });
    } catch (e) {
      this.setState({ browserSupport: SUPPORTED_LEVEL.NOT_SUPPORTED });
      this.props.appError(e, "err_load_tokbox");
    }
  }

  renderPresession = () => {
    if (!this.props.loaded) {
      return <div />;
    }

    return (
      <DeviceLister
        video={!this.props.isGroupSession}
        render={deviceParams => (
          <PreSession
            {...deviceParams}
            isRecordingAllowed={this.props.isRecordingAllowed}
            rtStopBroadcast={this.props.rtStopBroadcast}
            rtStartBroadcast={this.props.rtStartBroadcast}
            cameraNoneLabel={this.props.cameraNoneLabel}
            isGroupSession={this.props.isGroupSession}
            rtStartSelfTest={this.props.rtStartSelfTest}
            rtMicSelected={this.props.rtMicSelected}
            rtCameraSelected={this.props.rtCameraSelected}
            rtSpeakerSelected={this.props.rtSpeakerSelected}
            selfTestExecuting={this.props.selfTestExecuting}
            selfTestPercent={this.props.selfTestPercent}
            selectedCamera={
              this.props.selectedCamera === "default" ? deviceParams.selectedCamera : this.props.selectedCamera
            }
            selectedMic={this.props.selectedMic === "default" ? deviceParams.selectedMic : this.props.selectedMic}
            selectedSpeaker={
              this.props.selectedSpeaker === "default" ? deviceParams.selectedSpeaker : this.props.selectedSpeaker
            }
            joinAfter={!this.props.syscheck}
            onDone={this.props.syscheck ? this.backToScheduler : this.joinSession}
            onExit={this.backToScheduler}
          />
        )}
      />
    );
  };

  showBrowserWarning = () =>
    this.props.lionLoaded &&
    this.state.browserSupport !== SUPPORTED_LEVEL.SUPPORTED &&
    !this.state.browserWarningDismissed && (
      <BrowserSupportMessage
        onDismissWarning={() => this.setState({ browserWarningDismissed: true })}
        level={this.state.browserSupport}
      />
    );

  render = () => {
    if (this.state.done) {
      return <Redirect to={this.state.done} />;
    }
    return (
      <React.Fragment>
        {this.showBrowserWarning()}
        <LearnerAuth
          location={this.props.location}
          stubCredentials={this.props.stubCredentials}
          match={this.props.match}
        >
          {this.renderPresession()}
        </LearnerAuth>
        <ConnectionTestResult />
      </React.Fragment>
    );
  };
}

const mapActionsToProps = {
  appError,
  rtStartSelfTest,
  rtMicSelected,
  rtCameraSelected,
  rtSpeakerSelected,
  rtStopBroadcast,
  rtStartBroadcast,
  attendEnterPreSession,
  attendEnterSession
};

const mapStateToProps = (state, ownprops) => {
  if (!ownprops.syscheck) {
    const loaded = !!getAttendSession(state);
    if (!loaded) {
      return {
        loaded: false
      };
    }
  }

  const isGroupSession = ownprops.syscheck
    ? isScheduledSessionGroup(state, ownprops.sessionId) // If it's a syscheck, we need to go look up based on session id.
    : isAttendGroupSession(state); // if it's a pressession, we can just use the attend session that's set.

  return {
    loaded: true,
    isRecordingAllowed: isRecordingAllowed(state),
    isGroupSession,
    selectedMic: rtGetCurrentMic(state),
    selectedCamera: rtGetCurrentCamera(state),
    selectedSpeaker: rtGetCurrentSpeaker(state),
    selfTestExecuting: rtIsSelfTestExecuting(state),
    selfTestPercent: rtGetSelfTestPercent(state),
    cameraNoneLabel: lionSelectors.getLionTranslatedString(state, "system_check_camera_none"),
    lionLoaded: lionSelectors.getLionAppResourcesLoaded(state)
  };
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(StandAloneSystemCheck);
