import { put, select, takeLatest } from "redux-saga/effects";
import { delay } from "redux-saga";

import {
  SUPPORT_START_SESSION,
  supportPrivateSessionReady,
  SUPPORT_PRIVATE_SESSION_END,
  SUPPORT_GET_DIAGNOSTIC
} from "./support-reducer";
import { getSupportAVTokens } from "../app/services/eschool";
import { rtGetStudentIdFromConnectionId, rtRevertTokens } from "../realtime/realtime-reducer";
import { getESchoolApiKey, getESchoolAPIBase, appError } from "../app/reducer/reducer";
import { sendSignal } from "../realtime/realtime-sagas";
import { supportRoom, exitSupportRoom, getDiagnostic } from "../realtime/signals";
import { logMinorError } from "../errorlogging/errorlogging-reducer";

function* supportEndSession() {
  console.log("Sending signal to end support session");
  yield sendSignal(exitSupportRoom());
  console.log("Signal queued");
  yield delay(2000);
  console.log("Switching to main tokbox session");
  yield put(rtRevertTokens());
}

// eslint-disable-next-line require-yield
function* supportStartSession(action) {
  const { connectionId } = action.payload;
  console.log("Startting support for", connectionId);
  const studentId = yield select(rtGetStudentIdFromConnectionId, connectionId);
  const apiKey = yield select(getESchoolApiKey);
  const baseUrl = yield select(getESchoolAPIBase);
  if (!studentId) {
    yield put(appError(null, "support_no_student_id"));
    return;
  }

  try {
    const avSession = yield getSupportAVTokens(baseUrl, apiKey, studentId);

    console.log("Sending signal to start support session to", connectionId);
    yield sendSignal(
      supportRoom(avSession.audio_video_connection_id, avSession.student_token, avSession.api_key),
      connectionId
    );
    yield delay(2000);
    console.log("Switching to support tokbox session");
    // avSession looks like:
    // {
    //   api_key: 46198782;
    //   audio_video_connection_id: "2_MX40NjE5ODc4Mn5-MTU2MzgwMjUyMTA1M35DWEREMEFGZlRPNG5aTGE2MnNvVDJHZWh-fg";
    //   student_token: "T1==cGFydG5lcl9pZD00NjE5ODc4MiZzaWc9ZGM1YzcxMWVlMjk4MTM3MGE4OWM3N2U4OTkwNjNhOTU2YzRjZTJmNzpyb2xlPXB1Ymxpc2hlciZzZXNzaW9uX2lkPTJfTVg0ME5qRTVPRGM0TW41LU1UVTJNemd3TWpVeU1UQTFNMzVEV0VSRU1FRkdabFJQTkc1YVRHRTJNbk52VkRKSFpXaC1mZyZjcmVhdGVfdGltZT0xNTYzODAyNTIxJm5vbmNlPTAuMDEyNDYzNzU4NjU5MDUxMDI3JmV4cGlyZV90aW1lPTE1NjM4ODg5MjEmY29ubmVjdGlvbl9kYXRhPXN0dWRlbnRfaWQlM0RudWxs";
    //   token: "T1==cGFydG5lcl9pZD00NjE5ODc4MiZzaWc9YWNhZjg2Mzc1YzM4YjRlNDhjZjllNjUwYjIwYzliMjhmYmYzZTJhNjpyb2xlPW1vZGVyYXRvciZzZXNzaW9uX2lkPTJfTVg0ME5qRTVPRGM0TW41LU1UVTJNemd3TWpVeU1UQTFNMzVEV0VSRU1FRkdabFJQTkc1YVRHRTJNbk52VkRKSFpXaC1mZyZjcmVhdGVfdGltZT0xNTYzODAyNTIxJm5vbmNlPTAuODcyNDc4NDU2MDkwNjk1NyZleHBpcmVfdGltZT0xNTYzODg4OTIxJmNvbm5lY3Rpb25fZGF0YT10ZWFjaGVyX2lkJTNEc3VwcG9ydA==";
    // }
    yield put(
      supportPrivateSessionReady(
        connectionId,
        studentId,
        avSession.api_key,
        avSession.audio_video_connection_id,
        avSession.student_token,
        avSession.token
      )
    );
  } catch (e) {
    yield put(appError(null, "support_no_session"));
  }
}

function* supportGetDiagnostic(action) {
  try {
    yield sendSignal(getDiagnostic(), action.payload.connectionId);
  } catch (e) {
    yield logMinorError(e, "Could not send getDiagnostic signal");
  }
}

export function* supportSagas() {
  yield takeLatest(SUPPORT_GET_DIAGNOSTIC, supportGetDiagnostic);
  yield takeLatest(SUPPORT_START_SESSION, supportStartSession);
  yield takeLatest(SUPPORT_PRIVATE_SESSION_END, supportEndSession);
}
