import { padLeft } from "../util";
import styled from "@emotion/styled";
import React from "react";

export const encodeWelcomePacket = (token, eschool_session_id, api_url) =>
  window.btoa(JSON.stringify({ token, eschool_session_id, api_url }), true);
export const decodeWelcomePacket = wpStr => JSON.parse(window.atob(wpStr));

// const SESSION_LENGTH_MINUTES = 25;
// const SESSION_LENGTH_MINUTES_WARNING = SESSION_LENGTH_MINUTES - 5;
const WARNING_MINUTES = 5;

const RED = "#FF5555";
const BLACK = "#000000";
const GREEN = "#55FF00";

const ColorLabel = styled.span(({ color }) => ({
  color: color
}));

export const timerAmount = (targetTime, now, sessionLengthMinutes) => {
  if (targetTime > now) {
    const secOffset = Math.floor((targetTime - now) / 1000);
    const minutes = Math.floor(secOffset / 60);
    const seconds = secOffset - minutes * 60;

    if (minutes > 60)
      return {
        minutes,
        seconds,
        beforeEvent: true,
        nearEnd: false,
        display: false
      }; // We won't show a label if more than an hour out.

    return {
      minutes,
      seconds,
      beforeEvent: true,
      nearEnd: false,
      display: true
    };
  }
  const secOffset = Math.floor((now - targetTime) / 1000);
  const minutes = Math.floor(secOffset / 60);
  const seconds = secOffset - minutes * 60;
  if (minutes > 60)
    return {
      minutes,
      seconds,
      beforeEvent: true,
      nearEnd: false,
      display: false
    }; // We won't show a label if more than an hour out.
  return {
    minutes,
    seconds,
    beforeEvent: false,
    display: true,
    nearEnd: minutes >= sessionLengthMinutes - WARNING_MINUTES
  };
};

export const timerLabel = (targetTime, now, sessionLength) => {
  const { minutes, seconds, beforeEvent, nearEnd, display } = timerAmount(targetTime, now, sessionLength);

  if (!display) {
    return "";
  }

  if (beforeEvent) {
    return (
      <ColorLabel color={GREEN}>
        T-
        {padLeft(minutes, 2)}:{padLeft(seconds, 2)}
      </ColorLabel>
    );
  }
  const flag = nearEnd ? "⏰" : "";
  const color = nearEnd ? RED : BLACK;
  return (
    <ColorLabel color={color}>
      {padLeft(minutes, 2)}:{padLeft(seconds, 2)} {flag.trim()}
    </ColorLabel>
  );
};

export const vocabToSaveFormat = predefined => vocabItem => ({
  term: vocabItem.headword,
  definition: vocabItem.definition,
  is_predefined: predefined
});

const ratingToLabel = {
  vocab: "Vocabulary",
  grammar: "Grammar",
  appropriateness: "Appropriateness",
  pronunciation: "Pronunciation"
};

// The reverse key<->value of ratingToLabel
const labelToRating = Object.keys(ratingToLabel).reduce((acc, curr) => {
  acc[ratingToLabel[curr]] = curr;
  return acc;
}, {});

export const ratingsToSaveFormat = coachRatings =>
  Object.keys(coachRatings)
    .filter(label => coachRatings[label] >= 0)
    .filter(label => !!ratingToLabel[label])
    .reduce((acc, curr) => {
      acc.push({
        label: ratingToLabel[curr],
        score: coachRatings[curr]
      });
      return acc;
    }, []);

export const parseSavedVocabToStoreVocab = vocab =>
  (vocab || []).filter(item => !item.is_predefined).map(item => ({ headword: item.term, definition: item.definition }));

export const parseSavedRatingsToStoreRatings = ratings =>
  (ratings || []).reduce((acc, curr) => {
    const ratingType = labelToRating[curr.label];
    ratingType && (acc[ratingType] = curr.score);
    return acc;
  }, {});

export const parseFeedback = feedback => {
  if (feedback.error) {
    return {};
  }

  return {
    coachId: feedback.teacher_id,
    startTime: feedback.start_time_in_seconds * 1000,
    durationMinutes: Math.round(feedback.duration_in_seconds / 60),
    learnerFeedback: feedback.feedback.feedback_html || "",
    internalNotes: feedback.feedback.notes_for_internal_use_only || "",
    authoredVocab: parseSavedVocabToStoreVocab(feedback.vocab_items),
    ratings: parseSavedRatingsToStoreRatings(feedback.feedback.evaluation_items)
  };
};
