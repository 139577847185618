export const WB_TOOLS = {
  NONE: null,
  ARROW: "arrow",
  TEXT: "text",
  CHECK: "check",
  X: "x",
  HIGHLIGHTER: "highlighter",
  PEN: "pen",
  QUESTION: "question"
};

const initial = {
  activeTool: WB_TOOLS.NONE,
  tempTool: null
};

const UPDATE_TEMP_TOOL = "UPDATE_TEMP_TOOL";
const ACTIVATE_TOOL = "ACTIVATE_TOOL";
export const RESET_TOOLS = "RESET_TOOLS";
export const UPDATE_TOOL = "UPDATE_TOOL";
export const GRANT_WHITEBOARD_ACCESS = "GRANT_WHITEBOARD_ACCESS";
export const REVOKE_WHITEBOARD_ACCESS = "REVOKE_WHITEBOARD_ACCESS";

const emptyTool = toolType => ({
  x: 0,
  y: 0,
  type: toolType
});

export const whiteboardReducer = (state = initial, action = undefined) => {
  switch (action && action.type) {
    case RESET_TOOLS:
      return {
        ...state,
        tempTool: null,
        activeTool: WB_TOOLS.NONE
      };

    case UPDATE_TEMP_TOOL:
      return { ...state, tempTool: action.payload.tool };

    case ACTIVATE_TOOL:
      if (state.activeTool === action.payload.tool) {
        return {
          ...state,
          tempTool: null,
          activeTool: WB_TOOLS.NONE
        };
      }
      return {
        ...state,
        tempTool: emptyTool(action.payload.tool),
        activeTool: action.payload.tool
      };

    default:
      return state;
  }
};

// Actions

export const wbGrantWhiteboardAccess = connectionId => ({ type: GRANT_WHITEBOARD_ACCESS, payload: { connectionId } });
export const wbRevokeWhiteboardAccess = connectionId => ({ type: REVOKE_WHITEBOARD_ACCESS, payload: { connectionId } });

export const wbActivateTool = tool => ({ type: ACTIVATE_TOOL, payload: { tool } });
export const wbUpdateTempTool = tool => ({ type: UPDATE_TEMP_TOOL, payload: { tool } });
export const wbUpdateTool = (key, tool) => ({ type: UPDATE_TOOL, payload: { key, tool } });
export const wbResetTools = () => ({ type: RESET_TOOLS });
// Selectors

export const wbGetTools = state => {
  const tools = state.sharedstate.sharedData.whiteboard || {};
  return Object.keys(tools).map(key => ({
    key: key,
    tool: tools[key]
  }));
};
export const wbGetTempTool = state => state.whiteboard.tempTool;
export const wbGetActiveTool = state => state.whiteboard.activeTool;
