import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import exact from "prop-types-exact";
import styled from "@emotion/styled";
import TestSound from "../../../assets/sounds/SpeechPrompt.mp3";
import { LionTranslate } from "@rosetta/react-lion";

const Container = styled.div({
  width: 450,
  display: "flex",
  flexDirection: "column",
  marginLeft: 70
});

const Columns = styled.div({
  width: 450,
  justifyContent: "flex-start",
  display: "flex"
});

const Left = styled.div({
  display: "flex",
  flexDirection: "column",
  width: 200,
  flexGrow: 0,
  flexShrink: 0
});
const Right = styled.div({
  width: 157,
  textAlign: "right",
  display: "flex",
  justifyContent: "flex-end"
});

const TestButton = styled.button({
  background: "none",
  border: "none",
  outline: "none",
  fontFamily: "Effra",
  fontSize: "14px",
  lineHeight: "19px",
  color: "#4195D3",
  verticalAlign: "middle",
  cursor: "pointer",
  ":hover": {
    backgroundColor: "#EAEAEA"
  },
  "> svg": {
    marginRight: 10,
    verticalAlign: "middle"
  }
});

const Header = styled.div({
  fontFamily: "Effra",
  fontSize: "18px",
  lineHeight: "normal",
  color: "#4A4A4A",
  marginBottom: 14
});

class LearnerConnectionTest extends PureComponent {
  static propTypes = exact({
    onTestConnection: PropTypes.func,
    selfTestExecuting: PropTypes.bool,
    selfTestPercent: PropTypes.number
  });
  static defaultProps = {};

  componentDidMount() {
    this.sound = new Audio(TestSound);
  }

  render = () => {
    return (
      <Container data-qa="connetion_test">
        <Columns>
          <Left>
            <Header>
              <LionTranslate lionkey="system_check_connection_test_header" />
            </Header>
          </Left>
          <Right>
            {this.props.selfTestExecuting || (
              <TestButton onClick={this.props.onTestConnection}>
                <LionTranslate lionkey="system_check_connection_test_button" />
              </TestButton>
            )}
            {this.props.selfTestExecuting && `${this.props.selfTestPercent}%`}
          </Right>
        </Columns>
      </Container>
    );
  };
}

export default LearnerConnectionTest;
