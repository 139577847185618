import { RT_WELCOME_MESSAGE_RECEIVE, RT_CONNECTED, RT_DISCONNECTED } from "../../realtime/realtime-reducer";
export const ENTER_PRESESSION = "ENTER_PRESESSION";
export const ENTER_SESSION = "ENTER_SESSION";
export const EXIT_SESSION = "EXIT_SESSION";
export const EXIT_POST_SESSION = "EXIT_POST_SESSION";
export const SESSION_INFO_LOADED = "SESSION_INFO_LOADED";
export const TOGGLE_MUTE = "TOGGLE_MUTE";
export const SHOW_STICKER = "SHOW_STICKER";
export const LOAD_UPCOMING_SESSIONS = "LOAD_UPCOMING_SESSIONS";
export const UPCOMING_SESSIONS_LOADED = "UPCOMING_SESSIONS_LOADED";
export const SEND_STICKER = "SEND_STICKER";

export const AUDIO_BLOCKED = "AUDIO_BLOCKED";
export const AUDIO_UNBLOCKED = "AUDIO_UNBLOCKED";
export const SUBSCRIBED_TO_COACH = "SUBSCRIBED_TO_COACH";

const initial = {
  blockedAudio: false,

  sessionId: null,
  sessionLoaded: false,
  sessionStarted: false,

  subscribedToCoach: false,

  upcomingSessions: null, // Null for not loaded, an array otherwise.
  upcomingSessionsLoading: false,
  sticker: null
};

export const attendanceReducer = (state = initial, action = undefined) => {
  switch (action && action.type) {
    case RT_DISCONNECTED:
    case RT_CONNECTED:
      if (action.payload?.weConnected) {
        // Set some connected flags to false at the start of the connection sequence, or on disconnect.
        return { ...state, subscribedToCoach: false, sessionStarted: false };
      }
      return state;

    case SUBSCRIBED_TO_COACH:
      return { ...state, subscribedToCoach: true };
    case AUDIO_BLOCKED:
      return {
        ...state,
        blockedAudio: true
      };
    case AUDIO_UNBLOCKED:
      return {
        ...state,
        blockedAudio: false
      };
    case SHOW_STICKER:
      return { ...state, sticker: action.payload.sticker };
    case RT_WELCOME_MESSAGE_RECEIVE:
      return { ...state, sessionStarted: true };
    case LOAD_UPCOMING_SESSIONS:
      return { ...state, upcomingSessionsLoading: true, upcomingSessions: null };
    case UPCOMING_SESSIONS_LOADED:
      return {
        ...state,
        upcomingSessionsLoading: false,
        upcomingSessions: action.payload.sessions
      };
    case SESSION_INFO_LOADED:
      return {
        ...state,
        sessionLoaded: true
      };
    case EXIT_SESSION:
      return {
        ...state,
        sessionLoaded: false
      };
    case ENTER_PRESESSION:
    case ENTER_SESSION:
      return {
        ...state,
        sessionLoaded: action.payload.sessionId === state.sessionId ? state.sessionLoaded : false,
        sessionId: action.payload.sessionId
      };
    default:
      return state;
  }
};

// ---------------------------------------- Selectors ------------------------------

// eslint-disable-next-line no-unused-vars
export const getTutorName = state => "Tutor Name TODO";

export const getAttendSessionId = state => state.attend.sessionId;
export const getSessionById = (state, eschoolSessionId) =>
  state.attend.upcomingSessions &&
  state.attend.upcomingSessions.find(session => session.eschool_session_id === eschoolSessionId);
export const getAttendSession = state => getSessionById(state, getAttendSessionId(state));
export const isAttendSessionStarted = state => state.attend.sessionStarted;
export const isAttendSessionInfoLoaded = state => state.attend.sessionLoaded;
export const isAttendGroupSession = state => {
  const session = getAttendSession(state);
  return session && session.number_of_seats > 1;
};
export const getSticker = state => state.attend.sticker;
export const isAudioBlocked = state => state.attend.blockedAudio;
export const isSubscribedToCoach = state => state.attend.subscribedToCoach;
// ---------------------------------------- Actions -----------------------------------

export const attendSubscribedCoach = () => ({ type: SUBSCRIBED_TO_COACH });
export const attendAudioBlocked = () => ({ type: AUDIO_BLOCKED });
export const attendAudioUnblocked = () => ({ type: AUDIO_UNBLOCKED });
export const attendSendSticker = (stickerIndex, displayName, userId) => ({
  type: SEND_STICKER,
  payload: { stickerIndex, displayName, userId }
});
export const attendShowSticker = sticker => ({ type: SHOW_STICKER, payload: { sticker } });
export const attendLoadUpcomingSessions = () => ({ type: LOAD_UPCOMING_SESSIONS });
export const attendUpcomingSessionsLoaded = sessions => ({ type: UPCOMING_SESSIONS_LOADED, payload: { sessions } });
export const attendToggleMute = muted => ({ type: TOGGLE_MUTE, payload: { muted } });
export const attendSessionInfoLoaded = session => ({ type: SESSION_INFO_LOADED, payload: { session } });
export const attendEnterSession = sessionId => ({ type: ENTER_SESSION, payload: { sessionId } });
export const attendEnterPreSession = (sessionId, groupSession) => ({
  type: ENTER_PRESESSION,
  payload: { sessionId, groupSession }
});

export const attendExitSession = () => ({ type: EXIT_SESSION });
export const attendExitPostSession = (avQuality, edQuality, comments) => ({
  type: EXIT_POST_SESSION,
  payload: { avQuality, edQuality, comments }
});

export default attendanceReducer;
