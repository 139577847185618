//import { graphql } from "react-apollo";
import { gql } from "apollo-boost";

export const onDemandVideoQuery = (language, locale) => gql`
  {
    tutors(language: "${language}") {
      bio
      fullName
      id
      image(height: 300, width: 300)
      location
      videos(locale: "${locale}") {
        guid
      }
    }

    videos(language: "${language}", locale: "${locale}") {
      available
      category {
        description
        guid
        title
      }
      speechInteractions{
        cueTime
        id
        prompt{
          imageUri
          soundUri
          text
        }
        response{
          sre
        }
      }
      description
      duration
      objectives
      skills(locale: "${locale}")
      thumbnails {
        height
        width
        uri
      }
      title
      tutors {
        id
      }
      createdAt
      duration
      guid
      uri
    }

    topics(language: "${language}", locale: "${locale}") {
      description
      guid
      revision
      title
      videos {
        guid
      }
    }
  }
`;
