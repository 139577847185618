import React from "react";

import exact from "prop-types-exact";
import { PropTypes } from "prop-types";

// eslint-disable-next-line no-unused-vars
export const HappyFace = ({ color = "#A0CAE9", ...props }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM7.87158 18.5097L6.12842 19.4903C10.4584 27.1881 21.5415 27.1881 25.8716 19.4903L24.1284 18.5097C20.563 24.8483 11.437 24.8483 7.87158 18.5097ZM13 12C13 13.1046 12.1046 14 11 14C9.89543 14 9 13.1046 9 12C9 10.8954 9.89543 10 11 10C12.1046 10 13 10.8954 13 12ZM21 14C22.1046 14 23 13.1046 23 12C23 10.8954 22.1046 10 21 10C19.8954 10 19 10.8954 19 12C19 13.1046 19.8954 14 21 14Z"
      fill={color}
    />
  </svg>
);
HappyFace.propTypes = exact({
  color: PropTypes.string
});
HappyFace.defaultProps = {};
export default HappyFace;
