const RSTV_LIVE_VIDEO_SELECTED = "RSTV_LIVE_VIDEO_SELECTED";
const RSTV_LIVE_CLOSE_VIDEO = "RSTV_LIVE_CLOSE_VIDEO";

const initial = {
  currentVideo: null
};

export const rstvLiveReducer = (state = initial, action = undefined) => {
  switch (action && action.type) {
    case RSTV_LIVE_CLOSE_VIDEO:
      return { ...state, currentVideo: null };
    case RSTV_LIVE_VIDEO_SELECTED:
      return {
        ...state,
        currentVideo: action.payload.video
      };
    default:
      return state;
  }
};

export const rstvLiveVideoSelected = video => ({ type: RSTV_LIVE_VIDEO_SELECTED, payload: { video } });
export const rstvLiveCloseVideo = () => ({ type: RSTV_LIVE_CLOSE_VIDEO });

export const rstvGetCurrentLiveVideo = state => state.rstv.live.currentVideo;
