import "react-app-polyfill/stable";
import "whatwg-fetch";
import "core-js/features/promise";

import React from "react";
import ReactDOM from "react-dom";

// I was unable to reliably get the html to update on new versions with this service worker.
// import registerServiceWorker from "./registerServiceWorker";

// add global fonts, etc. here, BUT try not to add anything here
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "./index.css";

import "moment/locale/de";
import "moment/locale/es";
import "moment/locale/fr";
import "moment/locale/it";
import "moment/locale/ja";
import "moment/locale/ko";
import "moment/locale/nl";
import "moment/locale/pl";
import "moment/locale/pt";
import "moment/locale/ru";
import "moment/locale/zh-cn";

import { Provider } from "react-redux";
import { Router } from "./routes";
import configureStore from "./configureStores";
import { createBrowserHistory } from "history";
import { initTutorAnalytics } from "./modules/analytics/tutor-analytics";
import { setupAutomation } from "./modules/app/automation-util";
import { startInteraction, TIMING_STRATEGIES } from "@rosetta/eve-client";

startInteraction("application-startup", TIMING_STRATEGIES.FROM_PAGE_START);

const history = createBrowserHistory();
const store = configureStore(history);

setupAutomation(store);

const renderApp = () => {
  return ReactDOM.render(
    <Provider store={store}>
      <Router history={history} />
    </Provider>,
    document.getElementById("root")
  );
};

renderApp();

// registerServiceWorker();

initTutorAnalytics();

try {
  navigator &&
    navigator.serviceWorker &&
    navigator.serviceWorker.getRegistrations &&
    navigator.serviceWorker
      .getRegistrations()
      .then(registrations => {
        for (const registration of registrations) {
          registration.unregister();
          console.info("Removed service worker. Please reload the page to get latest version.");
        }
      })
      .catch(() => console.log("Could not remove service workers."));
} catch (e) {
  console.log("Could not remove service workers.");
}
