import React from "react";

export const MicIcon = props => (
  <svg width={14} height={15} fill="none" {...props}>
    <path
      d="M12.25 7.25h-1.275c0 .555-.12 1.072-.322 1.537l.922.923c.42-.735.675-1.568.675-2.46zm-3.015.128c0-.046.015-.083.015-.128v-4.5A2.247 2.247 0 0 0 7 .5a2.247 2.247 0 0 0-2.25 2.25v.135l4.485 4.493zM1.203 1.25l-.953.953L4.758 6.71v.54A2.24 2.24 0 0 0 7 9.5c.165 0 .33-.023.487-.06l1.245 1.245a4.124 4.124 0 0 1-1.732.39c-2.07 0-3.975-1.575-3.975-3.825H1.75c0 2.557 2.04 4.672 4.5 5.04v2.46h1.5v-2.46a5.26 5.26 0 0 0 1.905-.675l3.143 3.135.952-.953L1.203 1.25z"
      fill="#999"
    />
  </svg>
);

export default MicIcon;
