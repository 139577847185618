import { RT_REVERT_TOKENS } from "../realtime/realtime-reducer";

const SUPPORT_TOGGLE_BROADCAST = "SUPPORT_TOGGLE_BROADCAST";
export const SUPPORT_PRIVATE_SESSION_READY = "SUPPORT_PRIVATE_SESSION_READY";
export const SUPPORT_START_SESSION = "SUPPORT_START_SESSION";
export const SUPPORT_PRIVATE_SESSION_END = "SUPPORT_PRIVATE_SESSION_END";
export const SUPPORT_GET_DIAGNOSTIC = "SUPPORT_GET_DIAGNOSTIC";
export const SUPPORT_DIAGNOSTIC_RESULT_RECEIVE = "SUPPORT_DIAGNOSTIC_RESULT_RECEIVE";

const initialState = {
  broadcasting: false,
  currentPrivateSupportSession: null,
  diag: {}
};

export const supportReducer = (state = initialState, action = undefined) => {
  switch (action && action.type) {
    case SUPPORT_DIAGNOSTIC_RESULT_RECEIVE:
      return {
        ...state,
        diag: {
          ...state.diag,
          [action.payload.connectionId]: action.payload.info
        }
      };
    case SUPPORT_TOGGLE_BROADCAST:
      return { ...state, broadcasting: !state.broadcasting };
    case RT_REVERT_TOKENS:
      return {
        ...state,
        currentPrivateSupportSession: null
      };
    case SUPPORT_PRIVATE_SESSION_READY:
      return {
        ...state,
        currentPrivateSupportSession: action.payload
      };
    default:
      return state;
  }
};

// Actions
export const supportGetDiagnostic = connectionId => ({ type: SUPPORT_GET_DIAGNOSTIC, payload: { connectionId } });
export const supportDiagnosticResultReceived = (connectionId, info) => ({
  type: SUPPORT_DIAGNOSTIC_RESULT_RECEIVE,
  payload: { connectionId, info }
});
export const supportToggleBroadcast = () => ({ type: SUPPORT_TOGGLE_BROADCAST });
export const supportStartSession = connectionId => ({ type: SUPPORT_START_SESSION, payload: { connectionId } });
export const supportPrivateSessionReady = (
  connectionId,
  studentId,
  apiKey,
  audioVideoConnectionId,
  studentToken,
  token
) => ({
  type: SUPPORT_PRIVATE_SESSION_READY,
  payload: {
    connectionId,
    studentId,
    apiKey,
    audioVideoConnectionId,
    studentToken,
    token
  }
});
export const supportPrivateSessionEnd = () => ({ type: SUPPORT_PRIVATE_SESSION_END });

// Selectors
export const isBroadcasting = state => state.support.broadcasting;
export const getCurrentPrivateSupportSession = state => state.support.currentPrivateSupportSession;
export const getDiagnosticData = (state, connectionId) => state.support.diag[connectionId];
