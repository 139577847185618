/*
 * This module interacts with the realtime channel (currently OpenTok/TokBox)
 * This should be the only place window.OT is referenced from, and ideally the only place we need
 * to modify if we switch out realtime providers. (realistically, we probably have to swap out some UI components too)
 */

import { delay, eventChannel, END } from "redux-saga";
import { put, takeLatest, all, takeEvery, select, take, fork } from "redux-saga/effects";
import { Studio } from "@rosetta/sqrl-client";

import {
  CHAT_SEND_MESSAGE,
  chatReceiveMessage,
  CHAT_SEND_PRIVATE_MESSAGE,
  chatReceivePrivateMessage
} from "../chat/chat-reducer";

import { getTestConnectionInfo, reportConnectionStats } from "../app/services/eschool";
import NetworkTest, { ErrorNames } from "opentok-network-test-js";

import BeepUrl from "../../assets/sounds/SpeechPrompt.mp3";

import {
  RT_DISCONNECTED,
  rtGetSessionToken,
  RT_CONNECT,
  rtGetApiKey,
  rtGetSessionId,
  rtConnected,
  rtWelcomeMessageReceived,
  rtGetPublisher,
  rtConnectionStatsUpdated,
  rtReconnected,
  rtReconnecting,
  rtDisconnected,
  rtStreamRemoved,
  RT_START_SELF_TEST,
  rtQualityTestCompleted,
  rtConnectivityTestCompleted,
  rtSelfTestStatus,
  rtQualityTestFailed,
  RT_END_SESSION,
  RT_DISCONNECT,
  rtArchiveStarted,
  rtDisconnect,
  rtGetSubscriber,
  rtSignalReceived,
  rtStreamAdded,
  rtGetSession,
  RT_NEXT_VIDEO,
  RT_CONNECTED,
  RT_RECIEVE_TOKENS,
  rtRemoteUserMuted,
  rtGetAllStreams,
  rtRemoteUserForceMuted,
  rtReceiveSharedStateInit,
  rtIsSharedSyncComplete,
  rtSharedSycPacketData,
  rtSharedStateInit,
  rtLearnerWelcomeReceive,
  rtDestroyed,
  rtBeginDisconnecting,
  rtGetCurrentSpeaker,
  RT_CAMERA_SELECTED,
  RT_MIC_SELECTED,
  rtSignalStateAppendReceived,
  rtSignalStateSetReceived,
  rtSignalStateMergeReceived,
  rtGoodbyeReceived,
  rtSignalSent,
  rtSwitchTokens,
  rtConnect,
  rtRevertTokens,
  rtGetCurrentMicData,
  rtGroupEvent,
  GROUP_EVENT_TYPES,
  getGroupEventHistory,
  rtLearnerReadyReceive as rtLearnerReadyReceived,
  RT_IS_READY
} from "./realtime-reducer";

import {
  getUserType,
  USER_TYPES,
  getESchoolAPIBase,
  appError,
  getESchoolApiKey,
  getLearnerGUID,
  getLcpAPIUrl,
  getESchoolSessionId
} from "../app/reducer/reducer";
import {
  chatSignal,
  welcomeSignal,
  showSlideSignal,
  endSessionSignal,
  reconstructSignal,
  selfMute,
  forceMute,
  sharedStateInit,
  learnerWelcomeSignal,
  privateChatSignal,
  stickerSignal,
  stickerText,
  sharedStateSet,
  sharedStateAppend,
  sharedStateMerge,
  stickers,
  diagnosticResult,
  learnerReady
} from "./signals";

import { TOPIC_SHOW_SLIDE, getTopicResourceId, getCurrentTopicSlides } from "../topic/topic-reducer";

import { getCoachAuthoredVocab, COACH_UNMUTE, COACH_MUTE, COACH_EJECT_USER } from "../coach/coach-reducer";
import { recordUserStartEvent } from "../analytics/tutor-analytics";
import { flatten, statsDiff } from "./realtime-service";
import { logMinorError, logToEschool } from "../errorlogging/errorlogging-reducer";
import {
  getAttendSessionId,
  TOGGLE_MUTE,
  EXIT_SESSION,
  attendShowSticker,
  SEND_STICKER
} from "../learner/attend/attendance-reducer";
import { getCurrentSlide, getLearnerPreferredName } from "../root-selectors";
import {
  UPDATE_TOOL,
  RESET_TOOLS,
  GRANT_WHITEBOARD_ACCESS,
  REVOKE_WHITEBOARD_ACCESS
} from "../whiteboard/whiteboard-reducer";

import {
  getCurrentSlideIndex,
  sharedRemoteValueReceived,
  getGroupChatHistory,
  getSharedSnapshot,
  getSharedLastUpdate,
  getConnectionIdsForUser,
  hasCapability,
  SHARED_LOCAL_VALUE_APPENDED,
  SHARED_LOCAL_VALUE_RECEIVED,
  findTutorName,
  getPreferredNameForConnectionId
} from "../sharedstate/sharedstate-reducer";
import { isPenTool } from "../whiteboard/whiteboard-util";
import { supportDiagnosticResultReceived, getCurrentPrivateSupportSession } from "../support/support-reducer";
import { startInteraction, logSegment } from "@rosetta/eve-client";

const OT = window.OT;

const CONNECTION_CREATED = "connectionCreated";
const CONNECTION_DESTROYED = "connectionDestroyed";
const STREAM_CREATED = "streamCreated";
const STREAM_DESTROYED = "streamDestroyed";

const ARCHIVE_STARTED = "archiveStarted";
const ARCHIVE_STOPPED = "archiveStopped";
const SESSION_RECONNECTED = "sessionReconnected"; // we have reconnected
const SESSION_RECONNECTING = "sessionReconnecting"; // we lost our connection, and are reconnecting
const SESSION_DISCONNECTED = "sessionDisconnected";
const SIGNAL = "signal";

let session = null;

const remoteConnections = {};

const Beep = new Audio(BeepUrl);
Beep.load();

/**
 * Helper function that runs an OT quality test, but returns an event channel we can listen to.
 * @param  otNetworkTest
 */
const createTestChannel = otNetworkTest => {
  return eventChannel(emitter => {
    otNetworkTest
      .testQuality(r => {
        emitter({ type: "progress", r });
      })
      .then(results => {
        emitter({ type: "complete", results });
        emitter(END);
      })
      .catch(err => {
        emitter({ type: "error", err });
        emitter(END);
      });
    return () => otNetworkTest.stop();
  });
};

/**
 * Helper function to turn OpenTok session events into an eventChannel that we can more easily listen to
 * in a saga.
 *
 * @param {} session - the openTok session
 */
const createSessionChannel = session =>
  eventChannel(emitter => {
    const connectionCreated = event => emitter({ type: "connectionCreated", event });
    const connectionDestroyed = event => emitter({ type: "connectionDestroyed", event });
    const streamCreated = event => emitter({ type: "streamCreated", event });
    const streamDestroyed = event => emitter({ type: "streamDestroyed", event });
    const archiveStarted = event => emitter({ type: "archiveStarted", event });
    const archiveStopped = event => emitter({ type: "archiveStopped", event });
    const sessionReconnected = event => emitter({ type: "sessionReconnected", event });
    const sessionReconnecting = event => emitter({ type: "sessionReconnecting", event });
    const sessionDisconnected = event => {
      emitter({ type: "sessionDisconnected", event });
      emitter(END);
    };
    const signal = event => emitter({ type: "signal", event });

    session.on(CONNECTION_CREATED, connectionCreated);
    session.on(CONNECTION_DESTROYED, connectionDestroyed);
    session.on(STREAM_CREATED, streamCreated);
    session.on(STREAM_DESTROYED, streamDestroyed);
    session.on(ARCHIVE_STARTED, archiveStarted);
    session.on(ARCHIVE_STOPPED, archiveStopped);
    session.on(SESSION_RECONNECTED, sessionReconnected);
    session.on(SESSION_RECONNECTING, sessionReconnecting);
    session.on(SESSION_DISCONNECTED, sessionDisconnected);
    session.on(SIGNAL, signal);

    return () => {
      // Unsubscribe function
      session.off(CONNECTION_CREATED, connectionCreated);
      session.off(CONNECTION_DESTROYED, connectionDestroyed);
      session.off(STREAM_CREATED, streamCreated);
      session.off(ARCHIVE_STARTED, archiveStarted);
      session.off(ARCHIVE_STOPPED, archiveStopped);
      session.off(SESSION_RECONNECTED, sessionReconnected);
      session.off(SESSION_RECONNECTING, sessionReconnecting);
      session.off(SESSION_DISCONNECTED, sessionDisconnected);
      session.off(STREAM_DESTROYED, streamDestroyed);
      session.off(SIGNAL, signal);
    };
  });

const MAX_SNAPSHOT_SEND_SIZE = 5000; // Tokbox has 8k limit. Set 5 to make sure there's enough room for overhead. Might be able to go a bit higher?
function* sendCurrentSharedState(connectionId) {
  const userType = yield select(getUserType);
  if (userType !== USER_TYPES.COACH) return;

  const supportsSharedStateInit = yield select(hasCapability, connectionId, "sharedStateInit");
  if (!supportsSharedStateInit) {
    console.info("Client doesn't support sharedStateInit, skipping");
    return;
  }

  const snapshot = yield select(getSharedSnapshot);
  const snapshotTxt = JSON.stringify(snapshot);
  const packets = Math.ceil(snapshotTxt.length / MAX_SNAPSHOT_SEND_SIZE);
  const stateVersion = (yield select(getSharedLastUpdate)) || new Date().getTime();
  for (let i = 0; i < packets; i++) {
    const signal = sharedStateInit(
      stateVersion,
      i + 1,
      packets,
      snapshotTxt.substr(i * MAX_SNAPSHOT_SEND_SIZE, MAX_SNAPSHOT_SEND_SIZE)
    );
    console.log("Sending init", i, signal);
    try {
      yield sendSignal(signal, connectionId);
    } catch (e) {
      yield put(logMinorError(e, "Could not send signal to client"));
    }
  }
}

/**
 * Sends a data-signal over the RTC. Pass in a signal in format {type:'', data:{}}
 * Will automatically serialize the data param for you.
 *
 * @param {*} signal
 */
export function* sendSignal(signal, sendToConnection = undefined) {
  const session = yield select(rtGetSession);

  if (!session) {
    // We're going to stop reporting errors here. It's almost always caused by a tutor doing something like clicking a slide
    // or sending a chat before they've connected.
    // throw new Error("No session available");
    return;
  }

  const data = JSON.stringify(signal.data || {});

  if (sendToConnection) {
    yield put(rtSignalSent(signal, session.connection.id, new Date().getTime()));
  }

  const params = {
    type: signal.type,
    data: data
  };

  if (sendToConnection) {
    if (remoteConnections[sendToConnection]) {
      const connection = remoteConnections[sendToConnection];
      if (!connection || connection.destroyed()) {
        console.info("Tried to send a signal to a connection that didn't exist.");
        return;
      }
      params.to = connection;
    } else {
      // At this point, we had a connectionId to send to, but the connection isn't around anymore.
      // Usually do to a page reload and clients reconnecting. This is ok and normal.
      console.info("Tried to send a signal to a connection that didn't exist.");
      return;
    }
  }

  const signalPromise = new Promise((resolve, reject) => {
    console.log("Sending TokboxSignal", data.length, signal.type, sendToConnection);
    session.signal(params, err => {
      if (err) {
        console.warn("Failed to send signal", err, signal);
        reject(err);
      } else {
        console.log("Signal: ", signal.type, "Sent!");
        resolve();
      }
    });
  });

  yield put(logToEschool("Sending signal " + signal.type));

  yield signalPromise;
}

function* onToggleMute(action) {
  const isMuted = action.payload.muted;
  try {
    yield sendSignal(selfMute(isMuted));
  } catch (e) {
    yield put(logMinorError(e, "Could not send mute signal to client"));
  }
}

function* onSlideChange(action) {
  const { slideIndex } = action.payload;
  const slide = slideIndex === -1 ? null : (yield select(getCurrentTopicSlides))[slideIndex];
  const vocab = slide && slide.type === "vocabulary" ? yield select(getCoachAuthoredVocab) : [];
  try {
    yield sendSignal(showSlideSignal(slideIndex, slide ? slide.type : "none", vocab));
  } catch (e) {
    yield put(logMinorError(e, "Could not send slide signal to client"));
  }
}

/**
 * Sometimes we need to send a private message to a single learner. But, it's not *reall* just to one single
 * connection. It could be:
 *   - A single user connected multiple times (most likely due to tech issues, shouldn't happen often)
 *   - A coach (all coaches get to see private messages)
 *   - A support agent (all support agents get to see private messages)
 *
 * This function returns a list of all connection ID's we should send to for a given recipient user ID
 *
 * It will not include ourselves.
 *
 * @param {*} learnerId
 */
function* getPrivateMessageConnections(learnerId) {
  const connections = yield select(getConnectionIdsForUser, learnerId);
  const coachConnections = yield select(getConnectionIdsForUser, "coach");
  const supportConnections = yield select(getConnectionIdsForUser, "support");
  const myConnectionId = session.connection.id;
  // Use set to get only unique values
  return new Set([...connections, ...coachConnections, ...supportConnections].filter(con => con !== myConnectionId));
}

function* onPrivateChatSend(action) {
  if (yield interceptChatCommand(action)) {
    return;
  }
  const learnerId = action.payload.message.learnerUserId;
  const connections = yield getPrivateMessageConnections(learnerId);
  for (const connectionId of connections) {
    // A single user might have more than one connection, shouldn't really happen but it's possible.
    // especially in DEV when we do stupid things. Also, coaches and support should get this too.

    try {
      yield sendSignal(
        privateChatSignal(
          action.payload.message.displayName,
          action.payload.message.userId,
          action.payload.message.msg,
          action.payload.message.learnerUserId,
          action.payload.message.ts
        ),
        connectionId
      );
      yield put(logToEschool("Sent private chat to " + action.payload.message.learnerUserId));
    } catch (e) {
      // TODO - what should we do if a chat message fails to send?

      yield put(logMinorError(e, "Failed to send private chat message"));
    }
  }
}

function* interceptChatCommand(action) {
  if (action.payload.message.msg.length > 9 && action.payload.message.msg.substr(0, 8) === "/sticker") {
    yield sendSignal(stickerText(action.payload.message.msg.substr(9)));
    return true;
  }

  return false;
}

function* onSendSticker(action) {
  try {
    yield sendSignal(stickerSignal(action.payload.stickerIndex));
    yield sendSignal(
      chatSignal(
        action.payload.displayName,
        action.payload.userId,
        stickers[action.payload.stickerIndex].text.join(""),
        Math.floor(new Date().getTime() / 1000)
      )
    );
  } catch (e) {
    yield put(logMinorError(e, "Failed to send sticker signal"));
  }
  return true;
}

function* onChatSend(action) {
  if (yield interceptChatCommand(action)) {
    return;
  }
  try {
    yield sendSignal(
      chatSignal(
        action.payload.message.displayName,
        action.payload.message.userId,
        action.payload.message.msg,
        action.payload.message.ts
      )
    );
    yield put(logToEschool("Sent group chat"));
  } catch (e) {
    yield put(logMinorError(e, "Failed to send group chat signal"));
  }
}

function* onStreamDestroyed(event) {
  const stream = event.event.stream;
  const isUs = yield isOurConnection(stream.connection.id);
  if (isUs) return;

  const name = yield select(getPreferredNameForConnectionId, stream.connection.id);

  yield put(
    rtGroupEvent({
      displayName: name,
      eventType: GROUP_EVENT_TYPES.LEFT_SESSION,
      ts: Math.floor(new Date().getTime() / 1000)
    })
  );

  yield put(logToEschool("Stream destroyed: " + stream.connection.data));
}

function* onStreamCreated(event) {
  const stream = event.event.stream;
  const speaker = yield select(rtGetCurrentSpeaker);
  const user = yield select(getUserType);
  yield put(rtStreamAdded(stream));

  if (user !== USER_TYPES.LEARNER) {
    if (Beep.setSinkId && speaker !== "default") {
      Beep.setSinkId(speaker);
    }
    Beep.play().catch(err => console.warn("Could not play audio", err));
  }

  const name = (yield select(getPreferredNameForConnectionId, stream.connection.id)) || "";
  yield put(logToEschool(`Stream created: ${name} ${stream.connection.data}`));

  if (user === USER_TYPES.COACH) {
    if (!name && stream.connection.data.indexOf("student_id") === 0) {
      // oh no, something went wrong. We got a stream but we don't know the person's name. We should try
      // to get that if it's a student so we'll resend a welcome packet which should triger the learner
      // resending a learnerWelcome with the required info in it. But a lot might be happening right now, so
      // lets wait a few seconds first.
      console.warn("Stream connected, but we don't know who it is. Resending welcome.");
      yield delay(3000);
      yield sendWelcomeSignal(stream.connection.id);
    }
  }
}

function* sendFixedWelcomeSignal(userId, preferredName) {
  try {
    yield sendSignal(learnerWelcomeSignal(userId, preferredName));
  } catch (e) {
    yield put(logMinorError(e, "Failed to send support welcome signal"));
  }
}
function* sendSupportWelcomeSignal() {
  yield sendFixedWelcomeSignal("support", "Support");
}

function* sendInvisibleWelcomeSignal() {
  yield sendFixedWelcomeSignal("invisible", "");
}

function* sendLearnerWelcomeSignal() {
  const userId = (yield select(getLearnerGUID)) || "trainer";
  const preferredName = yield select(getLearnerPreferredName);
  try {
    yield sendSignal(learnerWelcomeSignal(userId, preferredName));
  } catch (e) {
    yield put(logMinorError(e, "Failed to send learnerWelcome signal"));
  }
}

function* sendWelcomeSignal(connectionId = undefined) {
  const chatHistory = yield select(getGroupChatHistory);
  const slideIndex = yield select(getCurrentSlideIndex);
  const slide = yield select(getCurrentSlide);
  const topicResourceId = yield select(getTopicResourceId);
  const vocab = slide && slide.type === "vocabulary" ? yield select(getCoachAuthoredVocab) : [];

  try {
    yield sendSignal(
      welcomeSignal(chatHistory, slideIndex, slide ? slide.type : "none", topicResourceId, vocab),
      connectionId
    );
  } catch (e) {
    yield put(logMinorError(e, "Failed to send welcome signal"));
  }
}

function* onEndSession() {
  try {
    yield sendSignal(endSessionSignal());
  } catch (e) {
    yield put(logMinorError(e, "Failed to send end session signal"));
  }
  yield delay(1000);
  yield put(rtDisconnect());
}

// eslint-disable-next-line no-unused-vars
function* onExitSession(action) {
  yield disconnect(true);
}

function* onDisconnect(action) {
  const forceLearnersDisconnect = action && action.payload && action.payload.forceLearnersDisconnect;
  yield disconnect(forceLearnersDisconnect);
}

// We want to disconnect
function* disconnect(forceLearnersDisconnect = true) {
  if (!session) {
    return;
  }

  yield put(rtBeginDisconnecting());

  const userType = yield select(getUserType);

  if (userType === USER_TYPES.COACH && forceLearnersDisconnect) {
    for (const conn of session.connections.map(c => c)) {
      if (conn.id !== session.connection.id) {
        try {
          // eslint-disable-next-line no-loop-func
          yield new Promise((resolve, reject) => {
            session.forceDisconnect(conn, err => {
              if (err) {
                reject();
              } else {
                resolve();
                console.log(`${conn.id} force disconnected`);
              }
            });
          });
        } catch (e) {
          console.warn("Could not force disconnect", conn.id);
        }
      }
    }
  }

  console.log("Disconnecting session");
  yield put(logToEschool("Disconnecting from session"));
  session.disconnect();

  yield put(rtDisconnected());
}

// We have disconnected
function* onDisconnected() {}

function* isOurConnection(connectionId) {
  const session = yield select(rtGetSession);
  return session && connectionId === session.connection.id;
}

function* onWelcomeMessage(weSentIt, { chatHistory, currentSlide, topicResourceId }, event) {
  const userType = yield select(getUserType);
  if (!weSentIt) {
    yield put(rtWelcomeMessageReceived(chatHistory, currentSlide, topicResourceId, event.event.from.connectionId));

    switch (userType) {
      case USER_TYPES.LEARNER:
        const speaker = yield select(rtGetCurrentSpeaker);

        if (Beep.setSinkId && speaker !== "default") {
          Beep.setSinkId(speaker);
        }

        Beep.play().catch(err => console.warn("Could not play audio", err));
        yield sendLearnerWelcomeSignal();
        logSegment("join-session", "tutor-welcome", {}, false);
        break;
      case USER_TYPES.SUPPORT:
        yield sendSupportWelcomeSignal();
        break;
      case USER_TYPES.INVISIBLE:
        yield sendInvisibleWelcomeSignal();
        break;
      default:
        console.error("Unknown user type in onWelcomeMessage", userType);
        break;
    }
  }
}

// This handles sending a sharedStateSet to everyone when a new learner
// comes in so the other clients have things like learnerName and capabilities.
function* sendLearnerDataToOtherLearners(data, connectionId) {
  const userType = yield select(getUserType);
  if (userType !== USER_TYPES.COACH) return;
  yield sendSignal(sharedStateMerge(`contextData.CON${connectionId}`, data));
}

function* onLearnerReadySignal(event) {
  yield put(rtLearnerReadyReceived(event.event.from.connectionId));
}

function* onLearnerWelcome(data, event) {
  yield put(rtLearnerWelcomeReceive(data, event.event.from.connectionId));
  yield sendCurrentSharedState(event.event.from.connectionId);
  yield sendLearnerDataToOtherLearners(data, event.event.from.connectionId);
  const ourConnection = yield isOurConnection(event.event.from.connectionId);
  if (ourConnection) return;
  // eslint-disable-next-line no-unused-vars

  yield put(logToEschool("User Joined: " + JSON.stringify(data)));
  if (data.userId === "invisible") return;
  yield put(
    rtGroupEvent({
      displayName: data.preferredName,
      eventType: GROUP_EVENT_TYPES.JOINED_SESSION,
      ts: Math.floor(new Date().getTime() / 1000)
    })
  );
}

function* onSignal(event) {
  const data = JSON.parse(event.event.data);
  const weSentIt = yield isOurConnection(event.event.from.connectionId);
  yield put(rtSignalReceived(reconstructSignal(event.event), event.event.from.connectionId, new Date().getTime()));
  console.log(
    "Received TokboxSignal",
    event.event.type,
    event.event.from.connectionId,
    JSON.stringify(event.event.data).substr(0, 200)
  );
  if (!weSentIt) {
    yield put(logToEschool(`Received ${event.event.type}`));
  }
  switch (event.event.type) {
    case "signal:sticker":
      yield put(attendShowSticker(data));
      return;
    case "signal:goodbye":
      yield put(rtGoodbyeReceived());
      yield disconnect(true);
      return;
    case "signal:welcome":
      yield onWelcomeMessage(weSentIt, data, event);
      return;

    case "signal:privatechat":
      yield put(
        chatReceivePrivateMessage(
          data.displayName,
          data.userId,
          data.msg,
          event.event.from.connectionId,
          data.learnerId
        )
      );
      return;

    case "signal:stateset":
      weSentIt || (yield put(rtSignalStateSetReceived(data.key, data.value)));
      return;

    case "signal:stateappend":
      weSentIt || (yield put(rtSignalStateAppendReceived(data.key, data.value, data.isArray || false)));
      return;

    case "signal:statemerge":
      weSentIt || (yield put(rtSignalStateMergeReceived(data.key, data.value)));
      return;

    case "signal:chat":
      yield put(chatReceiveMessage(data.displayName, data.userId, data.msg, event.event.from.connectionId));
      return;

    case "signal:showslide":
      weSentIt || (yield put(sharedRemoteValueReceived("currentSlide", data)));
      return;
    case "signal:forcemute":
      const shouldMuteUs = yield isOurConnection(data.connectionId);
      shouldMuteUs && (yield put(rtRemoteUserForceMuted(data.muted, data.connectionId))); // If this signal targeted us, mute us
      yield put(rtRemoteUserMuted(data.muted, data.connectionId)); // If not, we should mark that user as muted or not
      return;
    case "signal:selfmute":
      weSentIt || (yield put(rtRemoteUserMuted(data.muted, event.event.from.connectionId)));
      return;
    case "signal:learnerReady":
      yield onLearnerReadySignal(event);
      return;
    case "signal:learnerWelcome":
      yield onLearnerWelcome(data, event);
      return;
    case "signal:sharedStateInit":
      if (!weSentIt) {
        yield handleSharedStateInit(data);
      }
      return;
    case "signal:exitSupportRoom":
      if (!weSentIt) {
        yield exitSupportRoom();
      }
      return;
    case "signal:supportRoom":
      if (!weSentIt) {
        yield goToSupportRoom(data);
      }
      return;
    case "signal:getDiagnostic":
      if (!weSentIt) {
        yield respondDiagnostics(event.event.from.connectionId);
      }
      return;
    case "signal:diagnosticResult":
      if (!weSentIt) {
        yield put(supportDiagnosticResultReceived(event.event.from.connectionId, data.info));
      }
      return;
    default:
      console.debug("Unknown signal", event.event);
  }
}

function* respondDiagnostics(connectionId) {
  const mic = yield select(rtGetCurrentMicData);
  const diagnosticInfo = {
    browser: window.navigator.userAgent,
    mic,
    window: {
      width: window.innerWidth,
      height: window.innerHeight
    },
    screen: {
      width: window.screen.width,
      height: window.screen.height
    }
  };
  yield sendSignal(diagnosticResult(diagnosticInfo), connectionId);
}

function* exitSupportRoom() {
  yield put(logToEschool("Exiting private support room"));
  yield put(rtRevertTokens());
  yield put(rtConnect());
}

function* goToSupportRoom({ api_key, audio_video_connection_id, token }) {
  yield put(logToEschool("Going to private support room"));
  yield put(rtSwitchTokens(api_key, token, audio_video_connection_id));
  yield put(rtConnect());
}

function* handleSharedStateInit(data) {
  yield put(rtReceiveSharedStateInit(data.version, data.number, data.totalPackets, data.packet));
  const complete = yield select(rtIsSharedSyncComplete);
  if (complete) {
    const allPacketData = yield select(rtSharedSycPacketData);
    try {
      const data = JSON.parse(allPacketData);
      yield put(rtSharedStateInit(data));
      const coachName = yield select(findTutorName);
      const previousEvents = yield select(getGroupEventHistory);
      if (previousEvents.length < 2) {
        yield put(
          rtGroupEvent({
            displayName: coachName,
            eventType: GROUP_EVENT_TYPES.STARTED_SESSION,
            ts: Math.floor(new Date().getTime() / 1000)
          })
        );
      } else {
        yield put(
          rtGroupEvent({
            displayName: coachName,
            eventType: GROUP_EVENT_TYPES.JOINED_SESSION,
            ts: Math.floor(new Date().getTime() / 1000)
          })
        );
      }
    } catch (e) {
      // TODO - what do we do now?
    }
  }
}

function* onConnectionDestroyed(connection) {
  const streams = yield select(rtGetAllStreams);
  const connectionStreams = streams.filter(stream => stream.connection.id === connection.id);
  const actions = connectionStreams.map(stream => put(rtStreamRemoved(stream.id)));
  yield put(rtDestroyed(connection));
  yield all(actions);
}

function* handleOneSessionChannelEvent(event) {
  try {
    const conData =
      (event && event.event && event.event.connection && event.event.connection.data) ||
      (event &&
        event.event &&
        event.event.stream &&
        event.event.stream.connection &&
        event.event.stream.connection.data) ||
      "";

    event.type !== "signal" && (yield put(logToEschool(`RT Event: ${event.type} ${conData}`)));
  } catch (e) {
    console.warn("Could not log to eschool", e);
  }
  switch (event.type) {
    case SESSION_DISCONNECTED:
      yield put(rtDisconnected());
      break;
    case SESSION_RECONNECTED:
      yield put(rtReconnected());
      break;
    case SESSION_RECONNECTING:
      yield put(rtReconnecting());
      break;
    case CONNECTION_CREATED:
      yield onConnected(session, event);
      break;
    case CONNECTION_DESTROYED:
      yield onConnectionDestroyed(event.event.connection);
      break;
    case ARCHIVE_STARTED:
      yield put(rtArchiveStarted(event.event.archive.id));
      break;
    case STREAM_DESTROYED:
      yield onStreamDestroyed(event);
      break;
    case STREAM_CREATED:
      yield onStreamCreated(event);
      break;
    case SIGNAL:
      yield onSignal(event);
      break;
    default:
      console.warn("Did not know what to do with session event", event);
      break;
  }
}

function* sessionEvents(channel) {
  try {
    while (true) {
      const event = yield take(channel);
      yield fork(handleOneSessionChannelEvent, event);
    }
  } catch (e) {
    yield put(logToEschool("Error occurred in session loop. " + e.toString()));
    yield logMinorError(e, "Tokbox Session Loop Exited");
  } finally {
    // take(END) will cause the saga to terminate by jumping to the finally block
    yield put(logToEschool("Tokbox Session Completed"));
  }
}

/**
 * The client has requested to connect to the realtime service. This saga will perform that connection,
 * then listen to events from that session.
 *
 * You can pass in a key/session/token to force it to use those, otherwise we use the values from the store.
 *
 **/
function* onConnect() {
  startInteraction("join-session");
  if ((yield select(rtGetApiKey)) === null) {
    console.log("Waiting for keys");
    yield take(RT_RECIEVE_TOKENS);
  }

  yield put(logToEschool("Connecting to session."));

  if (session) {
    // Disconnect any existing session first.
    session.disconnect();
    yield delay(5000);
  }

  const apiKey = yield select(rtGetApiKey);
  const sessionId = yield select(rtGetSessionId);
  const sessionToken = yield select(rtGetSessionToken);

  console.info("Creating OT session", sessionId, apiKey, sessionToken);
  session = OT.initSession(apiKey, sessionId);

  console.info("Connecting");
  const channel = createSessionChannel(session);
  const task = yield fork(sessionEvents, channel);

  try {
    yield new Promise((resolve, reject) => {
      session.connect(sessionToken, err => {
        if (err) {
          reject(err);
        } else {
          logSegment("join-session", "ot-connect");
          resolve();
        }
      });
    });
  } catch (e) {
    yield put(rtDisconnected());
    yield put(appError(e, "err_can_not_connect"));
    return;
  }

  const eschoolSessionId = (yield select(getAttendSessionId)) || (yield select(getESchoolSessionId));

  yield put(logToEschool(`Connected to session ${eschoolSessionId}`));
  yield task.done; // wait for session loop to finish
  yield put(logToEschool("Connection completed"));
  console.log("onConnect: session loop completed.");
}

export function* shouldActAsCoach() {
  const isCoach = (yield select(getUserType)) === USER_TYPES.COACH;
  const isSupport = (yield select(getUserType)) === USER_TYPES.SUPPORT;
  const isSupportSession = !!(yield select(getCurrentPrivateSupportSession));
  return isCoach || (isSupportSession && isSupport);
}

function* onConnected(session, event) {
  const shouldSendWelcome = yield shouldActAsCoach();
  const weConnected = event.event.connection.id === session.connection.id;
  if (!weConnected) {
    // This event means SOMEONE ELSE connected and we should remember their connection with their connection ID
    remoteConnections[event.event.connection.id] = event.event.connection;
  }

  yield put(rtConnected(session, event.event.connection, weConnected));

  if (shouldSendWelcome) {
    if (!weConnected) {
      // If someone else is connecting, we can send the welcome to just them.
      yield sendWelcomeSignal(event.event.connection.id);
    }
    recordUserStartEvent();
  }
}

function* onNextVideo() {
  const publisher = yield select(rtGetPublisher);
  if (!publisher) {
    console.warn("Tried to set mic, but no publisher has been created yet.");
    return;
  }
  try {
    yield publisher.cycleVideo();
    console.info("Video cycled.");
  } catch (e) {
    console.warn("Could not cycle video.");
  }
}

let previousStats = null;

function* reportStats(publisherStats, subscriberStats) {
  if (publisherStats.length === 0) {
    return;
  }
  if (!publisherStats[0].stats || !subscriberStats) {
    return;
  }
  try {
    const flatPublisher = flatten(publisherStats[0].stats);
    const flatSubscriber = flatten(subscriberStats);

    flatPublisher.audio_packetsSentLost = flatPublisher.audio_packetsLost;
    flatPublisher.video_packetsSentLost = flatPublisher.video_packetsLost;
    delete flatPublisher.audio_packetsLost;
    delete flatPublisher.video_packetsLost;

    flatSubscriber.audio_packetsReceivedLost = flatSubscriber.audio_packetsLost;
    flatSubscriber.video_packetsReceivedLost = flatSubscriber.video_packetsLost;
    delete flatSubscriber.audio_packetsLost;
    delete flatSubscriber.video_packetsLost;

    const flatStats = { ...flatSubscriber, ...flatPublisher };

    const timeDelta = previousStats ? flatStats.timestamp - previousStats.timestamp : 0;
    const diff = statsDiff(previousStats, flatStats);
    previousStats = flatStats;

    const apiKey = yield select(getESchoolApiKey);
    const baseUrl = yield select(getESchoolAPIBase);
    const sessionId = yield select(rtGetSessionId);
    const stats = {
      ...diff,
      timestamp_delta: timeDelta,
      sessionId
    };
    yield reportConnectionStats(apiKey, baseUrl, stats);
  } catch (e) {
    console.log(e);
    yield put(logMinorError(e, "Could not log stats", publisherStats));
  }
}

function* connectionStatsLoop() {
  try {
    while (true) {
      yield delay(3000); // Update our stats every 3 seonds
      const publisher = yield select(rtGetPublisher);
      const subscriberNode = yield select(rtGetSubscriber);
      if (!subscriberNode) return;
      const subscriber = subscriberNode.getSubscriber();

      if (!subscriber) {
        console.warn("No subscriber");
        continue;
      }
      if (!publisher) {
        console.warn("No publisher");
        continue;
      }

      const publisherPromise = new Promise((resolve, reject) => {
        publisher.getStats((err, statsArray) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(statsArray);
        });
      });

      const subscriberPromise = new Promise((resolve, reject) => {
        subscriber.getStats((err, statsArray) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(statsArray);
        });
      });

      const [publisherStats, subscriberStats] = yield all([publisherPromise, subscriberPromise]);

      yield reportStats(publisherStats, subscriberStats);

      const statsByConnection = publisherStats.reduce((acc, curr) => {
        acc[curr.connectionId] = curr.stats;
        return acc;
      }, {});

      yield put(rtConnectionStatsUpdated(statsByConnection));
    }
  } catch (e) {
    console.log("Connection loop terminated.", e);
  }
}

function* onEjectUser(action) {
  try {
    yield sendSignal(endSessionSignal(), action.payload.connectionId);
  } catch (e) {
    yield put(logMinorError(e, "Failed to send eject signal"));
  }

  const connection = remoteConnections[action.payload.connectionId];
  if (connection) {
    try {
      yield new Promise((resolve, reject) =>
        session.forceDisconnect(connection, error => {
          if (error) {
            reject(error);
          } else {
            resolve();
          }
        })
      );
    } catch (e) {
      console.log("Could not eject user", e);
    }
  }
}
function* onCoachUnMute(action) {
  try {
    yield sendSignal(forceMute(action.payload.connectionId, false));
  } catch (e) {
    yield put(logMinorError(e, "Failed to send forceUnMute signal"));
  }
}

function* onCoachMute(action) {
  try {
    yield sendSignal(forceMute(action.payload.connectionId, true));
  } catch (e) {
    yield put(logMinorError(e, "Failed to send forceMute signal"));
  }
}

function* qualityTest(video) {
  const { apiKey, sessionId, token } = yield testSessionCredentials();

  const otNetworkTest = new NetworkTest(
    window.OT,
    {
      apiKey,
      sessionId,
      token
    },
    {
      timeout: 20000,
      audioOnly: !video
    }
  );

  let percent = 15;
  // yield put(rtSelfTestStatus(percent));
  try {
    const channel = createTestChannel(otNetworkTest);
    while (true) {
      const event = yield take(channel);
      switch (event.type) {
        case "progress":
          console.log("Progress", event.r);
          percent = Math.min(95, percent + 6);
          yield put(rtSelfTestStatus(percent));
          break;
        case "complete":
          yield put(logToEschool("Quality Results " + JSON.stringify(event.results)));
          if (!video) {
            event.results.video.mos = 5;
          }
          console.log("Quality results a/v mos score:", event.results.audio.mos, event.results.video.mos);
          yield put(rtQualityTestCompleted(event.results));
          break;
        case "error":
          yield put(logToEschool("Quality test failed " + event.err));
          yield put(rtQualityTestFailed(event.err));
          break;
        default:
          console.warn("Unknown test event ", event.type);
          break;
      }
    }
  } finally {
    console.log("Quality test complete");
  }
}

function* testSessionCredentials() {
  const userType = yield select(getUserType);
  if (userType === USER_TYPES.LEARNER) {
    return yield learnerTestSessionCredentials();
  }
  return yield coachTestSessionCredentials();
}

function* learnerTestSessionCredentials() {
  const lcpServerApiUrl = yield select(getLcpAPIUrl);
  const studio = new Studio(lcpServerApiUrl);
  const result = yield studio.getAudioVideoConnectionInfoForNetworkTestSession();

  return {
    sessionId: result["audio_video_connection_id"],
    token: result["token"],
    apiKey: result["api_key"]
  };
}

function* coachTestSessionCredentials() {
  const eSchool = yield select(getESchoolAPIBase);
  const apiKey = yield select(getESchoolApiKey);
  let connection = null;

  try {
    connection = yield getTestConnectionInfo(eSchool, apiKey);
  } catch (e) {
    throw e;
  }

  return {
    apiKey: connection.api_key,
    sessionId: connection.audio_video_connection_id,
    token: connection.token
  };
}

function* connectionTest(video = true) {
  const { apiKey, sessionId, token } = yield testSessionCredentials();

  const otNetworkTest = new NetworkTest(
    window.OT,
    {
      apiKey,
      sessionId,
      token
    },
    {
      audioOnly: !video
    }
  );

  const devices = yield new Promise(resolve => {
    OT.getDevices((err, results) => resolve({ err, results }));
  });

  const results = yield otNetworkTest.testConnectivity();

  yield put(rtSelfTestStatus(3));
  let hasAudioDevice = devices.results.filter(device => device.kind === "audioInput").length > 0;
  let hasVideoDevice = devices.results.filter(device => device.kind === "videoInput").length > 0;

  for (const result of results.failedTests) {
    switch (result.error.name) {
      case ErrorNames.FAILED_TO_CREATE_LOCAL_PUBLISHER:
        hasAudioDevice = false;
        hasVideoDevice = false;
        break;
      case ErrorNames.NO_AUDIO_CAPTURE_DEVICES:
        hasAudioDevice = false;
        break;
      case ErrorNames.NO_VIDEO_CAPTURE_DEVICES:
        hasVideoDevice = false;
        break;
      default:
        break;
    }
  }
  yield put(rtSelfTestStatus(15));
  console.log("connectivity", results);
  yield put(
    logToEschool(
      `Connetion Test hasAudioDevice:${hasAudioDevice}, hasVideoDevice:${hasVideoDevice}, fails:${results.failedTests}`
    )
  );
  yield put(rtConnectivityTestCompleted(hasAudioDevice, hasVideoDevice, results.failedTests));
}

function* onSelfTest(action) {
  yield put(logToEschool("Started connection test"));
  try {
    yield connectionTest(action.payload.video);
  } catch (e) {
    yield put(logToEschool("Could not run connection test: " + e));
    yield put(
      rtConnectivityTestCompleted(false, false, [{ error: { message: "Could not run connection test: " + e } }])
    );
  }

  try {
    yield qualityTest(action.payload.video);
  } catch (e) {
    yield put(logToEschool("Could not run quality test: " + e.toString()));
    yield put(
      rtConnectivityTestCompleted(false, false, [{ error: { message: "Could not run quality test: " + e.toString() } }])
    );
    yield put(rtQualityTestCompleted({}));
  }
}

function* saveMicPref(action) {
  yield window.localStorage.setItem("mic", action.payload.mic);
}
function* saveCameraPref(action) {
  yield window.localStorage.setItem("camera", action.payload.camera);
}

function* onWhiteboardToolUpdate(action) {
  const { key, tool } = action.payload;
  const memKey = `sharedData.whiteboard.${key}`;
  const pointsKey = `sharedData.whiteboard.${key}.points`;
  try {
    if (isPenTool(tool.type)) {
      // Pen tools might have a lot of data that exceeds the packet size. So, we're going to split them up into 100 points at
      // a time. First send the overall tool definition, then send the points data.
      yield sendSignal(sharedStateSet(memKey, { ...tool, points: [] }));
      if (tool.points) {
        for (let x = 0; x < tool.points.length; x += 100) {
          const points = tool.points.slice(x, x + 100);
          yield sendSignal(sharedStateAppend(pointsKey, points, true));
        }
      }
    } else {
      yield sendSignal(sharedStateSet(memKey, tool));
    }
  } catch (e) {
    yield put(logMinorError(e, "Failed to send whiteboard signal"));
  }
}
function* onWhiteboardToolReset() {
  try {
    yield sendSignal(sharedStateSet("sharedData.whiteboard", {}));
  } catch (e) {
    yield put(logMinorError(e, "Failed to send whiteboard reset signal"));
  }
}

function* onWhiteboardGrantAccess(action) {
  yield put(sharedRemoteValueReceived("whiteboardControl", true, "CON" + action.payload.connectionId));
  try {
    yield sendSignal(sharedStateSet(`contextData.CON${action.payload.connectionId}.whiteboardControl`, true));
  } catch (e) {
    yield put(logMinorError(e, "Failed to send whiteboard access signal"));
  }
}

function* onWhiteboardRevokeAccess(action) {
  yield put(sharedRemoteValueReceived("whiteboardControl", false, "CON" + action.payload.connectionId));
  try {
    yield sendSignal(sharedStateSet(`contextData.CON${action.payload.connectionId}.whiteboardControl`, false));
  } catch (e) {
    yield put(logMinorError(e, "Failed to send whiteboard revoke access signal"));
  }
}

function* onLocalSharedValueAppended(action) {
  const isCoach = yield shouldActAsCoach();
  if (!isCoach) return;
  try {
    const key = action.payload.context
      ? `contextData.${action.payload.context}.${action.payload.key}`
      : `sharedData.${action.payload.key}`;

    yield sendSignal(sharedStateAppend(key, action.payload.value));
  } catch (e) {
    yield put(logMinorError(e, "Could not send shared value"));
  }
}
function* onLocalSharedValueReceived(action) {
  const isCoach = yield shouldActAsCoach();
  if (!isCoach) return;
  try {
    const key = action.payload.context
      ? `contextData.${action.payload.context}.${action.payload.key}`
      : `sharedData.${action.payload.key}`;

    yield sendSignal(sharedStateSet(key, action.payload.value));
  } catch (e) {
    yield put(logMinorError(e, "Could not send shared value"));
  }
}

function* onLearnerReady() {
  const isCoach = yield shouldActAsCoach();
  if (isCoach) return;
  yield sendSignal(learnerReady());
}

export function* realtimeSagas() {
  yield takeEvery(RT_IS_READY, onLearnerReady);
  yield takeEvery(RT_CAMERA_SELECTED, saveCameraPref);
  yield takeEvery(RT_MIC_SELECTED, saveMicPref);
  yield takeEvery(RT_END_SESSION, onEndSession);
  yield takeEvery(CHAT_SEND_MESSAGE, onChatSend);
  yield takeEvery(CHAT_SEND_PRIVATE_MESSAGE, onPrivateChatSend);
  yield takeEvery(RT_CONNECT, onConnect);

  // A learner has explicitly tried to leave the session.
  yield takeEvery(EXIT_SESSION, onExitSession);
  yield takeEvery(RT_DISCONNECT, onDisconnect);

  yield takeEvery(RT_DISCONNECTED, onDisconnected);
  yield takeEvery(TOPIC_SHOW_SLIDE, onSlideChange);

  yield takeEvery(COACH_UNMUTE, onCoachUnMute);
  yield takeEvery(COACH_MUTE, onCoachMute);
  yield takeEvery(COACH_EJECT_USER, onEjectUser);

  yield takeEvery(SEND_STICKER, onSendSticker);
  // This one loops to poll for conneciton status, we only want it running once at a time
  yield takeLatest(RT_CONNECTED, connectionStatsLoop);

  yield takeEvery(RT_NEXT_VIDEO, onNextVideo);
  yield takeLatest(RT_START_SELF_TEST, onSelfTest);

  yield takeLatest(TOGGLE_MUTE, onToggleMute);

  yield takeLatest(RESET_TOOLS, onWhiteboardToolReset);
  yield takeLatest(UPDATE_TOOL, onWhiteboardToolUpdate);

  yield takeLatest(GRANT_WHITEBOARD_ACCESS, onWhiteboardGrantAccess);
  yield takeLatest(REVOKE_WHITEBOARD_ACCESS, onWhiteboardRevokeAccess);

  yield takeEvery(SHARED_LOCAL_VALUE_APPENDED, onLocalSharedValueAppended);
  yield takeEvery(SHARED_LOCAL_VALUE_RECEIVED, onLocalSharedValueReceived);

  // Note: The VideoPanel handles the mic being changed since we need to recreate the publisher.
}
