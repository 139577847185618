import React from "react";
import { LionTranslate } from "@rosetta/react-lion";
import { Title, Instructions } from "./presession-styles";
import PersonInCircleIcon from "../../icons/PersonInCircleIcon";

export const GrantAccess = () => (
  <React.Fragment>
    <PersonInCircleIcon />
    <Title>
      <LionTranslate lionkey="grant_access_header" />
    </Title>
    <Instructions>
      <LionTranslate lionkey="system_check_permissions_instructions" />
    </Instructions>
  </React.Fragment>
);

export default GrantAccess;
