import React from "react";

const Medium = <path d="M.5 8h6v14a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2V8z" fill="#F0C433" />;

const High = (
  <path d="M0.5 16H6.5V22C6.5 23.1046 5.60457 24 4.5 24H2.5C1.39543 24 0.5 23.1046 0.5 22V16Z" fill="#CC5555" />
);

const Low = <path d="M.5 2a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v20a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2V2z" fill="#0CB040" />;

const BandwidthMeterIcon = bar => ({ props }) => (
  <svg width={7} height={24} fill="none" {...props}>
    <rect x="0.5" width={6} height={24} rx={2} fill="#F1F1F1" />
    {bar}
  </svg>
);

export const MediumBandwidthMeterIcon = BandwidthMeterIcon(Medium);
export const HighBandwidthMeterIcon = BandwidthMeterIcon(Low);
export const LowBandwidthMeterIcon = BandwidthMeterIcon(High);
