import { fork } from "redux-saga/effects";

import { appSagas } from "./modules/app/sagas/sagas";
import { realtimeSagas } from "./modules/realtime/realtime-sagas";
import { topicSagas } from "./modules/topic/topic-sagas";
import { vocabSagas } from "./modules/coach/coach-sagas";
import { archiveSagas } from "./modules/archive/archive-sagas";
import { errorloggingSagas } from "./modules/errorlogging/errorlogging-saga";
import { schedulerSagas } from "./modules/learner/scheduler/scheduler-sagas";
import { attendanceSagas } from "./modules/learner/attend/attendance-sagas";
import { sharedStateSagas } from "./modules/sharedstate/sharedstate-sagas";
import { supportSagas } from "./modules/support/support-sagas";
import { rstvSagas } from "./modules/rstv/rstv-sagas";
import { sreCreateSaga } from "@rosetta/react-sre";
import { LOCATION_CHANGE } from "connected-react-router";
import { getVoiceType, getSoundVolume, getSoundInputDevice } from "./modules/app/reducer/reducer";

const selectors = {
  getCurrentProductLanguage: () => "es-419", // TODO - when we support other languages, we need to update this
  getSpeechDifficulty: () => 3, // We don't have a prefs dialog to change this, so I'm just going to keep it here in RSTV

  getSpeechVoiceType: getVoiceType,
  getCurrentMicrophone: getSoundInputDevice,
  getSoundVolume: getSoundVolume
};

const cancelListenActions = ["EXIT_APP", "RSTV_CLOSE_SRE", LOCATION_CHANGE];

export function* rootSaga() {
  yield fork(sreCreateSaga(selectors, cancelListenActions));
  yield fork(appSagas);
  yield fork(realtimeSagas);
  yield fork(topicSagas);
  yield fork(vocabSagas);
  yield fork(archiveSagas);
  yield fork(errorloggingSagas);
  yield fork(schedulerSagas);
  yield fork(attendanceSagas);
  yield fork(sharedStateSagas);
  yield fork(supportSagas);
  yield fork(rstvSagas);
}
