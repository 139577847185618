import { extractPath } from "@rosetta/redux-utils";
import memoizeOne from "memoize-one";

/* Connection Lifecycle:



+--------------+            +-------------              +-----------+                 +--------------+
|              | RT_CONNECT |            | RT_CONNECTED |           | RT_DISCONNECTED |              |
| DISCONNECTED +----------->+ CONNECTING +------------->+ CONNECTED +---------------->+ DISCONNECTED |
|              |            |            |              |           |                 |              |
+--------------+            +-------------              +-----------+                 +--------------+


*/

export const GROUP_EVENT_TYPES = {
  JOINED_SESSION: 0,
  LEFT_SESSION: 1,
  STARTED_SESSION: 2
};

export const CONNECTION_STATE = {
  DISCONNECTED: 0,
  CONNECTING: 1,
  CONNECTED: 2,
  DISCONNECTING: 3
};

export const SELF_TEST_STATE = {
  CLOSED: 0,
  OPENED: 1,
  RUNNING: 2,
  COMPLETE: 3
};

const realTimeReducerInitialState = {
  signalDebugger: false,
  signalLog: [],

  testPercent: 0,
  apiKey: null,
  sessionId: null,
  sessionToken: null,
  connectionState: CONNECTION_STATE.DISCONNECTED,
  session: null,
  streams: [],
  connections: [],

  groupEvents: [],

  publisher: null,

  micList: [{ deviceId: "default", label: "Default" }],
  currentMic: window.localStorage.getItem("mic") || "default",

  cameraList: [{ deviceId: "default", label: "Default" }],
  currentCamera: window.localStorage.getItem("camera") || "default",

  currentSpeaker: "default",

  reconnecting: false,
  broadcast: true,

  micAccess: null,
  cameraAccess: null,
  failedConnectivityTests: [],
  subscriber: null,

  selfTestState: SELF_TEST_STATE.CLOSED,

  sharedStateVersion: -1,
  sharedStatePackets: null,
  sharedStateTotalPackets: 1,

  savedTokens: null, // When we switch to a set of temporary tokens for a private support session
  // we save the original ones here so we can revert back after it's done.
  sessionReady: false
};

const RT_GROUP_EVENT = "RT_GROUP_EVENT";
export const RT_IS_READY = "RT_IS_READY";
export const RT_ENABLE_SIGNAL_LOG = "RT_ENABLE_SIGNAL_LOG";
export const RT_DISABLE_SIGNAL_LOG = "RT_DISABLE_SIGNAL_LOG";

export const RT_SWITCH_TOKENS = "RT_SWITCH_TOKENS";
export const RT_REVERT_TOKENS = "RT_REVERT_TOKENS";

export const RT_RECEIVE_SHARED_STATE_INIT = "RT_RECEIVE_SHARED_STATE_INIT";
export const RT_ARCHIVE_STARTED = "ARCHIVE_STARTED";
export const RT_RECIEVE_TOKENS = "RT_RECIEVE_TOKENS";
export const RT_CONNECTED = "RT_CONNECTED";
export const RT_CONNECT = "RT_CONNECT";
export const RT_DISCONNECTED = "RT_DISCONNECTED";
export const RT_DISCONNECT = "RT_DISCONNECT";

export const RT_SIGNAL_GOODBYE_RECEIVED = "RT_SIGNAL_GOODBYE_RECEIVED";

export const RT_SIGNAL_STATE_SET_RECEIVED = "RT_SIGNAL_STATE_SET_RECEIVED";
export const RT_SIGNAL_STATE_APPEND_RECEIVED = "RT_SIGNAL_STATE_APPEND_RECEIVED";
export const RT_SIGNAL_STATE_MERGE_RECEIVED = "RT_SIGNAL_STATE_MERGE_RECEIVED";

export const RT_STREAM_ADDED = "RT_STREAM_ADDED";
export const RT_WELCOME_MESSAGE_RECEIVE = "RT_WELCOME_MESSAGE_RECEIVE";
export const RT_STREAM_REMOVED = "RT_STREAM_REMOVED";
export const RT_NEW_SUBSCRIBER = "RT_NEW_SUBSCRIBER";

export const RT_PUBLISHER_CREATED = "RT_PUBLISHER_CREATED";

export const RT_SPEAKER_SELECTED = "RT_SPEAKER_SELECTED"; // TODO - implement
export const RT_CAMERA_LIST_UPDATED = "RT_CAMERA_LIST_UPDATED";
export const RT_CAMERA_SELECTED = "RT_CAMERA_SELECTED";
export const RT_MIC_LIST_UPDATED = "RT_MIC_LIST_UPDATED";
export const RT_MIC_SELECTED = "RT_MIC_SELECTED";
export const RT_NEXT_VIDEO = "RT_NEXT_VIDEO";

export const RT_STATS_UPDATED = "RT_STATS_UPDATED";

export const RT_RECONNECTING = "RT_RECONNECTING";
export const RT_RECONNECTED = "RT_RECONNECTED";

export const RT_BROADCAST = "RT_BROADCAST";
export const RT_STOP_BROADCAST = "RT_STOP_BROADCAST";

export const RT_OPEN_SELF_TEST = "RT_OPEN_SELF_TEST";
export const RT_CLOSE_SELF_TEST = "RT_CLOSE_SELF_TEST";
export const RT_START_SELF_TEST = "RT_START_SELF_TEST";
export const RT_QUALITY_TEST_COMPLETED = "RT_QUALITY_TEST_COMPLETED";
export const RT_CONNECTIVITY_TEST_COMPLETE = "RT_CONNECTIVITY_TEST_COMPLETE";

export const RT_SELF_TEST_STATUS = "RT_SELF_TEST_STATUS";
export const RT_QUALITY_TEST_FAILED = "RT_QUALITY_TEST_FAILED";
export const RT_END_SESSION = "RT_END_SESSION";

export const RT_REMOTE_USER_MUTED = "RT_REMOTE_USER_MUTED";
export const RT_REMOTE_FORCE_MUTE = "RT_REMOTE_FORCE_MUTE";

export const RT_BEGIN_DISCONNECTING = "RT_BEGIN_DISCONNECTING";
export const RT_SIGNAL_RECEIVED = "RT_SIGNAL_RECEIVED";
export const RT_SIGNAL_SEND = "RT_SIGNAL_SEND";
export const RT_SHARED_STATE_INIT = "RT_SHARED_STATE_INIT";
export const RT_CONNECTION_DESTROYED = "RT_CONNECTION_DESTROYED";

export const RT_LEARNER_WELCOME_RECEIVE = "RT_LEARNER_WELCOME_RECEIVE";
export const RT_LEARNER_READY_RECEIVE = "RT_LEARNER_READY_RECEIVE";

const handleSharedStateInit = (state, action) => {
  if (action.payload.version < state.sharedStateVersion) {
    // This is for an old version of the shared state that we do not care about anymore.
    // This can happen if two snapshots are somehow interleaved across multiple packets.
    return state;
  }

  let packets =
    action.payload.version > state.sharedStateVersion
      ? new Array(action.payload.total).fill(null) // This is a new version, so re-initialize our packets.
      : state.sharedStatePackets; // This was the previous state that we were building up

  packets = packets.map((p, idx) => {
    if (idx === action.payload.number - 1) {
      return action.payload.packet; // Replace this new packet in our array of (potentially unordered) packets.
    }
    return p;
  });

  return {
    ...state,
    sharedStateVersion: action.payload.version,
    sharedStateTotalPackets: action.payload.totalPackets,
    sharedStatePackets: packets
  };
};

export const realTimeReducer = (state = realTimeReducerInitialState, action) => {
  switch (action && action.type) {
    case RT_IS_READY:
      return { ...state, sessionReady: true };
    case RT_ENABLE_SIGNAL_LOG:
      return { ...state, signalDebugger: true };
    case RT_DISABLE_SIGNAL_LOG:
      return { ...state, signalDebugger: false };
    case RT_SIGNAL_RECEIVED:
    case RT_SIGNAL_SEND:
      if (!state.signalDebugger) return state;
      return {
        ...state,
        signalLog: [
          ...state.signalLog,
          {
            ...action.payload,
            broadcast: action.type === RT_SIGNAL_RECEIVED
          }
        ]
      };
    case RT_BEGIN_DISCONNECTING:
      return {
        ...state,
        connectionState: CONNECTION_STATE.DISCONNECTING
      };
    case RT_SHARED_STATE_INIT:
      return {
        ...state,
        sharedStateTotalPackets: 0,
        sharedStatePackets: null,
        sharedStateVersion: -1
      };
    case RT_RECEIVE_SHARED_STATE_INIT:
      return handleSharedStateInit(state, action);
    case RT_NEW_SUBSCRIBER:
      return { ...state, subscriber: action.payload.subscriber };
    case RT_SELF_TEST_STATUS:
      return {
        ...state,
        testPercent: action.payload.percent
      };
    case RT_CONNECTIVITY_TEST_COMPLETE:
      return {
        ...state,
        micAccess: action.payload.hasAudioDevice,
        cameraAccess: action.payload.hasVideoDevice,
        failedConnectivityTests: action.payload.failedTests.map(failure => failure.error)
      };
    case RT_CLOSE_SELF_TEST:
      return {
        ...state,
        selfTestState: SELF_TEST_STATE.CLOSED
      };
    case RT_OPEN_SELF_TEST:
      return {
        ...state,
        selfTestState: SELF_TEST_STATE.OPENED
      };
    case RT_START_SELF_TEST:
      return {
        ...state,
        testPercent: 0,
        selfTestState: SELF_TEST_STATE.RUNNING,
        micAccess: null,
        cameraAccess: null,
        failedConnectivityTests: [],
        stats: null
      };

    case RT_GROUP_EVENT:
      return {
        ...state,
        groupEvents: [...state.groupEvents, action.payload.event]
      };
    case RT_QUALITY_TEST_FAILED:
      return {
        ...state,
        testPercent: 100,
        failedConnectivityTests: [...state.failedConnectivityTests, action.payload.error],
        selfTestState: SELF_TEST_STATE.COMPLETE
      };
    case RT_QUALITY_TEST_COMPLETED:
      return {
        ...state,
        testPercent: 100,
        stats: action.payload.stats,
        selfTestState: SELF_TEST_STATE.COMPLETE
      };

    case RT_BROADCAST:
      return { ...state, broadcast: true };
    case RT_STOP_BROADCAST:
      return { ...state, broadcast: false };
    case RT_RECONNECTING:
      return { ...state, reconnecting: true };
    case RT_RECONNECTED:
      return { ...state, reconnecting: false };
    case RT_STATS_UPDATED:
      return { ...state, stats: action.payload.stats };
    case RT_PUBLISHER_CREATED:
      return {
        ...state,
        publisher: action.payload.publisher
      };
    case RT_CAMERA_SELECTED:
      return {
        ...state,
        currentCamera: action.payload.camera
      };
    case RT_SPEAKER_SELECTED:
      return {
        ...state,
        currentSpeaker: action.payload.speaker
      };
    case RT_MIC_SELECTED:
      return {
        ...state,
        currentMic: action.payload.mic
      };
    case RT_CAMERA_LIST_UPDATED:
      return {
        ...state,
        cameraList: action.payload.cameraList
      };
    case RT_MIC_LIST_UPDATED:
      return {
        ...state,
        micList: action.payload.micList
      };
    case RT_STREAM_ADDED:
      return {
        ...state,
        streams: [...state.streams, action.payload.stream]
      };
    case RT_STREAM_REMOVED:
      return {
        ...state,
        streams: state.streams.filter(stream => stream.id !== action.payload.streamId)
      };
    case RT_DISCONNECTED:
      return {
        ...state,
        connectionState: CONNECTION_STATE.DISCONNECTED,
        session: null,
        streams: [],
        connections: [],
        publisher: null
      };
    case RT_CONNECTION_DESTROYED:
      return {
        ...state,

        connections: state.connections.filter(conn => conn.id !== action.payload.connection.id)
      };
    case RT_CONNECTED:
      return {
        ...state,
        connectionState: CONNECTION_STATE.CONNECTED,
        reconnecting: false,
        session: action.payload.session,
        connections: [...state.connections, action.payload.connection]
      };
    case RT_CONNECT:
      return {
        ...state,
        sessionReady: false,
        connectionState: CONNECTION_STATE.CONNECTING,
        groupEvents: []
      };

    case RT_SWITCH_TOKENS:
      return {
        ...state,
        savedTokens: {
          apiKey: state.apiKey,
          sessionToken: state.sessionToken,
          sessionId: state.sessionId
        },
        connectionState: CONNECTION_STATE.DISCONNECTED,
        ...action.payload
      };
    case RT_REVERT_TOKENS:
      return {
        ...state,
        ...state.savedTokens,
        savedTokens: null
      };
    case RT_RECIEVE_TOKENS:
      return {
        ...state,
        ...action.payload,
        selfTestState: SELF_TEST_STATE.OPENED
      };
    default:
      return state;
  }
};

// Selectors:
export const rtGetInitialConnectionStatus = state => [
  state.realtime.connectionState === CONNECTION_STATE.CONNECTED,
  !!state.realtime.publisher
];
export const rtGetSelfTestPercent = state => state.realtime.testPercent;
export const rtGetFailedConnectivityTests = state => state.realtime.failedConnectivityTests;
export const rtCanAccessMic = state => state.realtime.micAccess;
export const rtCanAccessCamera = state => state.realtime.cameraAccess;
export const rtAudioMosScore = state => extractPath(state, "realtime.stats.audio.mos", undefined);
export const rtVideoMosScore = state => extractPath(state, "realtime.stats.video.mos", undefined);
export const rtIsAudioSupported = state => extractPath(state, "realtime.stats.audio.supported", undefined);
export const rtIsVideoSupported = state => extractPath(state, "realtime.stats.video.supported", undefined);
export const rtAudioFailReason = state => extractPath(state, "realtime.stats.audio.reason", "");
export const rtVideoFailReason = state => extractPath(state, "realtime.stats.video.reason", "");
export const rtIsSelfTestExecuting = state => state.realtime.selfTestState === SELF_TEST_STATE.RUNNING;
export const rtIsSelfTestOpen = state => state.realtime.selfTestState !== SELF_TEST_STATE.CLOSED;
export const rtIsSelfTestComplete = state => state.realtime.selfTestState === SELF_TEST_STATE.COMPLETE;

export const rtGetSubscriber = state => state.realtime.subscriber;
export const rtIsBroadcasting = state => state.realtime.broadcast;
export const rtGetReconnecting = state => state.realtime.reconnecting;
export const rtGetPublisher = state => state.realtime.publisher;
export const rtGetConnectionId = state => extractPath(state, "realtime.session.connection.id", null);
export const rtGetApiKey = state => state.realtime.apiKey;
export const rtGetStreams = state => state.realtime.streams.filter(stream => !stream.destroyed);
export const rtGetAllStreams = state => state.realtime.streams; // Includes destroyed streams

export const rtIsPublisherActive = state => {
  const publisher = rtGetPublisher(state);
  return publisher && publisher.stream && !publisher.stream.destroyed;
};

export const rtGetTutorStream = state =>
  state.realtime.streams
    .filter(stream => !stream.destroyed)
    .reverse()
    .find(stream => stream.name === "Coach");

const filterLearnerStreams = memoizeOne((streams, ourConnectionId) =>
  streams
    .filter(stream => !stream.destroyed)
    .filter(stream => stream.name !== "Coach")
    .filter(stream => stream.connection.connectionId !== ourConnectionId)
);

export const rtGetUserStreams = state => {
  const ourConnectionId = rtGetConnectionId(state);
  return filterLearnerStreams(state.realtime.streams, ourConnectionId);
};

export const rtGetSession = state => state.realtime.session;

export const rtGetSessionId = state => state.realtime.sessionId;
export const rtGetSessionToken = state => state.realtime.sessionToken;

export const rtGetOTAPIUrl = state => "https://api.opentok.com/v2/"; // eslint-disable-line no-unused-vars

export const rtIsTempSession = state => !!state.realtime.savedTokens;

export const rtIsConnected = state => state.realtime.connectionState === CONNECTION_STATE.CONNECTED;
export const rtGetConnectionState = state => state.realtime.connectionState;

export const rtGetMicList = state => state.realtime.micList;
export const rtGetCurrentMic = state => {
  if (!state.realtime.micList.find(d => d.deviceId === state.realtime.currentMic)) {
    // The current mic isn't in our list of mics, so it's not valid.
    if (state.realtime.micList.length > 0) {
      // Mic not found, using mic 0
      return state.realtime.micList[0].deviceId;
    }

    // Mic not found, using default
    return "default";
  }
  return state.realtime.currentMic;
};

export const rtGetCurrentMicData = state => {
  const mic = state.realtime.micList.find(d => d.deviceId === state.realtime.currentMic);
  if (!mic) {
    // The current mic isn't in our list of mics, so it's not valid.
    if (state.realtime.micList.length > 0) {
      // Mic not found, using mic 0
      return state.realtime.micList[0];
    }

    // Mic not found, using default
    return "default";
  }
  return mic;
};

/**
 * The group-wide event history.
 *
 * @param {*} state
 */
export const getGroupEventHistory = state => state.realtime.groupEvents;

export const rtGetCameraList = state => state.realtime.cameraList;
export const rtGetCurrentCamera = state => state.realtime.currentCamera;
export const rtGetCurrentCameraDevice = state =>
  state.realtime.currentCamera === "tutor-default" ? undefined : state.realtime.currentCamera;

export const rtGetCurrentSpeaker = state => state.realtime.currentSpeaker;

export const rtGetConnectionStats = (state, connectionId) => {
  // Depending on the connection type, we either get stats per connection ID, or overall with
  // an undefined connection ID. We'll try the connection specific one first
  const connectionSpecificStats = extractPath(state, `realtime.stats.${connectionId}`, null);
  return connectionSpecificStats || extractPath(state, "realtime.stats.undefined", null);
};

export const rtSharedSycPacketData = state => {
  const packets = state.realtime.sharedStatePackets;
  if (!packets) return null;
  return packets.reduce((acc, curr) => acc + curr);
};
export const rtIsSharedSyncComplete = state => {
  const packets = state.realtime.sharedStatePackets;
  const num = state.realtime.sharedStateTotalPackets;
  if (packets === null) return false;
  if (num === 0) return false;
  if (packets.length < num) return false;
  const nullPacket = packets.find(p => p === null);
  return nullPacket === undefined;
};

export const rtGetSignalDebugger = state => state.realtime.signalDebugger;
export const rtGetSignalLog = state => state.realtime.signalLog;
export const rtGetUserConnections = state => state.realtime.connections;

/**
 * Gets the student id for a given connection id from the tokbox connection data string.
 * I think this is only useful in support scenarios when something isn't working right.
 */
export const rtGetStudentIdFromConnectionId = (state, connectionId) => {
  const connection = state.realtime.connections.find(conn => !conn.destroyed() && conn.id === connectionId);
  if (!connection) return null;
  const data = connection.data; // student_id=7319
  const urlParams = new URLSearchParams(data);
  if (!urlParams.has("student_id")) return null;
  return urlParams.get("student_id");
};

// Actions:

export const rtGroupEvent = event => ({ type: RT_GROUP_EVENT, payload: { event } });
export const rtBeginDisconnecting = () => ({ type: RT_BEGIN_DISCONNECTING });

export const rtLearnerReadyReceive = connectionId => ({
  type: RT_LEARNER_READY_RECEIVE,
  payload: { connectionId }
});

export const rtLearnerWelcomeReceive = (data, connectionId) => ({
  type: RT_LEARNER_WELCOME_RECEIVE,
  payload: { data, connectionId }
});

export const rtSharedStateInit = data => ({
  type: RT_SHARED_STATE_INIT,
  payload: { data }
});

export const rtReceiveSharedStateInit = (version, number, total, packet) => ({
  type: RT_RECEIVE_SHARED_STATE_INIT,
  payload: { number, total, packet, version }
});

// A remote user force-muted US
export const rtRemoteUserForceMuted = (muted, connectionId) => ({
  type: RT_REMOTE_FORCE_MUTE,
  payload: { muted, connectionId }
});

// A remote user muted themselves
export const rtRemoteUserMuted = (muted, connectionId) => ({
  type: RT_REMOTE_USER_MUTED,
  payload: { muted, connectionId }
});

export const rtGoodbyeReceived = () => ({ type: RT_SIGNAL_GOODBYE_RECEIVED });

export const rtSignalSent = (signal, connectionId, timestamp) => ({
  type: RT_SIGNAL_SEND,
  payload: { signal, timestamp, connectionId }
});
export const rtSignalReceived = (signal, connectionId, timestamp) => ({
  type: RT_SIGNAL_RECEIVED,
  payload: { signal, timestamp, connectionId }
});
export const rtArchiveStarted = (archiveID, timestamp = undefined) => {
  return {
    type: RT_ARCHIVE_STARTED,
    payload: {
      archiveID,
      timestamp: timestamp || new Date().getTime()
    }
  };
};
export const rtDisconnect = (forceLearnersDisconnect = true) => ({
  type: RT_DISCONNECT,
  payload: { forceLearnersDisconnect }
});
export const rtSelfTestStatus = percent => ({ type: RT_SELF_TEST_STATUS, payload: { percent } });
export const rtConnectivityTestCompleted = (hasAudioDevice, hasVideoDevice, failedTests) => ({
  type: RT_CONNECTIVITY_TEST_COMPLETE,
  payload: { hasAudioDevice, hasVideoDevice, failedTests }
});
export const rtOpenSelfTest = () => ({ type: RT_OPEN_SELF_TEST });
export const rtCloseSelfTest = () => ({ type: RT_CLOSE_SELF_TEST });
export const rtStartSelfTest = (video = true) => ({ type: RT_START_SELF_TEST, payload: { video } });
export const rtQualityTestFailed = error => ({ type: RT_QUALITY_TEST_FAILED, payload: { error } });
export const rtQualityTestCompleted = stats => ({ type: RT_QUALITY_TEST_COMPLETED, payload: { stats } });
export const rtStartBroadcast = () => ({ type: RT_BROADCAST });
export const rtStopBroadcast = () => ({ type: RT_STOP_BROADCAST });

export const rtEndSession = () => ({ type: RT_END_SESSION });
export const rtReconnecting = () => ({ type: RT_RECONNECTING });
export const rtReconnected = () => ({ type: RT_RECONNECTED });

export const rtConnectionStatsUpdated = stats => ({ type: RT_STATS_UPDATED, payload: { stats } });

export const rtPublisherCreated = publisher => ({ type: RT_PUBLISHER_CREATED, payload: { publisher } });

export const rtMicSelected = mic => ({ type: RT_MIC_SELECTED, payload: { mic } });

export const rtNextVideo = () => ({ type: RT_NEXT_VIDEO });

export const rtMicListUpdated = micList => ({ type: RT_MIC_LIST_UPDATED, payload: { micList } });

export const rtCameraListUpdated = cameraList => ({ type: RT_CAMERA_LIST_UPDATED, payload: { cameraList } });
export const rtCameraSelected = camera => ({ type: RT_CAMERA_SELECTED, payload: { camera } });
export const rtSpeakerSelected = speaker => ({ type: RT_SPEAKER_SELECTED, payload: { speaker } });

export const rtWelcomeMessageReceived = (chatHistory, currentSlide, topicResourceId, connectionId) => ({
  type: RT_WELCOME_MESSAGE_RECEIVE,
  payload: { chatHistory, currentSlide, topicResourceId, connectionId }
});

export const rtStreamAdded = stream => ({
  type: RT_STREAM_ADDED,
  payload: { stream }
});
export const rtStreamRemoved = streamId => ({
  type: RT_STREAM_REMOVED,
  payload: { streamId }
});

// When a remote connections disconnects and leaves the session
export const rtDestroyed = connection => ({ type: RT_CONNECTION_DESTROYED, payload: { connection } });

export const rtConnect = () => ({
  type: RT_CONNECT
});

export const rtIsReady = () => ({ type: RT_IS_READY });
export const rtConnected = (session, connection, weConnected) => ({
  type: RT_CONNECTED,
  payload: { session, connection, weConnected }
});
export const rtDisconnected = () => ({ type: RT_DISCONNECTED });

// Switches to a temporary set of tokens.
export const rtSwitchTokens = (apiKey, sessionToken, sessionId) => ({
  type: RT_SWITCH_TOKENS,
  payload: { apiKey, sessionToken, sessionId }
});

// Reverts the temporary tokens
export const rtRevertTokens = () => ({
  type: RT_REVERT_TOKENS
});

export const rtRecieveTokens = (apiKey, sessionToken, sessionId) => ({
  type: RT_RECIEVE_TOKENS,
  payload: { apiKey, sessionToken, sessionId }
});

export const rtSignalStateSetReceived = (key, value) => ({
  type: RT_SIGNAL_STATE_SET_RECEIVED,
  payload: {
    key,
    value
  }
});
export const rtSignalStateAppendReceived = (key, value, isArray = false) => ({
  type: RT_SIGNAL_STATE_APPEND_RECEIVED,
  payload: {
    key,
    isArray,
    value
  }
});
export const rtSignalStateMergeReceived = (key, value) => ({
  type: RT_SIGNAL_STATE_MERGE_RECEIVED,
  payload: {
    key,
    value
  }
});

export const rtEnableSignalLog = () => ({ type: RT_ENABLE_SIGNAL_LOG });
export const rtDisableSignalLog = () => ({ type: RT_DISABLE_SIGNAL_LOG });

export default realTimeReducer;
