import { lionActions } from "@rosetta/react-lion";
import { cancelableSaga } from "@rosetta/redux-utils";
import { Session, Studio, Preferences } from "@rosetta/sqrl-client";
import { delay } from "redux-saga";
import { all, call, put, select, spawn, takeEvery, takeLatest } from "redux-saga/effects";
import { recordUserLoginEvent } from "../../analytics/tutor-analytics";
import { recordTutoringLaunched, initLearnerAnalytics } from "../../analytics/learner-analytics";
import { loadFeedbackHistoryFromLocalstore } from "../../coach/coach-sagas";
import { logMinorError, logToEschool } from "../../errorlogging/errorlogging-reducer";
import { rtGetCurrentMic, rtRecieveTokens, RT_END_SESSION, RT_CONNECTED } from "../../realtime/realtime-reducer";
import { topicKeysLoaded, topicStudioSelect, getTopicResourceId } from "../../topic/topic-reducer";
import { loadTopic } from "../../topic/topic-sagas";
import { DEBUG_ESCHOOL_SESSION_ID, PRODUCTS } from "../constants";
import { encryptAccessKey } from "@rosetta/react-sappy-util";
import { initEveClient, logSegment, setSessionAttributes } from "@rosetta/eve-client";

import { version } from "../../../../package.json";

import {
  trainerModeLearnerSessionStart,
  trainerLoadStudioPreferences,
  trainerLoadGlobalPreferences,
  trainerLoadTimezones
} from "./trainer-sagas";

import {
  ALERT_SUPPORT,
  appError,
  eschoolApiKeyUpdated,
  eschoolFeedbackLoaded,
  getESchoolAPIBase,
  getESchoolApiKey,
  getESchoolSessionId,
  getLcpAPIUrl,
  getLcpAuthInfo,
  initAppComplete,
  learnerWelcomeReceived,
  loadTimeZones,
  getTimeZones,
  SET_TIME_ZONE,
  LEARNER_INIT_APP,
  TUTOR_INIT_APP,
  USER_TYPES,
  welcomePacketReceived,
  getUpdateServiceUrl,
  userPreferencesLoaded,
  getPreference,
  getUserType,
  getPreferredLocale,
  isPracticeMode,
  setPreferredLocale,
  isTrainerMode,
  preferencesLoaded
} from "../reducer/reducer";

import {
  alertTechProblem,
  getAuthToken,
  getAVTokens,
  getEschoolWelcomePacket,
  getSessionFeedback,
  updateTeacherAttendance,
  sessionStudents
} from "../services/eschool";
import { getResourceIDfromComponentID } from "../services/update";
import { coachProductSpecified, coachStudentsLoaded, getCoachId } from "../../coach/coach-reducer";
import { identifyUser } from "../../analytics/common-analytics";
import moment from "moment-timezone/builds/moment-timezone-with-data";
import { getSelectedProduct, getSelectedProductId } from "../../learner/scheduler/scheduler-reducer";
import { identifyUserForErrors } from "../../errorlogging/errorlogging-saga";
import { LanguageParameterType } from "@rosetta/sqrl-client/dist/preferences";

const ONE_MINUTE = 60000;
const TEN_SECONDS = 10000;
const THREE_MINUTES = 180000;
const MAX_TOKEN_ERRORS = 5;
const SAVED_TOKEN_KEY = "tutor-saved-key";

let lcpSession = null;

const getClientInfo = mode => ({
  app_name: "com.rosettastone.ut_tutor",
  app_version: "1",
  app_type: mode === "rstv" ? "rstv_player" : "tutoring_player"
});

function loadReloadToken(originalApiKey) {
  /*
    We have a page-refresh problem in our tokens. Imagine:
    1. Tutor launches app
    2. Does work until after our initial token expires
    3. Reloads the app
    They'll try to re-authenticate with the original token, which is expired, and it will fail.

    So now, we record the updated token in localstorage, and the first time through, we check for that.
   */
  const savedRefreshKey = JSON.parse(window.localStorage.getItem(SAVED_TOKEN_KEY) || "{}");
  return savedRefreshKey[originalApiKey] || originalApiKey;
}

export function* alertSupport() {
  const baseUrl = yield select(getESchoolAPIBase);
  const apiKey = yield select(getESchoolApiKey);
  try {
    const result = yield alertTechProblem(baseUrl, apiKey);
    if (result && result.status === "ok") {
      console.log("Alerted tech support", result);
    } else {
      yield put(logMinorError(null, "Could not alert tech support", result));
    }
  } catch (e) {
    yield put(logMinorError(e, "Could not alert tech support"));
  }
}

function* refreshSqrlKeyLoop() {
  const trainerMode = yield select(isTrainerMode);
  if (trainerMode) return;
  while (true) {
    yield delay(ONE_MINUTE);
    try {
      lcpSession.heartbeat();
    } catch (e) {
      console.log("SQRL Heartbeat error", e);
    }
  }
}

function* refreshEschoolKeyLoop(originalApiKey) {
  let errorCount = 0;

  const sessionId = yield select(getESchoolSessionId);
  if (sessionId === DEBUG_ESCHOOL_SESSION_ID) {
    console.log("Not refreshing debug session token");
    // We have a long lived hard coded key for these.
    return;
  }

  // The key expires, so keep getting a new one every ONE_MINUTE.
  const baseUrl = yield select(getESchoolAPIBase);
  while (errorCount < MAX_TOKEN_ERRORS) {
    const apiKey = yield select(getESchoolApiKey);
    try {
      const newTokenResponse = yield getAuthToken(apiKey, baseUrl);
      const newTokenBody = yield newTokenResponse.json();
      const newToken = newTokenBody.token;
      if (!newToken || newToken.length === 0) {
        throw new Error("Token Fail");
      }
      errorCount = 0;
      yield put(eschoolApiKeyUpdated(newToken));

      window.localStorage.setItem(
        SAVED_TOKEN_KEY,
        JSON.stringify({
          [originalApiKey]: newToken
        })
      );

      yield delay(ONE_MINUTE);
    } catch (e) {
      console.log("Failed to refresh token", e);
      yield put(logMinorError(e, "Failed to refresh key", {}));
      yield delay(TEN_SECONDS * errorCount);
      errorCount++;
    }
  }

  yield put(appError(null, "err_token_refresh", { errorCount }));
}

function* _attendanceLoop() {
  let errorCount = 0;
  const baseUrl = yield select(getESchoolAPIBase);

  while (errorCount < MAX_TOKEN_ERRORS) {
    const teacherId = yield select(getCoachId);
    const apiKey = yield select(getESchoolApiKey);
    try {
      const audioInputDevice = yield select(rtGetCurrentMic);
      const audioOutputDevice = "Default"; // we don't currently set this anywhere...
      yield updateTeacherAttendance(baseUrl, apiKey, audioInputDevice, audioOutputDevice, teacherId);

      errorCount = 0;

      yield delay(THREE_MINUTES);
    } catch (e) {
      console.log("Failed to refresh attendance", e);
      yield put(logMinorError(e, "Failed to refresh attendance", {}));
      yield delay(TEN_SECONDS * errorCount);
      errorCount++;
    }
  }

  yield put(appError(null, "err_attendance"));
}

const attendanceLoop = cancelableSaga(_attendanceLoop, [RT_END_SESSION]);

function* loadStudents() {
  const userType = yield select(getUserType);
  if (userType === USER_TYPES.LEARNER) return;
  const baseUrl = yield select(getESchoolAPIBase);
  const apiKey = yield select(getESchoolApiKey);
  const students = yield sessionStudents(apiKey, baseUrl);
  yield put(coachStudentsLoaded(students));
}

// Recheck an empty class every minute.
function* emptyClassLoop() {
  const userType = yield select(getUserType);
  if (userType !== USER_TYPES.COACH) return;
  while (!(yield select(getTopicResourceId))) {
    yield delay(ONE_MINUTE);
    yield initializeContent();
  }
}

function* onConnectionAdded() {
  try {
    // Whenever a new connection is added, we reload the list of students in case we had a last-minute join and the list changed.
    yield loadStudents();
    const existingResourceId = yield select(getTopicResourceId);

    if (!existingResourceId) {
      console.log("Checking for content.");
      // Whenever a new connection is added, and we didn't have a topic set,
      // we're going to try to reload the content since one has likely been added since last we checked.
      yield initializeContent();
    }
  } catch (e) {
    console.error("Failed on onConnectionAdded", e);
  }
}

function* initializeContent() {
  const baseUrl = yield select(getESchoolAPIBase);
  const sessionId = yield select(getESchoolSessionId);
  const userType = yield select(getUserType);
  const isReviewMode = userType === USER_TYPES.CONTENT_REVIEW;
  const practiceMode = yield select(isPracticeMode);

  const apiKey = yield select(getESchoolApiKey);

  const { welcomePacket, feedbackResponse } = yield all({
    welcomePacket: getEschoolWelcomePacket(baseUrl, apiKey),
    feedbackResponse: getSessionFeedback(baseUrl, apiKey)
  });

  if (welcomePacket) {
    // Weird boolean is so if the property is missing we assume it is required.
    // This will be a temporary situation if the client rolls out before the eschool update to add it and can be removed later
    const shouldShowFeedback = !(false === welcomePacket.feedback_required);

    yield put(topicKeysLoaded(welcomePacket.keys));
    const studentSignedUpCount = welcomePacket.number_of_students_signed_up;

    const classResource = welcomePacket.class_resource && welcomePacket.class_resource.replace("rsus://", "");

    const sappyKey = encryptAccessKey(welcomePacket.keys, welcomePacket.web_services_access_key);

    yield put(
      welcomePacketReceived(
        welcomePacket.puddle_root,
        welcomePacket.support_link,
        welcomePacket.errbit_url,
        welcomePacket.errbit_key,
        welcomePacket.aws_presigned_get_url,
        welcomePacket.aws_presigned_put_url,
        welcomePacket.number_of_seats || 1,
        studentSignedUpCount,
        shouldShowFeedback,
        welcomePacket.update_service_url,
        welcomePacket.teacher_name,
        sappyKey,
        welcomePacket.web_services_base_url,
        classResource
      )
    );

    const isStudioContent = !!welcomePacket.class_resource;

    if (isStudioContent) {
      try {
        if (studentSignedUpCount > 0 || isReviewMode || practiceMode) {
          const updateServiceBase = yield select(getUpdateServiceUrl); //"https://updates-dev.dev.rosettastone.com";
          const resourceId = yield getResourceIDfromComponentID(updateServiceBase, welcomePacket.class_resource);
          yield put(topicStudioSelect(resourceId));
        }

        yield put(coachProductSpecified(PRODUCTS.PRODUCT_STUDIO, "")); // TODO: get the right language-product
      } catch (e) {
        yield put(appError(null, "err_no_topic"));
      }
    } else {
      // This is a little hacky... We check the language to determine WW-ENG (or any WW-) vs FB-
      if ((feedbackResponse.language || "").indexOf(" WW-") === -1) {
        yield put(coachProductSpecified(PRODUCTS.PRODUCT_FB, feedbackResponse.language));
      } else {
        yield put(coachProductSpecified(PRODUCTS.PRODUCT_WW, feedbackResponse.language));
      }
    }

    feedbackResponse.topic && (feedbackResponse.topic.title = "Debug");
  } else {
    yield put(appError(null, "err_no_keys", { welcomePacket }));
  }

  if (feedbackResponse.teacher_id) {
    identifyUserForErrors(feedbackResponse.teacher_id);
    identifyUser(feedbackResponse.teacher_id);
    recordUserLoginEvent({
      sessionId: sessionId,
      topicTitle: feedbackResponse.topic.title
    });
  }
  yield put(eschoolFeedbackLoaded(feedbackResponse));
  yield loadTopic();
}

function* initEschool(originalApiKey) {
  const sessionId = yield select(getESchoolSessionId);
  const baseUrl = yield select(getESchoolAPIBase);

  console.log("Eschool Session", sessionId);
  const apiKey = sessionId === DEBUG_ESCHOOL_SESSION_ID ? originalApiKey : loadReloadToken(originalApiKey);
  yield put(eschoolApiKeyUpdated(apiKey));

  const tokenResponse = yield getAVTokens(baseUrl, apiKey);

  if (tokenResponse.api_key && tokenResponse.token && tokenResponse.audio_video_connection_id) {
    yield put(rtRecieveTokens(tokenResponse.api_key, tokenResponse.token, tokenResponse.audio_video_connection_id));
  } else {
    yield put(appError(null, "err_no_av_tokens", { tokenResponse, msg: tokenResponse && tokenResponse.error }));
  }

  if (sessionId !== DEBUG_ESCHOOL_SESSION_ID) {
    yield loadReloadToken(originalApiKey);
  }

  yield initializeContent();
  yield loadStudents();

  yield spawn(emptyClassLoop);

  yield loadFeedbackHistoryFromLocalstore(sessionId); // we load the localstore feedback last so we can get unsaved changes.

  const trainerMode = yield select(isTrainerMode);
  if (trainerMode) {
    yield learnerInitApp();
  }

  yield put(logToEschool("Unified Tutoring App Launched and Initialized"));
}

function* learnerSessionStart(mode) {
  const trainerMode = yield select(isTrainerMode);
  if (trainerMode) {
    return yield trainerModeLearnerSessionStart();
  }

  const lcpServerApiUrl = yield select(getLcpAPIUrl);
  lcpSession = new Session(lcpServerApiUrl);
  const lcpAuthInfo = yield select(getLcpAuthInfo);
  const response = yield call(lcpSession.start, lcpSession.installId(), getClientInfo(mode), lcpAuthInfo);
  return response;
}

export function* learnerInitApp(action) {
  logSegment("application-startup", "pre-sagas");
  const trainerMode = yield select(isTrainerMode);

  try {
    const mode = action.payload.appRoute.indexOf("rstv") === 1 ? "rstv" : "tutoring";

    const response = yield learnerSessionStart(mode);

    yield spawn(refreshSqrlKeyLoop);

    const errbitAccessKeys =
      response.welcome_packet.web_access_credentials.find(ws => ws.web_service_name === "errbit") || {};

    const rstvAccessKeys =
      response.welcome_packet.web_access_credentials.find(ws => ws.web_service_name === "rstv") || {};

    const eveAccessKeys =
      response.welcome_packet.web_access_credentials.find(ws => ws.web_service_name === "eve") || {};

    if (eveAccessKeys) {
      const appName = action.payload.appRoute.indexOf("rstv") === 1 ? "rstv" : "tutor";
      // TODO: Change this back when dev ES works
      // ES doesn't work on dev, so send dev requests to qa1
      const host =
        eveAccessKeys.web_service_base_url === "https://eve-dev.dev.rosettastone.com"
          ? "https://eve-qa1.dev.rosettastone.com"
          : eveAccessKeys.web_service_base_url;

      initEveClient(
        host,
        eveAccessKeys.web_service_access_key,
        response.welcome_packet.user_context.guid,
        undefined,
        appName,
        version
      );
    }

    logSegment("application-startup", "sqrl-session-started");
    yield put(
      learnerWelcomeReceived(
        response.welcome_packet.static_decryption_keys,
        response.welcome_packet.client_config.puddle_root,
        response.welcome_packet.user_context.guid,
        response.welcome_packet.client_config.fluency_builder_url,
        response.welcome_packet.client_config.launchpad_url,
        errbitAccessKeys.web_service_access_key,
        errbitAccessKeys.web_service_base_url,
        response.welcome_packet.client_config.update_service_url,
        rstvAccessKeys.web_service_access_key,
        rstvAccessKeys.web_service_base_url
      )
    );
    yield loadTimeZonesInfo();
    yield all([call(loadStudioPreferences), call(loadGlobalPreferences)]);
    yield initLion();
    yield updateMomentTimeZone();

    const product = yield select(getSelectedProduct);

    const languageProduct = yield select(getSelectedProductId);
    const locale = (yield getLocale()).primaryLocale;

    // Analytics stuff...
    const useBraze = (product === PRODUCTS.PRODUCT_FB || product === PRODUCTS.PRODUCT_STUDIO) && !trainerMode;

    setSessionAttributes({
      product: languageProduct,
      locale
    });
    initLearnerAnalytics(product, locale, languageProduct);
    identifyUserForErrors(response.welcome_packet.user_context.guid);
    identifyUser(response.welcome_packet.user_context.guid, useBraze);

    if (!(action && action.payload && action.payload.appRoute.indexOf("/rstv") === 0)) {
      recordTutoringLaunched();
    }

    // End analytics stuff...

    yield put(initAppComplete());
    logSegment("application-startup", "learner-init-complete");
  } catch (err) {
    console.error(err);
    yield put(appError(err, "err_authentication_fail"));
  }
}

function* loadGlobalPreferences() {
  const isTrainer = yield select(isTrainerMode);
  if (isTrainer) {
    return yield trainerLoadGlobalPreferences();
  }

  try {
    const lcpServerApiUrl = yield select(getLcpAPIUrl);
    const preferences = new Preferences(lcpServerApiUrl);
    const productId = yield select(getSelectedProductId);

    const language = {
      language_parameter_type: LanguageParameterType.LANGUAGE_PRODUCT_IDENTIFIER,
      language_product_identifier: productId || "ESP"
    };

    const globalPreferences = yield preferences.getPreferences(language, null);

    let { sound_volume, time_as_24_hour, sound_input_device, speech_voice_type, considered_child_for_speech } =
      globalPreferences.universal || {}; // || {} in case there isn't a universal prop for some reason

    considered_child_for_speech = considered_child_for_speech || { value: false };
    sound_volume = sound_volume || { value: 0.5 };
    time_as_24_hour = time_as_24_hour || { value: false };
    sound_input_device = sound_input_device || { value: "default" };
    speech_voice_type = speech_voice_type || { value: null };

    const speechType = considered_child_for_speech.value ? "child" : speech_voice_type.value;

    yield put(preferencesLoaded(sound_volume.value, speechType, sound_input_device.value, time_as_24_hour.value));
  } catch (e) {
    console.warn("No preferences to load.");
  }
}

function* updateMomentTimeZone() {
  const selectedTimeZone = yield select(getPreference, "time_zone_id");
  try {
    moment.tz.setDefault(selectedTimeZone);
  } catch (e) {
    moment.tz.setDefault();
  }
}

function* loadStudioPreferences() {
  const isTrainer = yield select(isTrainerMode);
  if (isTrainer) {
    return yield trainerLoadStudioPreferences();
  }
  try {
    const lcpServerApiUrl = yield select(getLcpAPIUrl);
    const studio = new Studio(lcpServerApiUrl);
    const prefs = yield studio.getPreferences();
    const timeZones = yield select(getTimeZones);
    const selectedTimeZoneObject = timeZones.find(tz => tz.name === prefs.time_zone);
    prefs.time_zone_id = selectedTimeZoneObject.identifier;
    yield put(userPreferencesLoaded(prefs));
  } catch (e) {
    yield put(logMinorError(e, "Could not load user preferences"));
  }
}

function* loadTimeZonesInfo() {
  const isTrainer = yield select(isTrainerMode);
  if (isTrainer) {
    return yield trainerLoadTimezones();
  }

  try {
    const locale = yield call(getLocale);
    const lcpServerApiUrl = yield select(getLcpAPIUrl);
    const preferences = new Preferences(lcpServerApiUrl);
    const timeZones = yield preferences.getTimeZones(locale.primaryLocale);
    yield put(loadTimeZones(timeZones));
  } catch (e) {
    yield put(logMinorError(e, "Could not load time zones"));
  }
}

function* setTimeZone(action) {
  try {
    yield updateMomentTimeZone();
    const lcpServerApiUrl = yield select(getLcpAPIUrl);
    const studio = new Studio(lcpServerApiUrl);
    yield studio.setPreferences(action.payload.timeZoneName);
  } catch (e) {
    yield put(logMinorError(e, "Could not set time zone"));
  }
}

export function* tutorInitApp(action) {
  yield initLion();

  const originalApiKey = yield select(getESchoolApiKey);
  yield spawn(refreshEschoolKeyLoop, originalApiKey);

  if (
    action.payload.userType === USER_TYPES.COACH ||
    action.payload.userType === USER_TYPES.SUPPORT ||
    action.payload.userType === USER_TYPES.INVISIBLE
  ) {
    try {
      yield initEschool(originalApiKey);
    } catch (e) {
      console.error(e);
      yield put(appError(e, "err_init_eschool"));
      return;
    }

    if (action.payload.userType === USER_TYPES.COACH) {
      yield spawn(attendanceLoop);
    }
  } else if (action.payload.userType === USER_TYPES.CONTENT_REVIEW) {
    try {
      yield initEschool(originalApiKey);
    } catch (e) {
      yield put(appError(e, "err_init_eschool"));
      return;
    }
  } else {
    // learners sill need these keys
    try {
      yield initEschool(originalApiKey);
    } catch (e) {
      yield put(appError(e, "err_init_eschool"));
      return;
    }
    yield loadTopic();
  }

  yield put(initAppComplete());
}

function* getLocale() {
  const userType = yield select(getUserType);
  if (userType === USER_TYPES.LEARNER) {
    const queryStringLocale = yield select(getPreferredLocale);
    const debugLocale = yield window.localStorage.getItem("tutor.debug.locale");
    const currentLocale = yield select(getPreference, "locale");
    const defaultLocale = "en-US";

    const primaryLocale = queryStringLocale || debugLocale || currentLocale;
    const fallbackLocale = defaultLocale;
    return { primaryLocale, fallbackLocale };
  }

  return {
    primaryLocale: "en-US",
    fallbackLocale: "en-US"
  };
}

export function* initLion() {
  const { primaryLocale, fallbackLocale } = yield getLocale();
  yield put(setPreferredLocale(primaryLocale));

  if (primaryLocale) moment.locale(primaryLocale.split("-")[0]);
  if (!primaryLocale && fallbackLocale) moment.locale(fallbackLocale.split("-")[0]);

  const primaryAppResources = yield fetch(
    "/assets/lion/language-" + primaryLocale + ".json?build=" + process.env.REACT_APP_BUILD_NUMBER
  ).then(response => response.json());

  const fallbackAppResources = yield fetch(
    "/assets/lion/language-" + fallbackLocale + ".json?build=" + process.env.REACT_APP_BUILD_NUMBER
  ).then(response => response.json());

  yield put(
    lionActions.initLion(
      primaryLocale, // Primary user locale
      fallbackLocale, // Fallback user locale
      primaryAppResources, // app resources for primary locale
      {}, // product resources for primary locale
      fallbackAppResources, // app resources for fallback locale
      {}, // product resources for fallback locale
      false // Should we display an image if the translation is missing?
    )
  );
}

export function* appSagas() {
  yield takeEvery(SET_TIME_ZONE, setTimeZone);

  yield takeLatest(TUTOR_INIT_APP, tutorInitApp);
  yield takeLatest(LEARNER_INIT_APP, learnerInitApp);
  yield takeLatest(ALERT_SUPPORT, alertSupport);
  yield takeLatest(RT_CONNECTED, onConnectionAdded);
}
