export const EFFRA_FONT_FAMILY = "'Effra', sans-serif";

export const MODAL_BACKDROP_Z_INDEX = 5008;
export const MODAL_DIALOG_Z_INDEX = 7000;
export const MEDIA_QUERIES = {
  chromebook: "@media only screen and (max-height: 768px)",
  dashboardNarrow: "@media (max-width: 1100px)"
};

export const RSTV_MIN_WIDTH = 600;
export const RSTV_MAX_WIDTH = 1042;
export const IS_SMALL_SCREEN_WIDTH = 950; // adjust other sizes when width resized below this
export const MIN_SCREEN_WIDTH = 630; // adjust other sizes when width resized below this
export const MIN_SCREEN_HEIGHT = 540; // adjust other sizes when height resized below this
export const RSTV_MAX_VIDEOS_PER_TOPIC = 6; // max videos per topic for right now

// for ScreenSizeContext.jsx
export const SCREEN_SIZE_TOO_SMALL = 2;
export const SCREEN_SIZE_COLLAPSED = 1;
export const SCREEN_SIZE_NORMAL = 0;
export const SCREEN_RESIZE_DEBOUNCE_DELAY = 200;
