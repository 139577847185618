import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import exact from "prop-types-exact";
import styled from "@emotion/styled";
import Select from "./Select";
import { LionTranslate } from "@rosetta/react-lion";
import VideoPreview from "../../coach/VideoPreview";
import NoVideoIcon from "../../icons/NoVideoIcon";
import { RefreshButton } from "./RefreshButton";

const NoVideo = styled.div({
  position: "absolute"
});
const Container = styled.div({
  width: 450,
  display: "flex",
  flexDirection: "column"
});

const PreviewContainer = styled.div({
  width: 200,
  height: 200,
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  borderRadius: 6,
  overflow: "hidden",
  marginLeft: "auto",
  marginRight: "auto",
  backgroundColor: "#ffffff"
});
const Header = styled.div({
  display: "flex",

  justifyContent: "space-between",
  width: 355,
  fontFamily: "Effra",
  fontSize: "18px",
  lineHeight: "normal",
  color: "#4A4A4A",
  marginTop: 7,
  marginBottom: 7
});

const BumpRight = styled.div({ marginLeft: 35 });

class LearnerCameraSelection extends PureComponent {
  static propTypes = exact({
    cameraList: PropTypes.array,
    selectedCamera: PropTypes.string,
    onChange: PropTypes.func,
    refreshDevices: PropTypes.func,
    cameraNoneLabel: PropTypes.string
  });
  static defaultProps = {};

  onChange = event => this.props.onChange(event.target.value);

  hasCamera = () => this.props.selectedCamera && this.props.selectedCamera !== "none";

  render = () => {
    return (
      <Container data-qa="camera_selector">
        <PreviewContainer>
          <NoVideo data-qa="no_camera">
            <NoVideoIcon />
          </NoVideo>
          {this.hasCamera() && (
            <VideoPreview
              data-qa="video_preview"
              key={this.hasCamera()}
              size={200}
              currentCamera={this.props.selectedCamera}
            />
          )}
        </PreviewContainer>

        <BumpRight>
          <Header>
            <LionTranslate lionkey="system_check_camera_header" />
            <RefreshButton onClick={this.props.refreshDevices} />
          </Header>
          <Select data-qa="camera_list" value={this.props.selectedCamera} onChange={this.onChange}>
            {this.props.cameraList.map(camera => (
              <option key={camera.deviceId} value={camera.deviceId}>
                {camera.label}
              </option>
            ))}
            <option key="none" value="none">
              {this.props.cameraNoneLabel}
            </option>
          </Select>
        </BumpRight>
      </Container>
    );
  };
}

export default LearnerCameraSelection;
