/**
 * Flattens a multi dimensional hash to a single dimenstional one with underscores in the key names.
 *
 * flatten({
 *  a: {foo: 1, bar: 2},
 *  b: {foo: 3, bar: 4},
 * })
 * becomes
 * {
 *  a_foo:1,
 *  a_bar: 2,
 *  b_foo: 3,
 *  b_bar: 4
 * }
 *
 */
export const flatten = stats => _flatten("", stats);

const _flatten = (prefix, stats) =>
  Object.keys(stats).reduce((acc, curr) => {
    if (Object.keys(stats[curr]).length > 0) {
      acc = { ...acc, ..._flatten(prefix + curr + "_", stats[curr]) };
    } else {
      acc[prefix + curr] = stats[curr];
    }
    return acc;
  }, {});

/**
 * Computes a diff in stats from the previous one.
 *
 * statsDiff({a:10, b:15}, {a:20, b:15}) === {a:10, b:0}
 *
 * Will not modify the following fields:
 *    - timestamp
 *    - video_frameRate
 *
 */
const _reservedProps = ["timestamp", "video_frameRate"];
export const statsDiff = (previousStats, currentStats) => {
  if (!previousStats) return currentStats;
  return Object.keys(currentStats).reduce((acc, curr) => {
    if (_reservedProps.indexOf(curr) !== -1) {
      acc[curr] = currentStats[curr];
    } else if (isNaN(previousStats[curr]) || isNaN(currentStats[curr])) {
      acc[curr] = currentStats[curr];
    } else {
      acc[curr] = currentStats[curr] - previousStats[curr];
    }
    return acc;
  }, {});
};
