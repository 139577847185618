import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import exact from "prop-types-exact";
import AppHeader, { AppHeaderLeft, AppHeaderButton } from "../../app/components/AppHeader";
import { LeftArrowIcon } from "../../icons/LeftArrowIcon";
import { LionTranslate } from "@rosetta/react-lion";
import styled from "@emotion/styled";
import DeviceSelection from "./DeviceSelection";
import GrantAccess from "./GrantAccess";
import EndInteraction from "../../app/components/EndInteraction";

const SystemCheckBackground = styled.div({
  label: "SystemCheckBackground",
  position: "absolute",
  overflow: "auto",
  top: 64,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "#f1f1f1",
  display: "flex",
  flexDirection: "column",
  // justifyContent: "center",
  alignItems: "center"
});

export class PreSession extends PureComponent {
  static propTypes = exact({
    isGroupSession: PropTypes.bool,
    joinAfter: PropTypes.bool,
    micList: PropTypes.array,
    cameraList: PropTypes.array,
    speakerList: PropTypes.array,
    selectedMic: PropTypes.string,
    selectedCamera: PropTypes.string,
    selectedSpeaker: PropTypes.string,
    rtMicSelected: PropTypes.func,
    rtCameraSelected: PropTypes.func,
    rtSpeakerSelected: PropTypes.func,
    rtStartSelfTest: PropTypes.func,
    grantedAccess: PropTypes.bool,
    selfTestExecuting: PropTypes.bool,
    selfTestPercent: PropTypes.number,
    onDone: PropTypes.func,
    onExit: PropTypes.func,
    refreshDevices: PropTypes.func,
    rtStopBroadcast: PropTypes.func,
    rtStartBroadcast: PropTypes.func,
    cameraNoneLabel: PropTypes.string,
    isRecordingAllowed: PropTypes.bool.isRequired
  });

  static defaultProps = {};

  render = () => {
    return (
      <React.Fragment>
        <EndInteraction interaction="application-startup" />
        <AppHeader>
          <AppHeaderLeft>
            <AppHeaderButton onClick={this.props.onExit}>
              <LeftArrowIcon />
              <LionTranslate lionkey="exit_system_check_button" />
            </AppHeaderButton>
          </AppHeaderLeft>
        </AppHeader>
        <SystemCheckBackground>
          {this.props.grantedAccess && (
            <DeviceSelection
              isRecordingAllowed={this.props.isRecordingAllowed}
              cameraNoneLabel={this.props.cameraNoneLabel}
              isGroupSession={this.props.isGroupSession}
              joinAfter={this.props.joinAfter}
              micList={this.props.micList}
              cameraList={this.props.cameraList}
              speakerList={this.props.speakerList}
              selectedMic={this.props.selectedMic}
              selectedCamera={this.props.selectedCamera}
              selectedSpeaker={this.props.selectedSpeaker}
              rtMicSelected={this.props.rtMicSelected}
              rtCameraSelected={this.props.rtCameraSelected}
              rtSpeakerSelected={this.props.rtSpeakerSelected}
              rtStartSelfTest={this.props.rtStartSelfTest}
              onDone={this.props.onDone}
              selfTestExecuting={this.props.selfTestExecuting}
              selfTestPercent={this.props.selfTestPercent}
              refreshDevices={this.props.refreshDevices}
              rtStopBroadcast={this.props.rtStopBroadcast}
              rtStartBroadcast={this.props.rtStartBroadcast}
            />
          )}

          {this.props.grantedAccess || <GrantAccess />}
        </SystemCheckBackground>
      </React.Fragment>
    );
  };
}

export default PreSession;
