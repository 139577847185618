import { transformData } from "./ondemand-data-transform";
import { RSTV_MAX_VIDEOS_PER_TOPIC, SCREEN_SIZE_NORMAL, SCREEN_SIZE_TOO_SMALL } from "../../../styles/constants";

const RSTV_OD_DATA_LOADED = "RSTV_OD_DATA_LOADED";
export const RSTV_OD_EXIT_VIDEO_RATING = "RSTV_OD_EXIT_VIDEO_RATING";
const RSTV_MAX_VIDEOS_REQUESTED = "RSTV_MAX_VIDEOS_REQUESTED";
const DISMISS_TOO_SMALL_PROMPT = "DISMISS_TOO_SMALL_PROMPT";
export const SCREEN_SIZE_CHANGED = "SCREEN_SIZE_CHANGED";
export const RSTV_OD_INIT_SRE = "RSTV_OD_INIT_SRE";

export const RSTV_VOICE_TYPE_PROMPT = "RSTV_VOICE_TYPE_PROMPT";
export const RSTV_VOICE_TYPE_SELECTED = "RSTV_VOICE_TYPE_SELECTED";

const initial = {
  currentVideo: null,
  loading: true,

  videos: {}, // key = video GUID, value = video object. Use this to look up specific videos.
  tutors: {}, // key = tutor GUID, value = tutor object. Use this to look up specific tutors.
  topics: [], // array of topic objects, in order that we should display them

  // if max_videos_requested is null, return all videos
  // else the number of videos to be returned
  maxVideosRequested: RSTV_MAX_VIDEOS_PER_TOPIC,
  screenTooSmallDismissed: false,
  screenSize: SCREEN_SIZE_NORMAL,

  voiceTypePromptOpen: false
};

export const rstvODReducer = (state = initial, action = undefined) => {
  switch (action && action.type) {
    case RSTV_VOICE_TYPE_PROMPT:
      return {
        ...state,
        voiceTypePromptOpen: true
      };
    case RSTV_VOICE_TYPE_SELECTED:
      return {
        ...state,
        voiceTypePromptOpen: false
      };
    case RSTV_OD_DATA_LOADED:
      return {
        ...state,
        ...transformData(action.payload.response),
        loading: false
      };
    case RSTV_OD_EXIT_VIDEO_RATING:
      return {
        ...state,
        currentVideo: null
      };
    case RSTV_MAX_VIDEOS_REQUESTED:
      return {
        ...state,
        maxVideosRequested: action.payload.maxVideosRequested
      };
    case DISMISS_TOO_SMALL_PROMPT:
      return { ...state, screenTooSmallDismissed: true };
    case SCREEN_SIZE_CHANGED:
      return { ...state, screenSize: action.payload.screenSizeState };

    default:
      return state;
  }
};

export const rstvVoiceTypePrompt = () => ({ type: RSTV_VOICE_TYPE_PROMPT });
export const rstvVoiceTypePromptSelected = voiceType => ({ type: RSTV_VOICE_TYPE_SELECTED, payload: { voiceType } });
export const rstvODDataLoaded = response => ({ type: RSTV_OD_DATA_LOADED, payload: { response } });
export const rstvODExitVideoRating = () => ({ type: RSTV_OD_EXIT_VIDEO_RATING });
export const maxVideosRequested = maxVideosRequested => ({
  type: RSTV_MAX_VIDEOS_REQUESTED,
  payload: { maxVideosRequested }
});
export const dismissTooSmallPrompt = () => ({ type: DISMISS_TOO_SMALL_PROMPT });
export const screenSizeChanged = screenSizeState => ({ type: SCREEN_SIZE_CHANGED, payload: { screenSizeState } });
export const rstvODInitSRE = () => ({ type: RSTV_OD_INIT_SRE });

// I imagine we're only using this on debug pages?
export const getDebugVideos = state => state.rstv.onDemand.videos;

export const getODTutorDetail = (state, tutorId) => state.rstv.onDemand.tutors[tutorId];
export const getODVideoDetailsByTutor = (state, tutorId) =>
  state.rstv.onDemand.tutors[tutorId].videos
    .map(videoGuid => state.rstv.onDemand.videos[videoGuid])
    .filter(video => !!video);
export const getODVideo = (state, videoGUID) => state.rstv.onDemand.videos[videoGUID];
export const getVideoTutors = (state, videoGuid) =>
  state.rstv.onDemand.videos[videoGuid].tutors.map(tutorId => state.rstv.onDemand.tutors[tutorId]);
export const isODDataLoading = state => state.rstv.onDemand.loading;
export const isScreenTooSmallDismissed = state => state.rstv.onDemand.screenTooSmallDismissed;
export const isScreenTooSmall = state => state.rstv.onDemand.screenSize === SCREEN_SIZE_TOO_SMALL;
export const isVoiceTypePromptOpen = state => state.rstv.onDemand.voiceTypePromptOpen;
