import React, { PureComponent } from "react";

import styled from "@emotion/styled";
import { Global, css } from "@emotion/core";

import { appMainBackground } from "../../../styles/colors";
import { EFFRA_FONT_FAMILY } from "../../../styles/constants";
import ErrorBoundary from "../../errorlogging/ErrorBoundary";

import effraFontWoff from "../../../assets/fonts/EffraW02-Regular.woff";
import effraFontWoff2 from "../../../assets/fonts/EffraW02-Regular.woff2";
import effraFontTtf from "../../../assets/fonts/EffraW02-Regular.ttf";

import effraLightFontWoff from "../../../assets/fonts/EffraW02-Light.woff";
import effraLightFontWoff2 from "../../../assets/fonts/EffraW02-Light.woff2";
import effraLightFontTtf from "../../../assets/fonts/EffraW02-Light.ttf";

import effraMediumFontWoff from "../../../assets/fonts/EffraW02-Medium.woff";
import effraMediumFontWoff2 from "../../../assets/fonts/EffraW02-Medium.woff2";
import effraMediumFontTtf from "../../../assets/fonts/EffraW02-Medium.ttf";

import effraBoldFontWoff from "../../../assets/fonts/EffraW02-Bold.woff";
import effraBoldFontWoff2 from "../../../assets/fonts/EffraW02-Bold.woff2";
import effraBoldFontTtf from "../../../assets/fonts/EffraW02-Bold.ttf";

const effraSrc = `url('${effraFontWoff2}') format('woff2'),
url('${effraFontWoff}') format('woff'),
url('${effraFontTtf}') format('truetype')`;

const effraLightSrc = `url('${effraLightFontWoff2}') format('woff2'),
url('${effraLightFontWoff}') format('woff'),
url('${effraLightFontTtf}') format('truetype')`;

const effraMediumSrc = `url('${effraMediumFontWoff2}') format('woff2'),
url('${effraMediumFontWoff}') format('woff'),
url('${effraMediumFontTtf}') format('truetype')`;

const effraBoldSrc = `url('${effraBoldFontWoff2}') format('woff2'),
url('${effraBoldFontWoff}') format('woff'),
url('${effraBoldFontTtf}') format('truetype');`;

const AppWrapper = styled.div({
  fontSize: "14px",
  backgroundColor: appMainBackground,
  fontFamily: EFFRA_FONT_FAMILY,
  overflowY: "hidden"
});

export class App extends PureComponent {
  render = () => {
    return (
      <AppWrapper>
        <Global
          styles={css`
            @font-face {
              font-family: "Effra";
              src: ${effraSrc};
            }
            @font-face {
              font-family: "Effra";
              src: ${effraLightSrc};
              font-weight: 100;
            }
            @font-face {
              font-family: "Effra";
              src: ${effraMediumSrc};
              font-weight: 500;
              font-style: normal;
            }
            @font-face {
              font-family: "Effra";
              src: ${effraBoldSrc};
              font-weight: 700;
              font-style: normal;
            }
          `}
        />
        <ErrorBoundary>
          {/* Include all sub-components of <App /> here with this.props.children */}
          {this.props.children}
          {/* <AppFooter /> */}
        </ErrorBoundary>
      </AppWrapper>
    );
  };
}

export default App;
