import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import exact from "prop-types-exact";
import { Spacer, Title, DoneButton } from "./presession-styles";
import LearnerMicSelection from "./LearnerMicSelection";
import LearnerCameraSelection from "./LearnerCameraSelection";
import LearnerSpeakerSelection from "./LearnerSpeakerSelection";
import { LionTranslate } from "@rosetta/react-lion";
import LearnerConnectionTest from "./LearnerConnectionTest";

import styled from "@emotion/styled";

const Legalese = styled.div({
  marginBottom: 10,
  marginTop: 10,
  maxWidth: 805,
  fontSize: 14,
  lineHeight: "16px",
  textAlign: "center",
  color: "#84898E"
});

class DeviceSelection extends PureComponent {
  static propTypes = exact({
    isGroupSession: PropTypes.bool,
    joinAfter: PropTypes.bool,
    micList: PropTypes.array,
    cameraList: PropTypes.array,
    speakerList: PropTypes.array,
    selectedMic: PropTypes.string,
    selectedCamera: PropTypes.string,
    selectedSpeaker: PropTypes.string,
    rtMicSelected: PropTypes.func,
    rtCameraSelected: PropTypes.func,
    rtSpeakerSelected: PropTypes.func,
    rtStartSelfTest: PropTypes.func,
    selfTestExecuting: PropTypes.bool,
    selfTestPercent: PropTypes.number,
    refreshDevices: PropTypes.func,
    onDone: PropTypes.func,
    cameraNoneLabel: PropTypes.string,
    rtStopBroadcast: PropTypes.func,
    rtStartBroadcast: PropTypes.func,
    isRecordingAllowed: PropTypes.bool
  });
  static defaultProps = {};

  componentWillUnmount() {
    if (this.props.selectedCamera === "none") {
      this.props.rtStopBroadcast();
    } else {
      this.props.rtStartBroadcast();
    }
  }
  onCameraChange = val => {
    this.props.rtCameraSelected(val);
  };

  recordingWarning = () => {
    return (
      <Legalese>
        <LionTranslate
          lionkey={
            this.props.isGroupSession ? "presession_sessions_recording_audio_only" : "presession_sessions_recording"
          }
        />
      </Legalese>
    );
  };
  render = () => {
    return (
      <React.Fragment>
        <Spacer height={1} grow={2} />
        <Title>
          <LionTranslate lionkey="system_check_title" />
        </Title>
        <Spacer height={10} />

        {this.props.isGroupSession || (
          <React.Fragment>
            <LearnerCameraSelection
              cameraNoneLabel={this.props.cameraNoneLabel}
              selectedCamera={this.props.selectedCamera}
              cameraList={this.props.cameraList}
              onChange={this.onCameraChange}
              refreshDevices={this.props.refreshDevices}
            />
          </React.Fragment>
        )}

        <Spacer height={30} />

        <LearnerMicSelection
          selectedMic={this.props.selectedMic}
          micList={this.props.micList}
          onChange={this.props.rtMicSelected}
          refreshDevices={this.props.refreshDevices}
        />

        <Spacer height={30} />
        <LearnerSpeakerSelection
          selectedSpeaker={this.props.selectedSpeaker}
          speakerList={this.props.speakerList}
          onChange={this.props.rtSpeakerSelected}
        />

        <Spacer height={30} />
        <LearnerConnectionTest
          onTestConnection={() => this.props.rtStartSelfTest(!this.props.isGroupSession)}
          selfTestExecuting={this.props.selfTestExecuting}
          selfTestPercent={this.props.selfTestPercent}
        />
        <Spacer height={30} />

        <DoneButton onClick={this.props.onDone} data-qa="done_button">
          {this.props.joinAfter && <LionTranslate lionkey="system_check_join_button" />}
          {this.props.joinAfter || <LionTranslate lionkey="system_check_done_button" />}
        </DoneButton>

        <Spacer height={1} grow={5} />

        {this.props.isRecordingAllowed && this.recordingWarning()}
      </React.Fragment>
    );
  };
}
export default DeviceSelection;
