import { schedulerSetDebugTutorFilter } from "../learner/scheduler/scheduler-reducer";
import { sreActions } from "@rosetta/react-sre";
import { rstvODDataLoaded, rstvVoiceTypePromptSelected } from "../rstv/ondemand/rstv-ondemand-reducer";
import sampleData from "../rstv/ondemand/fixtures/sample-response.json";

/*
  Set of utility functions the automation tests can run.

  They will be called with a context that includes:
    - store
    - (more to come)

  So you can do things like this.store.blah

 */

const funcs = {
  tutor: function(tutorName) {
    this.store.dispatch(schedulerSetDebugTutorFilter(tutorName));
  },
  fakePositiveCalibration: function() {
    this.store.dispatch(sreActions.cancelSRESession());
    this.store.dispatch(sreActions.calibrateComplete("ok"));
  },
  enableFakeSRE: function() {
    this.store.dispatch(sreActions.enableFakeSRE());
  },
  setVoiceTypeUnspecified: function() {
    this.store.dispatch(rstvVoiceTypePromptSelected("unspecified"));
  },
  simulateSREResponse: function(
    score,
    canceled = false,
    silence = false,
    error = false,
    wordResult = null,
    audioQualityMetrics = null,
    saveSoundLogID = null,
    tooLoud = false,
    tooSoft = false,
    tooMuchBackgroundNoise = false
  ) {
    this.store.dispatch(
      sreActions.listenForPhraseResult(
        "rstv",
        score,
        canceled,
        silence,
        score > 0,
        error,
        wordResult,
        audioQualityMetrics,
        saveSoundLogID,
        tooLoud,
        tooSoft,
        tooMuchBackgroundNoise
      )
    );
  },
  simulateSREError: function() {
    this.store.dispatch(sreActions.listenForPhrasesError("ERR", "Simulated Error"));
  },
  loadRstvTestData: function() {
    this.store.dispatch(rstvODDataLoaded(sampleData));
  }
};

export const setupAutomation = store => {
  const context = { store };

  window.automationUtil = {};

  Object.keys(funcs).forEach(key => {
    window.automationUtil[key] = funcs[key].bind(context);
  });
};

export default setupAutomation;
