import React, { PureComponent } from "react";

import exact from "prop-types-exact";
import { Spinner } from "@rosetta/react-common-ui";
import styled from "@emotion/styled";

const Message = styled.div({
  position: "fixed",
  top: 64,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "#EfEfEf",
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
});

class LoadingMessage extends PureComponent {
  static propTypes = exact({});
  static defaultProps = {};

  render = () => {
    return (
      <Message>
        <Spinner />
      </Message>
    );
  };
}

export default LoadingMessage;
