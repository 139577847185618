import styled from "@emotion/styled";

export const Caps = styled.span({ label: "Caps", textTransform: "uppercase" });

export const Spacer = styled.div(({ height, shrink = 1, grow = 0 }) => ({
  height,
  flexShrink: shrink,
  flexGrow: grow,
  label: "Spacer"
}));

export const Instructions = styled.div({
  maxWidth: 695,
  label: "Instructions",
  fontFamily: "Effra",
  fontSize: 18,

  lineHeight: "22px",
  textAlign: "center",
  color: "#4A4A4A",
  flexGrow: 0
});

export const DeviceNote = styled.span({
  label: "DeviceNote",
  paddingTop: 5,
  fontFamily: "Effra",
  fontSize: "12px",
  lineHeight: "20px",
  color: "#84898E"
});
export const Title = styled.h1({
  label: "Title",
  color: "#3E3D3F",
  fontFamily: "Effra",
  fontSize: 22,
  lineHeight: "32px",
  marginLeft: "auto",
  marginRight: "auto",
  textAlign: "center",
  display: "inline-block"
});

export const DoneButton = styled.button({
  label: "DoneButton",
  background: "#4195D3",
  borderRadius: "6px",
  minWidth: 156,
  height: 40,
  color: "#ffffff",
  fontFamily: "Effra",
  fontSize: "16px",
  flexShrink: 0
});
