import React from "react";
import Webcam from "react-webcam";
import styled from "@emotion/styled";
import { COACH_VIDEO_HEIGHT, COACH_VIDEO_WIDTH } from "../app/constants";

const constraints = deviceId => ({
  facingMode: "user",
  deviceId
});

const CamFlipper = styled.div({
  transform: "scale(-1, 1)"
});

const VideoPreview = props => (
  <CamFlipper key={props.currentCamera}>
    <Webcam
      height={props.size || COACH_VIDEO_HEIGHT}
      width={props.size || COACH_VIDEO_WIDTH}
      videoConstraints={constraints(props.currentCamera)}
    />
  </CamFlipper>
);

export default VideoPreview;
