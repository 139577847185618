import { rtGetConnectionId, rtGetUserStreams } from "./realtime/realtime-reducer";
import {
  isUserMuted,
  getCurrentSlideIndex,
  isControllingWhiteboard,
  anyClientSupportsViewingWhiteboard
} from "./sharedstate/sharedstate-reducer";
import { getCurrentTopicSlides, getCurrentTopicVocabulary } from "./topic/topic-reducer";
import { isWorldWideTutoring, getCoachVocab } from "./coach/coach-reducer";
import { getPreference, isTrainerMode } from "./app/reducer/reducer";
import { getSchedulingConfig } from "./learner/scheduler/scheduler-reducer";

/**
 * Returns whether or not the current user is currently muted.
 */
export const amIMuted = state => {
  const myConnectionId = rtGetConnectionId(state);
  return isUserMuted(state, myConnectionId);
};

/**
 * Returns whether or not the current user is currently controlling the whiteboard.
 */
export const doIControllWhiteboard = state => {
  const myConnectionId = rtGetConnectionId(state);
  return isControllingWhiteboard(state, myConnectionId);
};

export const supportsWhiteboard = state => {
  const streamCount = rtGetUserStreams(state).length;
  return streamCount === 0
    ? !isWorldWideTutoring(state) // If no connected clients, show it unless WWE
    : anyClientSupportsViewingWhiteboard(state); // If we have clients, show it if anyone supports it
};

export const getCurrentSlide = state => getCurrentTopicSlides(state)[getCurrentSlideIndex(state)];

const getConfigName = state => {
  const config = getSchedulingConfig(state);
  return config && config.first_name;
};

export const getLearnerPreferredName = state => {
  if (isTrainerMode(state)) return "Learner Name";
  return getPreference(state, "preferred_name") || getConfigName(state) || "Unknown";
};

export const getActiveCoachVocabulary = state => {
  const slideIndex = getCurrentSlideIndex(state);
  const allVocab = getCoachVocab(state);
  return allVocab[slideIndex] || [];
};

export const getActiveVocabulary = state => {
  try {
    const topicVocab = getCurrentTopicVocabulary(state);
    if (topicVocab && topicVocab.length > 0) return topicVocab;
    const currentSlide = getCurrentSlide(state);
    if (!currentSlide || !currentSlide.vocabulary) return [];
    const slideTerms = currentSlide.vocabulary.term || [];
    const terms = slideTerms
      .map(t => t.script.map(s => s.text))
      .reduce((a, b) => a.concat(b), [])
      .filter(t => !!t);
    return terms;
  } catch (e) {
    console.warn("Could not get active vocab");
    return [];
  }
};
