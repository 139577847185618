import { combineReducers } from "redux";
// @rosetta DNA2 libraries selector and reducer hookup
import { lionReducer /*, lionSelectors*/ } from "@rosetta/react-lion";

import realTimeReducer from "./modules/realtime/realtime-reducer";
// app module reducers
import appReducer /*, { appSelectors }*/ from "./modules/app/reducer/reducer";
import { topicReducer } from "./modules/topic/topic-reducer";

import { sreReducer } from "@rosetta/react-sre";
import { coachReducer } from "./modules/coach/coach-reducer";
import { learnerReducer } from "./modules/learner/learner-reducer";
import { supportReducer } from "./modules/support/support-reducer";
import { archiveReducer } from "./modules/archive/archive-reducer";
import attendanceReducer from "./modules/learner/attend/attendance-reducer";
import { sharedStateReducer } from "./modules/sharedstate/sharedstate-reducer";
import { chatReducer } from "./modules/chat/chat-reducer";
import { schedulerReducer } from "./modules/learner/scheduler/scheduler-reducer";
import { connectRouter } from "connected-react-router";
import { whiteboardReducer } from "./modules/whiteboard/whiteboard-reducer";
import { rstvReducer } from "./modules/rstv/rstv-reducer";

export const rootReducer = history =>
  combineReducers({
    sre: sreReducer,
    router: connectRouter(history),
    app: appReducer,
    lion: lionReducer,
    realtime: realTimeReducer,
    topic: topicReducer,
    coach: coachReducer,
    learner: learnerReducer,
    scheduler: schedulerReducer,
    support: supportReducer,
    archive: archiveReducer,
    attend: attendanceReducer,
    sharedstate: sharedStateReducer,
    chat: chatReducer,
    whiteboard: whiteboardReducer,
    rstv: rstvReducer
  });
