import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import exact from "prop-types-exact";
import AudioBar from "../../realtime/AudioBar";

class MicCheck extends PureComponent {
  state = {
    level: 0,
    error: false
  };

  static propTypes = exact({
    deviceId: PropTypes.string
  });

  currentDevice = null;
  static defaultProps = {};

  onError = e => {
    console.log("MicCheck onError", e);
    this.setState({ error: true });
  };

  reset = () => {
    clearInterval(this.interval);
    if (this.mediaStream) {
      this.mediaStream.getTracks().forEach(track => {
        track.stop();
      });
    }
  };
  onMedia = (media, deviceId) => {
    console.log("onMedia", deviceId);
    const AudioContext = window.AudioContext || window.webkitAudioContext;

    this.setState({ deviceId });
    this.mediaStream = media;
    const audioContent = new AudioContext();
    const audioStream = audioContent.createMediaStreamSource(media);
    this.analyser = audioContent.createAnalyser();
    audioStream.connect(this.analyser);
    this.analyser.fftSize = 32;
    this.interval = setInterval(this.tick, 100);
    this.frequencyArray = new Uint8Array(this.analyser.frequencyBinCount);
  };

  componentWillUnmount() {
    this.reset();
  }

  tick = () => {
    this.analyser.getByteFrequencyData(this.frequencyArray);
    let sum = 0;
    for (let i = 0; i < this.frequencyArray.length; i++) {
      sum += this.frequencyArray[i];
    }
    const level = Math.min(5, Math.max(0, (sum / this.frequencyArray.length - 10) / 20));
    this.setState({
      level
    });
  };

  listen = deviceId => {
    if (!deviceId || deviceId === "") return;
    this.reset();
    console.log("Trying to listen to", deviceId);
    this.currentDevice = deviceId;
    try {
      navigator.mediaDevices
        .getUserMedia({
          video: false,
          audio: {
            deviceId: { exact: deviceId }
          }
        })
        .then(media => this.onMedia(media, deviceId))
        .catch(this.onError);
    } catch (e) {
      this.onError(e);
    }
  };

  render = () => {
    if (this.state.error) {
      console.log("MicCheck error", this.state.error);
      return "Error";
    }
    if (this.currentDevice !== this.props.deviceId) {
      this.listen(this.props.deviceId);
      return null;
    }
    return <AudioBar muted={false} level={this.state.level} />;
  };
}

export default MicCheck;
