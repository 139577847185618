export const forceArray = a => (a.concat ? a : [a]);

/**
 * Coerces a value to an int if parseInt succeeds, otherwise returns the original value.
 *
 * toIntIfInt('5') == 5
 * toIntIfInt('bannana') == 'bannana'
 *
 * @param val
 * @return {*}
 */
const toIntIfInt = val => {
  const r = parseInt(val, 10);
  if (isNaN(r)) return val;
  return r;
};

/**
 * attributesToInt looks for attributes with the given names and coerces them to an int. Works recursively
 * on arrays and objects.
 *
 * attributesToInt({a:'1'},['a']) == {a:1}
 *
 */
export const attributesToInt = (obj, properties) => {
  if (Array.isArray(obj)) {
    return obj.map(v => attributesToInt(v, properties));
  }

  if (obj === null) {
    return null;
  }

  if (typeof obj === "object") {
    return Object.keys(obj).reduce((acc, key) => {
      if (properties.indexOf(key) !== -1) {
        acc[key] = toIntIfInt(obj[key]);
      } else {
        acc[key] = attributesToInt(obj[key], properties);
      }

      return acc;
    }, {});
  }

  return obj;
};

/**
 * mergeAttributes takes the format that xml2js gives us, and recursively merges _attributes with child nodes to
 * make a more sane JSON object. BEWARE: if you have attributes and node names that are the same, you'll lose the
 * attribute.
 *
 * @arrayProps is an array of node names to always present as an array, even if there's only one element.
 *
 * @replaceProps is an array of node names that are guaranteed to have a single key, and we should only return that key,
 *               not the parent. ie. do this {acts:[..., ..., ...]} instead of this {acts:{act:[..., ..., ...]}}
 *               We do this because the XML input sometimes has redundant nodes we want to get rid of.
 *
 */
export const mergeAttributes = (arrayProps, replaceProps, node) => {
  if (node === null) {
    return null;
  }
  if (Array.isArray(node)) {
    return node.map(v => mergeAttributes(arrayProps, replaceProps, v));
  }

  if (node._attributes && node._attributes.nil === "true" && Object.keys(node).length === 1) {
    // <node nil='true' /> should be converted to {node:null}
    return null;
  }

  if (typeof node === "object") {
    return {
      ...node._attributes,
      ...Object.keys(node)
        .filter(key => key !== "_attributes")
        .reduce((acc, key) => {
          if (replaceProps.indexOf(key) > -1) {
            // going to ignore these props and use their children instead
            const keys = Object.keys(node[key]);
            if (keys.length > 1) {
              console.error(`Tried to condense ${key} but there were multiple child keys`, key);
              throw new Error(`Tried to condense ${key} but there were multiple child keys`);
            } else if (keys.length === 1) {
              acc[key] = mergeAttributes(arrayProps, replaceProps, node[key][keys[0]]);
            } else {
              acc[key] = [];
            }

            return acc;
          }

          if (arrayProps.indexOf(key) > -1) {
            // Going to force these properties to an array.
            acc[key] = forceArray(node[key]).map(v => mergeAttributes(arrayProps, replaceProps, v));
            return acc;
          }

          acc[key] = mergeAttributes(arrayProps, replaceProps, node[key]);
          return acc;
        }, {})
    };
  }
  return node;
};

export const flattenText = node => {
  if (Array.isArray(node)) {
    return node.map(v => flattenText(v));
  }

  if (node && node._attributes && node._attributes.nil === "true" && Object.keys(node).length === 1) {
    // <node nil='true' /> should be converted to {node:null}
    return null;
  }

  if (node && typeof node === "object") {
    return {
      ...node._attributes,
      ...Object.keys(node)
        .filter(key => key !== "_attributes")
        .reduce((acc, key) => {
          if (node && node[key] && node[key].hasOwnProperty("_text") && Object.keys(node[key]).length === 1) {
            acc[key] = node[key]._text;
          } else {
            acc[key] = flattenText(node[key]);
          }
          return acc;
        }, {})
    };
  }
  return node;
};

export const flattenCData = node => {
  if (Array.isArray(node)) {
    return node.map(v => flattenCData(v));
  }

  if (node && node._attributes && node._attributes.nil === "true" && Object.keys(node).length === 1) {
    // <node nil='true' /> should be converted to {node:null}
    return null;
  }

  if (node && typeof node === "object") {
    return {
      ...node._attributes,
      ...Object.keys(node)
        .filter(key => key !== "_attributes")
        .reduce((acc, key) => {
          if (node && node[key] && node[key].hasOwnProperty("_cdata") && Object.keys(node[key]).length === 1) {
            acc[key] = node[key]._cdata;
          } else {
            acc[key] = flattenCData(node[key]);
          }
          return acc;
        }, {})
    };
  }
  return node;
};

export const parseNonCompactXML = (xml, alwaysArray = [], doNotCollapse = []) => {
  const updateLayout = /*eslint no-use-before-define: "off"*/ node => {
    if (node.type === "element") {
      return {
        ...node.attributes,
        ...updateChildren(node.elements)
      };
    }
    return {};
  };

  const increment = (key, hash) => {
    if (!hash[key]) {
      hash[key] = 0;
    }
    hash[key]++;
    return hash;
  };

  const forceIntoArray = nodeName => doNotCollapse.indexOf(nodeName) !== -1;

  /**
   * The reducer function for type==='element' for reducing the children nodes in updateChildren
   * @param {*} acc
   * @param {*} curr
   */
  const reduceElement = (acc, curr) => {
    const nodeName = curr.name;
    if (forceIntoArray(nodeName) && curr.elements) {
      acc[nodeName].push({
        ...curr.attributes,
        children: curr.elements.map(child => ({ name: child.name, value: updateLayout(child) }))
      });
    } else if (Array.isArray(acc[nodeName])) {
      acc[nodeName].push(updateLayout(curr));
    } else {
      acc[nodeName] = updateLayout(curr);
    }
    return acc;
  };

  /**
   * The reducer function for type==='text' for reducing the children nodes in updateChildren
   */
  const reduceText = (acc, curr) => {
    // type: 'text', text: 'Some Text'
    return {
      ...acc,
      text: curr.text || curr.cdata
    };
  };

  /**
   * Return a map representing the children of this node.
   *
   * @param {*} childList
   */
  const updateChildren = childList => {
    if (!childList) return {};

    /*
    for each key, we need to decide if we return an object or an Array.
        Object: only 1 of that key
        Array: More than one for a key.

    So, first, let's figure out how many of each key we have.
    */
    const tagCounts = childList.reduce((acc, curr) => increment(curr.name, acc), {});

    // Then we'll initialize arrays for array values.
    const initial = childList.reduce((acc, curr) => {
      if (forceIntoArray(curr.name)) {
        acc[curr.name] = []; // { children: [] };
      } else if (tagCounts[curr.name] > 1) {
        acc[curr.name] = [];
      } else if (alwaysArray.indexOf(curr.name) >= 0) {
        acc[curr.name] = [];
      }

      return acc;
    }, {});

    const validTypes = ["element", "text", "cdata"];

    return childList
      .filter(node => validTypes.indexOf(node.type) !== -1)
      .reduce((acc, curr) => {
        if (curr.type === "element") {
          return reduceElement(acc, curr);
        }
        if (curr.type === "text" || curr.type === "cdata") {
          return reduceText(acc, curr);
        }
        return null;
      }, initial);
  };

  return updateChildren(xml.elements);
};

export const stringifyXml = xmlData => {
  let xmlString;
  //IE
  if (window.ActiveXObject) {
    xmlString = xmlData.xml;
  } else {
    // code for Mozilla, Firefox, Opera, etc.
    xmlString = new XMLSerializer().serializeToString(xmlData);
  }
  return xmlString.replace(/(\r\n|\n|\r)/gm, "");
};

export const parseXml = xmlData => {
  const parser = new DOMParser();
  return parser.parseFromString(xmlData, "text/xml");
};
