/**
 * Takes a chat history and returns a JSON representation that is no longer than maxSize bytes
 * by only returning the last N chat messages that fit.
 *
 * This is used for the welcomePacket, but that's deprecated now and the sharedStateInit signalling
 * system is a lot more robust and can split up a message across multiple signals.
 *
 * @param {*} history A chatHistory array
 * @param {*} maxSize Max string size to return
 */
export const trimChatHistory = (history, maxSize = 7000) => {
  let rv = "";
  let count = 0;

  if (!history) {
    return "[]";
  }

  do {
    rv = JSON.stringify(history.slice(count, history.length));
    count++;
  } while (rv.length > maxSize);

  return rv;
};
