import { NATIVE_SLIDE_SIZE, NATIVE_RECT_SLIDE_SIZE } from "../app/constants";

export const getNativeSlideHeight = slide => {
  if (!slide) return NATIVE_SLIDE_SIZE;
  if (slide.type && slide.type === "full_image") {
    return NATIVE_RECT_SLIDE_SIZE;
  }

  if (!slide.type) {
    // Foundations style slides don't have a type.
    return NATIVE_RECT_SLIDE_SIZE;
  }

  return NATIVE_SLIDE_SIZE;
};
// eslint-disable-next-line no-unused-vars
export const getNativeSlideWidth = slide => NATIVE_SLIDE_SIZE;

export const sortKey = scheduledDates => {
  if (!scheduledDates || scheduledDates === "") return "";
  const endDates = scheduledDates
    .split(",")
    .map(range => {
      const parts = range.split(" - ");
      if (parts.length === 2) return parts[1];
      return "";
    })
    .filter(endDate => !!endDate)
    .sort((ed1, ed2) => {
      if (ed1 < ed2) return 1;
      if (ed1 > ed2) return -1;
      return 0;
    });

  return endDates[0];
};

export const imageResourceId = hydraImageContainer => {
  if (!hydraImageContainer) {
    return null;
  }
  const possibilities = Object.keys(hydraImageContainer);

  // We prefer a 640 width version, if not found, grab the biggest
  const preferred = possibilities.find(key => parseInt(key.split("_")[3], 10) === 640);

  if (preferred) {
    return hydraImageContainer[preferred].split(":")[2];
  }

  const sortedPos = possibilities.sort((a, b) => {
    try {
      const width1 = parseInt(a.split("_")[3], 10);
      const width2 = parseInt(b.split("_")[3], 10);

      return width2 - width1;
    } catch (e) {
      return 0;
    }
  });
  const targetKey = sortedPos[0];
  if (!targetKey) {
    return null;
  }
  return hydraImageContainer[targetKey].split(":")[2];
};
