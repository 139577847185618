import React, { PureComponent } from "react";
import MicIcon from "./MicIcon";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

const BARS = [1, 2, 3, 4, 5, 6];

const Bar = backgroundColor =>
  styled.div({
    background: backgroundColor,
    display: "inline-block",
    marginRight: 2,
    width: "4px",
    height: "14px",
    left: "244px",
    marginBottom: 2,
    borderRadius: "4px",
    verticalAlign: "middle"
  });

const EmptyBar = Bar("#828282");
const FilledBar = Bar("#99CC66");

const MuteIconContainer = styled.div({
  width: 56,
  textAlign: "right"
});
const MuteIcon = () => (
  <MuteIconContainer>
    <MicIcon />
  </MuteIconContainer>
);

class AudioBar extends PureComponent {
  static propTypes = {
    level: PropTypes.number.isRequired,
    muted: PropTypes.bool,
    showMuted: PropTypes.bool
  };
  static defaultProps = { muted: false, showMuted: true };

  render = () => {
    if (this.props.muted) {
      if (this.props.showMuted) {
        return <MuteIcon />;
      }
      return null; // muted, but we shouldn't show the icon.
    }

    return BARS.map(val => (this.props.level >= val ? <FilledBar key={val} /> : <EmptyBar key={val} />));
  };
}

export default AudioBar;
