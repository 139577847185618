import moment from "moment";
import { PRODUCTS } from "../../app/constants";
import { SET_TIME_ZONE } from "../../app/reducer/reducer";

export const SCHEDULER_DATE_SELECTED = "SCHEDULER_DATE_SELECTED";
export const SCHEDULING_INFO_LOADED = "SCHEDULING_INFO_LOADED";

export const SCHEDULER_INIT_APP = "SCHEDULER_INIT_APP";
export const SCHEDULER_INIT_APP_COMPLETE = "SCHEDULER_INIT_APP_COMPLETE";
export const LANGUAGE_PRODUCT_SELECTED = "LANGUAGE_PRODUCT_SELECTED";
export const SCHEDULER_LOAD_TOPICS = "SCHEDULER_LOAD_TOPICS";
export const LOAD_TOPICS = "LOAD_TOPICS";
export const SCHEDULER_TOPIC_SELECTED = "SCHEDULER_TOPIC_SELECTED";
export const SCHEDULER_TOPIC_FILTER_SELECTED = "SCHEDULER_TOPIC_FILTER_SELECTED";
export const LOAD_SIGNED_UP_SESSIONS = "LOAD_SIGNED_UP_SESSIONS";
export const SESSION_SELECTED = "SESSION_SELECTED";
export const UNIT_LESSON_SELECTED = "UNIT_LESSON_SELECTED";
export const UNIT_LESSON_FILTER_SELECTED = "UNIT_LESSON_FILTER_SELECTED";
export const SCHEDULER_BOOK_SESSION = "SCHEDULER_BOOK_SESSION";
export const SCHEDULER_CANCEL_SESSION = "SCHEDULER_CANCEL_SESSION";
export const SESSION_CANCELLED = "SESSION_CANCELLED";
export const SESSION_CANCEL_FAILED = "SESSION_CANCEL_FAILED";
export const SESSION_CANCEL_CLEAR_STATE = "SESSION_CANCEL_CLEAR_STATE";
export const SESSION_BOOKED = "SESSION_BOOKED";
export const SHOW_DASHBOARD_VIEW = "SHOW_DASHBOARD_VIEW";
export const SHOW_SCHEDULER_VIEW = "SHOW_SCHEDULER_VIEW";
export const SET_TUTOR_FILTER = "SET_TUTOR_FILTER";
export const SET_DEBUG_TUTOR_FILTER = "SET_DEBUG_TUTOR_FILTER";
export const SCHEDULER_REQUEST_TIME = "SCHEDULER_REQUEST_TIME";
export const SCHEDULER_SET_REQUESTED_TIME = "SCHEDULER_SET_REQUESTED_TIME";
export const SCHEDULER_SET_REQUESTED_DATE = "SCHEDULER_SET_REQUESTED_DATE";

const initial = {
  schedulerLoading: true,
  schedulerInitialized: false,
  selectedProductId: "WW-ENG",
  selectedUnit: -1,
  consumableInfo: {},
  selectedTopicFilter: null,
  selectedLessonFilter: null,
  selectedUnitFilter: null,
  sessionCancelled: null,
  selectedDate: null,
  tutorFilter: "All",
  allTutors: [] // Tutors from all the sessions
};

/*
  Checking if the topics of two sessions match for groupability is a little bit
  hard because we aren't really sure if it's a topical or a unit/lesson session here.

  Case 1:
    Two sessions with wildcard set, and neither has a topic - they are wildcard unit/lessons and can be grouped.
  Case 2:
    Two sessions with topics set, and they're the same - they are topical sessions and can be grouped.
  Case 3:
    Two sessions
       without topics
       without a scheduled_lesson
       wildcard: false
       - These are 2 topical sessions with no topic set and can be grouped.
  Case 4:
    Two sessions:
      Wildcard: false
      scheduled_unit and scheduled_lesson are set and the same
      - These are 2 unit/lesson sessions and can be grouped


*/
export const topicsMatch = (lastSession, testSession) =>
  (lastSession.wildcard && testSession.wildcard && !lastSession.topic && !testSession.topic) || // Case #1
  (lastSession.topic && // Case #2
    testSession.topic &&
    lastSession.topic.id === testSession.topic.id) ||
  (!lastSession.topic && // Case #3
    !testSession.topic &&
    !lastSession.scheduled_lesson &&
    !testSession.scheduled_lesson &&
    !lastSession.wildcard &&
    !testSession.wildcard) ||
  (!lastSession.wildcard && // Case #4
    !testSession.wildcard &&
    lastSession.scheduled_unit &&
    lastSession.scheduled_lesson &&
    lastSession.scheduled_unit === testSession.scheduled_unit &&
    lastSession.scheduled_lesson === testSession.scheduled_lesson);

export const areSessionsGroupable = (lastSession, testSession) => {
  if (!lastSession || !testSession) return false;
  return (
    lastSession.start_time_in_seconds === testSession.start_time_in_seconds &&
    lastSession.duration_in_seconds === testSession.duration_in_seconds &&
    lastSession.can_be_scheduled_as_one_on_one === testSession.can_be_scheduled_as_one_on_one &&
    lastSession.can_be_scheduled_as_group === testSession.can_be_scheduled_as_group &&
    topicsMatch(lastSession, testSession)
  );
};

export const groupSimilarSessions = sessions => {
  const sortedSessions = sessions.concat().sort((a, b) => {
    if (a.can_be_scheduled_as_one_on_one !== b.can_be_scheduled_as_one_on_one) {
      return a.can_be_scheduled_as_one_on_one ? 1 : -1;
    }
    if (a.can_be_scheduled_as_group !== b.can_be_scheduled_as_group) {
      return a.can_be_scheduled_as_group ? 1 : -1;
    }
    return a.start_time_in_seconds - b.start_time_in_seconds;
  });
  const groupedSessions = [];
  let lastSession = null;

  for (const testSession of sortedSessions) {
    if (!areSessionsGroupable(lastSession, testSession)) {
      groupedSessions.push(testSession);
    }
    lastSession = testSession;
  }

  return groupedSessions;
};

export const splitWildcardSessions = sessions => {
  const splitSessions = sessions.reduce((array, session) => {
    if (session.can_be_scheduled_as_one_on_one && session.can_be_scheduled_as_group) {
      array.push(
        { ...session, can_be_scheduled_as_one_on_one: false },
        { ...session, can_be_scheduled_as_group: false }
      );
    } else {
      array.push(session);
    }
    return array;
  }, []);
  return splitSessions;
};

export const processUniqueTutors = sessions => {
  const result = [];
  const map = new Map();
  for (const session of sessions) {
    if (session.tutor && !map.has(session.tutor.tutor_id)) {
      map.set(session.tutor.tutor_id, true); // set any value to Map
      result.push({
        tutor_id: session.tutor.tutor_id,
        tutor_name: session.tutor.tutor_name
      });
    }
  }
  return result;
};

const getTutorIdByName = (state, name) => {
  const tutor = state.allTutors.find(tutor => tutor.tutor_name === name);
  return tutor && tutor.tutor_id;
};

export const schedulerReducer = (state = initial, action = undefined) => {
  switch (action && action.type) {
    case SET_TIME_ZONE:
      return {
        ...state,
        schedulerLoading: true
      };

    case SCHEDULER_DATE_SELECTED:
      return {
        ...state,
        schedulerLoading: true,
        selectedDate: action.payload.date
      };

    case SCHEDULING_INFO_LOADED:
      const days = action.payload.schedulableDays || [];
      return {
        ...state,
        allTutors: processUniqueTutors(action.payload.schedulableStudioSessions),
        sessions: splitWildcardSessions(action.payload.schedulableStudioSessions),
        groupedSessions: groupSimilarSessions(splitWildcardSessions(action.payload.schedulableStudioSessions)),
        sessionsLength: splitWildcardSessions(action.payload.schedulableStudioSessions).length,
        groupedSessionsLength: groupSimilarSessions(splitWildcardSessions(action.payload.schedulableStudioSessions))
          .length,
        uniqueSessionDays: days,
        scheduledSessions: action.payload.scheduledAttendances,
        schedulingConfig: action.payload.schedulingConfig,
        consumableInfo: action.payload.consumableInfo,
        selectedDate: state.selectedDate || days[0] || moment().format("YYYY-MM-DD"),
        schedulerLoading: false
      };

    case SET_DEBUG_TUTOR_FILTER:
      return {
        ...state,
        tutorFilter: getTutorIdByName(state, action.payload.tutorName)
      };
    case SET_TUTOR_FILTER:
      return {
        ...state,
        tutorFilter: action.payload.tutor
      };
    case SCHEDULER_INIT_APP:
      return {
        ...state
      };
    case SCHEDULER_INIT_APP_COMPLETE:
      return {
        ...state,
        schedulerInitialized: true
      };

    case LOAD_SIGNED_UP_SESSIONS:
      return {
        ...state,
        signedUpSessions: action.payload.sessions,
        uniqueTutors: processUniqueTutors(action.payload.sessions)
      };
    case LANGUAGE_PRODUCT_SELECTED:
      return {
        ...state,
        selectedProductId: action.payload.productId
      };
    case LOAD_TOPICS:
      return {
        ...state,
        topics: action.payload.topics
      };
    case UNIT_LESSON_SELECTED:
      return {
        ...state,
        selectedUnit: action.payload.unit,
        selectedLesson: action.payload.lesson
      };
    case UNIT_LESSON_FILTER_SELECTED:
      return {
        ...state,
        schedulerLoading: true,
        selectedUnitFilter: action.payload.unitFilter,
        selectedLessonFilter: action.payload.lessonFilter
      };
    case SCHEDULER_BOOK_SESSION:
      return {
        ...state
      };
    case SCHEDULER_CANCEL_SESSION:
      return {
        ...state
      };
    case SESSION_CANCELLED:
      return {
        ...state,
        sessionCancelled: action.payload.sessionCancelled
      };
    case SESSION_CANCEL_FAILED:
      return {
        ...state,
        sessionCancelled: false,
        sessionCancelErrorCode: action.payload.errorCode
      };
    case SESSION_CANCEL_CLEAR_STATE:
      return {
        ...state,
        sessionCancelled: null
      };
    case SESSION_BOOKED:
      return {
        ...state,
        lastBookedAttendanceId: action.payload.attendanceId
      };
    case SESSION_SELECTED:
      return {
        ...state,
        selectedSession: action.payload.selectedSession
      };
    case SCHEDULER_TOPIC_SELECTED:
      return {
        ...state,
        selectedTopic: action.payload.topic
      };
    case SCHEDULER_TOPIC_FILTER_SELECTED:
      return {
        ...state,
        selectedTopicFilter: action.payload.topicFilter
      };
    case SCHEDULER_REQUEST_TIME:
      return {
        ...state
      };
    case SCHEDULER_SET_REQUESTED_DATE:
      return {
        ...state,
        requestedDate: action.payload.requestedDate
      };
    case SCHEDULER_SET_REQUESTED_TIME:
      return {
        ...state,
        requestedTime: action.payload.requestedTime
      };
    default:
      return state;
  }
};

// -=-=-=-=-=-=-=-=-=-=- Action Creators -=-=-=-=-=-=-=-=-=-=-
export const schedulerInitApp = () => ({
  type: SCHEDULER_INIT_APP
});

export const schedulingInfoLoaded = (
  schedulableStudioSessions,
  scheduledAttendances,
  schedulingConfig,
  consumableInfo,
  schedulableDays
) => ({
  type: SCHEDULING_INFO_LOADED,
  payload: {
    schedulableStudioSessions,
    scheduledAttendances,
    schedulingConfig,
    consumableInfo,
    schedulableDays
  }
});

export const schedulerInitAppComplete = () => ({
  type: SCHEDULER_INIT_APP_COMPLETE
});

export const loadTopics = topics => ({
  type: LOAD_TOPICS,
  payload: { topics }
});

export const loadSignedUpSessions = sessions => ({
  type: LOAD_SIGNED_UP_SESSIONS,
  payload: { sessions }
});
export const languageProductSelected = productId => ({
  type: LANGUAGE_PRODUCT_SELECTED,
  payload: { productId }
});

export const sessionSelected = selectedSession => ({
  type: SESSION_SELECTED,
  payload: { selectedSession }
});
export const unitLessonSelected = (unit, lesson) => ({
  type: UNIT_LESSON_SELECTED,
  payload: { unit, lesson }
});
export const unitLessonFilterSelected = (unitFilter, lessonFilter) => ({
  type: UNIT_LESSON_FILTER_SELECTED,
  payload: { unitFilter, lessonFilter }
});
export const topicSelected = topic => ({
  type: SCHEDULER_TOPIC_SELECTED,
  payload: { topic }
});
export const topicFilterSelected = topicFilter => ({
  type: SCHEDULER_TOPIC_FILTER_SELECTED,
  payload: { topicFilter }
});
export const schedulerBookSession = (onSuccess, onError) => ({
  type: SCHEDULER_BOOK_SESSION,
  payload: { onSuccess, onError }
});
export const schedulerCancelSession = () => ({
  type: SCHEDULER_CANCEL_SESSION
});
export const schedulerRequestDateTime = () => ({
  type: SCHEDULER_REQUEST_TIME
});
export const schedulerSetRequestedDate = requestedDate => ({
  type: SCHEDULER_SET_REQUESTED_DATE,
  payload: { requestedDate }
});
export const schedulerSetRequestedTime = requestedTime => ({
  type: SCHEDULER_SET_REQUESTED_TIME,
  payload: { requestedTime }
});
export const sessionCancelled = sessionCancelled => ({
  type: SESSION_CANCELLED,
  payload: { sessionCancelled }
});
export const sessionCancelFailed = errorCode => ({
  type: SESSION_CANCEL_FAILED,
  payload: { errorCode }
});
export const sessionCancelClearState = () => ({
  type: SESSION_CANCEL_CLEAR_STATE
});
export const sessionBooked = attendanceId => ({
  type: SESSION_BOOKED,
  payload: { attendanceId }
});
export const schedulerLoadTopics = (onSuccess, onError) => ({
  type: SCHEDULER_LOAD_TOPICS,
  payload: { onSuccess, onError }
});
export const schedulerSetTutorFilter = tutor => ({ type: SET_TUTOR_FILTER, payload: { tutor } });
export const schedulerSetDebugTutorFilter = tutorName => ({ type: SET_DEBUG_TUTOR_FILTER, payload: { tutorName } });
export const schedulerDateSelected = date => ({ type: SCHEDULER_DATE_SELECTED, payload: { date } });

// -=-=-=-=-=-=-=-=-=-=- Selectors -=-=-=-=-=-=-=-=-=-=-
export const getAvailableSessions = state => state.scheduler.sessions;
export const getAvailableGroupedSessions = state => state.scheduler.groupedSessions;
export const getScheduledSessions = state => state.scheduler.scheduledSessions;
export const isSchedulerInitialized = state => state.scheduler.schedulerInitialized;
export const getSelectedProductId = state => state.scheduler.selectedProductId;
export const getSelectedProduct = state => {
  const id = getSelectedProductId(state);
  // This is a little hacky, but we don't get the info any other way.
  if (!id || id === "") return PRODUCTS.PRODUCT_UNKNOWN;
  if (id.substr(0, 3) === "FB-") return PRODUCTS.PRODUCT_FB;
  if (id.substr(0, 3) === "WW-") return PRODUCTS.PRODUCT_WW;
  return PRODUCTS.PRODUCT_STUDIO;
};

export const isSchedulerLoading = state => state.scheduler.schedulerLoading;
export const getScheduleSelectedDate = state => state.scheduler.selectedDate;
export const getScheduleTutorFilter = state => state.scheduler.tutorFilter;
export const getUniqueSessionDays = state => state.scheduler.uniqueSessionDays;
export const getSelectedSession = state => state.scheduler.selectedSession;
export const getSelectedUnit = state => state.scheduler.selectedUnit;
export const getSelectedUnitFilter = state => state.scheduler.selectedUnitFilter;
export const getSelectedLesson = state => state.scheduler.selectedLesson;
export const getSelectedLessonFilter = state => state.scheduler.selectedLessonFilter;
export const getSelectedTopic = state => state.scheduler.selectedTopic;
export const getSelectedTopicFilter = state => state.scheduler.selectedTopicFilter;
export const getLastBookedAttendanceId = state => state.scheduler.lastBookedAttendanceId;
export const getSchedulingConfig = state => state.scheduler.schedulingConfig;
export const isRecordingAllowed = state => (getSchedulingConfig(state) || {}).session_recording_allowed;
export const getTopics = state => state.scheduler.topics;
export const getConsumableInfo = state => state.scheduler.consumableInfo;

const hasConsumable = consumables => consumables && consumables.filter(c => !c.claimed).length > 0;

export const hasAnyUnlimited = state => {
  const info = getConsumableInfo(state);
  return info && (info.has_unlimited_group_studio || info.has_unlimited_one_on_one_studio);
};

export const canSchedule = state => {
  const info = getConsumableInfo(state);
  return (
    info &&
    (info.has_unlimited_group_studio ||
      info.has_unlimited_one_on_one_studio ||
      hasConsumable(info.group_consumables) ||
      hasConsumable(info.one_on_one_consumables))
  );
};
export const canScheduleIndividual = state => {
  const info = getConsumableInfo(state);
  return info && (info.has_unlimited_one_on_one_studio || hasConsumable(info.one_on_one_consumables));
};
export const canScheduleGroup = state => {
  const info = getConsumableInfo(state);
  return info && (info.has_unlimited_group_studio || hasConsumable(info.group_consumables));
};
export const getSignedUpSessions = state => state.scheduler.signedUpSessions;
export const getUniqueTutors = state => state.scheduler.uniqueTutors;
export const getSessionCancelled = state => state.scheduler.sessionCancelled;
export const getRequestedDate = state => state.scheduler.requestedDate;
export const getRequestedTime = state => state.scheduler.requestedTime;
export const getSessionCancelErrorCode = state => state.scheduler.sessionCancelErrorCode;

export const getScheduledSession = (state, sessionId) =>
  state.scheduler.scheduledSessions.find(session => session.id === sessionId);

export const isScheduledSessionGroup = (state, sessionId) => {
  const session = getScheduledSession(state, sessionId);
  if (!session) return false;
  return session.number_of_seats > 1;
};

export const findSessionById = (state, sessionId) =>
  state.scheduler.sessions.find(s => s.eschool_session_id === sessionId) ||
  state.scheduler.scheduledSessions.find(s => s.eschool_session_id === sessionId);
