import { ResourceManager } from "@rosetta/resource-manager";

let _rm = null;
const resourceManager = (keys, puddleRoot) => {
  _rm = _rm || new ResourceManager(keys, puddleRoot, "", true);
  return _rm;
};

const forceSecure = url => (url.substr(0, 5) === "http:" ? "https:" + url.substr(5) : url);

export const TopicService = {
  loadTopic: (resourceId, keys, puddleRoot) => resourceManager(keys, forceSecure(puddleRoot)).loadResource(resourceId),
  loadImage: (resourceId, keys, puddleRoot) => resourceManager(keys, forceSecure(puddleRoot)).loadResource(resourceId)
};
