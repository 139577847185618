import { combineReducers } from "redux";
import { rstvODReducer } from "./ondemand/rstv-ondemand-reducer";
import { rstvLiveReducer } from "./live/rstv-live-reducer";
import sreReducer from "./rstv-sre-reducer";

export const RSTV_INIT_APP = "RSTV_INIT_APP";

export const rstvReducer = combineReducers({
  onDemand: rstvODReducer,
  live: rstvLiveReducer,
  sre: sreReducer
});

export const rstvInitApp = () => ({ type: RSTV_INIT_APP });
