import React, { Component } from "react";
import { connect } from "react-redux";
import { appError } from "../app/reducer/reducer";

class ErrorBoundary extends Component {
  state = {
    hasError: false,
    error: null,
    info: null
  };

  async componentDidCatch(error, info) {
    this.props.appError(error, "Unknown Error", {
      info
    });

    this.setState({
      ...this.state,
      hasError: true,
      error: error,
      info: info
    });
  }

  render() {
    if (this.state.hasError) {
      const error = this.state.error;
      const info = this.state.info;

      // Fallback UI
      return (
        <div
          style={{
            backgroundColor: "#222",
            border: "2px solid #eee",
            borderRadius: 12,
            boxShadow: "#86888a 3px 3px 6px",
            color: "#dede4e",
            margin: 20,
            padding: 20
          }}
        >
          <h4 style={{ color: "#c73333", paddingBottom: 10 }}>An unrecoverable application error has occurred</h4>

          <hr style={{ background: "#7d8187" }} />
          <p>{error.message.toString()}</p>
          <h3>Reference: {this.state.id}</h3>
          <h3>Trace:</h3>
          <ul>
            {info.componentStack
              .split(/(?:\r\n|\r|\n)/g)
              .filter(item => item !== "")
              .map((item, index) => (
                <li key={index}>{item}</li>
              ))}
          </ul>
        </div>
      );
    }
    return this.props.children;
  }
}

const mapStateToProps = () => {
  return {};
};

export default connect(
  mapStateToProps,
  { appError }
)(ErrorBoundary);
