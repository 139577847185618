import React from "react";
import Button from "@material-ui/core/Button";
import styled from "@emotion/styled";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import { Grid, Card, CardContent, CardActions, MenuItem, InputLabel } from "@material-ui/core";
import { connect } from "react-redux";
import { rtRecieveTokens } from "../../realtime/realtime-reducer";

import * as DebugConstants from "../../learner/debug-constants";

const DebugPageContainer = styled.div({
  backgroundColor: "white",
  padding: 10
});

class DebugPage extends React.Component {
  state = {
    username: DebugConstants.authInfo.username,
    password: DebugConstants.authInfo.password,
    locale: "en-US",
    product: DebugConstants.authInfo.product
  };

  handleChange = fieldName => event => this.setState({ [fieldName]: event.target.value });

  componentDidMount() {
    this.setState({
      username: localStorage.getItem("tutor.debug.username") || this.state.username,
      password: localStorage.getItem("tutor.debug.password") || this.state.password,
      locale: localStorage.getItem("tutor.debug.locale") || this.state.locale,
      product: localStorage.getItem("tutor.debug.product") || this.state.product
    });
  }

  saveDebugUser = () => {
    localStorage.setItem("tutor.debug.username", this.state.username);
    localStorage.setItem("tutor.debug.password", this.state.password);
    localStorage.setItem("tutor.debug.locale", this.state.locale);
    localStorage.setItem("tutor.debug.product", this.state.product);
  };

  clearLocale = () => {
    localStorage.removeItem("tutor.debug.locale");
  };

  render() {
    return (
      <DebugPageContainer>
        <h1>Debug Page</h1>
        <Grid container spacing={24} alignItems="stretch">
          <Grid item xs={4}>
            <Card>
              <CardContent>
                Learner Debug User Login
                <br />
                <TextField
                  style={{ width: "100%" }}
                  id="standard-name"
                  label="username"
                  value={this.state.username}
                  onChange={this.handleChange("username")}
                  margin="normal"
                />
                <TextField
                  style={{ width: "100%" }}
                  id="standard-pass"
                  label="password"
                  value={this.state.password}
                  onChange={this.handleChange("password")}
                  margin="normal"
                />
                <br />
                <InputLabel htmlFor="standard-product">Product</InputLabel>
                <br />
                <TextField
                  style={{ width: "100%" }}
                  inputProps={{ "data-qa": "standard-product" }}
                  id="standard-product"
                  label="product"
                  value={this.state.product}
                  onChange={this.handleChange("product")}
                  margin="normal"
                />
                <br />
                <InputLabel htmlFor="standard-locale">Locale</InputLabel>
                <br />
                <Select
                  style={{ width: "30%" }}
                  id="standard-locale"
                  label="locale"
                  value={this.state.locale}
                  onChange={this.handleChange("locale")}
                >
                  <MenuItem value={"de-DE"}>de-DE</MenuItem>
                  <MenuItem value={"en-US"}>en-US</MenuItem>
                  <MenuItem value={"es-419"}>es-419</MenuItem>
                  <MenuItem value={"fr-FR"}>fr-FR</MenuItem>
                  <MenuItem value={"it-IT"}>it-IT</MenuItem>
                  <MenuItem value={"ja-JP"}>ja-JP</MenuItem>
                  <MenuItem value={"ko-KR"}>ko-KR</MenuItem>
                  <MenuItem value={"nl-NL"}>nl-NL</MenuItem>
                  <MenuItem value={"pl-PL"}>pl-PL</MenuItem>
                  <MenuItem value={"pt-BR"}>pt-BR</MenuItem>
                  <MenuItem value={"ru-RU"}>ru-RU</MenuItem>
                  <MenuItem value={"zh-CN"}>zh-CN</MenuItem>
                </Select>
                <Button size="small" color="primary" onClick={this.clearLocale}>
                  Clear locale
                </Button>
              </CardContent>
              <CardActions>
                <Button size="small" color="primary" onClick={this.saveDebugUser}>
                  <div data-qa="save-button">Save</div>
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </DebugPageContainer>
    );
  }
}

export default connect(
  null,
  { rtRecieveTokens }
)(DebugPage);
