/* These are the entries that are stored in our events log for archiving of past sessions so we can play
   back the session.
*/

/**
 * Generates some common properties for all our events.
 * @param {} type
 * @param {*} timestamp
 * @param {*} rest
 */
const commonEvent = (type, timestamp, rest) => {
  const ts = timestamp || new Date().getTime();
  return {
    type,
    timestamp: ts,
    ...rest
  };
};

/**
 * Occurs when the archive recording starts. We need this to sync up the video with the event stream.
 *
 * @param {*} archiveId
 * @param {*} timestamp
 */
export const archiveStartEvent = (archiveId, timestamp = undefined) => {
  return commonEvent("archiveStart", timestamp, {
    id: archiveId
  });
};

/**
 * Happens when a stream connects. Right now, we don't do anything with it, but it may be important to
 * sync up events later.
 *
 * @param {*} streamName
 * @param {*} timestamp
 */
export const streamConnectedEvent = (streamName, timestamp = undefined) => {
  return commonEvent("streamConnected", timestamp, {
    streamName: "name of stream"
  });
};

/**
 * All our custom signals go in like this. The two most important for playback are chat and showSlide
 *
 * @param {*} signal
 * @param {*} connectionId
 * @param {*} timestamp
 */
export const signalEvent = (signal, connectionId, timestamp = undefined) => {
  return commonEvent("signal", timestamp, {
    signal: {
      ...signal,
      connectionId
    }
  });
};

/**
 * Occurs if an archive recording stops. Need this to sync events & videos.
 *
 * @param {*} archiveId
 * @param {*} timestamp
 */
export const archiveStopEvent = (archiveId, timestamp = undefined) => {
  return commonEvent("archiveStop", timestamp, {
    id: archiveId
  });
};

/**
 * Occurs when the whiteboard state changes
 *
 * @param {*} timestamp
 */
export const whiteboardEvent = (whiteboardState, timestamp = undefined) => {
  return commonEvent("whiteboard", timestamp, {
    data: whiteboardState
  });
};

/**
 * We also use the event log for storing past chat data. This turns the event log into a format suitable to use
 * as chat history.
 *
 * @param {*} events
 */
export const eventsToChatHistory = events =>
  (events || [])
    .filter(event => event.type === "signal" && event.signal.type === "chat")
    .map(event => event.signal)
    .map(signal => ({
      displayName: signal.data.displayName,
      userId: signal.data.userId,
      msg: signal.data.msg,
      connectionId: signal.connectionId,
      ts: signal.data.ts
    }));
