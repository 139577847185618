import React from "react";
import exact from "prop-types-exact";
import { PropTypes } from "prop-types";

// eslint-disable-next-line no-unused-vars
export const StarIcon = ({ color = "#E6E6E6", ...props }) => (
  <svg width={props.width || "46"} height={props.height || "44"} viewBox="0 0 46 44" fill="none">
    <path
      d="M23 0L28.3883 16.5836H45.8254L31.7185 26.8328L37.1068 43.4164L23 33.1672L8.89315 43.4164L14.2815 26.8328L0.174644 16.5836H17.6117L23 0Z"
      fill={color}
    />
  </svg>
);
StarIcon.propTypes = exact({
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string
});
StarIcon.defaultProps = {};
export default StarIcon;
