import { ESCHOOL_FEEDBACK_LOADED, WELCOME_PACKET_RECIEVED } from "../app/reducer/reducer";
import { parseFeedback } from "./coach-service";
import { RT_LEARNER_WELCOME_RECEIVE } from "../realtime/realtime-reducer";
import { PRODUCTS } from "../app/constants";

export const COACH_EJECT_USER = "COACH_EJECT_USER";
export const COACH_MUTE = "COACH_MUTE";
export const COACH_UNMUTE = "COACH_UNMUTE";
export const COACH_MUTE_SELF = "COACH_MUTE_SELF";
export const COACH_UNMUTE_SELF = "COACH_UNMUTE_SELF";
export const COACH_PUBLISH_FEEDBACK = "COACH_PUBLISH_FEEDBACK";
export const COACH_MODIFY_VOCAB = "COACH_MODIFY_VOCAB";
export const COACH_MODIFY_FEEDBACK = "COACH_MODIFY_FEEDBACK";
export const COACH_RATE_LEARNER = "COACH_RATE_LEARNER";
export const COACH_MODIFY_INTERNAL_NOTES = "COACH_MODIFY_INTERNAL_NOTES";
export const COACH_PRODUCT_SPECIFIED = "COACH_PRODUCT_SPECIFIED";
export const COACH_STUDENTS_LOADED = "COACH_STUDENTS_LOADED";

export const COACH_SAVE_FEEDBACK = "COACH_SAVE_FEEDBACK";
const COACH_FEEDBACK_SAVED = "COACH_FEEDBACK_SAVED";
const COACH_FEEDBACK_LOADED = "COACH_FEEDBACK_LOADED";
const COACH_FEEDBACK_PUBLISHED = "COACH_FEEDBACK_PUBLISHED";

const HIDE_SETTINGS = "HIDE_SETTINGS";
const SHOW_SETTINGS = "SHOW_SETTINGS";

export const COACH_NOTES_LOADED = "COACH_NOTES_LOADED";
export const COACH_NOTE_ADDED = "COACH_NOTE_ADDED";
export const COACH_NOTE_SAVED = "COACH_NOTE_SAVED";
export const COACH_NOTE_MODIFIED = "COACH_NOTE_MODIFIED";
export const COACH_SLIDE_VOCAB_LOADED = "COACH_SLIDE_VOCAB_LOADED";

const defaultRatings = {
  vocab: -1,
  pronunciation: -1,
  appropriateness: -1,
  grammar: -1
};

const initial = {
  studentIdsSeen: [], // a list of student ID's who actually showed up at some point.
  coachId: null,
  students: [],
  coachMuted: false,
  languageProduct: null,
  saving: false,
  settingsVisible: false,
  learnerName: "learner",
  durationMinutes: 90,
  startTime: 0,
  authoredVocab: [],
  learnerFeedback: "",
  ratings: defaultRatings,
  internalNotes: "",
  published: false,
  unsaved: false,
  groupSession: false,
  product: PRODUCTS.PRODUCT_UNKNOWN,

  slideNotes: [],
  slideVocab: [],
  noteRecords: [],
  dirtyNotes: [], // list of indexes of notes that need saving
  noteSaving: false
};

const onCoachSlideVocabLoaded = (state, action) => {
  const slideVocab = state.slideVocab.concat();
  slideVocab[action.payload.slideIndex] = action.payload.vocab;

  return {
    ...state,
    slideVocab
  };
};

const onCoachNotesLoaded = (state, action) => {
  const slideNotes = state.slideNotes.concat();
  slideNotes[action.payload.noteIndex] = action.payload.combinedNoteText;

  return {
    ...state,
    noteRecords: [...state.noteRecords, ...action.payload.noteRecords],
    slideNotes
  };
};

const onCoachNoteModified = (state, action) => {
  const slideNotes = state.slideNotes.concat();
  slideNotes[action.payload.noteIndex] = action.payload.noteText;

  const dirtyNotes = state.dirtyNotes.includes(action.payload.noteIndex)
    ? state.dirtyNotes
    : [...state.dirtyNotes, action.payload.noteIndex];

  return {
    ...state,
    dirtyNotes,
    slideNotes
  };
};

export const coachReducer = (state = initial, action = undefined) => {
  switch (action && action.type) {
    case COACH_SLIDE_VOCAB_LOADED:
      return onCoachSlideVocabLoaded(state, action);

    case COACH_NOTES_LOADED:
      return onCoachNotesLoaded(state, action);

    case COACH_NOTE_MODIFIED:
      return onCoachNoteModified(state, action);

    case COACH_NOTE_ADDED:
      return {
        ...state,
        noteSaving: true
      };

    case COACH_NOTE_SAVED:
      return {
        ...state,
        dirtyNotes: state.dirtyNotes.filter(index => index !== action.payload.slideIndex),
        noteSaving: false
      };

    case COACH_UNMUTE_SELF:
      return { ...state, coachMuted: false };
    case COACH_MUTE_SELF:
      return { ...state, coachMuted: true };
    case RT_LEARNER_WELCOME_RECEIVE:
      if (!action.payload.data || !action.payload.data.userId) return state;
      return {
        ...state,
        studentIdsSeen: [...state.studentIdsSeen, action.payload.data.userId]
      };
    case WELCOME_PACKET_RECIEVED:
      return {
        ...state,
        groupSession: action.payload.numberOfSeats > 1
      };
    case COACH_FEEDBACK_PUBLISHED:
      return { ...state, saving: false, published: true };
    case COACH_FEEDBACK_SAVED:
      return { ...state, saving: false, unsaved: false };
    case COACH_SAVE_FEEDBACK:
      return { ...state, saving: true };
    case COACH_PUBLISH_FEEDBACK:
      return { ...state, saving: true };
    case SHOW_SETTINGS:
      return { ...state, settingsVisible: true };
    case HIDE_SETTINGS:
      return { ...state, settingsVisible: false };

    case ESCHOOL_FEEDBACK_LOADED:
      // this is the api load
      return {
        ...state,
        ...parseFeedback(action.payload.feedback)
      };

    case COACH_PRODUCT_SPECIFIED:
      return {
        ...state,
        languageProduct: action.payload.languageProduct,
        product: action.payload.product
      };

    case COACH_FEEDBACK_LOADED:
      // This is the localstore load
      return {
        ...state,
        authoredVocab: action.payload.vocab || [],
        learnerFeedback: action.payload.feedback || "",
        internalNotes: action.payload.internalNotes || "",
        ratings: action.payload.ratings || defaultRatings
      };

    case COACH_MODIFY_VOCAB:
      return {
        ...state,
        unsaved: true,
        authoredVocab: action.payload.vocab
      };

    case COACH_STUDENTS_LOADED:
      return { ...state, students: action.payload.students };

    case COACH_MODIFY_FEEDBACK:
      return {
        ...state,
        unsaved: true,
        learnerFeedback: action.payload.feedback
      };

    case COACH_RATE_LEARNER:
      return {
        ...state,
        unsaved: true,
        ratings: {
          ...state.ratings,
          [action.payload.ratingType]: action.payload.ratingScore
        }
      };

    case COACH_MODIFY_INTERNAL_NOTES:
      return {
        ...state,
        unsaved: true,
        internalNotes: action.payload.internalNotes
      };

    default:
      return state;
  }
};

// -=-=-=-=-=-=-=-=-=-=- Action Creators -=-=-=-=-=-=-=-=-=-=-

export const hideSettings = () => ({ type: HIDE_SETTINGS });
export const showSettings = () => ({ type: SHOW_SETTINGS });
export const coachStudentsLoaded = students => ({ type: COACH_STUDENTS_LOADED, payload: { students } });
export const coachModifyVocab = vocab => ({ type: COACH_MODIFY_VOCAB, payload: { vocab } });
export const coachModifyFeedback = feedback => ({ type: COACH_MODIFY_FEEDBACK, payload: { feedback } });
export const coachRateLearner = (ratingType, ratingScore) => ({
  type: COACH_RATE_LEARNER,
  payload: { ratingType, ratingScore }
});
export const coachModifyInternalNotes = internalNotes => ({
  type: COACH_MODIFY_INTERNAL_NOTES,
  payload: { internalNotes }
});

export const coachSlideVocabLoaded = (vocab, slideIndex) => ({
  type: COACH_SLIDE_VOCAB_LOADED,
  payload: { vocab, slideIndex }
});

export const coachNotesLoaded = (combinedNoteText, noteIndex, noteRecords) => ({
  type: COACH_NOTES_LOADED,
  payload: { combinedNoteText, noteIndex, noteRecords }
});
export const coachNoteAdded = () => ({ type: COACH_NOTE_ADDED, payload: {} });
export const coachNoteSaved = slideIndex => ({ type: COACH_NOTE_SAVED, payload: { slideIndex } });
export const coachNoteModified = (noteText, noteIndex) => ({
  type: COACH_NOTE_MODIFIED,
  payload: { noteText, noteIndex }
});

export const coachFeedbackLoaded = (feedback, internalNotes, vocab, ratings) => ({
  type: COACH_FEEDBACK_LOADED,
  payload: { internalNotes, vocab, feedback, ratings }
});

export const coachFeedbackSaved = () => ({
  type: COACH_FEEDBACK_SAVED
});

export const coachProductSpecified = (product, languageProduct) => ({
  type: COACH_PRODUCT_SPECIFIED,
  payload: { product, languageProduct }
});

export const coachSaveFeedback = () => ({ type: COACH_SAVE_FEEDBACK });
export const coachPublishFeedback = () => ({ type: COACH_PUBLISH_FEEDBACK });
export const coachFeedbackPublished = () => ({ type: COACH_FEEDBACK_PUBLISHED });

export const coachMuteSelf = () => ({ type: COACH_MUTE_SELF });
export const coachUnMuteSelf = () => ({ type: COACH_UNMUTE_SELF });

export const coachMuteLearner = connectionId => ({ type: COACH_MUTE, payload: { connectionId } });
export const coachUnMuteLearner = connectionId => ({ type: COACH_UNMUTE, payload: { connectionId } });
export const coachEjectUser = connectionId => ({ type: COACH_EJECT_USER, payload: { connectionId } });

// -=-=-=-=-=-=-=-=-=-=- Selectors -=-=-=-=-=-=-=-=-=-=-

export const getCoachNoteRecords = (state, slideIndex) =>
  state.coach.noteRecords.filter(record => record.tags.slide_index === String(slideIndex));
export const getCoachId = state => state.coach.coachId;
export const getProduct = state => state.coach.product;
export const getLanguageProduct = state => state.coach.languageProduct;
export const getDurationMinutes = state => state.coach.durationMinutes;
export const getStartTime = state => state.coach.startTime;
export const hasUnsavedWork = state => state.coach.unsaved;
export const isWorldWideTutoring = state => state.coach.product === PRODUCTS.PRODUCT_WW;
export const isCoachMuted = state => state.coach.coachMuted;
export const isFeedbackPublished = state => state.coach.published;
export const isFeedbackSaving = state => state.coach.saving;
export const isSettingsVisible = state => state.coach.settingsVisible;
export const isCoachGroupSession = state => state.coach.groupSession;
export const getLearnerName = state => state.coach.learnerName;
export const getDirtyNoteIndexes = state => state.coach.dirtyNotes;
export const getCoachNotes = state => state.coach.slideNotes;
export const getCoachVocab = state => state.coach.slideVocab;
export const getCoachAuthoredVocab = state => state.coach.authoredVocab;
export const getCoachLearnerFeedback = state => state.coach.learnerFeedback;
export const getCoachInternalNote = state => state.coach.internalNotes;
const getCoachRating = (state, ratingType) => state.coach.ratings[ratingType];
export const getCoachRatingVocab = state => getCoachRating(state, "vocab");
export const getCoachRatingPronunciation = state => getCoachRating(state, "pronunciation");
export const getCoachRatingAppropriateness = state => getCoachRating(state, "appropriateness");
export const getCoachRatingGrammar = state => getCoachRating(state, "grammar");
export const getCoachStudents = state => state.coach.students;
export const getAllCoachRatings = state => state.coach.ratings;
export const getStudentsSeen = state => state.coach.studentIdsSeen;
