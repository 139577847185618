import { WELCOME_PACKET_RECIEVED } from "../app/reducer/reducer";

export const CHAT_SEND_MESSAGE = "CHAT_SEND_MESSAGE";
export const CHAT_SEND_PRIVATE_MESSAGE = "CHAT_SEND_PRIVATE_MESSAGE";
export const CHAT_RECIEVE_MESSAGE = "CHAT_RECIEVE_MESSAGE";
export const CHAT_RECIEVE_PRIVATE_MESSAGE = "CHAT_RECIEVE_PRIVATE_MESSAGE";
export const CHAT_HISTORY_LOADED = "CHAT_HISTORY_LOADED";

export const CHAT_SELECT_CHAT = "CHAT_SELECT_CHAT";
export const CHAT_DESELECT_CHAT = "CHAT_DESELECT_CHAT";

const initial = {
  activeChat: null, // Either    null  - for no active chat,
  //          group  - for the group chat
  //         userId  - for a private chat
  // support:userId  - for a support chat
  unreadMessages: {
    // How many unread messages are there in a given chat (group or learner user ID)
    group: 0
  }
};
export const chatReducer = (state = initial, action = undefined) => {
  switch (action && action.type) {
    case CHAT_DESELECT_CHAT:
      return { ...state, activeChat: null };
    case CHAT_SELECT_CHAT:
      return {
        ...state,
        activeChat: action.payload.chat,
        unreadMessages: {
          ...state.unreadMessages,
          [action.payload.chat]: 0
        }
      };
    case CHAT_RECIEVE_MESSAGE:
      if (state.activeChat === "group") return state;
      return {
        ...state,
        unreadMessages: {
          ...state.unreadMessages,
          group: state.unreadMessages.group + 1
        }
      };
    case CHAT_RECIEVE_PRIVATE_MESSAGE:
      const learnerId = action.payload.message.learnerId;
      if (state.activeChat === learnerId) return state;
      return {
        ...state,
        unreadMessages: {
          ...state.unreadMessages,
          [learnerId]: (state.unreadMessages[learnerId] || 0) + 1
        }
      };
    case WELCOME_PACKET_RECIEVED:
      return {
        ...state,
        activeChat: action.payload.numberOfSeats === 1 ? "group" : state.activeChat
      };
    default:
      return state;
  }
};

// Pass in a timestamp, and it returns it. Pass in undefined, and the current timestamp is returned. (mostly for unit tests)
const tsOrNow = ts => ts || Math.floor(new Date().getTime() / 1000);

// -=-=-=-=-=-=-=-=-=-=- Action Creators -=-=-=-=-=-=-=-=-=-=-

export const chatSelectChat = chat => ({ type: CHAT_SELECT_CHAT, payload: { chat } });
export const chatDeselectChat = () => ({ type: CHAT_DESELECT_CHAT });

/**
 *
 * The application has loaded up the chat history (likely from localStorage) and it can be set as the current
 * chat history.
 *
 * @param {} chatHistory
 */
export const chatHistoryLoaded = chatHistory => ({ type: CHAT_HISTORY_LOADED, payload: { chatHistory } });

/**
 *
 * The user is sending a chat message.
 *
 * @param {*} from
 * @param {*} msg
 * @param {*} connectionId
 * @param {*} ts
 */
export const chatSendMessage = (displayName, userId, msg, connectionId, ts = undefined) => ({
  type: CHAT_SEND_MESSAGE,
  payload: { message: { displayName, userId, msg, connectionId, ts: tsOrNow(ts) } }
});

/**
 *
 * The user is sending a chat message to a specific person
 *
 * @param {*} from
 * @param {*} msg
 * @param {*} connectionId
 * @param {*} ts
 */
export const chatSendPrivateMessage = (displayName, userId, msg, connectionId, learnerUserId, ts = undefined) => ({
  type: CHAT_SEND_PRIVATE_MESSAGE,
  payload: { message: { displayName, userId, msg, connectionId, learnerUserId, ts: tsOrNow(ts) } }
});

/**
 *
 * We have received a chat message from a user (it might be us, or another party)
 *
 * @param {*} from
 * @param {*} msg
 * @param {*} connectionId
 * @param {*} ts
 */
export const chatReceiveMessage = (displayName, userId, msg, connectionId, ts = undefined) => ({
  type: CHAT_RECIEVE_MESSAGE,
  payload: { message: { displayName, userId, msg, connectionId, ts: tsOrNow(ts) } }
});

export const chatReceivePrivateMessage = (displayName, userId, msg, connectionId, learnerId, ts = undefined) => ({
  type: CHAT_RECIEVE_PRIVATE_MESSAGE,
  payload: { message: { displayName, userId, msg, connectionId, learnerId, ts: tsOrNow(ts) } }
});

// -=-=-=-=-=-=-=-=-=-=- Selectors -=-=-=-=-=-=-=-=-=-=-
export const getActiveChat = state => state.chat.activeChat;
export const getUnreadCount = (state, userId) => state.chat.unreadMessages[userId] || 0;
