import React from "react";
import { SUPPORTED_LEVEL } from "../../app/services/user-agent-util";
import BrowserLogos from "./BrowserLogos";
import { LionTranslate } from "@rosetta/react-lion";

import styled from "@emotion/styled";
import { EFFRA_FONT_FAMILY } from "../../../styles/constants";
const BrowserSupportContainer = styled.div({
  position: "absolute",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: "#F1F3F6",
  zIndex: 10,
  justifyContent: "center"
});

const BrowserMessage = styled.div({
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  maxWidth: 900,
  marginLeft: 15,
  marginRight: 15
});

const Title = styled.div({
  textAlign: "center",
  fontFamily: EFFRA_FONT_FAMILY,
  color: "#3E3D3F",
  fontSize: 32,
  lineHeight: "39px"
});
const Message = styled.div({
  textAlign: "center",
  fontFamily: EFFRA_FONT_FAMILY,
  color: "#3E3D3F",
  fontSize: 22,
  lineHeight: 1.5,
  marginBottom: 30
});
const Requirements = styled.div({
  fontFamily: EFFRA_FONT_FAMILY,
  color: "#3E3D3F",
  fontSize: 22,
  lineHeight: 1.5,
  marginTop: 50,
  marginBottom: 65
});

const Button = styled.button({
  color: "#ffffff",
  fontFamily: EFFRA_FONT_FAMILY,
  fontSize: 18,
  background: "#4195D3",
  borderRadius: 6,
  padding: "15px 50px"
});

export const BrowserSupportMessage = props => (
  <BrowserSupportContainer>
    <BrowserMessage>
      <Title>
        <LionTranslate lionkey="browser_support_title" />
      </Title>
      <Message>
        {props.level === SUPPORTED_LEVEL.NOT_SUPPORTED && <LionTranslate lionkey="browser_support_update" />}
        {props.level === SUPPORTED_LEVEL.MAYBE_SUPPORTED && <LionTranslate lionkey="browser_support_warn" />}
      </Message>

      <BrowserLogos />

      <Requirements>
        <LionTranslate lionkey="browser_support_requirements" />
      </Requirements>

      {props.level === SUPPORTED_LEVEL.NOT_SUPPORTED || (
        <Button onClick={props.onDismissWarning}>
          <LionTranslate lionkey="browser_support_continue" />
        </Button>
      )}
    </BrowserMessage>
  </BrowserSupportContainer>
);
