import React from "react";
import { rtMicListUpdated } from "../../realtime/realtime-reducer";
import { connect } from "react-redux";
import { appError } from "../../app/reducer/reducer";

/**
 * Utility component to get the currently available media devices and then render out the render prop
 * with one param containing the following properties:
 *
 * grantedAccess
 * cameraList
 * micList
 * speakerList
 * selectedCamera
 * selectedMic
 * selectedSpeaker
 */
class DeviceLister extends React.Component {
  static defaultProps = {
    video: true
  };

  state = {
    selectedCamera: "",
    selectedMic: "",
    selectedSpeaker: "",
    cameraList: [],
    micList: [],
    speakerList: [],
    grantedAccess: false
  };

  deviceRetries = 0;

  namelessDevices = devices => devices.length > 0 && devices.filter(d => d.label.length > 0).length === 0;

  onDevices = devices => {
    console.log("Got devices", devices);
    this.clearRetry();
    const micList = devices.filter(d => d.kind === "audioinput");

    if (this.namelessDevices(devices) && this.deviceRetries < 50) {
      // Retry getting device names in 5 seconds if we got a list back with no names.
      this.retryTimeout = setTimeout(this.refreshDevices, 5000);
      this.deviceRetries++;
      return;
    }

    this.props.rtMicListUpdated && this.props.rtMicListUpdated(micList);
    this.setState({
      grantedAccess: true,
      cameraList: devices.filter(d => d.kind === "videoinput"),
      micList,
      speakerList: devices.filter(d => d.kind === "audiooutput"),
      selectedCamera: (devices.find(d => d.kind === "videoinput") || {}).deviceId,
      selectedMic: (devices.find(d => d.kind === "audioinput") || {}).deviceId,
      selectedSpeaker: (devices.find(d => d.kind === "audiooutput") || {}).deviceId
    });
  };

  refreshDevices = () => {
    this.setState({ cameraList: [], micList: [] });
    this.getDevices();
  };

  getDevices = () => {
    console.log("Getting device list");
    window.navigator.mediaDevices.enumerateDevices().then(this.onDevices);
  };

  clearRetry = () => this.retryTimeout && clearTimeout(this.retryTimeout);

  componentWillUnmount() {
    this.clearRetry();
    navigator.mediaDevices.removeEventListener("devicechange", this.getDevices);
  }

  componentDidMount() {
    console.log("Getting user media", this.props.video);

    navigator.mediaDevices.addEventListener("devicechange", this.getDevices);

    // Do a getUserMedia first, so we're most likely to be able to get the names of devices.
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: this.props.video })
      .catch(e => {
        // If we fail once, try again without asking for video.
        console.warn("getUserMedia failed, retrying without requesting video", e);
        return navigator.mediaDevices.getUserMedia({ audio: true, video: false });
      })
      .then(stream => {
        console.log("getUserMedia success");
        this.getDevices();
        this.tempTracks = stream.getTracks();
        this.tempTracks.forEach(track => {
          // Stop the getUserMedia tracks that we used to get permissions
          track.stop();
        });
      })
      .then(() => setTimeout(this.getDevices))
      .catch(error => {
        this.props.appError(error, "err_access_mic");
      });
  }

  render() {
    console.info("DEviceLister", this.props.video);
    return this.props.render({ ...this.state, refreshDevices: this.refreshDevices });
  }
}

export default connect(
  null,
  { rtMicListUpdated, appError }
)(DeviceLister);
