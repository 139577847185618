import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { rootReducer } from "./root-reducer";
import { rootSaga } from "./root-saga";
import { routerMiddleware } from "connected-react-router";

const configureStore = history => {
  const sagaMiddleware = createSagaMiddleware();

  const middleware = [routerMiddleware(history), sagaMiddleware];

  const createStoreWithMiddleware = applyMiddleware(...middleware)(createStore);

  let store = null;
  if (process.env.NODE_ENV === "development") {
    // Filter out actions that happen a lot and in quick succession
    // These define the actions which show in the redux tool
    const devToolsOptions = {
      maxAge: 20,
      actionsBlacklist: ["WB_MOUSE_MOVE"]
    };

    // Set up store with redux dev tools in dev mode.
    store = createStoreWithMiddleware(
      rootReducer(history),
      window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(devToolsOptions)
    );
  } else {
    store = createStoreWithMiddleware(rootReducer(history));
  }

  return {
    ...store,
    runSaga: sagaMiddleware.run(rootSaga)
  };
};

export default configureStore;
