import { Notifier } from "@airbrake/browser";
import { extractPath } from "@rosetta/redux-utils";
import StackTrace from "stacktrace-js";

import {
  INIT_APP_COMPLETE,
  getErrbitUrl,
  getErrbitApi,
  APP_ERROR,
  getUserType,
  USER_TYPES,
  getLcpAPIUrl,
  isTrainerMode,
  getESchoolSessionId,
  getESchoolAPIBase,
  getESchoolApiKey
} from "../app/reducer/reducer";
import { select, takeEvery, takeLatest } from "redux-saga/effects";
import { LOG_MINOR_ERROR, LOG_TO_ESCHOOL } from "./errorlogging-reducer";
import { getAttendSessionId } from "../learner/attend/attendance-reducer";

import { Studio } from "@rosetta/sqrl-client";
import { playerEvent } from "../app/services/eschool";

import { version } from "../../../package.json";

let airbrake = null;

const MAX_ERRORS = 50;
let errorsReported = 0;
let userId = null;

export function identifyUserForErrors(userGUID) {
  userId = userGUID;
}

/**
 * This is added as a filter to airbrake below. It limits us to 50 errors reported per page load and
 * adds some info helpful for debugging.
 * @param {} notice
 */
function airbrakeFilter(notice) {
  if (notice.errors) {
    notice.errors = notice.errors.filter(err => err.message !== "[object Object]");
  }
  if (notice.errors && notice.errors.length === 0) return null;
  errorsReported++;
  if (errorsReported > MAX_ERRORS) return null;

  notice.context.version = version;
  if (userId) {
    notice.context.user = { id: userId };
  }
  return notice;
}

function onUnhandledrejection(e) {
  console.log("onUnhandledrejection", e);

  const error = e.reason || (e.detail && e.detail.reason);
  StackTrace.fromError(error)
    .catch(err => {
      console.info("Couldn't get stack trace from unhandled rejection", err);
      return [];
    })
    .then(stack => stack.map(frame => frame.toString()))
    .then(stack => {
      airbrake.notify({
        error: e.reason,
        reporter: "unhandledRejection",
        params: {
          stack: stack.join(" ==> ")
        },
        context: {
          severity: "warning",
          component: "unhandledRejectionHandler"
        }
      });
    });
}

function* initApp() {
  const errbitUrl = yield select(getErrbitUrl);
  const errbitApi = yield select(getErrbitApi);

  if (!errbitUrl || !errbitApi) {
    console.warn("No errbit credentials, errors won't be logged.");
    return;
  }

  window.addEventListener("unhandledrejection", onUnhandledrejection);

  airbrake = new Notifier({
    host: errbitUrl,
    projectId: 1,
    projectKey: errbitApi,
    ignoreWindowError: true,
    instrumentation: {
      fetch: false,
      onerror: false
    }
  });

  airbrake.addFilter(airbrakeFilter);

  // yield airbrake.notify(`Test message`);
}

let loggedErrors = 0;
let eschoolLogs = 0;
const MAX_ESCHOOL_LOGS = 750;

const userTypeToContext = userType => (userType === USER_TYPES.LEARNER ? "learnerclient" : "tutorclient");

function* sendEschoolLog(msg) {
  const trainerMode = yield select(isTrainerMode);
  if (trainerMode) return;
  const userType = yield select(getUserType);
  if (userType === USER_TYPES.LEARNER) {
    yield sendLearnerEschoolLog(msg);
  } else if (userType === USER_TYPES.COACH) {
    yield sendCoachEschoolLog(msg);
  }
}
function* sendCoachEschoolLog(msg) {
  try {
    // Only learners log to eschool
    const sessionId = yield select(getESchoolSessionId);
    if (!sessionId) return; // We can only log to eschool in the context of a session

    eschoolLogs++;
    if (eschoolLogs > MAX_ESCHOOL_LOGS) return; // Limit the amount of logging per session

    const baseUrl = yield select(getESchoolAPIBase);
    const apiKey = yield select(getESchoolApiKey);

    if (eschoolLogs === MAX_ESCHOOL_LOGS) {
      yield playerEvent(apiKey, baseUrl, `Max number ${MAX_ESCHOOL_LOGS} of log entries reached.`);
    }

    yield playerEvent(apiKey, baseUrl, msg);
  } catch (e) {
    // Don't logMinorError or appError here since it'll create an infinite loop.
    console.error("Could not send eschool log", e);
  }
}

function* sendLearnerEschoolLog(msg) {
  try {
    // Only learners log to eschool
    const sessionId = yield select(getAttendSessionId);
    if (!sessionId) return; // We can only log to eschool in the context of a session

    eschoolLogs++;
    if (eschoolLogs > MAX_ESCHOOL_LOGS) return; // Limit the amount of logging per session

    const lcpServerApiUrl = yield select(getLcpAPIUrl);
    const studio = new Studio(lcpServerApiUrl);

    if (eschoolLogs === MAX_ESCHOOL_LOGS) {
      yield studio.playerEventLog(sessionId, `Max number ${MAX_ESCHOOL_LOGS} of log entries reached.`);
    }

    yield studio.playerEventLog(sessionId, msg);
  } catch (e) {
    // Don't logMinorError or appError here since it'll create an infinite loop.
    console.error("Could not send eschool log", e);
  }
}

function* logToEschool(action) {
  console.log(action.payload.msg);
  yield sendEschoolLog(action.payload.msg);
}

export function* logToErrbit(error, msg, userType, severity, params = {}, reporter, components = "") {
  try {
    if (error) {
      // If we have an error object, then try and get a proper stack trace
      yield StackTrace.fromError(error)
        .then(stack => stack.map(frame => frame.toString()))
        .then(stack => {
          // Yay! We got a stack trace
          try {
            error.message = msg + " " + error.message;
          } catch (e) {
            console.info("Could not decorate error message");
          }
          return airbrake.notify({
            error,
            reporter: reporter,
            environment: {
              components
            },
            params: {
              ...params,
              stack: stack.join(" ==> ")
            },
            context: {
              severity: severity,
              component: userTypeToContext(userType)
            }
          });
        })
        // eslint-disable-next-line no-unused-vars
        .catch(err => {
          // Boo, something went wrong getting the stack trace, log it without it.
          try {
            error.message = msg + " " + error.message;
          } catch (e) {
            console.info("Could not decorate error message");
          }
          return airbrake.notify({
            error,
            reporter: reporter,
            environment: {
              components
            },
            params: params,
            context: {
              severity: severity,
              component: userTypeToContext(userType)
            }
          });
        });
    } else {
      // No error object, no useful stack trace can be retrieved
      try {
        error.message = msg + " " + error.message;
      } catch (e) {
        console.info("Could not decorate error message");
      }
      yield airbrake.notify({
        error,
        reporter: reporter,
        environment: {
          components
        },
        params: params,
        context: {
          severity: severity,
          component: userTypeToContext(userType)
        }
      });
    }
  } catch (e) {
    console.error("Could not log error to errbit!", e);
  }
}

export function* onMinorError(action) {
  const trainerMode = yield select(isTrainerMode);
  if (trainerMode) return;

  if (!airbrake) {
    console.error("Error occurred before we got errbit credentials.");
    console.log(JSON.stringify(action.payload, null, 2));
    return;
  }
  loggedErrors++;
  if (loggedErrors > 25) {
    console.warn("Throttling errors logged");
    return;
  }
  const userType = yield select(getUserType);
  const error = action.payload.error || { message: "" };
  const msg = action.payload.msg;
  const components = extractPath(action, "payload.data.info.componentStack", "");

  yield logToErrbit(error, msg, userType, "warning", action.payload.data, "minorError", components);

  yield sendEschoolLog(error.message);
}

export function* onAppError(action) {
  const trainerMode = yield select(isTrainerMode);
  if (trainerMode) return;

  if (!airbrake) {
    console.error("Error occurred before we got errbit credentials.");
    console.log(JSON.stringify(action.payload, null, 2));
    return;
  }
  loggedErrors++;
  if (loggedErrors > 25) {
    console.warn("Throttling errors logged");
    return;
  }
  const userType = yield select(getUserType);
  const error = action.payload.error || { message: "" };
  const msg = action.payload.msg;
  const components = extractPath(action, "payload.data.info.componentStack", "");

  yield logToErrbit(error, msg, userType, "warning", action.payload.data, "appError", components);

  yield sendEschoolLog(error.message);
}

export function* errorloggingSagas() {
  yield takeEvery(APP_ERROR, onAppError);
  yield takeEvery(LOG_MINOR_ERROR, onMinorError);
  yield takeLatest(INIT_APP_COMPLETE, initApp);
  yield takeEvery(LOG_TO_ESCHOOL, logToEschool);
}
