import { all, takeEvery, select, put } from "redux-saga/effects";
import {
  SHARED_ENABLE_LOCAL_BACKUP,
  SHARED_REMOTE_VALUE_RECEIVED,
  SHARED_REMOTE_VALUE_APPENDED,
  SHARED_LOCAL_VALUE_RECEIVED,
  SHARED_LOCAL_VALUE_APPENDED,
  getSharedSnapshot,
  getSharedSnapshotId,
  sharedRemoteValueReceived,
  sharedReceiveSnapshot,
  sharedRemoteValueAppended,
  contextDestroyed
} from "./sharedstate-reducer";
import {
  RT_REMOTE_USER_MUTED,
  RT_REMOTE_FORCE_MUTE,
  rtGetConnectionId,
  RT_SHARED_STATE_INIT,
  RT_LEARNER_WELCOME_RECEIVE,
  RT_CONNECTED,
  RT_CONNECTION_DESTROYED,
  RT_LEARNER_READY_RECEIVE
} from "../realtime/realtime-reducer";
import { TOGGLE_MUTE } from "../learner/attend/attendance-reducer";
import { TOPIC_SHOW_SLIDE, getCurrentTopicSlides } from "../topic/topic-reducer";
import { getCoachAuthoredVocab } from "../coach/coach-reducer";
import {
  CHAT_RECIEVE_MESSAGE,
  CHAT_RECIEVE_PRIVATE_MESSAGE,
  CHAT_SEND_PRIVATE_MESSAGE,
  CHAT_HISTORY_LOADED
} from "../chat/chat-reducer";
import { getUserType, USER_TYPES, getTutorName } from "../app/reducer/reducer";

/**
 * A localstore key to use to save our session data.
 *
 * @param {*} sessionId
 */
const localstoreKey = sessionId => `tutor.session.${sessionId}`;
function* onSaveState() {
  const snapshot = yield select(getSharedSnapshot);
  const snapshotId = yield select(getSharedSnapshotId);

  window.localStorage.setItem(localstoreKey(snapshotId), JSON.stringify(snapshot));
}

/**
 * Enable saving our shared state to localstore as it changes.
 *
 * @param {*} action
 */
function* onBackupEnabled(action) {
  yield takeEvery(SHARED_REMOTE_VALUE_RECEIVED, onSaveState);
  yield takeEvery(SHARED_REMOTE_VALUE_APPENDED, onSaveState);
  yield takeEvery(SHARED_LOCAL_VALUE_RECEIVED, onSaveState);
  yield takeEvery(SHARED_LOCAL_VALUE_APPENDED, onSaveState);
  yield loadSharedStateFromLocalstore(action.payload.key);
}

/**
 * Sets the shared state value when a remot user is muted.
 *
 * @param {*} action
 */
function* onRemoteUserMuted(action) {
  const { muted, connectionId } = action.payload;
  yield put(sharedRemoteValueReceived("muted", muted, "CON" + connectionId));
}

/**
 * Sets the shared state value when we locally mute
 *
 * @param {*} action
 */
function* onLocalMute(action) {
  const { muted } = action.payload;
  const myConnectionId = yield select(rtGetConnectionId);
  yield put(sharedRemoteValueReceived("muted", muted, "CON" + myConnectionId));
}

function* onLocalShowSlide(action) {
  const slides = yield select(getCurrentTopicSlides);
  const { slideIndex } = action.payload;
  const type = slideIndex === -1 ? "none" : slides[slideIndex].type;
  const authoredVocab = yield select(getCoachAuthoredVocab);
  const vocab = type === "vocab" ? authoredVocab : undefined;

  const slide = {
    index: slideIndex,
    type,
    vocab
  };

  yield put(sharedRemoteValueReceived("currentSlide", slide));
}

function* onLearnerWelcome(action) {
  // Set the 3 properties from the welcome message that we care about on this user.
  yield all(
    ["userId", "preferredName", "capabilities"].map(key =>
      put(sharedRemoteValueReceived(key, action.payload.data[key], "CON" + action.payload.connectionId))
    )
  );
}

function* onPrivateChatReceive(action) {
  const chatForUserId = action.payload.message.learnerId;
  const context = "USR" + chatForUserId;

  yield put(sharedRemoteValueAppended("coachChat", action.payload.message, context));
}

function* onPrivateChatSend(action) {
  if (action.payload.message.msg.substr(0, 1) === "/") return;
  const context = "USR" + action.payload.message.learnerUserId;
  yield put(sharedRemoteValueAppended("coachChat", action.payload.message, context));
}

function* onGroupChatReceive(action) {
  yield put(sharedRemoteValueAppended("groupChat", action.payload.message));
}

function* loadSharedStateFromLocalstore(sessionId) {
  const sessionKey = localstoreKey(sessionId);
  const historyStr = window.localStorage.getItem(sessionKey, null);
  if (historyStr) {
    const history = JSON.parse(historyStr);
    yield put(sharedReceiveSnapshot(history));
  }

  // Now, we can delete all the shared state from old sessions we don't care about anymore.
  for (let i = 0; i < window.localStorage.length; i++) {
    const key = window.localStorage.key(i);
    if (key.substr(0, 14) === "tutor.session." && key !== sessionKey) {
      window.localStorage.removeItem(key);
    }
  }
}

function* onRemoteSharedState(action) {
  yield put(sharedReceiveSnapshot(action.payload.data));
}

function* onConnect(action) {
  const userType = yield select(getUserType);
  if (userType === USER_TYPES.COACH) {
    // Force our user id on connection if we're the coach
    yield put(sharedRemoteValueReceived("userId", "coach", "CON" + action.payload.session.connection.id));
    const myName = yield select(getTutorName);
    yield put(sharedRemoteValueReceived("preferredName", myName, "CON" + action.payload.session.connection.id));
  }
}

function* onChatHistoryLoad(action) {
  yield put(sharedRemoteValueReceived("groupChat", action.payload.chatHistory));
}

function* onConnectionDestroyed(action) {
  yield put(contextDestroyed("CON" + action.payload.connection.id));
}

function* onLearnerReady(action) {
  yield put(sharedRemoteValueReceived("ready", true, "CON" + action.payload.connectionId));
}

export function* sharedStateSagas() {
  yield takeEvery(RT_CONNECTED, onConnect);
  yield takeEvery(SHARED_ENABLE_LOCAL_BACKUP, onBackupEnabled);
  yield takeEvery(TOGGLE_MUTE, onLocalMute);
  yield takeEvery(TOPIC_SHOW_SLIDE, onLocalShowSlide);
  yield takeEvery(RT_SHARED_STATE_INIT, onRemoteSharedState);
  yield takeEvery(CHAT_RECIEVE_MESSAGE, onGroupChatReceive);
  yield takeEvery(RT_LEARNER_WELCOME_RECEIVE, onLearnerWelcome);

  yield takeEvery(CHAT_RECIEVE_PRIVATE_MESSAGE, onPrivateChatReceive);
  yield takeEvery(CHAT_SEND_PRIVATE_MESSAGE, onPrivateChatSend);
  yield takeEvery(CHAT_HISTORY_LOADED, onChatHistoryLoad);

  yield takeEvery(RT_REMOTE_USER_MUTED, onRemoteUserMuted);
  yield takeEvery(RT_REMOTE_FORCE_MUTE, onRemoteUserMuted);
  yield takeEvery(RT_LEARNER_READY_RECEIVE, onLearnerReady);

  yield takeEvery(RT_CONNECTION_DESTROYED, onConnectionDestroyed);

  // yield takeEvery()
}
