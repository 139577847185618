/* eslint-disable no-unused-vars */
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { BrandLogo } from "../constants";
import isPropValid from "@emotion/is-prop-valid";

import { showSettings } from "../../coach/coach-reducer";
import styled from "@emotion/styled";
import { getSupportLink, alertSupport } from "../reducer/reducer";
import {
  appHeaderBackground,
  appHeaderForeground,
  appTitleForeground,
  linkHightlightColor
} from "../../../styles/colors";

const LogoContainer = styled.div({ flexGrow: 1 });

// Need a button without borders / background on your header? Here you go!
export const AppHeaderButton = styled.button(({ children, ...props }) => ({
  color: appHeaderForeground,
  height: 64,
  fontSize: 16,
  fontFamily: "Effra",
  verticalAlign: "middle",
  background: "none",
  border: "none",
  cursor: "pointer",
  outline: "none",
  ...props,
  "&:hover": {
    color: linkHightlightColor
  },
  ">svg": {
    marginRight: 10
  }
}));

// Need a button with just an icon and no extra spacing? Here you go!
export const AppHeaderIconButton = styled("button", {
  shouldForwardProp: prop => {
    return isPropValid(prop) || prop === "data-qa";
  }
})({
  color: appHeaderForeground,
  height: 53,
  fontSize: 16,
  fontFamily: "Effra",
  verticalAlign: "middle",
  padding: 0,
  background: "none",
  border: "none",
  cursor: "pointer",
  outline: "none",
  "&:hover": {
    color: linkHightlightColor
  }
});

// Need a left / center / right? Here you go!
export const AppHeaderLeft = styled.div(({ children, ...props }) => ({
  minWidth: 140,
  ...props
}));

export const AppHeaderCenter = styled.span(({ textAlign = "center", children, ...props }) => ({
  color: appTitleForeground,
  fontSize: 16,
  textAlign: textAlign,
  fontFamily: "Effra",
  verticalAlign: "middle",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  margin: "0px 20px",
  ...props
}));

export const AppHeaderRight = styled.div(({ children, ...props }) => ({
  minWidth: 140,
  textAlign: "right",
  ...props
}));

export const AppHeader = styled.div(({ children, ...props }) => ({
  display: "flex",
  height: 64,
  backgroundColor: appHeaderBackground,
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0px 24px",
  ...props
}));

export default AppHeader;
