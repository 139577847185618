import React from "react";

export const RefreshIcon = props => (
  <svg width={22} height={21} fill="none" {...props}>
    <path
      d="M15.664 5.556A6.963 6.963 0 0 0 10.72 3.5a6.99 6.99 0 0 0-6.991 7c0 3.867 3.123 7 6.99 7 3.264 0 5.986-2.231 6.764-5.25h-1.82a5.242 5.242 0 0 1-4.943 3.5 5.254 5.254 0 0 1-5.25-5.25 5.254 5.254 0 0 1 5.25-5.25c1.452 0 2.747.604 3.692 1.558l-2.817 2.817h6.125V3.5l-2.056 2.056z"
      fill="#4195D3"
    />
  </svg>
);

export default RefreshIcon;
