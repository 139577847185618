import { RT_WELCOME_MESSAGE_RECEIVE } from "../realtime/realtime-reducer";

const initial = {
  sessionActive: false
};

export const learnerReducer = (state = initial, action = undefined) => {
  switch (action && action.type) {
    case RT_WELCOME_MESSAGE_RECEIVE:
      return { ...state, sessionActive: true };

    default:
      return state;
  }
};

export const isLearnerActive = state => state.learner.sessionActive;
