import { trimChatHistory } from "../chat/chat-util";

// See protocoldocs/index.html for more info on each signal

// Takes a received signal structure and converts it back into our signal structure like we would have sent.
export const reconstructSignal = signal => ({
  type: signal.type.split(":")[1],
  data: JSON.parse(signal.data)
});

export const sharedStateSet = (key, value) => ({ type: "stateset", data: { key, value } });
export const sharedStateMerge = (key, value) => ({ type: "statemerge", data: { key, value } });
export const sharedStateAppend = (key, value, isArray = false) => ({
  type: "stateappend",
  data: { key, value, isArray }
});

export const forceMute = (connectionId, muted) => ({
  type: "forcemute",
  data: { muted, connectionId }
});

export const selfMute = muted => ({
  type: "selfmute",
  data: { muted }
});

export const sharedStateInit = (version, number, totalPackets, packet) => ({
  type: "sharedStateInit",
  data: {
    version,
    number,
    totalPackets,
    packet
  }
});

export const showSlideSignal = (slideIndex, slideType, vocab) => ({
  type: "showslide",
  data: {
    type: slideType,
    index: slideIndex,
    vocab: vocab
  }
});

export const privateChatSignal = (username, userId, msg, learnerId, ts) => {
  return {
    type: "privatechat",
    data: {
      learnerId,
      displayName: username,
      userId: userId,
      ts: ts,
      msg
    }
  };
};
export const chatSignal = (username, userId, msg, ts) => {
  return {
    type: "chat",
    data: {
      displayName: username,
      userId: userId,
      ts: ts,
      msg
    }
  };
};

export const endSessionSignal = () => ({
  type: "goodbye",
  data: {}
});

export const learnerReady = () => ({
  type: "learnerReady",
  data: {}
});

export const learnerWelcomeSignal = (userId, preferredName) => ({
  type: "learnerWelcome",
  data: {
    userId,
    preferredName,
    capabilities: {
      mute: 1,
      groupSession: 1,
      sharedStateInit: 1,
      privateChat: 1,
      groupChat: 1,
      sticker: 1,
      viewWhiteboard: 1,
      manipulateWhiteboard: 1,
      supportRoom: 1
    }
  }
});

export const exitSupportRoom = () => ({
  type: "exitSupportRoom"
});

export const diagnosticResult = info => ({ type: "diagnosticResult", data: { info } });

export const getDiagnostic = () => ({
  type: "getDiagnostic",
  data: {}
});

export const supportRoom = (audio_video_connection_id, token, api_key) => ({
  type: "supportRoom",
  data: {
    audio_video_connection_id,
    token,
    api_key
  }
});

export const welcomeSignal = (
  chatHistory,
  slideIndex,
  slideType = "image",
  topicResourceId,
  vocab,
  ts = undefined
) => ({
  type: "welcome",
  data: {
    currentTime: ts || Math.floor(new Date().getTime() / 1000),
    chatHistory: JSON.parse(trimChatHistory(chatHistory)),
    topicResourceId,
    currentSlide: {
      type: slideType,
      vocab: vocab,
      index: slideIndex
    }
  }
});

export const stickerText = text => ({
  type: "sticker",
  data: {
    text: [text],
    key: new Date().getTime(),
    duration: 5,
    spawnArea: {
      x: [-0.3, -0.3],
      y: [0.5, 0.5]
    },
    count: 1,
    fontSize: 40,
    keyframes: [
      {
        percent: 0,
        x: [0, 0],
        opacity: [1, 1]
      },

      { percent: 100, x: [1.6, 1.6], opacity: [1, 1] }
    ]
  }
});
export const stickerSignal = idx => ({
  type: "sticker",
  data: {
    ...stickers[idx],
    key: new Date().getTime()
  }
});

export const jumpSticker = character => ({
  text: [character],
  count: 11,
  duration: 2.5,
  ease: "linear",
  fontSize: 24,
  spawnArea: {
    x: [0.1, 0.9],
    y: [1.1, 1.4]
  },
  fixedSpriteSpawn: [
    {
      fontSize: 24,
      startCoordinate: [0.05, 1.2]
    },
    {
      fontSize: 48,
      startCoordinate: [0.14, 1.3]
    },
    {
      fontSize: 36,
      startCoordinate: [0.23, 1.1]
    },
    {
      fontSize: 24,
      startCoordinate: [0.32, 1.25]
    },
    {
      fontSize: 48,
      startCoordinate: [0.41, 1.15]
    },
    {
      fontSize: 36,
      startCoordinate: [0.5, 1.05]
    },
    {
      fontSize: 48,
      startCoordinate: [0.59, 1.3]
    },
    {
      fontSize: 24,
      startCoordinate: [0.68, 1.125]
    },
    {
      fontSize: 36,
      startCoordinate: [0.77, 1.225]
    },
    {
      fontSize: 48,
      startCoordinate: [0.86, 1.1]
    },
    {
      fontSize: 24,
      startCoordinate: [0.95, 1.25]
    }
  ],
  keyframes: [
    {
      opacity: [0, 0],
      percent: 0,
      y: [0, 0]
    },
    {
      opacity: [1, 1],
      percent: 5,
      x: [0, 0],
      y: [-0.35, -0.35]
    },
    {
      opacity: [1, 1],
      percent: 88,
      x: [0, 0],
      y: [0, 0]
    },

    {
      opacity: [0, 0],
      percent: 100,
      x: [0, 0],
      y: [-1, -1]
    }
  ]
});

export const stickers = [
  jumpSticker("😭"),
  jumpSticker("😍"),
  jumpSticker("🎉"),
  jumpSticker("😃"),
  jumpSticker("👎"),
  jumpSticker("👍"),
  jumpSticker("🏆")
];

/*
Old Stickers:

{
  text: ["😃", "😁", "😀"],
  duration: 2,
  ease: "linear",
  spawnArea: {
    x: [0, 1],
    y: [0.9, 0.9]
  },
  count: 10,
  fontSize: 32,
  keyframes: [
    {
      percent: 0,
      y: [0, 0],
      opacity: [0, 0]
    },
    { percent: 35, x: [-0.1, 0.1], y: [-0.25, -0.3], opacity: [1, 1] },
    { percent: 70, x: [-0.1, 0.1], y: [-0.5, -0.55], opacity: [1, 1] },
    { percent: 100, x: [-0.1, 0.1], y: [-0.7, -0.75], opacity: [0, 0] }
  ]
},
{
  text: ["👍🏻"],
  ease: "ease",
  duration: 3,
  spawnArea: {
    x: [0.3, 0.3],
    y: [0.5, 0.5]
  },
  count: 1,
  fontSize: 256,
  keyframes: [
    {
      percent: 0,
      opacity: [0, 0],
      y: [0, 0]
    },
    {
      percent: 20,
      opacity: [1, 1],
      y: [-0.1, -0.1]
    },
    {
      percent: 90,
      opacity: [1, 1],
      y: [-0.1, -0.1]
    },
    {
      percent: 100,
      opacity: [0, 0],
      y: [0, 0]
    }
  ]
},
{
  text: ["🎊", "🎉", "🤩", "👑", "🌸", "🌷", "🌺"],
  duration: 2,
  ease: "ease-in",
  spawnArea: {
    x: [0.5, 0.5],
    y: [0.5, 0.5]
  },
  count: 100,
  fontSize: 18,
  keyframes: [
    {
      percent: 0,
      y: [0, 0],
      opacity: [0, 0]
    },
    {
      percent: 100,
      x: [-2, 2],
      y: [-2, 2],
      opacity: [1, 1]
    }
  ]
},
{
  text: ["😻"],
  duration: 5,
  spawnArea: {
    x: [0, 0],
    y: [1, 1]
  },
  count: 1,
  fontSize: 64,
  keyframes: [
    {
      percent: 0,
      y: [0, 0],
      x: [0, 0],
      opacity: [1, 1],
      scale: [0, 0]
    },
    {
      percent: 100,
      x: [1, 1],
      y: [-1, -1],
      opacity: [1, 1],
      scale: [3, 3]
    }
  ]
},
{
  text: ["😭"],
  duration: 3,
  ease: "ease",
  spawnArea: {
    x: [0.5, 0.5],
    y: [0, 0]
  },
  count: 1,
  fontSize: 64,
  keyframes: [
    {
      percent: 0,
      y: [0, 0],
      x: [0, 0],
      opacity: [0, 0]
    },
    {
      percent: 40,
      x: [0, 0],
      y: [0.25, 0.25],
      opacity: [1, 1]
    },
    {
      percent: 60,
      x: [0, 0],
      y: [0.25, 0.25],
      opacity: [1, 1]
    },
    {
      percent: 100,
      x: [0, 0],
      y: [0.5, 0.5],
      opacity: [0, 0]
    }
  ]
},
*/
