import { RT_WELCOME_MESSAGE_RECEIVE } from "../realtime/realtime-reducer";
import { extractPath } from "@rosetta/redux-utils";
import { TUTOR_INIT_APP, ESCHOOL_FEEDBACK_LOADED, LEARNER_WELCOME_RECEIVED } from "../app/reducer/reducer";

export const TOPIC_SELECTED = "TOPIC_SELECTED";
export const TOPIC_LOADED = "TOPIC_LOADED";
const TOPIC_LOAD_FAIL = "TOPIC_LOAD_FAIL";
export const TOPIC_SHOW_SLIDE = "TOPIC_SHOW_SLIDE"; // we selected a slide
export const TOPIC_RECEIVED_KEYS = "TOPIC_RECEIVED_KEYS";
export const BEGIN_TOPIC_REVIEW = "BEGIN_TOPIC_REVIEW";
export const TOPIC_LIST_LOADED = "TOPIC_LIST_LOADED";
export const TOPIC_STUDIO_SELECT = "TOPIC_STUDIO_SELECT";

const topicReducerInitialState = {
  images: {
    // Map of resourceID to blob:// url
  },
  topicList: null,
  reviewMode: false,

  loadFail: false,
  loading: false,
  studioSession: false,
  resourceId: null,
  keys: null,
  currentlyLoadedTopicResourceId: null,
  classId: null,
  topic: {
    title: null,
    cover_image: null,
    domain: null,
    category: null,
    teaser: null,
    objectives: [],
    vocabulary: [],
    slides: []
  }
};

export const topicReducer = (state = topicReducerInitialState, action) => {
  switch (action && action.type) {
    case TOPIC_STUDIO_SELECT:
      return {
        ...state,
        loading: action.payload.resourceId !== state.resourceId, // we're not loading if we set it to the same content
        studioSession: true,
        resourceId: action.payload.resourceId
      };
    case LEARNER_WELCOME_RECEIVED:
      return { ...state, keys: action.payload.decryptionKeys };
    case TOPIC_LIST_LOADED:
      return { ...state, topicList: action.payload.topicList };
    case BEGIN_TOPIC_REVIEW:
      return { ...state, reviewMode: true };
    case TOPIC_SELECTED:
      return { ...state, loading: true, resourceId: action.payload.resourceId };
    case TOPIC_RECEIVED_KEYS:
      return { ...state, keys: action.payload.keys };
    case ESCHOOL_FEEDBACK_LOADED:
      const resourceId = (action.payload.feedback.topic || {}).resource_id;
      return state.studioSession
        ? state // if a studio session, we don't take the resourceId from the feedback response.
        : {
            ...state,
            loading: resourceId !== state.resourceId,
            resourceId
          };
    case TUTOR_INIT_APP:
      return { ...state, loading: true };
    case TOPIC_LOAD_FAIL:
      return { ...state, loadFail: true, loading: false };
    case RT_WELCOME_MESSAGE_RECEIVE:
      return {
        ...state,
        resourceId: action.payload.topicResourceId
      };
    case TOPIC_LOADED:
      return {
        ...state,
        loading: false,
        currentlyLoadedTopicResourceId: action.payload.resourceId,
        images: action.payload.images,
        topic: action.payload.topic,
        classId: action.payload.classId
      };
    default:
      return state;
  }
};

export const topicListLoaded = topicList => ({ type: TOPIC_LIST_LOADED, payload: { topicList } });
export const beginContentReview = () => ({ type: BEGIN_TOPIC_REVIEW });
export const topicSelected = resourceId => ({ type: TOPIC_SELECTED, payload: { resourceId } });
export const topicKeysLoaded = keys => ({ type: TOPIC_RECEIVED_KEYS, payload: { keys } });
export const topicFailedLoad = () => ({ type: TOPIC_LOAD_FAIL });
export const topicLoaded = (topicData, images, resourceId, classId) => ({
  type: TOPIC_LOADED,
  payload: { topic: topicData, images, resourceId, classId }
});
export const showSlide = slideIndex => ({ type: TOPIC_SHOW_SLIDE, payload: { slideIndex } });
export const topicStudioSelect = resourceId => ({ type: TOPIC_STUDIO_SELECT, payload: { resourceId } });

export const getClassId = state => state.topic.classId;
export const getTopicList = state => state.topic.topicList;
export const isReviewMode = state => state.topic.reviewMode;
export const isTopicLoading = state => state.topic.loading;
export const isStudioTopic = state => state.topic.studioSession;
export const getStudioLayouts = state => extractPath(state.topic, "topic.layouts.layout", []);
export const didTopicFailToLoad = state => state.topic.loadFail;
export const getTopicResourceId = state => state.topic.resourceId;
export const getCurrentTopic = state => state.topic.topic;
export const getCurrentTopicCategoryTitle = state => extractPath(getCurrentTopic(state), "category.title", "");
export const getCurrentlyLoadedResourceId = state => state.topic.currentlyLoadedTopicResourceId;
export const getCurrentTopicTitle = state => {
  const topic = getCurrentTopic(state);
  return topic && topic.title;
};
export const getCurrentTopicSubTitle = state => {
  const topic = getCurrentTopic(state);
  return topic && topic.subtitle;
};
export const getCurrentTopicDomain = state => {
  const topic = getCurrentTopic(state);
  return topic && topic.domain;
};
export const getCurrentTopicCategory = state => {
  const topic = getCurrentTopic(state);
  return topic && topic.category;
};
export const getCurrentTopicTeaser = state => {
  const topic = getCurrentTopic(state);
  return topic && topic.teaser;
};
export const getCurrentTopicObjectives = state => {
  const topic = getCurrentTopic(state);
  return topic && topic.objectives;
};

export const getCurrentTopicVocabulary = state => {
  const topic = getCurrentTopic(state);
  return (topic && topic.vocabulary) || [];
};
export const getCurrentTopicSlideCount = state => {
  const topic = getCurrentTopic(state);
  return topic && topic.slides.length;
};
export const getCurrentTopicSlides = state => {
  const topic = getCurrentTopic(state);
  return topic && topic.slides;
};

export const getContentKeys = state => state.topic.keys;

export const getCurrentTopicCoverUri = state => extractPath(getCurrentTopic(state), "cover_images.main.uri", "");

export const getImageUrlForResource = (state, resourceId) => state.images[resourceId];
