import { take, all, select, put, takeLatest, race, fork } from "redux-saga/effects";
import { delay } from "redux-saga";
import {
  attendUpcomingSessionsLoaded,
  ENTER_SESSION,
  LOAD_UPCOMING_SESSIONS,
  getAttendSession,
  EXIT_SESSION,
  ENTER_PRESESSION,
  getAttendSessionId,
  EXIT_POST_SESSION
} from "./attendance-reducer";

import { Studio } from "@rosetta/sqrl-client";
import {
  getLcpAPIUrl,
  INIT_APP_COMPLETE,
  appError,
  isAppInitialized,
  getUserType,
  USER_TYPES,
  getUpdateServiceUrl
} from "../../app/reducer/reducer";
import { rtRecieveTokens, RT_SIGNAL_GOODBYE_RECEIVED } from "../../realtime/realtime-reducer";
import { topicSelected, topicStudioSelect, isStudioTopic } from "../../topic/topic-reducer";
import { replace } from "connected-react-router";
import { END_SESSION_ROUTE, SCHEDULE_ROUTE } from "../../../routes";
import { getResourceIDfromComponentID } from "../../app/services/update";
import { loadSchedulingInfo } from "../scheduler/scheduler-sagas";
import { languageProductSelected, getSelectedProductId, findSessionById } from "../scheduler/scheduler-reducer";
import { recordSessionStarted } from "../../analytics/learner-analytics";
import { lionSelectors } from "@rosetta/react-lion";
import { findTutorName, SHARED_RECEIVE_SNAPSHOT } from "../../sharedstate/sharedstate-reducer";
import { logMinorError, logToEschool } from "../../errorlogging/errorlogging-reducer";

const ATTENDANCE_POLL_DELAY = 60000;

// eslint-disable-next-line no-unused-vars
function* onExitSession(action) {
  const sessionId = yield select(getAttendSessionId);
  yield put(replace(END_SESSION_ROUTE + "/" + sessionId));
  yield put(logToEschool("Session Exited"));
}

// eslint-disable-next-line no-unused-vars
function* onGoodbye(action) {
  const userType = yield select(getUserType);
  if (userType === USER_TYPES.LEARNER) {
    yield put(logToEschool("Received GoodBye, exiting session"));
    const sessionId = yield select(getAttendSessionId);
    yield put(replace(END_SESSION_ROUTE + "/" + sessionId));
  }
}

// eslint-disable-next-line no-unused-vars
function* onExitPostSession(action) {
  yield put(replace(SCHEDULE_ROUTE));
}

// eslint-disable-next-line no-unused-vars
function* onEnterPresession(action) {
  const isAppInitComplete = yield select(isAppInitialized);
  if (!isAppInitComplete) {
    yield take(INIT_APP_COMPLETE);
  }
  yield onLoadSessions();

  if (action.payload.sessionId && !isNaN(action.payload.sessionId)) {
    // We won't have a session ID of it's the system check that doesn't actually lead to a session.
    const currentSession = yield select(getAttendSession);

    if (!currentSession) {
      // OH NO!
      yield put(appError(null, "err_session_not_found"));
      return;
    }

    const productId = currentSession.eschool_class.product_identifier;

    yield put(languageProductSelected(productId));
    yield loadSchedulingInfo();

    if (currentSession.topic) {
      const topicId = currentSession.topic.resource_id;
      yield put(topicSelected(topicId));
    }
    yield put(logToEschool("Entered Presession"));
  }
}

function* pollAttendance(attendanceId) {
  const lcpServerApiUrl = yield select(getLcpAPIUrl);
  const studio = new Studio(lcpServerApiUrl);
  let errors = 0;

  while (true) {
    try {
      yield studio.poll(attendanceId);
      // Wait for either a timeout, or an exit_session action.
      const exitOrDelay = yield race({
        exit: take(EXIT_SESSION),
        delay: delay(ATTENDANCE_POLL_DELAY)
      });
      if (exitOrDelay.exit) return; // Got the exit session action, we're all done!
    } catch (e) {
      errors++;
      if (errors >= 20) {
        console.error(e);
        yield put(logMinorError(e, "Could not poll attendance record ", { attendanceId }));
        return; // I guess we shouldn't try forever?
      }
    }
  }
}

function* onEnterSession(action) {
  const { sessionId } = action.payload;

  const isAppInitComplete = yield select(isAppInitialized);
  if (!isAppInitComplete) {
    yield take(INIT_APP_COMPLETE);
  }

  const lcpServerApiUrl = yield select(getLcpAPIUrl);
  const studio = new Studio(lcpServerApiUrl);

  yield onLoadSessions(); // going to do this here, but you could also have  used the
  // attendLoadUpcomingSessions action to trigger it manually outside of entering a session.

  const currentSession = yield select(getAttendSession);

  if (!currentSession) {
    // OH NO!
    yield put(appError(null, "err_session_not_found"));
    return;
  }

  yield fork(pollAttendance, currentSession.attendance_id);

  if (currentSession.topic) {
    const topicId = currentSession.topic.resource_id;
    yield put(topicSelected(topicId));
  } else if (currentSession.eschool_class) {
    const classResource = currentSession.eschool_class.class_definition_url;

    const updateServiceBase = yield select(getUpdateServiceUrl);

    const resourceId = yield getResourceIDfromComponentID(updateServiceBase, classResource);
    yield put(topicStudioSelect(resourceId));
  }

  const result = yield all({
    realKeys: studio.getAudioVideoConnectionInfo(sessionId)
  });

  yield put(rtRecieveTokens(result.realKeys.api_key, result.realKeys.token, result.realKeys.audio_video_connection_id));
}

function* onLoadSessions() {
  const lcpServerApiUrl = yield select(getLcpAPIUrl);
  const studio = new Studio(lcpServerApiUrl);
  const attendances = yield studio.getAttendances();
  yield put(attendUpcomingSessionsLoaded(attendances));
}

function* onSharedStateInit(action) {
  // We need to wait to get shared state before reporting on session started so we have the tutor name.
  // Otherwise this could just go into the LearnerPage componentdidmount
  const userType = yield select(getUserType);
  if (userType !== USER_TYPES.LEARNER) return;

  const studio = yield select(isStudioTopic);
  const productName = studio ? "Foundations" : "Fluency Builder"; // Note: we don't have a web client for WWE learners right now. We'll need a better solution here if we do.
  const tutorName = yield select(findTutorName);
  const locale = yield select(lionSelectors.getLionLanguage);
  const selectedProductId = yield select(getSelectedProductId);
  const sessionId = yield select(getAttendSessionId);
  const session = yield select(findSessionById, sessionId);

  const analyticsProps = {
    "Session ID": sessionId,
    Product: productName,
    Tutor: tutorName,
    "Tutor ID": session?.tutor?.tutor_id,
    "Interface Language (L1)": locale,
    "Active Learning Language (L2)": selectedProductId
  };

  recordSessionStarted(analyticsProps);
  const tutor = yield select(findTutorName);
  yield put(
    logToEschool(`Entered Session with ${tutor} Shared State = ${JSON.stringify(action.payload.snapshot).length} bytes`)
  );
}

export function* attendanceSagas() {
  yield takeLatest(SHARED_RECEIVE_SNAPSHOT, onSharedStateInit);
  yield takeLatest(RT_SIGNAL_GOODBYE_RECEIVED, onGoodbye);
  yield takeLatest(EXIT_SESSION, onExitSession);
  yield takeLatest(EXIT_POST_SESSION, onExitPostSession);
  yield takeLatest(ENTER_PRESESSION, onEnterPresession);
  yield takeLatest(ENTER_SESSION, onEnterSession);
  yield takeLatest(LOAD_UPCOMING_SESSIONS, onLoadSessions);
}
