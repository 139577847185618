import React, { PureComponent } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import exact from "prop-types-exact";
import LearnerAuth from "../LearnerAuth";
import styled from "@emotion/styled";

import { LionTranslate, lionSelectors } from "@rosetta/react-lion";
import { SadFace } from "../../icons/SadFace";
import { HappyFace } from "../../icons/HappyFace";
import { brightBlue, white } from "../../../styles/colors";
import { StarGauge } from "../../coach/StarGauge";
import { attendExitPostSession, getAttendSessionId } from "../attend/attendance-reducer";
import { isStudioTopic, getCurrentTopicCategoryTitle, getCurrentTopicTitle } from "../../topic/topic-reducer";
import {
  recordTechFeedbackViewed,
  recordTechFeedbackSubmitted,
  recordSessionFeedbackViewed,
  recordSessionFeedbackSubmitted,
  initLearnerAnalytics,
  recordSessionExited
} from "../../analytics/learner-analytics";
import { getSelectedProductId, findSessionById } from "../scheduler/scheduler-reducer";
import { findTutorName } from "../../sharedstate/sharedstate-reducer";
import { logToEschool } from "../../errorlogging/errorlogging-reducer";
import { isTrainerMode } from "../../app/reducer/reducer";

const TopHeading = styled.h1({
  fontFamily: "Effra",
  fontSize: "22px",
  lineHeight: "27px",
  textAlign: "center",
  color: "#3E3D3F",
  fontWeight: 100
});

const Question = styled.h2({
  marginTop: 68,
  marginBottom: 55,
  fontFamily: "Effra",
  fontSize: "22px",
  lineHeight: "27px",
  display: "flex",
  textAlign: "center",
  color: "#3E3D3F",
  maxWidth: 430,
  fontWeight: "bold"
});

const PostSessionContainer = styled.div({
  position: "fixed",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: "#F1F1F1",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  paddingBottom: 100
});

const NextButton = styled.button({
  marginTop: 54,
  padding: "9px 75px",
  fontSize: 16,
  backgroundColor: "#4195D3",
  outline: "none",
  color: "#ffffff",
  borderRadius: 6,
  ":disabled": {
    opacity: 0.5
  }
});

const Comments = styled.textarea({
  outline: "none",
  marginTop: 15,
  padding: 20,
  width: 430,
  height: 130,
  resize: "none",
  background: "#FFFFFF",
  border: "0.5px solid #E6E6E6",
  borderRadius: "3px"
});

const SkipButton = styled.button({
  position: "absolute",
  bottom: 24,
  ":hover": {
    textDecoration: "underline"
  },
  fontFamily: "Effra",
  fontSize: "18px",
  textAlign: "center",
  color: "#4195D3",
  cursor: "pointer",
  background: "#F1F1F1",
  outline: "none",
  border: "none"
});

// eslint-disable-next-line no-unused-vars
const VideoQualityButton = styled.button(({ toggled, ...props }) => ({
  width: 145,
  height: 112,
  background: toggled ? brightBlue : white,
  borderRadius: 6,
  border: "none",
  outline: "none",
  margin: 5,
  cursor: "pointer"
}));

const ButtonContainer = styled.div({
  display: "flex"
});

class LearnerPostSession extends PureComponent {
  static propTypes = exact({
    currentSession: PropTypes.object,
    match: PropTypes.object,
    location: PropTypes.object,
    history: PropTypes.object,
    staticContext: PropTypes.object,
    stubCredentials: PropTypes.bool,
    tutorName: PropTypes.string,
    productName: PropTypes.string,
    topicCategory: PropTypes.string,
    topicTitle: PropTypes.string,
    attendExitPostSession: PropTypes.func,
    placeholder: PropTypes.string,
    sessionId: PropTypes.number,
    selectedProductId: PropTypes.string,
    locale: PropTypes.string,
    trainerMode: PropTypes.bool,
    logToEschool: PropTypes.func
  });
  static defaultProps = {};

  state = {
    question: 0,
    videoQuality: null,
    stars: 0,
    comments: ""
  };

  setStars = stars => this.setState({ stars });

  next = () => {
    this.setState({ question: 1 });
    recordTechFeedbackSubmitted(this.analyticsProps({ Rating: this.state.videoQuality || "Skipped" }));
    recordSessionFeedbackViewed(
      this.analyticsProps({
        Topic: this.props.topicTitle,
        Category: this.props.topicCategory,
        Level: "N/A" // TODO when we get flubu content imported
      })
    );
    this.props.logToEschool("Learner rated connection " + this.state.videoQuality);
  };

  // eslint-disable-next-line no-unused-vars
  setVideo = val => event => this.setState({ videoQuality: val });

  skip = () => {
    this.props.attendExitPostSession();
    recordTechFeedbackSubmitted(this.analyticsProps({ Rating: "Skipped" }));
  };

  onCommentChange = event => this.setState({ comments: event.currentTarget.value });

  submit = () => {
    this.props.attendExitPostSession(this.state.videoQuality, this.state.stars, this.state.comments);
    recordSessionFeedbackSubmitted(
      this.analyticsProps({
        Topic: this.props.topicTitle,
        Category: this.props.topicCategory,
        Level: "N/A", // TODO when we get flubu content imported
        Rating: this.state.stars || "Skipped",

        Feedback: this.state.comments
      })
    );

    // We set feedback.given to the sessionId so we remember if the last one we gave feedback for was this session.
    window.localStorage.setItem("feedback.given", String(this.props.sessionId));
  };

  analyticsProps = (otherProps = {}) => {
    return {
      "Session ID": this.props.sessionId,
      Product: this.props.productName,
      "Tutor ID": this.props.currentSession?.tutor.tutor_id,
      Tutor: this.props.tutorName,
      "Interface Language (L1)": this.props.locale,
      "Active Learning Language (L2)": this.props.selectedProductId,
      "ESchool Session ID": this.props.sessionId,
      ...otherProps
    };
  };

  weAlreadyGaveFeedback = () => window.localStorage.getItem("feedback.given") === String(this.props.sessionId);

  componentDidMount() {
    initLearnerAnalytics(); // only need this here for debugging if you go directly to the post session route. Doesn't hurt to do it twice.

    recordSessionExited(this.analyticsProps());

    if (this.weAlreadyGaveFeedback()) {
      this.props.attendExitPostSession(this.state.videoQuality, this.state.stars, this.state.comments);
      return;
    }

    recordTechFeedbackViewed(this.analyticsProps());
  }

  render = () => {
    if (this.props.trainerMode) {
      return <div>Training session complete.</div>;
    }

    return (
      <LearnerAuth location={this.props.location} stubCredentials={this.props.stubCredentials} match={this.props.match}>
        <PostSessionContainer>
          <TopHeading>
            <LionTranslate lionkey="post_session_header" />
          </TopHeading>
          {this.state.question === 0 && (
            <React.Fragment>
              <Question>
                <LionTranslate lionkey="post_session_question_1" />
              </Question>

              <ButtonContainer>
                <VideoQualityButton toggled={this.state.videoQuality === "Sad"} onClick={this.setVideo("Sad")}>
                  <SadFace color={this.state.videoQuality === "Sad" ? "#ffffff" : "#A0CAE9"} />
                </VideoQualityButton>
                <VideoQualityButton toggled={this.state.videoQuality === "Happy"} onClick={this.setVideo("Happy")}>
                  <HappyFace color={this.state.videoQuality === "Happy" ? "#ffffff" : "#A0CAE9"} />
                </VideoQualityButton>
              </ButtonContainer>

              <NextButton onClick={this.next} disabled={this.state.videoQuality === null}>
                <LionTranslate lionkey="post_session_next_button" />
              </NextButton>
            </React.Fragment>
          )}

          {this.state.question === 1 && (
            <React.Fragment>
              <Question>
                <LionTranslate lionkey="post_session_question_2" />
              </Question>
              <ButtonContainer>
                <StarGauge value={this.state.stars} onChange={this.setStars} background={false} border={false} />
              </ButtonContainer>

              <Comments
                value={this.state.comments}
                placeholder={this.props.placeholder}
                onChange={this.onCommentChange}
              />

              <NextButton onClick={this.submit} disabled={this.state.stars === 0}>
                <LionTranslate lionkey="post_session_submit_button" />
              </NextButton>
            </React.Fragment>
          )}

          <SkipButton onClick={this.skip}>
            <LionTranslate lionkey="post_session_skip_button" />
          </SkipButton>
        </PostSessionContainer>
      </LearnerAuth>
    );
  };
  //
}

const mapActionsToProps = { attendExitPostSession: attendExitPostSession, logToEschool };

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state, ownprops) => {
  const sessionId = getAttendSessionId(state);

  return {
    tutorName: findTutorName(state),
    currentSession: findSessionById(state, sessionId),
    placeholder: lionSelectors.getLionTranslatedString(state, "additional_feedback_placeholder"),
    productName: isStudioTopic(state) ? "Foundations" : "Fluency Builder",
    topicCategory: getCurrentTopicCategoryTitle(state) || "N/A",
    topicTitle: getCurrentTopicTitle(state),
    sessionId,
    selectedProductId: getSelectedProductId(state),
    locale: lionSelectors.getLionLanguage(state),
    trainerMode: isTrainerMode(state)
  };
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(LearnerPostSession);
