import { LOCATION_CHANGE } from "connected-react-router";

export const RSTV_START_SRE_INTERACTION = "RSTV_START_SRE_INTERACTION";
export const RSTV_CLOSE_SRE = "RSTV_CLOSE_SRE";
export const RSTV_BEGIN_SRE_LISTEN = "RSTV_BEGIN_SRE_LISTEN";
export const RSTV_SRE_RESULTS_RECEIVED = "RSTV_SRE_RESULTS_RECEIVED";
export const RSTV_PLAY_SRE_PROMPT = "RSTV_PLAY_SRE_PROMPT";
export const RSTV_PROMPT_PLAY_COMPLETED = "RSTV_PROMPT_PLAY_COMPLETED";

const RSTV_SOUND_PROMPT_LOADED = "RSTV_SOUND_PROMPT_LOADED";
/*
                         +------------+
                         |  INACTIVE  |
                         +------+-----+
                                |
                                |
                     RSTV_START_SRE_INTERACTION
                                |
                                |
                                v
                         +------+------+                                                      +------------------+
                         |   INTRO     |--------------------RSTV_CLOSE_SRE------------------->+    INACTIVE      |
                         +------+------+                                                      +------+-----------+
                                |                                                                    ^
                     RSTV_BEGIN_PROMPT_PLAYBACK                                                      |
                                |                                                                    |
                       +--------v---------+                                                          |
                       |  PROMPT_PLAYBACK +--------------->RSTV_CLOSE_SRE+---------------------------+
                       +--------+---------+                                                          ^
                                |                                                                    |
                      RSTV_BEGIN_SRE_LISTEN                                                          |
                                |                                                                    |
                                v                                                                    |
                      +---------+------+                                                             |
     +--------------->+   LISTENING    +------------------>RSTV_CLOSE_SRE+---------------------------+
     |                +---------+------+                                                             ^
     |                          |                                                                    |
     |              RSTV_SRE_RESULTS_RECEIVED                                                        |
     |                          v                                                                    |
     +                   +------+------+                                                             |
RSTV_BEGIN_SRE_LISTEN<---+    RESULTS  +------------------> RSTV_CLOSE_SRE---------------------------+
                         +-------------+

Each of these phases ends up being rendered by SREInteraction as a separate UI component in the ondemand/sre package

 * INACTIVE = nothing
 * INTRO = SREIntro
 * PROMPT_PLAYBACK = SREPrompt
 * LISTENING = SREPromptListening
 * RESULTS = SREPromptResult

*/

const SRE_PHASE = {
  INACTIVE: -1,
  INTRO: 0,
  PROMPT_PLAYBACK: 1,
  LISTENING: 2,
  RESULTS: 3
};

const initial = {
  interaction: null,
  phase: SRE_PHASE.INACTIVE,
  results: null,
  promptPlaying: false
};

const reducer = (state = initial, action = undefined) => {
  switch (action && action.type) {
    case RSTV_PLAY_SRE_PROMPT:
      return {
        ...state,
        promptPlaying: !state.promptPlaying
      };
    case RSTV_PROMPT_PLAY_COMPLETED:
      return {
        ...state,
        promptPlaying: false
      };
    case RSTV_SOUND_PROMPT_LOADED:
      return { ...state, interaction: { ...state.interaction, soundUrl: action.payload.url } };
    case RSTV_SRE_RESULTS_RECEIVED:
      return {
        ...state,
        phase: SRE_PHASE.RESULTS,
        results: action.payload.results
      };
    case RSTV_BEGIN_SRE_LISTEN:
      return {
        ...state,
        phase: SRE_PHASE.LISTENING
      };

    case LOCATION_CHANGE:
    case RSTV_CLOSE_SRE:
      return { ...state, interaction: null, phase: SRE_PHASE.INACTIVE };

    case RSTV_START_SRE_INTERACTION:
      return {
        ...state,
        interaction: action.payload.interaction,
        phase: SRE_PHASE.INTRO
      };
    default:
      return state;
  }
};

export const rstvSoundPromptLoaded = url => ({ type: RSTV_SOUND_PROMPT_LOADED, payload: { url } });
export const rstvBeginListen = () => ({ type: RSTV_BEGIN_SRE_LISTEN });
export const rstvPlaySREPrompt = () => ({ type: RSTV_PLAY_SRE_PROMPT });
export const rstvResultsReceived = results => ({ type: RSTV_SRE_RESULTS_RECEIVED, payload: { results } });
export const rstvCloseSRE = () => ({ type: RSTV_CLOSE_SRE });
export const rstvStartSREInteraction = interaction => ({ type: RSTV_START_SRE_INTERACTION, payload: { interaction } });
export const rstvSREPromptCompeleted = () => ({ type: RSTV_PROMPT_PLAY_COMPLETED });

export const getSREResults = state => state.rstv.sre.results;
export const getSREInteraction = state => state.rstv.sre.interaction;
export const isSREIntro = state => state.rstv.sre.phase === SRE_PHASE.INTRO;
export const isSREPrompt = state => state.rstv.sre.phase === SRE_PHASE.PROMPT_PLAYBACK;
export const isSREListening = state => state.rstv.sre.phase === SRE_PHASE.LISTENING;
export const isSREResults = state => state.rstv.sre.phase === SRE_PHASE.RESULTS;
export const isPromptPlaying = state => state.rstv.sre.promptPlaying;

export default reducer;
