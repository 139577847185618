import { DEBUG_ESCHOOL_API, DEBUG_PUDDLE_ROOT } from "../constants";
import { RSTV_VOICE_TYPE_SELECTED } from "../../rstv/ondemand/rstv-ondemand-reducer";

export const TUTOR_INIT_APP = "TUTOR_INIT_APP";
export const LEARNER_INIT_APP = "LEARNER_INIT_APP";

export const LEARNER_PREFERENCES_LOADED = "LEARNER_PREFERENCES_LOADED";
export const INIT_APP_COMPLETE = "INIT_APP_COMPLETE";
export const SET_PREFERRED_LOCALE = "SET_PREFERRED_LOCALE";
export const ALERT_SUPPORT = "ALERT_SUPPORT";
export const ESCHOOL_API_KEY_UPDATED = "ESCHOOL_API_KEY_UPDATED";
export const LEARNER_WELCOME_RECEIVED = "LEARNER_WELCOME_RECEIVED";
export const LOAD_TIME_ZONES = "LOAD_TIME_ZONES";
export const PREFERENCES_LOADED = "PREFERENCES_LOADED";
export const SET_TIME_ZONE = "SET_TIME_ZONE";
export const WELCOME_PACKET_RECIEVED = "WELCOME_PACKET_RECIEVED";

const ROUTE_CHANGE = "@@router/LOCATION_CHANGE";

export const USER_TYPES = {
  COACH: "coach",
  LEARNER: "learner",
  SUPPORT: "support",
  CONTENT_REVIEW: "content_review",
  INVISIBLE: "invisible"
};
export const APP_ERROR = "APP_ERROR";
export const CLEAR_APP_ERROR = "CLEAR_APP_ERROR";
export const ESCHOOL_FEEDBACK_LOADED = "ESCHOOL_FEEDBACK_LOADED";

export const forceHttps = url => url && (url.substr(0, 5) === "http:" ? "https:" + url.substr(5) : url);
export const addTrailingSlash = url => url && (url.substr(url.length - 1, 1) === "/" ? url : url + "/");

const initial = {
  hasAuthenticated: false,
  userType: null,

  trainerMode: false, // Is this a fake learner in training mode?

  puddleRoot: DEBUG_PUDDLE_ROOT,
  supportLink: null,
  errbitUrl: "",
  errbitApi: "",
  recordingGetUrl: "",
  recordingPutUrl: "",
  appError: [],
  initialized: false,

  eSchoolAPI: DEBUG_ESCHOOL_API, // Set up defaults to dev for debug page, but production will get them from the welcome packet
  eSchoolApiKey: null, // eschool api key, for tutors/support/managers
  eSchoolSessionId: null, // eschool session ID, for tutors/support/managers

  lcpAuthInfo: null, // Sqrl auth info, for learners. It might be token based. It might be user/password based.
  lcpAPI: null, // Sqrl api endpoint, for learners
  lcpTimestampOffset: 0,
  learnerGuid: null,
  shouldShowFeedback: true,
  preferences: {
    time_zone: "America/New_York",
    locale: "en-US",
    preferred_name: null
  },

  tutorName: "",
  sappyKey: null,
  sappyUrl: null,

  showTimeAs24Hour: false,
  voiceType: null,
  soundInputDevice: null,
  soundVolume: 0.5
};

const reducer = (state = initial, action = null) => {
  switch (action && action.type) {
    case ROUTE_CHANGE:
      return {
        ...state,
        lastRoute: state.currentRoute,
        currentRoute: action.payload.location && action.payload.location.pathname
      };
    case LEARNER_WELCOME_RECEIVED:
      return {
        ...state,
        errbitApi: action.payload.errbitKey,
        errbitUrl: action.payload.errbitUrl,
        puddleRoot: action.payload.puddleRoot,
        learnerGuid: action.payload.userGuid,
        fluencyBuilderURL: action.payload.fluencyBuilderURL,
        launchpadURL: action.payload.launchpadURL,
        hasAuthenticated: true,
        updateServiceUrl: action.payload.updateServiceURL,
        rstvKey: action.payload.rstvKey,
        rstvUrl: action.payload.rstvUrl
      };
    case RSTV_VOICE_TYPE_SELECTED:
      return {
        ...state,
        voiceType: action.payload.voiceType
      };
    case WELCOME_PACKET_RECIEVED:
      return {
        ...state,
        ...action.payload,
        hasAuthenticated: true
      };
    case LEARNER_PREFERENCES_LOADED:
      return {
        ...state,
        preferences: action.payload.preferences
      };
    case INIT_APP_COMPLETE:
      return { ...state, initialized: true };
    case CLEAR_APP_ERROR:
      return { ...state, appError: state.appError.slice(1) };
    case SET_PREFERRED_LOCALE:
      return { ...state, preferredLocale: action.payload.preferredLocale };
    case APP_ERROR:
      return { ...state, appError: [...state.appError, { error: action.payload.error, msg: action.payload.msg }] };
    case ESCHOOL_API_KEY_UPDATED:
      return {
        ...state,
        eSchoolApiKey: action.payload.newKey
      };
    case PREFERENCES_LOADED:
      return {
        ...state,
        showTimeAs24Hour: action.payload.showTimeAs24Hour,
        voiceType: action.payload.voiceType,
        soundInputDevice: action.payload.soundInputDevice,
        soundVolume: action.payload.soundVolume
      };
    case LOAD_TIME_ZONES:
      return { ...state, timeZones: action.payload.timeZones };
    case SET_TIME_ZONE:
      return {
        ...state,
        preferences: {
          ...state.preferences,
          time_zone: action.payload.timeZoneName,
          time_zone_id: action.payload.timeZoneIdentifier
        }
      };
    case LEARNER_INIT_APP:
      return {
        ...state,
        //apiKey: action.payload.apiKey,
        //sessionId: action.payload.sessionId,
        userType: USER_TYPES.LEARNER,
        lcpAuthInfo: action.payload.authInfo,
        lcpAPI: forceHttps(addTrailingSlash(action.payload.lcpUrl)),
        preferredLocale: action.payload.preferredLocale
      };
    case TUTOR_INIT_APP:
      return {
        ...state,
        eSchoolApiKey: action.payload.apiKey,
        eSchoolSessionId: action.payload.sessionId,
        userType: action.payload.userType,
        eSchoolAPI: forceHttps(addTrailingSlash(action.payload.apiUrl)),
        practiceMode: action.payload.practiceMode,
        trainerMode: action.payload.userType === USER_TYPES.LEARNER
      };

    default:
      return state;
  }
};

// -=-=-=-=-=-=-=-=-=-=- Action Creators -=-=-=-=-=-=-=-=-=-=-
export const learnerInitApp = (authInfo, lcpUrl, preferredLocale, appRoute) => ({
  type: LEARNER_INIT_APP,
  payload: { authInfo, lcpUrl, preferredLocale, appRoute }
});

export const tutorInitApp = (userType, apiKey, sessionId, apiUrl, practiceMode = false) => ({
  type: TUTOR_INIT_APP,
  payload: { userType, apiKey, sessionId, apiUrl, practiceMode }
});

export const learnerWelcomeReceived = (
  decryptionKeys,
  puddleRoot,
  userGuid,
  fluencyBuilderURL,
  launchpadURL,
  errbitKey,
  errbitUrl,
  updateServiceURL,
  rstvKey,
  rstvUrl
) => ({
  type: LEARNER_WELCOME_RECEIVED,
  payload: {
    decryptionKeys,
    puddleRoot,
    userGuid,
    fluencyBuilderURL,
    launchpadURL,
    errbitKey,
    errbitUrl,
    updateServiceURL,
    rstvKey,
    rstvUrl
  }
});

export const userPreferencesLoaded = preferences => ({
  type: LEARNER_PREFERENCES_LOADED,
  payload: {
    preferences
  }
});

export const welcomePacketReceived = (
  puddleRoot,
  supportLink,
  errbitUrl,
  errbitApi,
  recordingGetUrl,
  recordingPutUrl,
  numberOfSeats,
  numberOfStudentsSignedUp,
  shouldShowFeedback,
  updateServiceUrl,
  tutorName,
  sappyKey,
  sappyUrl,
  classResource
) => ({
  type: WELCOME_PACKET_RECIEVED,
  payload: {
    tutorName,
    puddleRoot,
    supportLink,
    errbitUrl,
    errbitApi,
    recordingGetUrl,
    recordingPutUrl,
    numberOfSeats,
    numberOfStudentsSignedUp,
    shouldShowFeedback,
    updateServiceUrl,
    sappyKey,
    sappyUrl,
    classResource
  }
});

export const loadTimeZones = timeZones => ({ type: LOAD_TIME_ZONES, payload: { timeZones } });
export const setTimeZone = (timeZoneName, timeZoneIdentifier) => ({
  type: SET_TIME_ZONE,
  payload: { timeZoneName, timeZoneIdentifier }
});
export const preferencesLoaded = (soundVolume, voiceType, soundInputDevice, showTimeAs24Hour) => ({
  type: PREFERENCES_LOADED,
  payload: { showTimeAs24Hour, voiceType, soundVolume, soundInputDevice }
});
export const setPreferredLocale = preferredLocale => ({ type: SET_PREFERRED_LOCALE, payload: { preferredLocale } });
export const alertSupport = () => ({ type: ALERT_SUPPORT });
export const eschoolApiKeyUpdated = newKey => ({ type: ESCHOOL_API_KEY_UPDATED, payload: { newKey } });
export const appError = (error, msg, data = {}) => ({ type: APP_ERROR, payload: { error, msg, data } });
export const clearAppError = error => ({ type: CLEAR_APP_ERROR, payload: { error } });
export const initAppComplete = () => ({ type: INIT_APP_COMPLETE });
export const eschoolFeedbackLoaded = feedback => ({ type: ESCHOOL_FEEDBACK_LOADED, payload: { feedback } });

// -=-=-=-=-=-=-=-=-=-=- Selectors -=-=-=-=-=-=-=-=-=-=-
export const getAppError = state => state.app.appError[0];

export const isPracticeMode = state => state.app.practiceMode;
export const getClassResource = state => state.app.classResource;
export const getSappyAuthInfo = state => ({
  sappyKey: state.app.sappyKey,
  sappyUrl: state.app.sappyUrl
});

export const getPreference = (state, preferenceKey) => state.app.preferences[preferenceKey];
export const getUpdateServiceUrl = state => state.app.updateServiceUrl;
export const isFeedbackEnabled = state => state.app.shouldShowFeedback;
export const hasLearnerAuthenticated = state => state.app.hasAuthenticated;
export const isAppInitialized = state => state.app.initialized;
export const getUserType = state => state.app.userType;
export const getTutorName = state => state.app.tutorName;

export const getESchoolAPIBase = state => state.app.eSchoolAPI;
export const getESchoolApiKey = state => (state.app.eSchoolApiKey || "").replace(/\n/g, "");
export const getESchoolSessionId = state => state.app.eSchoolSessionId;

export const getLcpAuthInfo = state => state.app.lcpAuthInfo;
export const getLcpAPIUrl = state => state.app.lcpAPI;

export const getPuddleRoot = state => state.app.puddleRoot;
export const getSupportLink = state => state.app.supportLink;
export const getErrbitUrl = state => state.app.errbitUrl;
export const getErrbitApi = state => state.app.errbitApi;

export const getRSTVKey = state => state.app.rstvKey;
export const getRSTVUrl = state => state.app.rstvUrl;

export const getRecordingGetUrl = state => state.app.recordingGetUrl;
export const getRecordingPutUrl = state => state.app.recordingPutUrl;
export const getLearnerGUID = state => state.app.learnerGuid;
export const getFluencyBuilderURL = state => state.app.fluencyBuilderURL;
export const getLaunchpadURL = state => state.app.launchpadURL;
export const getPreferredLocale = state => state.app.preferredLocale;
export const getTimeZones = state => state.app.timeZones;
export const showTimeAs24Hour = state => state.app.showTimeAs24Hour;
export const isTrainerMode = state => state.app.trainerMode;

export const getVoiceType = state => state.app.voiceType;
export const getSoundInputDevice = state => state.app.soundInputDevice;
export const getSoundVolume = state => state.app.soundVolume;

export const getPreviousRoute = state => state.app.lastRoute;

export default reducer;
