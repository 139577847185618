/**
 * Take the response we get back from graphQL and transform it into a useful data structure for
 * our purposes.
 * @param {} graphQLResponse
 */
export const transformData = graphQLResponse => {
  return {
    videos: transformVideos(graphQLResponse.data.videos),
    topics: graphQLResponse.data.topics.map(topic => transformTopicData(topic, graphQLResponse.data.videos)),
    tutors: transformTutors(graphQLResponse.data.tutors)
  };
};

const stripAttributes = (obj, attributes) =>
  attributes.reduce((acc, curr) => {
    return stripAttribute(acc, curr);
  }, obj);

// Returns the obj without a __typename
const stripAttribute = (obj, attrName = "__typename") => {
  // eslint-disable-next-line no-unused-vars
  const { ...rest } = obj;
  delete rest[attrName];
  return rest;
};

/**
 * Take the list of videos we get back from graphQL and create a map we
 * can use to look up specific videos.
 * @param {*} videos
 */
const transformVideos = videos => {
  return videos.reduce((acc, curr) => {
    acc[curr.guid] = transformVideo(curr);
    return acc;
  }, {});
};

/**
 * Take the list of tutors we get back from graphQL and create a map we
 * can use to look up specific tutors.
 * @param {*} tutors
 */
const transformTutors = tutors => {
  return tutors.reduce((acc, curr) => {
    acc[curr.id] = transformTutor(curr);
    return acc;
  }, {});
};

const transformTutor = tutor => ({
  ...stripAttribute(tutor),
  videos: tutor.videos.map(video => video.guid)
});

// TODO - We need to more intellengtly implement getLargeThumb and getSmallThumb
const getLargeThumb = thumbnails => thumbnails[thumbnails.length - 1].uri;
const getSmallThumb = thumbnails => thumbnails[0].url;

/**
 * Transform a single video object from graphQL format to what we want.
 *
 */
const transformVideo = video => ({
  ...stripAttributes(video, ["__typename", "thumbnails"]),
  largeThumbnail: getLargeThumb(video.thumbnails),
  smallThumbnail: getSmallThumb(video.thumbnails),
  tutors: video.tutors.map(tutor => tutor.id),
  speechInteractions:
    video.speechInteractions &&
    video.speechInteractions.map((interaction, index) => ({ ...interaction, number: index + 1 }))
});

/**
 * Take a single topic object from the graphQL response and transform it to
 * a more useful shape.
 *
 * Returns:
 *    { description: '',
        guid: '3745c816-1eb2-40ce-b7f7-a000569a14f8',
        revision: 1,
        title: 'Topic 1',
        videos: [ 'guid1', 'guid2' ] }
 *
 * @param {} topic
 */
const transformTopicData = (topic, videosResponse) => ({
  ...stripAttribute(topic),
  videos: topic.videos.map(v => v.guid).filter(v => videosResponse.find(video => video.guid === v))
});
