export const white = "#FFFFFF";
export const black = "#000000";
export const lightGrey = "#c7c7c7";

const darkGrey = "#84898e";
const veryLightGrey = "#EEE";
export const brightButtonBlue = "#4195D3";

// Application wide colors:
export const appMainBackground = "#ffffff";
export const appHeaderBackground = "#ffffff";
export const appFooterBackground = "#e7e7e7";
export const appHeaderForeground = darkGrey;
export const appTitleForeground = "#3E3D3F";
export const linkHightlightColor = "#34A4E7"; // a bright blue
export const lightGreyBackground = "#f0f2f5";

export const addTodoBtnBackground = "#ffffff";
export const lightGreyBorder = "#F1F1F1"; //Light grey used in the border between sessions in Scheduler
export const brightBlue = "#4195d3"; // Bright blue used in the "Book" button in Scheduler

export const chatTimestampColor = veryLightGrey;
export const chatHeaderBackground = "#ffffff";

export const chatHeaderActiveColor = brightButtonBlue;
export const chatHeaderColor = "#DADADA";
export const chatSeparatorColor = "#f1f1f1";
export const chatBackButtonColor = "#888888";
export const chatMessageHeaderColor = "#939697";

export const chatOurMessageColor = brightButtonBlue;
export const chatTheirMessageColor = "#F1F1F1";
export const chatTheirMessageTextColor = "#3E3D3F";
export const chatInputBorder = "#F1F1F1";

export const learnerBoxShadow = "0px 4px 4px rgba(62, 61, 63, 0.07)";
export const slidesBoxShadow = "0px 6px 6px rgba(62, 61, 63, 0.07)";

export const coachPageBackground = "#f1f1f1";
export const coachNotesBackground = "#e1e3e6";
export const coachPanelForegroundColor = darkGrey;
export const coachPanelBackgroundColor = "#ffffff";
export const coachNotesNextColor = brightButtonBlue;

export const unreadIconBackgroundColor = "#8FCE3E";

// Scheduler colors:
export const modalBackdrop = "#0A1D37";
export const modalTextColor = "#4A4A4A";
export const modalLightTextColor = "#7D7D7D";
export const secondaryButtonBackground = "#FFFFFF";
export const secondaryButtonBorder = brightBlue;
export const primaryButtonBackground = brightBlue;
export const primaryButtonBorder = brightBlue;
export const veryLightGreyBackground = "#fafafa";

// RSTV colors:
export const fixedTopicBar = "#F1F1F1";
export const dashboardBackground = "#F8F8F8";
export const underlineAndBorder = "#E5E5E5";
export const videoTextBlock = darkGrey;
export const rstvTextAndBorder = darkGrey;
export const rstvVideoText = "#3E3D3F";
export const rstvBackButton = "#3E3D3F";
export const rstvObjectives = brightButtonBlue;
