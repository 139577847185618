import { UAParser } from "ua-parser-js";
import Hls from "hls.js";

export const SUPPORTED_LEVEL = {
  NOT_SUPPORTED: 0,
  MAYBE_SUPPORTED: 1,
  SUPPORTED: 2
};

export const getMinor = uaResult => {
  try {
    const minor = uaResult.browser.version.split(".")[1];
    return parseInt(minor, 10);
  } catch (e) {
    return 0;
  }
};

const currentVersions = {
  Chrome: 76,
  Firefox: 68,
  Edge: 17,
  Safari: 12
};

export const getMajor = uaResult => {
  try {
    return parseInt(uaResult.browser.major, 10);
  } catch (e) {
    return 0;
  }
};
/*
  First, ask TokBox if we're supported. If we are not:
    - NOT_SUPPORTED
  Browser is IE or IEMobile
    - NOT_SUPPORTED
  Device type is one of "tablet", "smarttv", "wearable", "embedded", "mobile"
    - MAYBE_SUPPORTED
  Browser is Safari < 12
    - NOT_SUPPORTED
  Browser is Safari 12 && < 12.1
    - NOT_SUPPORTED
  Chrome, Firefox, Edge, Safari - Check if our major version is not within 2 of the
  current version (see currentVersions above)
    - MAYBE_SUPPORTED
  Check that we're Chrome, Firefox, Edge, Safari
    - SUPPORTED
  Otherwise
    - MAYBE_SUPPORTED
*/
export const isBrowserSupported = () => {
  const uaParser = new UAParser();
  const uaResult = uaParser.getResult();
  const majorVersion = getMajor(uaResult);
  const minorVersion = getMinor(uaResult);
  const browserName = uaResult.browser.name;

  return checkBrowser(
    majorVersion,
    minorVersion,
    browserName,
    uaResult.device.type,
    window.OT.checkSystemRequirements()
  );
};

export const checkBrowser = (majorVersion, minorVersion, browserName, deviceType, tokboxResult, ignoreHLS = false) => {
  const nonDesktopDevices = ["tablet", "smarttv", "wearable", "embedded", "mobile"];
  const badBrowsers = ["IE", "IEMobile"];
  const goodBrowsers = ["Chrome", "Firefox", "Edge", "Safari"];

  if (tokboxResult === 0) {
    return SUPPORTED_LEVEL.NOT_SUPPORTED;
  }

  if (badBrowsers.includes(browserName)) {
    return SUPPORTED_LEVEL.NOT_SUPPORTED;
  }

  if (nonDesktopDevices.includes(deviceType)) {
    return SUPPORTED_LEVEL.MAYBE_SUPPORTED;
  }

  if (browserName === "Safari" && majorVersion < 12) {
    return SUPPORTED_LEVEL.NOT_SUPPORTED;
  }

  if (browserName === "Safari" && majorVersion === 12 && minorVersion < 1) {
    return SUPPORTED_LEVEL.NOT_SUPPORTED;
  }

  if (currentVersions[browserName] && majorVersion < currentVersions[browserName] - 2) {
    return SUPPORTED_LEVEL.MAYBE_SUPPORTED;
  }

  if (!Hls.isSupported() && !ignoreHLS) {
    // Does the browser support HLS playback for on demand and live RSTV?
    return SUPPORTED_LEVEL.MAYBE_SUPPORTED;
  }

  if (goodBrowsers.includes(browserName)) {
    return SUPPORTED_LEVEL.SUPPORTED;
  }

  return SUPPORTED_LEVEL.MAYBE_SUPPORTED;
};
