import { extractPath } from "@rosetta/redux-utils";
import {
  RT_LEARNER_WELCOME_RECEIVE,
  RT_SIGNAL_STATE_SET_RECEIVED,
  RT_SIGNAL_STATE_MERGE_RECEIVED,
  RT_SIGNAL_STATE_APPEND_RECEIVED
} from "../realtime/realtime-reducer";
import { UPDATE_TOOL, RESET_TOOLS } from "../whiteboard/whiteboard-reducer";

export const SHARED_RECEIVE_SNAPSHOT = "SHARED_RECEIVE_SNAPSHOT";
export const SHARED_REMOTE_VALUE_RECEIVED = "SHARED_REMOTE_VALUE_RECEIVED";
export const SHARED_REMOTE_VALUE_APPENDED = "SHARED_REMOTE_VALUE_APPENDED";
export const SHARED_REMOTE_VALUE_MERGED = "SHARED_REMOTE_VALUE_MERGED";

export const SHARED_LOCAL_VALUE_RECEIVED = "SHARED_LOCAL_REMOTE_VALUE_RECEIVED";
export const SHARED_LOCAL_VALUE_APPENDED = "SHARED_LOCAL_REMOTE_VALUE_APPENDED";
export const SHARED_ENABLE_LOCAL_BACKUP = "SHARED_ENABLE_LOCAL_BACKUP";

export const CONTEXT_DESTROYED = "CONTEXT_DESTROYED";

/*
 There are two main types of shared state we're going to care about.

 1. Shared state global to the session. Things like the group chat history or what slide we're currently on.
 2. Context specific state, such as whether a particular connection ID is currently muted.

 These are in shareData and contextData respectively.

 There are currently 2 operations that can be performed. One is setting a key's value, and the other is for appending
 a value (to an array).

*/

const initial = {
  localstoreKey: null,
  localstoreBackupEnabled: false,
  timestampOffset: 0, // diff of our clock from servertime
  lastUpdate: null, // timestamp of when we were last updated

  sharedData: {
    whiteboard: {},
    groupChat: [], // Group chat history
    currentSlide: {
      // What slide is the session currently pointing to?
      index: -1,
      type: null
    }
  }, // key value pairs

  /* contextData[context key][key] = value
    Valid contexts right now:
      CON + conectionId
         muted  - is this connection muted?
         userId,
         preferredName,
         capabilities: {}
      USR + userId
         coachChat   - private learner<->coach chat history
         supportActive - Is the private support chat shown to the learner and coach?
         supportChat - private learner<->coach<->support chat history
  */
  contextData: {}
};

const setKey = (sharedData, key, value) => {
  return { ...sharedData, [key]: value };
};

const setContextKey = (contextData, context, key, value) => {
  return {
    ...contextData,
    [context]: {
      ...contextData[context],
      [key]: value
    }
  };
};

const appendKey = (sharedData, key, value) => {
  return {
    ...sharedData,
    [key]: [...(sharedData[key] || []), value]
  };
};

const appendContextKey = (contextData, context, key, value) => {
  const existingContext = contextData[context] || {};
  const existingKey = existingContext[key] || [];

  return {
    ...contextData,
    [context]: {
      ...existingContext,
      [key]: [...existingKey, value]
    }
  };
};

export const setDeep = (state, key, value) => {
  const path = key.split(".").filter(p => p !== "");

  if (path.length === 0) return value;
  const next = path[0];
  return {
    ...state,
    [next]: setDeep(state[next] || {}, path.slice(1).join("."), value)
  };
};

export const setSharedState = (state, key, value) => {
  return setDeep(state, key, value);
};
export const appendSharedState = (state, key, value, isArray = false) => {
  const existing = extractPath(state, key, []);
  if (isArray) {
    return setDeep(state, key, [...existing, ...value]);
  }
  return setDeep(state, key, [...existing, value]);
};
export const mergeSharedState = (state, key, value) => {
  const existing = extractPath(state, key, {});
  return setDeep(state, key, { ...existing, ...value });
};

export const sharedStateReducer = (state = initial, action = undefined) => {
  switch (action && action.type) {
    case RT_SIGNAL_STATE_SET_RECEIVED:
      return setSharedState(state, action.payload.key, action.payload.value);
    case RT_SIGNAL_STATE_APPEND_RECEIVED:
      return appendSharedState(state, action.payload.key, action.payload.value, action.payload.isArray);
    case RT_SIGNAL_STATE_MERGE_RECEIVED:
      return mergeSharedState(state, action.payload.key, action.payload.value);

    case CONTEXT_DESTROYED:
      // eslint-disable-next-line no-unused-vars
      const { [action.payload.contextId]: throwAway, ...newContextData } = state.contextData;
      return {
        ...state,
        contextData: newContextData
      };

    case RESET_TOOLS:
      return {
        ...state,
        sharedData: {
          ...state.sharedData,
          whiteboard: {}
        }
      };
    case UPDATE_TOOL:
      return {
        ...state,
        sharedData: {
          ...state.sharedData,
          whiteboard: {
            ...state.sharedData.whiteboard,
            [action.payload.key]: action.payload.tool
          }
        }
      };

    case SHARED_ENABLE_LOCAL_BACKUP:
      return {
        ...state,
        localstoreKey: action.payload.key,
        localstoreBackupEnabled: true
      };
    case SHARED_RECEIVE_SNAPSHOT:
      return {
        ...state,
        ...action.payload.snapshot
      };

    case SHARED_REMOTE_VALUE_RECEIVED:
    case SHARED_LOCAL_VALUE_RECEIVED:
      return {
        ...state,
        lastUpdate: new Date().getTime() + state.timestampOffset,
        sharedData: action.payload.context
          ? state.sharedData
          : setKey(state.sharedData, action.payload.key, action.payload.value),
        contextData: action.payload.context
          ? setContextKey(state.contextData, action.payload.context, action.payload.key, action.payload.value)
          : state.contextData
      };
    case SHARED_LOCAL_VALUE_APPENDED:
    case SHARED_REMOTE_VALUE_APPENDED:
      return {
        ...state,
        lastUpdate: new Date().getTime() + state.timestampOffset,
        sharedData: action.payload.context
          ? state.sharedData
          : appendKey(state.sharedData, action.payload.key, action.payload.value),
        contextData: action.payload.context
          ? appendContextKey(state.contextData, action.payload.context, action.payload.key, action.payload.value)
          : state.contextData
      };

    case RT_LEARNER_WELCOME_RECEIVE:
      return {
        ...state,
        contextData: {
          ...state.contextData,
          ["CON" + action.payload.connectionId]: {
            userId: action.payload.data["userId"],
            preferredName: action.payload.data["preferredName"],
            capabilities: action.payload.data["capabilities"]
          }
        }
      };

    default:
      return state;
  }
};

// Actions

/** Need to get rid of a context? Use this! */

export const contextDestroyed = contextId => ({ type: CONTEXT_DESTROYED, payload: { contextId } });

/**
 * Sometimes, such as app startup for the coach or a shareDataInit signal for a learner, that we want to set the entire
 * shared state to a value. This action accomplishes that.
 *
 * @param {*} snapshot
 */
export const sharedReceiveSnapshot = snapshot => ({ type: SHARED_RECEIVE_SNAPSHOT, payload: { snapshot } });

/**
 * A remote value has been received and we should merge it in our internal shared state store.
 *
 * If a context is specified, it's stored under a specific context value (usually a connection ID
 * that corresponds with a specific other person)
 *
 * This just stores it in the store. It won't broadcast it to other clients. Use it when the change is incoming,
 * or when the change is being broadcast via other means.
 *
 */
export const sharedRemoteValueMerged = (key, value, context = undefined) => ({
  type: SHARED_REMOTE_VALUE_MERGED,
  payload: { key, value, context }
});
/**
 * A remote value has been received and we should store it in our internal shared state store.
 *
 * If a context is specified, it's stored under a specific context value (usually a connection ID
 * that corresponds with a specific other person)
 *
 * This just stores it in the store. It won't broadcast it to other clients. Use it when the change is incoming,
 * or when the change is being broadcast via other means.
 */
export const sharedRemoteValueReceived = (key, value, context = undefined) => ({
  type: SHARED_REMOTE_VALUE_RECEIVED,
  payload: { key, value, context }
});

/**
 * A remote value has been appended and we should store it in our internal shared state store.
 *
 * If a context is specified, it's stored under a specific context value (usually a connection ID
 * that corresponds with a specific other person)
 *
 * This just stores it in the store. It won't broadcast it to other clients. Use it when the change is incoming,
 * or when the change is being broadcast via other means.
 */
export const sharedRemoteValueAppended = (key, value, context = undefined) => ({
  type: SHARED_REMOTE_VALUE_APPENDED,
  payload: { key, value, context }
});

/**
 * A Shared value should be modified, but the source of the modification was local.
 *
 * If this is the coach, we should send that modification out to the other clients as a signal.
 *
 *
 * @param {*} key Key of the data item
 * @param {*} value What value to set
 * @param {*} context If it's a context-specific value, what context to use.
 */
export const sharedLocalValueReceived = (key, value, context = undefined) => ({
  type: SHARED_LOCAL_VALUE_RECEIVED,
  payload: { key, value, context }
});

/**
 * A Shared value should be appended to, but the source of the modification was local.
 *
 * If this is the coach, we should send that modification out to the other clients as a signal.
 */
export const sharedLocalValueAppended = (key, value, context = undefined) => ({
  type: SHARED_LOCAL_VALUE_APPENDED,
  payload: { key, value, context }
});

export const sharedEnableLocalBackup = key => ({ type: SHARED_ENABLE_LOCAL_BACKUP, payload: { key } });

// Selectors -=-=-=-=-=--=-=-=-=--=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=

export const getSharedSnapshotId = state => state.sharedstate.localstoreKey;

export const getSharedSnapshot = state => ({
  sharedData: state.sharedstate.sharedData,
  contextData: state.sharedstate.contextData
});

export const getSharedState = (state, key, context = undefined) => {
  if (context) {
    return (state.sharedstate.contextData[context] || {})[key];
  }
  return state.sharedstate.sharedData[key];
};

export const getSharedStateForConnection = (state, key, connectionId) =>
  getSharedState(state, key, "CON" + connectionId);

export const getPreferredNameForConnectionId = (state, connectionId) =>
  getSharedState(state, "preferredName", "CON" + connectionId);

export const hasCapability = (state, connectionId, capability, minLevel = 1) => {
  const capabilities = getSharedState(state, "capabilities", "CON" + connectionId);
  if (!capabilities) return false;
  if (!capabilities[capability]) return false;
  return capabilities[capability] >= minLevel;
};

// Non-Generic Selectors that look at a specific piece of shared state to give an domain specific value.

/**
 * Returns a hash of connectionId => muted state.
 *
 * {
 *  CONNID1: true,
 *  CONNID2: false
 * }
 *
 * @param {*} state
 */
export const anyClientSupportsViewingWhiteboard = state =>
  Object.keys(state.sharedstate.contextData)
    .filter(key => key.substr(0, 3) === "CON")
    .map(key => key.substr(3))
    .find(connectionId => hasCapability(state, connectionId, "viewWhiteboard", 1));

/**
 * Returns a hash of connectionId => muted state.
 *
 * {
 *  CONNID1: true,
 *  CONNID2: false
 * }
 *
 * @param {*} state
 */
export const getSharedMutedHash = state =>
  Object.keys(state.sharedstate.contextData)
    .filter(key => key.substr(0, 3) === "CON")
    .map(key => key.substr(3))
    .reduce((acc, curr) => {
      acc[curr] = getSharedState(state, "muted", "CON" + curr) || false;
      return acc;
    }, {});

/**
 * Returns a hash that tells us about each connection ID
 *
 * {
 *   ConnectionId1: {
 *       muted: false,
 *       userId: "xxx",
 *       preferredName: "xxxx",
 *       capabilities: {},
 *       whiteboardControl: false
 *   }
 * }
 *
 * NOTE: we only have this information about a connection after it sends a learnerWelcome packet
 *
 */
export const getSharedConnectionHash = state =>
  Object.keys(state.sharedstate.contextData)
    .filter(key => key.substr(0, 3) === "CON")
    .reduce((acc, key) => {
      const connectionId = key.substr(3);
      acc[connectionId] = state.sharedstate.contextData[key];
      return acc;
    }, {});

/**
 *
 * Is a particular connection ID currently muted?
 *
 * @param {*} state
 * @param {*} connectionId
 */
export const isUserMuted = (state, connectionId) => {
  return getSharedState(state, "muted", "CON" + connectionId);
};

export const isControllingWhiteboard = (state, connectionId) => {
  return getSharedState(state, "whiteboardControl", "CON" + connectionId);
};

/**
 * What slide index are we currently viewing? -1 for no slide.
 *
 * @param {*} state
 */
export const getCurrentSlideIndex = state => extractPath(state.sharedstate.sharedData, "currentSlide.index", -1);

/**
 * The group-wide chat history.
 *
 * @param {*} state
 */
export const getGroupChatHistory = state => state.sharedstate.sharedData.groupChat;

/**
 * Private chat history for a given connection id
 *
 * @param {*} state
 */
export const getPrivateChatHistory = (state, userId) => getSharedState(state, "coachChat", "USR" + userId);

/**
 *
 * When was the last update to shared data made, as a timestamp.
 *
 * @param {*} state
 */
export const getSharedLastUpdate = state => state.sharedstate.lastUpdate;

/**
 * Returns a list of connection ID's for a user. Usually it's just one, but you might have a user connected
 * more than once in rare situations.
 * @param {} state
 */
export const getConnectionIdsForUser = (state, userId) => {
  const contextData = state.sharedstate.contextData;
  return Object.keys(contextData)
    .filter(key => key.substr(0, 3) === "CON")
    .filter(key => userId === contextData[key].userId)
    .map(key => key.substr(3));
};

export const findTutorName = state => {
  const contextData = state.sharedstate.contextData;
  const coachCon = Object.values(contextData)
    .reverse()
    .find(con => con.userId === "coach");
  if (coachCon) return coachCon.preferredName;
  return "Tutor";
};

export const getConnectionIds = state => {
  const contextData = state.sharedstate.contextData;
  return Object.keys(contextData)
    .filter(key => key.substr(0, 3) === "CON")
    .map(key => key.substr(3));
};
