import { call, all, select, put, take, takeLatest, fork } from "redux-saga/effects";
import { Studio } from "@rosetta/sqrl-client";
import {
  getLcpAPIUrl,
  getPuddleRoot,
  INIT_APP_COMPLETE,
  isAppInitialized,
  getPreferredLocale,
  SET_TIME_ZONE
} from "../../app/reducer/reducer";
import { getPreference } from "../../../modules/app/reducer/reducer";
import { recordSessionDateTimeRequested } from "../../../modules/analytics/learner-analytics";
import { getContentKeys } from "../../topic/topic-reducer";
import { logMinorError } from "../../../modules/errorlogging/errorlogging-reducer";
import { TopicService } from "../../topic/topic-service";

import { logSegment, endInteraction, startInteraction } from "@rosetta/eve-client";
import moment from "moment";

import {
  getSelectedSession,
  getSelectedUnit,
  getSelectedLesson,
  getSelectedTopic,
  getSelectedProductId,
  getSchedulingConfig,
  loadTopics,
  loadSignedUpSessions,
  schedulerInitAppComplete,
  sessionBooked,
  sessionCancelled,
  sessionCancelFailed,
  SCHEDULER_INIT_APP,
  SCHEDULER_BOOK_SESSION,
  SCHEDULER_CANCEL_SESSION,
  SCHEDULER_LOAD_TOPICS,
  SCHEDULER_REQUEST_TIME,
  getScheduleTutorFilter,
  getSelectedUnitFilter,
  getRequestedDate,
  getRequestedTime,
  schedulingInfoLoaded,
  getScheduleSelectedDate,
  SCHEDULER_DATE_SELECTED
} from "./scheduler-reducer";
import { recordSessionCancelled, recordSessionScheduled } from "../../analytics/learner-analytics";

export function* loadSchedulingInfo() {
  // Note: This also gets called when entering the presession screen for a session.
  try {
    const lcpServerApiUrl = yield select(getLcpAPIUrl);
    const lcpStudio = new Studio(lcpServerApiUrl);
    const productId = yield select(getSelectedProductId);
    // const unit = yield select(getSelectedUnit);
    const unit = yield select(getSelectedUnitFilter);
    const lesson = yield select(getSelectedLesson);
    const selectedDate = yield select(getScheduleSelectedDate);

    const m = selectedDate ? moment(selectedDate) : moment();

    const startTime = m.startOf("day").unix();
    const endTime = m.endOf("day").unix();

    const response = yield lcpStudio.getSchedulingInfo(
      productId, //product_identifier,
      unit || -1, //cdt_style_unit,
      lesson, //lesson,
      startTime, //start_time,
      endTime, //end_time,
      true //retrieve_all_sessions
    );

    if (response.scheduled_attendances) {
      const imageLoaders = [];
      for (const session of response.scheduled_attendances) {
        if (session.topic && session.topic.category_icon) {
          imageLoaders.push(call(loadTopicCategoryIcon, session.topic));
        }
      }
      yield all(imageLoaders);
    }

    yield put(
      schedulingInfoLoaded(
        response.schedulable_studio_sessions,
        response.scheduled_attendances,
        response.scheduling_config,
        response.consumable_info,
        response.schedulable_days
      )
    );

    logSegment("application-startup", "schedule-info-loaded");
  } catch (e) {
    yield put(logMinorError(e, "Could not load SchedulingInfo"));
  }
}

export function* loadSignedUpSessionsInfo() {
  try {
    const lcpServerApiUrl = yield select(getLcpAPIUrl);
    const lcpStudio = new Studio(lcpServerApiUrl);
    const response = yield call(lcpStudio.getSignedUpSessions);
    const imageLoaders = [];
    for (const session of response) {
      if (session.topic && session.topic.category_icon) {
        imageLoaders.push(call(loadTopicCategoryIcon, session.topic));
      }
    }
    yield all(imageLoaders);
    yield put(loadSignedUpSessions(response));
  } catch (e) {
    yield put(logMinorError(e, "Could not load SignedUpSessionsInfo"));
  }
}

// A cache of promises that resolve to uri's for given resource ID's
const iconCache = {};

function* loadTopicCategoryIcon(topic) {
  const keys = yield select(getContentKeys);
  const puddleRoot = yield select(getPuddleRoot);
  if (topic.category_icon) {
    const resourceId = topic.category_icon.match(/[a-z0-9]{40}/)[0];
    if (iconCache[resourceId]) {
      topic.categoryIconUri = yield iconCache[resourceId];
    } else {
      const imagePromise = TopicService.loadImage(resourceId, keys, puddleRoot);
      const uriPromise = imagePromise.then(imageResource => {
        const uri = URL.createObjectURL(imageResource);
        topic.categoryIconUri = uri;
        return uri;
      });
      iconCache[resourceId] = uriPromise;
      return uriPromise;
    }
  } else {
    topic.categoryIconUri = null;
  }
}

export function* schedulerLoadTopics(action) {
  const lcpServerApiUrl = yield select(getLcpAPIUrl);
  const lcpStudio = new Studio(lcpServerApiUrl);
  const schedulingConfig = yield select(getSchedulingConfig);
  const session = yield select(getSelectedSession);
  const productId = yield select(getSelectedProductId);

  if (schedulingConfig.topical_tutoring) {
    let iso8601Date = moment().format("YYYY-MM-DD");
    if (session) {
      iso8601Date = moment.unix(session.start_time_in_seconds).format("YYYY-MM-DD");
    }
    const topics = yield call(lcpStudio.getDiscussionTopics, productId, iso8601Date);
    const imageLoaders = [];
    for (const topic of topics) {
      imageLoaders.push(call(loadTopicCategoryIcon, topic));
    }
    yield all(imageLoaders);
    yield put(loadTopics(topics));
  }
  if (session) {
    if (action && action.payload.onSuccess) {
      action.payload.onSuccess();
    }
  }
}

export function* schedulerInitApp() {
  const isAppInitComplete = yield select(isAppInitialized);
  if (!isAppInitComplete) {
    yield take(INIT_APP_COMPLETE);
  }
  yield all([call(loadSchedulingInfo), call(loadSignedUpSessionsInfo)]);
  yield call(schedulerLoadTopics);
  yield put(schedulerInitAppComplete());
  logSegment("application-startup", "scheduler-init-app");
}

export function* schedulerBookSession(action) {
  startInteraction("book-session");
  const lcpServerApiUrl = yield select(getLcpAPIUrl);
  const lcpStudio = new Studio(lcpServerApiUrl);
  const session = yield select(getSelectedSession);
  const unit = yield select(getSelectedUnit);
  const lesson = yield select(getSelectedLesson);
  const topic = yield select(getSelectedTopic);
  const selectedProductId = yield select(getSelectedProductId);
  const canBeScheduledAsSoloSession = selectedProductId === "WW-ENG" ? false : session.can_be_scheduled_as_one_on_one;
  const locale = yield select(getPreferredLocale);

  const tutorFilter = yield select(getScheduleTutorFilter);
  const useScheduledSession = tutorFilter !== "All";

  try {
    const response = yield call(
      lcpStudio.signUp,
      session.eschool_session_id,
      unit,
      lesson,
      canBeScheduledAsSoloSession,
      topic && topic.id,
      useScheduledSession
    );

    yield fork(loadSchedulingInfo);

    yield put(sessionBooked(response.attendanceId));
    if (action.payload.onSuccess) {
      const date = moment.unix(session.start_time_in_seconds);
      recordSessionScheduled({
        "Session ID": session.eschool_session_id,
        "Days Before Session": date.diff(moment(), "days"),
        "Day of the Week (Learner)": date.format("dddd"),
        "Time (Learner)": date.format("HH:mm"),
        Date: date.utc().format("YYYY-MM-DD"),
        "Day of the Week": date.utc().format("dddd"),
        Time: date.utc().format("HH:mm"),
        "Interface Language (L1)": locale,
        "Active Learning Language (L2)": selectedProductId
      });
      action.payload.onSuccess();
    }
    endInteraction("book-session");
  } catch (e) {
    if (action.payload.onError) {
      yield fork(loadSchedulingInfo);
      action.payload.onError(e.standardized_error_code);
    }
  }
}

export function* schedulerCancelSession() {
  const lcpServerApiUrl = yield select(getLcpAPIUrl);
  const lcpStudio = new Studio(lcpServerApiUrl);
  const session = yield select(getSelectedSession);
  const locale = yield select(getPreferredLocale);
  const selectedProductId = yield select(getSelectedProductId);
  const sessionId = session.eschool_session_id;

  try {
    yield call(lcpStudio.unsignUp, sessionId);

    yield call(loadSchedulingInfo);

    yield put(sessionCancelled(true));
    recordSessionCancelled({
      "Session ID": sessionId,
      "Interface Language (L1)": locale,
      "Active Learning Language (L2)": selectedProductId,
      "Minutes Before Session": moment.unix(session.start_time_in_seconds).diff(moment(), "minutes")
    });
  } catch (e) {
    yield fork(loadSchedulingInfo);
    yield put(sessionCancelFailed(e.standardized_error_code));
  }
}

export function* schedulerRequestTime() {
  const selectedTimeZone = yield select(getPreference, "time_zone_id");
  const requestedDate = yield select(getRequestedDate);
  const requestedTime = yield select(getRequestedTime);

  recordSessionDateTimeRequested({
    "Requested Date": requestedDate.format("MMMM Mo YYYY"),
    "Requested Time": requestedTime,
    "User Timezone": selectedTimeZone
  });
}

export function* schedulerSagas() {
  yield takeLatest(SCHEDULER_DATE_SELECTED, loadSchedulingInfo);
  yield takeLatest(SET_TIME_ZONE, loadSchedulingInfo);
  yield takeLatest(SCHEDULER_INIT_APP, schedulerInitApp);
  yield takeLatest(SCHEDULER_BOOK_SESSION, schedulerBookSession);
  yield takeLatest(SCHEDULER_CANCEL_SESSION, schedulerCancelSession);
  yield takeLatest(SCHEDULER_LOAD_TOPICS, schedulerLoadTopics);
  yield takeLatest(SCHEDULER_REQUEST_TIME, schedulerRequestTime);
}
