import React from "react";
import exact from "prop-types-exact";

export const PersonInCircleIcon = props => (
  <svg width={120} height={120} fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M60 120c33.137 0 60-26.863 60-60S93.137 0 60 0 0 26.863 0 60s26.863 60 60 60z"
      fill="#fff"
    />
    <path
      d="M47.611 42.19c0 6.606 5.615 11.909 12.406 11.909 6.792 0 12.407-5.39 12.407-11.91 0-6.605-5.615-11.908-12.407-11.908-6.791 0-12.406 5.303-12.406 11.909zm33.145 40.507V76.35c0-10.952-9.328-19.905-20.738-19.905s-20.737 8.953-20.737 19.906v6.345c4.8 2.955 12.497 4.694 20.737 4.694 8.241 0 15.938-1.826 20.738-4.694z"
      fill="#4195D3"
    />
  </svg>
);
PersonInCircleIcon.propTypes = exact({});
PersonInCircleIcon.defaultProps = {};
export default PersonInCircleIcon;
