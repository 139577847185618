import React from "react";
import styled from "@emotion/styled";
import { PropTypes } from "prop-types";
import { brightBlue } from "../../styles/colors";
import { StarIcon } from "../icons/StarIcon";

const IconStyle = backgroundColor =>
  styled.div({
    display: "inline-block",
    marginLeft: 1,
    marginBottom: 0,
    width: 8,
    height: 30,
    backgroundColor: backgroundColor,
    borderRadius: 8,
    position: "relative",
    top: 2
  });

// Note - this used to visually look like 5 stars. Now it's 5 rectangles.
const EmptyIcon = IconStyle("#e7e7e7");
const FilledIcon = IconStyle("#4596d3");

const StarContainer = styled.div(({ border, background }) => {
  const common = {
    marginLeft: "auto",
    marginRight: "auto",
    border: "none",
    display: "inline-block",
    backgroundColor: background ? "#ffffff" : "none",
    padding: 5
  };

  if (border) {
    return {
      ...common,
      border: "1px solid #dddddd",
      marginBottom: 10
    };
  }

  return {
    ...common,
    verticalAlign: "middle"
  };
});

const ClickyIcon = styled.span(({ margin = 3 }) => ({
  cursor: "pointer",
  margin: `0px ${margin}px`
}));

const StarIndexes = Array(5)
  .fill(null)
  .map((v, idx) => idx);

const Star = props => (
  <ClickyIcon onClick={props.onClick} margin={10}>
    <StarIcon color={props.filled ? brightBlue : "#E6E6E6"} />
  </ClickyIcon>
);

const RSTVStar = props => (
  <ClickyIcon onClick={props.onClick} margin={1}>
    <StarIcon color={props.filled ? "#FBCF10" : "#84898E"} width={"25"} height={"24"} />
  </ClickyIcon>
);

const Bar = props => (
  <ClickyIcon>
    {props.filled ? <FilledIcon color="primary" onClick={props.onClick} /> : <EmptyIcon onClick={props.onClick} />}
  </ClickyIcon>
);

export class IconGauge extends React.PureComponent {
  static propTypes = {
    onChange: PropTypes.func,
    itemRender: PropTypes.func,
    value: PropTypes.number,
    border: PropTypes.bool,
    background: PropTypes.bool
  };

  static defaultProps = {
    border: true,
    background: true
  };

  starClicked = index => event => this.props.onChange && this.props.onChange(index + 1, event);

  render() {
    return (
      <StarContainer border={this.props.border} background={this.props.background}>
        {StarIndexes.map(index => this.props.itemRender(this.starClicked(index), this.props.value > index, index))}
      </StarContainer>
    );
  }
}

export const BarGauge = props => (
  <IconGauge
    {...props}
    itemRender={(onClick, filled, index) => <Bar key={index} onClick={onClick} filled={filled} />}
  />
);

export const StarGauge = props => (
  <IconGauge
    {...props}
    itemRender={(onClick, filled, index) => <Star key={index} onClick={onClick} filled={filled} />}
  />
);

export const RSTVStarGauge = props => (
  <IconGauge
    {...props}
    itemRender={(onClick, filled, index) => <RSTVStar key={index} onClick={onClick} filled={filled} />}
  />
);
