import { PropTypes } from "prop-types";

export const padLeft = (n, width, z) => {
  z = z || "0";
  n = n + "";
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
};

export const isProduction = () => "tutor.rosettastone.com" === window.location.host;

export const reactRouterPropTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
  staticContext: PropTypes.object
};
