import memoizeOne from "memoize-one";
import { extractPath } from "@rosetta/redux-utils";
import { RT_ARCHIVE_STARTED, RT_SIGNAL_RECEIVED } from "../realtime/realtime-reducer";
import { signalEvent, archiveStartEvent, eventsToChatHistory } from "./archive-events";

const NEXT_REPLAY = "NEXT_REPLAY";
const PREV_REPLAY = "PREV_REPLAY";
const ACTIVATE_REPLAY = "ACTIVATE_REPLAY";
const RECORDINGS_LOADED = "RECORDINGS_LOADED";

const REPLAY_PROGRESS = "REPLAY_PROGRESS";

const EVENT_OCCURED = "EVENT_OCCURED";

const EVENTS_LOADED = "EVENTS_LOADED";
const EVENTS_SAVED = "EVENTS_SAVED";
export const MANUAL_SAVE_EVENTS = "MANUAL_SAVE_EVENTS";

const initial = {
  lastEventSaveCount: 0,
  eventLog: [],

  recordingArchive: false, // Are we currently recording?
  replayActive: false, // Are we currently reviewing the replays?
  archiveId: null, // What archived ID are we currently recording to?

  currentReplayTime: 0, // The timestamp, in seconds, that we're current on in the replay video
  currentReplay: 0, // What replay video are we currently on?
  replays: [] // List of previously recorded videos, they each have a url param
};

// We only save these signal types to our eventLog. Some signals, especially the welcome one, would be too big and have no real value.
const signalTypesToReport = ["chat", "showslide"];
const recordEntireSignal = signal => signal && signal.type && signalTypesToReport.includes(signal.type);

const onArchiveStarted = (state, action) => {
  if (state.eventLog.find(log => log.type === "archiveStart" && log.id === action.payload.archiveID)) {
    // Don't add duplicate archiveStart eventLog items
    return {
      ...state,
      archiveId: action.payload.archiveID,
      recordingArchive: true
    };
  }

  return {
    ...state,
    archiveId: action.payload.archiveID,
    recordingArchive: true,
    eventLog: [...state.eventLog, archiveStartEvent(action.payload.archiveID, action.payload.timestamp)]
  };
};

const eventFilter = eventType => memoizeOne(allEvents => allEvents.filter(event => event.type === eventType) || []);
export const archiveStartEvents = eventFilter("archiveStart");
export const signalEvents = eventFilter("signal");
export const whiteboardEvents = eventFilter("whiteboard");

export const lastWhiteboard = events => {
  const last = events.slice(-1)[0];
  return last ? last.data : [];
};

export const lastSlide = events => {
  const last = events.filter(event => event.signal && event.signal.type === "showslide").slice(-1)[0];
  return last ? last.signal.data.index : -1;
};

const _getArchiveStartTimestamp = (events, archiveId) => {
  const archiveEvent = archiveStartEvents(events).find(event => event.id === archiveId);
  if (archiveEvent) {
    return archiveEvent.timestamp;
  } else if (events.length > 0) {
    // If we can't find the archive start, return the initial timestamp we know about.
    return events[0].timestamp;
  }
  // If there are no events, it doesn't really matter.
  return 0;
};
export const getArchiveStartTimestamp = memoizeOne(_getArchiveStartTimestamp);

const _archiveTimeToTimestamp = (events, archiveId, currentTimeInSeconds) => {
  return getArchiveStartTimestamp(events, archiveId) + currentTimeInSeconds * 1000;
};
export const archiveTimeToTimestamp = memoizeOne(_archiveTimeToTimestamp);

// Record the raw signal. Useful for chat & showSlide signals
const recordSignal = (state, action) => ({
  ...state,
  eventLog: [
    ...state.eventLog,
    signalEvent(action.payload.signal, action.payload.connectionId, action.payload.timestamp)
  ]
});

const onSignal = (state, action) => {
  if (recordEntireSignal(action.payload.signal)) {
    return recordSignal(state, action);
  }
  return state;
};

export const archiveReducer = (state = initial, action = undefined) => {
  switch (action && action.type) {
    case REPLAY_PROGRESS:
      return { ...state, currentReplayTime: action.payload.time };

    case EVENT_OCCURED:
      return {
        ...state,
        eventLog: [...state.eventLog, action.payload.event]
      };

    case EVENTS_LOADED:
      return {
        ...state,
        eventLog: action.payload.events,
        lastEventSaveCount: action.payload.events.length
      };
    case EVENTS_SAVED:
      return {
        ...state,
        lastEventSaveCount: action.payload.events.length
      };
    case ACTIVATE_REPLAY:
      return { ...state, replayActive: true };
    case RECORDINGS_LOADED:
      return { ...state, replays: action.payload.recordings };
    case RT_SIGNAL_RECEIVED:
      return onSignal(state, action);
    case RT_ARCHIVE_STARTED:
      return onArchiveStarted(state, action);
    case NEXT_REPLAY:
      return {
        ...state,
        currentReplay: Math.min(state.replays.length - 1, state.currentReplay + 1)
      };
    case PREV_REPLAY:
      return {
        ...state,
        currentReplay: Math.max(0, state.currentReplay - 1)
      };
    default:
      return state;
  }
};

// Selectors

export const isRecordingArchive = state => state.archive.recordingArchive;
export const isReplayAvailable = state => state.archive.replays && state.archive.replays.length > 0;
export const isReplayActive = state => state.archive.replays && state.archive.replayActive > 0;
export const getCurrentReplay = state => state.archive.replays[state.archive.currentReplay];
export const getCurrentReplayIndex = state => state.archive.currentReplay;
export const getCurrentReplayArchiveId = state =>
  extractPath(state.archive.replays[state.archive.currentReplay], "archive_id", null);
export const getReplayCount = state => state.archive.replays.length;
export const getArchiveId = state => state.archive.archiveId;
export const getEventLog = state => state.archive.eventLog;

// Returns true if we have events in memory that aren't saved. Since this is append-only, we can just check the length.
export const hasUnsavedEvents = state => state.archive.eventLog.length !== state.archive.lastEventSaveCount;
export const getArchiveStartEvents = state => archiveStartEvents(getEventLog(state));

// Returns what we think the timestamp should be for the current position in the current replay based on
// the event log telling us when that archive started.
export const getReplayTimestamp = state =>
  archiveTimeToTimestamp(state.archive.eventLog, getCurrentReplayArchiveId(state), state.archive.currentReplayTime);

export const getReplayEvents = state =>
  state.archive.eventLog.filter(log => log.timestamp <= getReplayTimestamp(state));

export const getReplayChat = state => eventsToChatHistory(getReplayEvents(state));
export const getReplaySlideIndex = state => lastSlide(signalEvents(getReplayEvents(state)));
export const getReplayWhiteboard = state => lastWhiteboard(whiteboardEvents(getReplayEvents(state)));

// Actions
export const onReplayProgress = time => ({ type: REPLAY_PROGRESS, payload: { time } });
export const activateReplay = () => ({ type: ACTIVATE_REPLAY });
export const recordingsLoaded = recordings => ({ type: RECORDINGS_LOADED, payload: { recordings } });
export const eventOccured = event => ({ type: EVENT_OCCURED, payload: { event } });
export const manualSaveEvents = () => ({ type: MANUAL_SAVE_EVENTS });
export const onEventsLoaded = events => ({ type: EVENTS_LOADED, payload: { events } });
export const onEventsSaved = events => ({ type: EVENTS_SAVED, payload: { events } });

export const nextReplay = () => ({ type: NEXT_REPLAY });
export const prevReplay = () => ({ type: PREV_REPLAY });
