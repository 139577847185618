import appboy from "appboy-web-sdk"; // aka Braze
import { takeEvery, spawn, takeLatest, select, put, all } from "redux-saga/effects";
import { delay } from "redux-saga";
import {
  USER_TYPES,
  getUserType,
  getESchoolApiKey,
  getESchoolSessionId,
  getESchoolAPIBase,
  appError,
  getTutorName,
  getSappyAuthInfo
} from "../app/reducer/reducer";
import { publishFeedback, updateSessionFeedback } from "../app/services/eschool";
import uuid from "uuid/v4";
import { RT_RECIEVE_TOKENS, RT_END_SESSION } from "../realtime/realtime-reducer";
import { strToVocab, vocabToStr } from "./vocab-util";
import {
  COACH_MODIFY_VOCAB,
  getCoachAuthoredVocab,
  COACH_MODIFY_FEEDBACK,
  getCoachLearnerFeedback,
  COACH_MODIFY_INTERNAL_NOTES,
  getCoachInternalNote,
  coachFeedbackLoaded,
  getAllCoachRatings,
  COACH_RATE_LEARNER,
  COACH_SAVE_FEEDBACK,
  coachFeedbackSaved,
  COACH_PUBLISH_FEEDBACK,
  coachFeedbackPublished,
  coachSaveFeedback,
  getCoachStudents,
  getStudentsSeen,
  getLanguageProduct,
  getProduct,
  getCoachId,
  coachNotesLoaded,
  COACH_NOTE_MODIFIED,
  getDirtyNoteIndexes,
  getCoachNoteRecords,
  getCoachNotes,
  coachNoteSaved,
  coachSlideVocabLoaded
} from "./coach-reducer";
import { getCurrentTopicVocabulary, TOPIC_LOADED, getCurrentTopicSlides, getClassId } from "../topic/topic-reducer";
import { vocabToSaveFormat, ratingsToSaveFormat } from "./coach-service";
import {
  LEARNER_AMPLITUDE_KEY,
  AMPLITUDE_API_URL,
  FLUBU_BRAZE_KEY,
  STUDIO_BRAZE_KEY,
  PRODUCTS
} from "../app/constants";

import { getNote, deleteNote, saveCoachNote } from "./sappy-service";
import { logMinorError, logToEschool } from "../errorlogging/errorlogging-reducer";

const COACH_NOTE_SAVE_DELAY = 2000;

/* We're going to save our coach authored vocab in localstorage in case the coach reloads the page, so they don't lose it. */
const MAX_LOCAL_STORAGE_BYTES = 500000; // let's limit localstorage chat history to around 500k so we don't go crazy.
const HISTORY_KEY_PREFIX = "tutor-history";
const historyKey = sessionId => `${HISTORY_KEY_PREFIX}-${sessionId}`;

const SESSION_COMPLETED_EVENT_NAME = "Session Completed";

function clearOldVocabHistories(sessionId) {
  // If a coach starts up a session, they're unlikely to re-visit other sessions, so we clear those out.
  for (let i = 0; i < window.localStorage.length; i++) {
    const key = window.localStorage.key(i);
    if (key.substr(0, HISTORY_KEY_PREFIX.length) === HISTORY_KEY_PREFIX && key !== historyKey(sessionId)) {
      window.localStorage.removeItem(key);
    }
  }
}

const eventProperties = (productName, tutorName, productId, sessionId) => ({
  Product: productName,
  Tutor: tutorName,
  "Session ID": sessionId,
  "Active Learning Language (L2)": productId
  // "Interface Language (L1)": props.locale,
});

const studentIdToSessionCompleteEvent = (productName, tutorName, productId, sessionId) => studentId => ({
  user_id: studentId,
  event_type: SESSION_COMPLETED_EVENT_NAME,
  event_properties: eventProperties(productName, tutorName, productId, sessionId),
  time: new Date().getTime()
});

// eslint-disable-next-line no-unused-vars
export function* onSessionCompleted(action) {
  const userType = yield select(getUserType);
  if (userType !== USER_TYPES.COACH) return;
  const product = yield select(getProduct);
  const productName = product === PRODUCTS.PRODUCT_FB ? "Fluency Builder" : "Foundations";
  const tutorName = yield select(getTutorName);
  const selectedProductId = yield select(getLanguageProduct);
  const students = yield select(getStudentsSeen);
  const sessionId = yield select(getESchoolSessionId);
  yield put(logToEschool("Session Completed"));
  yield recordAmplitudeSessionCompleted(students, productName, tutorName, selectedProductId, sessionId);
  if (product === PRODUCTS.PRODUCT_STUDIO || product === PRODUCTS.PRODUCT_FB) {
    yield recordBrazeSessionCompleted(product, students, productName, tutorName, selectedProductId);
  }
}

// eslint-disable-next-line require-yield
function* recordBrazeSessionCompleted(product, students, productName, tutorName, selectedProductId) {
  const key = product === PRODUCTS.PRODUCT_STUDIO ? STUDIO_BRAZE_KEY : FLUBU_BRAZE_KEY;
  appboy.initialize(key, { sessionTimeoutInSeconds: 1 }); // Set a 1-second timeout so braze doesn't continue thinking we're this learner.
  const eventProps = eventProperties(productName, tutorName, selectedProductId);
  for (const studentId of students) {
    appboy.changeUser(studentId);
    appboy.openSession();
    appboy.logCustomEvent(SESSION_COMPLETED_EVENT_NAME, eventProps);
  }
}

function* recordAmplitudeSessionCompleted(students, productName, tutorName, selectedProductId, sessionId) {
  const data = students.map(studentIdToSessionCompleteEvent(productName, tutorName, selectedProductId, sessionId));
  const event = encodeURIComponent(JSON.stringify(data));
  try {
    const response = yield fetch(AMPLITUDE_API_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      body: `api_key=${LEARNER_AMPLITUDE_KEY}&event=${event}`
    });
    console.info("Session Completed Event: ", response);
  } catch (e) {
    console.error("Could not send session compelted amplitude events");
    yield put(logMinorError(e, "Could not send session completed amplitude events"));
  }
}

// eslint-disable-next-line no-unused-vars
function* onTopicLoaded(action) {
  const userType = yield select(getUserType);
  if (userType === USER_TYPES.LEARNER) return;
  const slides = yield select(getCurrentTopicSlides);
  const teacherId = yield select(getCoachId);
  try {
    const { sappyKey, sappyUrl } = yield select(getSappyAuthInfo);
    const classId = yield select(getClassId);
    const loadPromises = slides.map((slide, index) => getNote(classId, index, teacherId, sappyKey, sappyUrl));
    const results = yield all(loadPromises);

    for (const [index, notes] of results.entries()) {
      const vocabNotes = notes.filter(note => note.tags.note_type === "vocabulary");
      const nonVocabNotes = notes.filter(note => note.tags.note_type !== "vocabulary");
      const combinedNotes = nonVocabNotes.reduce((acc, curr) => acc + curr.value.text, "").trim();

      if (nonVocabNotes.length > 0) {
        yield put(coachNotesLoaded(combinedNotes, index, nonVocabNotes));
      }
      if (vocabNotes.length > 0) {
        const vocab = vocabNotes.map(v => v.value.text.trim());
        yield put(coachSlideVocabLoaded(vocab, index));
      }
    }
  } catch (e) {
    yield put(logMinorError(e, "Could not load tutor notes"));
  }
}

export function* loadFeedbackHistoryFromLocalstore(sessionId) {
  const historyStr = window.localStorage.getItem(historyKey(sessionId));
  if (!historyStr) {
    return;
  }
  const history = JSON.parse(historyStr);
  const vocabHistory = strToVocab(history.vocab);
  const { feedback, internalNotes, ratings } = history;

  yield put(coachFeedbackLoaded(feedback, internalNotes, vocabHistory, ratings));
}

function* onReceiveTokens() {
  try {
    const userType = yield select(getUserType);
    if (userType !== USER_TYPES.COACH) {
      return;
    }
    const sessionId = yield select(getESchoolSessionId);
    clearOldVocabHistories(sessionId);
  } catch (e) {
    console.warn("Could not load feedback history from localstorage.");
  }
}

function* onFeedbackModified() {
  try {
    const userType = yield select(getUserType);
    if (userType !== USER_TYPES.COACH) {
      return;
    }

    const vocab = yield select(getCoachAuthoredVocab);
    const feedback = yield select(getCoachLearnerFeedback);
    const internalNotes = yield select(getCoachInternalNote);
    const sessionId = yield select(getESchoolSessionId);
    const ratings = yield select(getAllCoachRatings);
    const vocabStr = vocabToStr(vocab);

    const data = {
      ratings,
      feedback,
      internalNotes,
      vocab: vocabStr.substr(0, MAX_LOCAL_STORAGE_BYTES)
    };

    window.localStorage.setItem(historyKey(sessionId), JSON.stringify(data));
  } catch (e) {
    console.warn("Could not persist vocab to localstorage.");
  }
}

function* coachPublishFeedback() {
  const students = yield select(getCoachStudents);
  if (students.length === 0) return;
  const apiKey = yield select(getESchoolApiKey);
  const sessionId = yield select(getESchoolSessionId);
  const baseUrl = yield select(getESchoolAPIBase);
  const coachRatings = yield select(getAllCoachRatings);
  const internalNotes = yield select(getCoachInternalNote);
  const learnerFeedback = yield select(getCoachLearnerFeedback);
  const tutorVocab = yield select(getCoachAuthoredVocab);
  const topicVocab = yield select(getCurrentTopicVocabulary);

  try {
    yield saveFeedback(
      apiKey,
      baseUrl,
      sessionId,
      coachRatings,
      topicVocab,
      tutorVocab,
      learnerFeedback,
      internalNotes
    );
  } catch (e) {
    yield put(appError(e, "err_save_feedback", e));
    return;
  }

  try {
    const response = yield publishFeedback(apiKey, baseUrl);
    const responseBody = yield response.json();
    if (responseBody.status !== "ok") {
      yield put(appError(null, "err_publish_feedback", { responseBody }));
    } else {
      yield put(coachFeedbackPublished());
    }
    yield put(logToEschool("Feedback Published"));
  } catch (e) {
    yield put(appError(e, "err_publish_feedback"));
  }
}

function* saveFeedback(
  apiKey,
  baseUrl,
  sessionId,
  coachRatings,
  topicVocab,
  tutorVocab,
  learnerFeedback,
  internalNotes
) {
  const students = yield select(getCoachStudents);
  if (students.length === 0) return;

  const vocab = [...topicVocab.map(vocabToSaveFormat(true)), ...tutorVocab.map(vocabToSaveFormat(false))];
  const scores = ratingsToSaveFormat(coachRatings);

  try {
    const result = yield updateSessionFeedback(apiKey, baseUrl, scores, vocab, learnerFeedback, internalNotes);
    const resultBody = yield result.json();
    if (resultBody.status !== "ok") {
      const e = new Error("Invalid save status" + resultBody.status);
      e.body = resultBody;
      throw e;
    } else {
      window.localStorage.removeItem(historyKey(sessionId));
    }
  } catch (e) {
    throw e;
  }
}

let previousSaveSignature = null;
function* onSaveFeedback() {
  const apiKey = yield select(getESchoolApiKey);
  const sessionId = yield select(getESchoolSessionId);
  const baseUrl = yield select(getESchoolAPIBase);
  const coachRatings = yield select(getAllCoachRatings);
  const internalNotes = yield select(getCoachInternalNote);
  const learnerFeedback = yield select(getCoachLearnerFeedback);
  const tutorVocab = yield select(getCoachAuthoredVocab);
  const topicVocab = yield select(getCurrentTopicVocabulary);

  const saveSignature = JSON.stringify({ coachRatings, topicVocab, tutorVocab, learnerFeedback, internalNotes });

  // Since we now auto-save, let's make sure we don't save the same exact thing over and over.
  if (saveSignature !== previousSaveSignature) {
    try {
      yield saveFeedback(
        apiKey,
        baseUrl,
        sessionId,
        coachRatings,
        topicVocab,
        tutorVocab,
        learnerFeedback,
        internalNotes
      );
    } catch (e) {
      yield put(appError(e, "err_save_feedback"));
    }
  }
  previousSaveSignature = saveSignature;
  yield put(coachFeedbackSaved());
}

// eslint-disable-next-line no-unused-vars
function* autosaveFeedback(action) {
  /* We takeLatest a bunch of actions that can change feedback into this saga
     Then we delay for 2 seconds so we're not constantly saving
     If another update comes in, this saga is canceled and another one is run, waiting another 3 seconds
     Eventually the saga isn't canceled, the 3 seconds elapses, and it actually saves.
  */

  yield delay(2000);
  yield put(coachSaveFeedback());
}

function* saveNote(slideIndex) {
  try {
    const { sappyKey, sappyUrl } = yield select(getSappyAuthInfo);
    const oldNotes = yield select(getCoachNoteRecords, slideIndex);
    if (oldNotes.length > 1) {
      // We're deleting old notes if we have more than one because we're combining those notes in the new UI
      // The old legacy player saved notes in multiple records, but we do not. We already combined the text of
      // those notes when loading above.
      for (let i = 1; i < oldNotes.length; i++) {
        const oldNote = oldNotes[i];
        yield deleteNote(oldNote.tags, sappyKey, sappyUrl);
      }
    }

    const isNewNote = oldNotes.length === 0;

    // If we had a previous note, overwrite that record. If not, create a new one with a new GUID
    const guid = isNewNote ? uuid() : oldNotes[0].tags.guid;

    const teacherId = yield select(getCoachId);
    const classId = yield select(getClassId);
    const noteText = (yield select(getCoachNotes))[slideIndex];

    if (noteText) {
      yield saveCoachNote(noteText, classId, slideIndex, teacherId, "note", guid, sappyKey, sappyUrl);

      if (isNewNote) {
        // If it's a brand new note, we should load the record we just made and add it to the store
        // so next time we save, we re-use it instead of creating a second note, duplicating data.
        // This was the likely cause of UT-575
        const loadedNotes = yield getNote(classId, slideIndex, teacherId, sappyKey, sappyUrl);
        const nonVocabNotes = loadedNotes.filter(note => note.tags.note_type !== "vocabulary");
        yield put(coachNotesLoaded(noteText, slideIndex, nonVocabNotes));
      }
    } else if (oldNotes.length > 0) {
      // If the user has removed all text, delete the note instead of saving it.
      const oldNote = oldNotes[0];
      yield deleteNote(oldNote.tags, sappyKey, sappyUrl);
    }
    yield put(coachNoteSaved(slideIndex));
  } catch (e) {
    console.warn("Could not save tutor note", e);
    yield put(logMinorError(e, "Could not save tutor note"));
  }
}

function* onCoachNoteModified() {
  // we takeLatest, so if multiple actions come in, this will bail out and we don't chain-save the same thing
  // over and over.
  yield delay(COACH_NOTE_SAVE_DELAY);

  const dirtyNotes = yield select(getDirtyNoteIndexes);
  for (const index of dirtyNotes) {
    yield spawn(saveNote, index);
  }
}

export function* vocabSagas() {
  yield takeLatest(TOPIC_LOADED, onTopicLoaded);
  yield takeEvery(RT_RECIEVE_TOKENS, onReceiveTokens);
  yield takeEvery(COACH_MODIFY_VOCAB, onFeedbackModified);
  yield takeEvery(COACH_MODIFY_FEEDBACK, onFeedbackModified);
  yield takeEvery(COACH_MODIFY_INTERNAL_NOTES, onFeedbackModified);
  yield takeEvery(COACH_RATE_LEARNER, onFeedbackModified);
  yield takeLatest(COACH_SAVE_FEEDBACK, onSaveFeedback);
  yield takeLatest(COACH_PUBLISH_FEEDBACK, coachPublishFeedback);
  yield takeLatest(RT_END_SESSION, onSessionCompleted);
  yield takeLatest(COACH_NOTE_MODIFIED, onCoachNoteModified);

  yield takeLatest(
    [COACH_MODIFY_VOCAB, COACH_MODIFY_FEEDBACK, COACH_RATE_LEARNER, COACH_MODIFY_INTERNAL_NOTES],
    autosaveFeedback
  );
}
