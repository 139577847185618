import React from "react";

import { extractPath } from "@rosetta/redux-utils";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";

import { LionTranslate } from "@rosetta/react-lion";

const findErrorReason = error =>
  extractPath(error, "error.localized_message", null) ||
  extractPath(error, "error.body.error", null) ||
  extractPath(error, "data.msg") ||
  extractPath(error, "message");

export const ErrorDialog = props => {
  const reason = findErrorReason(props.error);

  return (
    <Dialog
      open={true}
      disableBackdropClick={true}
      onClose={props.clearAppError}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <LionTranslate lionkey="error_title" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <LionTranslate lionkey={props.error.msg} />
        </DialogContentText>
        {reason && <DialogContentText>{reason}</DialogContentText>}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.clearAppError} color="primary" autoFocus>
          <LionTranslate lionkey="error_dismiss_button" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
