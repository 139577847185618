import amplitude from "amplitude-js";
import appboy from "appboy-web-sdk"; // aka Braze
import { version } from "../../../package.json";

const sreVersion = () => {
  try {
    if (!window.sonic) return "N/A";
    const se = new window.sonic.SpeechEngine();
    return se.getVersion();
  } catch (e) {
    return "N/A";
  }
};

export const identifyUser = (userId, braze = false) => {
  try {
    amplitude.getInstance().setUserId(userId);
    if (braze) {
      appboy.changeUser(userId);
      appboy.openSession();
    }
  } catch (e) {
    console.warn("Analytics: Could not identify user", e);
  }
};

export const recordBrazeEvent = eventKey => (properties = {}) => {
  appboy.logCustomEvent(eventKey, properties);
  console.log("Braze record event", eventKey, properties);
};

export const recordAmplitudeEvent = eventKey => (properties = {}) => {
  const eventProps = {
    ...properties,
    Application: "Unified Tutoring",
    "Application Version": version,
    "SRE Version": sreVersion(),
    "Screen Width": document.documentElement.clientWidth,
    "Screen Height": document.documentElement.clientHeight,
    Host: window.location.host
  };
  console.log("Amplitude Record Event", eventKey, eventProps);
  // Logging host to all events so we can filter dev/production/qa
  amplitude.getInstance().logEvent(eventKey, eventProps);
};
