import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import exact from "prop-types-exact";
import styled from "@emotion/styled";
import Select from "./Select";
import { LionTranslate } from "@rosetta/react-lion";
import MicCheck from "./MicCheck";
import { DeviceNote } from "./presession-styles";
import { RefreshButton } from "./RefreshButton";

const Container = styled.div({
  width: 450,
  display: "flex",
  flexDirection: "column",
  marginLeft: 70
});

const Columns = styled.div({
  width: 450,
  justifyContent: "space-between",
  display: "flex"
});

const Left = styled.div({
  display: "flex",
  flexDirection: "column",
  width: 355,
  flexGrow: 0,
  flexShrink: 0
});
const Right = styled.div({
  width: 78,

  flexGrow: 0,
  flexShrink: 0,
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "flex-end",
  marginBottom: 10
});

const Header = styled.div({
  display: "flex",

  justifyContent: "space-between",
  width: 355,
  fontFamily: "Effra",
  fontSize: "18px",
  lineHeight: "normal",
  color: "#4A4A4A",
  marginTop: 7,
  marginBottom: 7
});

class LearnerMicSelection extends PureComponent {
  static propTypes = exact({
    micList: PropTypes.array,
    selectedMic: PropTypes.string,
    onChange: PropTypes.func,
    refreshDevices: PropTypes.func
  });
  static defaultProps = {};

  onChange = event => this.props.onChange(event.target.value);

  render = () => {
    return (
      <Container data-qa="mic_selector">
        <Columns>
          <Left>
            <Header>
              <LionTranslate lionkey="system_check_mic_header" />
              <RefreshButton onClick={this.props.refreshDevices} />
            </Header>
            <Select data-qa="mic_list" value={this.props.selectedMic} onChange={this.onChange}>
              {this.props.micList.map(mic => (
                <option key={mic.deviceId} value={mic.deviceId}>
                  {mic.label}
                </option>
              ))}
            </Select>
          </Left>
          <Right>
            <MicCheck key={this.props.selectedMic} deviceId={this.props.selectedMic} />
          </Right>
        </Columns>
        <DeviceNote>
          <LionTranslate lionkey="system_check_mic_instructions" />
        </DeviceNote>
      </Container>
    );
  };
}

export default LearnerMicSelection;
