/**
 * Takes a string in the format of:
 *   word|definition\nword|definition
 * And spits out a list of objects with headword and definition attributes.
 * @param {*} vocabStr
 */
export const strToVocab = vocabStr =>
  vocabStr
    .split("\n")
    .map(oneLine => {
      const parts = oneLine.split("|");
      if (parts.length !== 2) {
        return undefined;
      }
      return {
        headword: parts[0].trim(),
        definition: parts[1].trim()
      };
    })
    .filter(entry => !!entry)
    .filter(entry => entry.headword && entry.definition);

/**
 * Reverse of strToVocab
 *
 * @param {*} vocabList
 */
export const vocabToStr = vocabList => vocabList.map(vocab => `${vocab.headword}|${vocab.definition}`).join("\n");
