import React from "react";
import styled from "@emotion/styled";
import { LionTranslate } from "@rosetta/react-lion";
import RefreshIcon from "../../realtime/RefreshIcon";

const Button = styled.button({
  color: "#4195D3",
  background: "none",
  border: "none",
  outline: "none",
  paddingRight: 0,
  cursor: "pointer",
  ":active": {
    position: "relative",
    top: 1,
    left: 1
  },
  ":hover": {
    textDecoration: "underline"
  },
  "> *": {
    verticalAlign: "middle"
  }
});

export const RefreshButton = props => (
  <Button onClick={props.onClick}>
    <LionTranslate lionkey="system_check_refresh_button" />
    <RefreshIcon />
  </Button>
);
