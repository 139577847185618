import React from "react";
import exact from "prop-types-exact";
import { PropTypes } from "prop-types";

// eslint-disable-next-line no-unused-vars
export const SadFace = ({ color = "#A0CAE9", ...props }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM13 12C13 13.1046 12.1046 14 11 14C9.89543 14 9 13.1046 9 12C9 10.8954 9.89543 10 11 10C12.1046 10 13 10.8954 13 12ZM23.8638 22.4961L22.1362 23.5039C19.395 18.8046 12.605 18.8046 9.86378 23.5039L8.13622 22.4961C11.6492 16.4738 20.3508 16.4738 23.8638 22.4961ZM21 14C22.1046 14 23 13.1046 23 12C23 10.8954 22.1046 10 21 10C19.8954 10 19 10.8954 19 12C19 13.1046 19.8954 14 21 14Z"
      fill={color}
    />
  </svg>
);
SadFace.propTypes = exact({
  color: PropTypes.string
});
SadFace.defaultProps = {};
export default SadFace;
