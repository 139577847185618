import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import exact from "prop-types-exact";
import {
  rtIsSelfTestComplete,
  rtAudioMosScore,
  rtVideoMosScore,
  rtIsSelfTestExecuting,
  rtCloseSelfTest
} from "../../realtime/realtime-reducer";
import styled from "@emotion/styled";
import { learnerBoxShadow } from "../../../styles/colors";
import {
  HighBandwidthMeterIcon,
  LowBandwidthMeterIcon,
  MediumBandwidthMeterIcon
} from "../../icons/BandwidthMeterIcon";
import { LionTranslate } from "@rosetta/react-lion";

const Container = styled.div({
  width: "328px",

  background: "#FFFFFF",
  borderRadius: "0px 0px 4px 4px",
  display: "flex",
  boxShadow: learnerBoxShadow,
  position: "Fixed",
  top: 72,
  right: 15,
  padding: "16px 25px",
  boxSizing: "border-box"
});

const Text = styled.div({
  marginLeft: 25,
  color: "#3E3D3F",
  "> p": {
    color: "#84898E",
    fontSize: 14
  }
});

const GOOD_ENOUGH = 3;
const REALLY_BAD = 2;

export class ConnectionTestResult extends PureComponent {
  static propTypes = exact({
    rtCloseSelfTest: PropTypes.func,
    completed: PropTypes.bool,
    mosScore: PropTypes.number,
    executing: PropTypes.bool
  });
  static defaultProps = {};

  icon = () => {
    if (this.props.mosScore > GOOD_ENOUGH) {
      return <HighBandwidthMeterIcon />;
    }
    if (this.props.mosScore >= REALLY_BAD) {
      return <MediumBandwidthMeterIcon />;
    }
    return <LowBandwidthMeterIcon />;
  };

  renderBandwidthResult = () => {
    return (
      <Container>
        {this.icon()}

        <Text>
          <b>
            <LionTranslate lionkey="system_check_bandwidth" />
          </b>
          <p>
            <LionTranslate lionkey={`system_check_bandwidth_${Math.floor(this.props.mosScore)}`} />
          </p>
          {/* <LinkButton onClick={this.props.rtCloseSelfTest}>
            <LionTranslate lionkey="dismiss_button" />
          </LinkButton> */}
        </Text>
      </Container>
    );
  };

  render = () => {
    if (!this.props.completed) return null;
    return this.renderBandwidthResult();
  };
}

const mapActionsToProps = { rtCloseSelfTest };

const mapStateToProps = state => ({
  completed: rtIsSelfTestComplete(state),
  mosScore: rtVideoMosScore(state) || rtAudioMosScore(state),
  executing: rtIsSelfTestExecuting(state)
});

export default connect(
  mapStateToProps,
  mapActionsToProps
)(ConnectionTestResult);
