/**
 * Generates the neccessary headers to talk to eschool, including content type and authorization.
 *
 * @param {*} apiKey
 */
export const headers = apiKey => ({
  "Content-Type": "application/json",
  Authorization: "bearer " + apiKey
});

export /* istanbul ignore next */ const getDiscussionTopics = (baseUrl, apiKey) =>
  fetch(baseUrl + "discussion_topics", {
    headers: headers(apiKey),
    method: "GET"
  }).then(r => r.json());

/**
 * Set the alert flag to summon tech support to this session
 *
 * @param {*} baseUrl
 * @param {*} apiKey
 */
export /* istanbul ignore next */ const alertTechProblem = (baseUrl, apiKey) =>
  fetch(baseUrl + "alert_tech_problem", {
    headers: headers(apiKey),
    method: "POST"
  }).then(r => r.json());

/**
 * Meant to be used as a polling request every 3 minutes to tell eschool we're still here.
 *
 * @param {*} baseUrl
 * @param {*} apiKey
 * @param {*} sessionId
 * @param {*} audioInputDevice
 * @param {*} audioOutputDevice
 */
export /* istanbul ignore next */ const updateTeacherAttendance = (
  baseUrl,
  apiKey,
  audioInputDevice,
  audioOutputDevice,
  teacherId
) =>
  fetch(baseUrl + "teacher_attendance", {
    headers: headers(apiKey),
    method: "PUT",
    body: JSON.stringify({
      teacher_id: teacherId,
      audio_input_device: audioInputDevice,
      audio_output_device: audioOutputDevice
    })
  }).then(r => r.json());

/**
 *
 * Get the puddle decryption keys for resources.
 *
 * @param {*} baseUrl
 * @param {*} apiKey
 */
export /* istanbul ignore next */ const getEschoolWelcomePacket = (baseUrl, apiKey) =>
  fetch(baseUrl + "welcome_packet", {
    headers: headers(apiKey)
  }).then(r => r.json());

/**
 * Retrieves the tokbox session and token information
 *
 * @param {*} baseUrl
 * @param {*} apiKey
 * @param {*} sessionId
 */
export /* istanbul ignore next */ const getAVTokens = (baseUrl, apiKey) =>
  fetch(baseUrl + "audio_video_connection_info", {
    headers: headers(apiKey)
  }).then(r => r.json());

/**
 * Retrieves the tokbox session and token information for a support session.
 *
 * @param {*} baseUrl
 * @param {*} apiKey
 * @param {*} sessionId
 */
export /* istanbul ignore next */ const getSupportAVTokens = (baseUrl, apiKey, studentId) =>
  fetch(`${baseUrl}get_support_session/${studentId}`, {
    headers: headers(apiKey),
    method: "GET"
  }).then(r => r.json());

/**
 * Retrieves the previously saved session feedback. (also contains topic info)
 *
 * @param {*} baseUrl
 * @param {*} apiKey
 * @param {*} sessionId
 */
export /* istanbul ignore next */ const getSessionFeedback = (baseUrl, apiKey) =>
  fetch(baseUrl + "session_feedback", {
    headers: headers(apiKey)
  }).then(r => r.json());

/**
 * Retrieves a one time use tokbox session/token used for our initial quality test.
 *
 * @param {*} baseUrl
 */
export /* istanbul ignore next */ const getTestConnectionInfo = (baseUrl, apiKey) =>
  fetch(`${baseUrl}audio_video_connection_info_for_network_test_session`, {
    headers: headers(apiKey)
  }).then(response => response.json());

/**
 * Our auth tokens have short expirations. This gets a new one.
 *
 * @param {*} apiKey
 * @param {*} baseUrl
 */
export /* istanbul ignore next */ const getAuthToken = (apiKey, baseUrl) =>
  fetch(baseUrl + "token", {
    headers: headers(apiKey)
  });

/**
 * Mark feedback as published and ready for the learner to see.
 *
 * @param {*} apiKey
 * @param {*} baseUrl
 * @param {*} sessionId
 */
export /* istanbul ignore next */ const publishFeedback = (apiKey, baseUrl) =>
  fetch(baseUrl + "publish_session_feedback", {
    headers: headers(apiKey),
    method: "POST"
  });

/**
 * Save the tutor session feedback
 *
 * @param {*} apiKey
 * @param {*} baseUrl
 * @param {*} sessionId
 * @param {*} scores
 * @param {*} vocab
 * @param {*} feedback
 * @param {*} internalNotes
 */
export /* istanbul ignore next */ const updateSessionFeedback = (
  apiKey,
  baseUrl,
  scores,
  vocab,
  feedback,
  internalNotes
) => {
  const body = feedbackBody(scores, vocab, feedback, internalNotes);

  return fetch(baseUrl + "update_session_feedback", {
    headers: headers(apiKey),
    method: "PUT",
    body: body
  });
};

/**
 * Expected format for the updateSessionFeedback api call.
 *
 * @param {*} sessionId
 * @param {*} scores
 * @param {*} vocab
 * @param {*} feedback
 * @param {*} internalNotes
 */
export const feedbackBody = (scores, vocab, feedback, internalNotes) =>
  JSON.stringify(
    {
      vocab_items: vocab,
      feedback: {
        evaluation_items: scores,
        feedback_html: feedback,
        notes_for_internal_use_only: internalNotes
      }
    },
    null,
    2
  );

export /* istanbul ignore next */ const getRecordings = (apiKey, baseUrl) =>
  fetch(baseUrl + "recordings", {
    headers: headers(apiKey)
  }).then(r => r.json());

export /* istanbul ignore next */ const startRecording = (apiKey, baseUrl) =>
  fetch(baseUrl + "start_recording", {
    headers: headers(apiKey),
    method: "POST"
  }).then(r => r.json());

export /* istanbul ignore next */ const stopRecording = (apiKey, baseUrl, archiveId) =>
  fetch(baseUrl + "stop_recording", {
    headers: headers(apiKey),
    method: "POST",
    body: JSON.stringify({
      archive_id: archiveId
    })
  }).then(r => r.json());

export const reportConnectionStats = (apiKey, baseUrl, stats) =>
  fetch(baseUrl + "session_stats", {
    headers: headers(apiKey),
    method: "POST",
    body: JSON.stringify(stats)
  }).then(r => r.json());

export const sessionStudents = (apiKey, baseUrl) =>
  fetch(baseUrl + "session_students", {
    headers: headers(apiKey),
    method: "GET"
  }).then(r => r.json());

export const playerEvent = (apiKey, baseUrl, msg) =>
  fetch(baseUrl + "player_event_log", {
    headers: headers(apiKey),
    method: "POST",
    body: JSON.stringify({
      message: msg
    })
  }).then(r => r.json());
