import amplitude from "amplitude-js";
import { TUTOR_AMPLITUDE_KEY } from "../app/constants";
import { recordAmplitudeEvent } from "./common-analytics";

export const initTutorAnalytics = () => {
  amplitude.getInstance().init(TUTOR_AMPLITUDE_KEY);
};

export const recordUserLoginEvent = recordAmplitudeEvent("General - App Open");
export const recordUserStartEvent = recordAmplitudeEvent("General - Start Session");
export const recordUserLogoutEvent = recordAmplitudeEvent("General - App Close");
