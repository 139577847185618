export const getDebugHostForUrl = host => {
  switch (host) {
    case "tutor-qa3.dev.rosettastone.com":
      return "https://lcp-qa3.dev.rosettastone.com/";
    case "tutor-dev.dev.rosettastone.com":
    default:
      return "https://lcp-dev.dev.rosettastone.com/";
  }
};
export const lcpUrl = getDebugHostForUrl(window.location.host);
export const authInfo = {
  product: "WW-ENG",
  username: "sqrl-test@rosettastone.com",
  ls_auth_token: null,
  password: "test",
  namespace: null,
  viper_mode: false,
  k_12: false
};
