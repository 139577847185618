import { decodeWelcomePacket } from "../coach/coach-service";

/* This helps a component to make sure the right sessionId is set.
    It could come from:
        1. a fresh launch with a welcomePacket that has the sesisonID
        2. a debug launch with a sessionId on the url
        3. a linked launch from the scheduler with a sessionId on the url (really same as #2)

    We expect to call it from a component with a react route match object and an attendEnterSession action, so we just
    let you pass in the props of the component.

    Also returns the session id
*/

export const setSessionFromProps = props => {
  const welcomePacket = props.match.params.welcomePacket || window.location.search.substring(1); // skip the ? at the start of location.search
  if (welcomePacket && welcomePacket.length > 0) {
    try {
      const wp = decodeWelcomePacket(welcomePacket);
      const sessionId = parseInt(wp.eschool_session_id, 10);
      sessionId && props.attendEnterSession(sessionId);
      return sessionId;
    } catch (e) {
      console.error("Could not parse session ID");
    }
  } else {
    const sessionId = parseInt(props.match.params.sessionId, 10);
    sessionId && props.attendEnterSession(sessionId);
    return sessionId;
  }

  return null;
};
