// application wide constants can go here

import Logo from "../../assets/images/rosetta_stone_brand_logo_140x27.png";
import { isProduction } from "../util";

export const BrandLogo = Logo;

export const DEBUG_ESCHOOL_API = "https://eschool-dev.dev.rosettastone.com/api/v2/tutor/";
export const DEBUG_PUDDLE_ROOT = "https://resources-dev.dev.rosettastone.com/rs3/content/";

// This key expires on Feb 5, 2029 - Ask Keith to make a new one.
export const DEBUG_ESCHOOL_API_KEY =
  "ODBkYzk4Y2E5ZTliNmNlOWMwZThkNmEyM2RlZWI4MGExOTBiMWYyMzUyODk1OTI5MjQ0NGRlNjE5MDgyMGRlNWY0ODFlOTlmZTQ5MmM4YTIyMjRhOTkzZjdjNTMzMGQzMDE0ZTQyZDQ0ZDFiYjQ2MzI1YzM3MjNlZTc0NjdhODdmNDU1MTc1NTAwNWNkYWY3YWQ2Njk2NTJlOGJlYjUyNDlhMzUzYzQ3ZDk2YzVhZDI1YTcwZGE1NWI3YTQ0ZmI1";
export const DEBUG_ESCHOOL_SESSION_ID = "199192";

export const NATIVE_SLIDE_SIZE = 720;
export const NATIVE_RECT_SLIDE_SIZE = 540;

export const COACH_VIDEO_WIDTH = 172;
export const COACH_VIDEO_HEIGHT = 172;

export const STAMP_TOOLBAR_HEIGHT = 56;
export const STAMP_TOOLBAR_MARGIN = 10;

export const TUTOR_AMPLITUDE_KEY = "9ad7867e31149ce4843ce007fa636757";

export const AMPLITUDE_API_URL = "https://api.amplitude.com/httpapi";
export const BRAZE_API_URL = "https://rest.iad-01.braze.com";

export const LEARNER_AMPLITUDE_KEY = isProduction()
  ? "516a3b34f0c569d87cb7a1e5da7de063"
  : "fc3641eafde1f669e596acda245157ec";

export const LEARNER_AMPLITUDE_ZOOM_KEY = isProduction()
  ? "ecbcbc42f8533abf8b32e0804af0c362"
  : "0235cc89d7a53dbb326572b219e2d92c";

export const FLUBU_BRAZE_KEY = isProduction()
  ? "c60008bd-a2fe-439c-a56f-e1dc03c2d78a"
  : "a6500576-763f-4b08-804f-9c1a1d7f9031";

export const STUDIO_BRAZE_KEY = isProduction()
  ? "06fb0bd3-1b87-4cb3-9b2c-711d40916903"
  : "50edad17-12df-4829-bc89-11470b69aa6c";

export const SPECIAL_CAMERAS = ["default", "none"];

export const PRODUCTS = {
  PRODUCT_UNKNOWN: -1,
  PRODUCT_STUDIO: 0,
  PRODUCT_WW: 1,
  PRODUCT_FB: 2
};
