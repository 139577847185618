import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import exact from "prop-types-exact";
import styled from "@emotion/styled";
import TestSound from "../../../assets/sounds/SpeechPrompt.mp3";
import { LionTranslate } from "@rosetta/react-lion";
import { DeviceNote } from "./presession-styles";
import { PlayIcon } from "../../icons/PlayIcon";

const Container = styled.div({
  width: 450,
  display: "flex",
  flexDirection: "column",
  marginLeft: 70
});

const Columns = styled.div({
  width: 450,
  justifyContent: "flex-start",
  display: "flex"
});

const Left = styled.div({
  display: "flex",
  flexDirection: "column",
  width: 200,
  flexGrow: 0,
  flexShrink: 0
});
const Right = styled.div({
  width: 157,
  textAlign: "right",
  display: "flex",
  justifyContent: "flex-end"
});

const PlayButton = styled.button({
  background: "none",
  border: "none",
  outline: "none",
  fontFamily: "Effra",
  fontSize: "14px",
  lineHeight: "19px",
  color: "#4195D3",
  verticalAlign: "middle",
  cursor: "pointer",
  ":hover": {
    backgroundColor: "#EAEAEA"
  },
  "> svg": {
    marginRight: 10,
    verticalAlign: "middle"
  }
});

const Header = styled.div({
  fontFamily: "Effra",
  fontSize: "18px",
  lineHeight: "normal",
  color: "#4A4A4A",
  marginBottom: 14
});

class LearnerSpeakerSelection extends PureComponent {
  static propTypes = exact({
    speakerList: PropTypes.array,
    selectedSpeaker: PropTypes.string,
    onChange: PropTypes.func
  });
  static defaultProps = {};

  componentDidMount() {
    this.sound = new Audio(TestSound);
  }

  play = () => {
    this.sound.play();

    // TODO - if we ever re-enable speaker selection, we want something like this:
    // console.log("Playing on", this.props.selectedSpeaker);
    // if (this.sound.setSinkId) {
    //   this.sound.setSinkId(this.props.selectedSpeaker).then(() => this.sound.play());
    // } else {
    //   this.sound.play();
    // }
  };
  onChange = event => this.props.onChange(event.target.value);

  speakerLabel = () =>
    this.props.speakerList && this.props.speakerList.length > 0 ? (
      this.props.speakerList[0].label
    ) : (
      <LionTranslate lionkey="system_check_default_audio_device" />
    );

  render = () => {
    return (
      <Container data-qa="speaker_selector">
        <Columns>
          <Left>
            <Header>
              <LionTranslate lionkey="system_check_speaker_header" />
            </Header>

            {/* {this.speakerLabel()} */}
            {/*

            Welp... this only worked on Chrome. And even on Chrome, we had a lot of problems actually
                    directing the session audio to that device. So we're disabling it for now, and you
                    can just use your OS controls like Balmer and Jobs intended.

            <Select value={this.props.selectedSpeaker} onChange={this.onChange}>
              {this.props.speakerList.map(speaker => (
                <option key={speaker.deviceId} value={speaker.deviceId}>
                  {speaker.label}
                </option>
              ))}
            </Select> */}
          </Left>
          <Right>
            <PlayButton data-qa="play_button" onClick={this.play}>
              <PlayIcon />
              <LionTranslate lionkey="system_check_test_audio" />
            </PlayButton>
          </Right>
        </Columns>
        <DeviceNote>
          <LionTranslate lionkey="system_check_speaker_instructions" />
        </DeviceNote>
      </Container>
    );
  };
}

export default LearnerSpeakerSelection;
