import { takeEvery, takeLatest, put, select, take, race } from "redux-saga/effects";
import { delay, eventChannel, END } from "redux-saga";
import { Preferences } from "@rosetta/sqrl-client";

import ApolloClient from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { onDemandVideoQuery } from "./ondemand/queries";
import { RSTV_INIT_APP } from "./rstv-reducer";
import { HttpLink } from "apollo-link-http";

import {
  rstvODDataLoaded,
  RSTV_OD_INIT_SRE,
  RSTV_OD_EXIT_VIDEO_RATING,
  RSTV_VOICE_TYPE_SELECTED,
  rstvVoiceTypePrompt
} from "./ondemand/rstv-ondemand-reducer";

import {
  appError,
  getRSTVKey,
  getRSTVUrl,
  LEARNER_WELCOME_RECEIVED,
  getPuddleRoot,
  getVoiceType,
  getLcpAPIUrl,
  getPreferredLocale
} from "../app/reducer/reducer";
import { RSTV_ROUTE } from "../../routes";
import { replace } from "connected-react-router";
import { sreActions } from "@rosetta/react-sre";
import { getContentKeys } from "../topic/topic-reducer";
import { ResourceManager } from "@rosetta/resource-manager";
import {
  RSTV_START_SRE_INTERACTION,
  rstvSoundPromptLoaded,
  RSTV_BEGIN_SRE_LISTEN,
  RSTV_PLAY_SRE_PROMPT,
  getSREInteraction,
  rstvResultsReceived,
  rstvCloseSRE,
  rstvSREPromptCompeleted,
  isPromptPlaying
} from "./rstv-sre-reducer";
import { getSelectedProductId } from "../learner/scheduler/scheduler-reducer";
import { endInteraction, startInteraction, logSegment } from "@rosetta/eve-client";

const cache = new InMemoryCache();

let link = null;
let client = null;

function* initApolloClient() {
  if (client !== null) return;

  const url = yield select(getRSTVUrl);
  const key = yield select(getRSTVKey);

  link = new HttpLink({
    uri: url,
    headers: {
      authorization: `Bearer ${key}`
    }
  });

  client = new ApolloClient({
    link,
    cache
  });
}

function* initRSTV() {
  try {
    yield take(LEARNER_WELCOME_RECEIVED); // Wait for us to authenticate to sqrl
    startInteraction("rstv-data-loaded");
    yield initApolloClient();
    const productId = yield select(getSelectedProductId);
    const locale = yield select(getPreferredLocale);
    const result = yield client.query({
      query: onDemandVideoQuery(productId, locale ? locale : "en-US")
    });
    yield put(rstvODDataLoaded(result));
    endInteraction("rstv-data-loaded");
  } catch (e) {
    yield put(appError(e, "rstv_error_load_videos"));
  }
}

// eslint-disable-next-line no-unused-vars
function* onRSTVExitVideoRating(action) {
  yield put(replace(RSTV_ROUTE));
}

function* disableSRE() {
  yield put(sreActions.disableSpeech());
}

function* initSRE() {
  logSegment("watch-video", "init-sre-started");
  const voiceType = yield select(getVoiceType);
  const validTypes = ["male", "female", "child"];
  if (!voiceType || validTypes.indexOf(voiceType) === -1) {
    // Need to prompt for voice type first!
    yield put(rstvVoiceTypePrompt());
    const action = yield take(RSTV_VOICE_TYPE_SELECTED);
    if (!action.payload.voiceType) {
      yield put(sreActions.disableSpeech());
      return;
    }
  }

  yield put(sreActions.initSRE());
  yield take(sreActions.INIT_SRE_SUCCESS);
  yield put(sreActions.startMicCheckFlow());
  yield put(sreActions.refreshMicList());
}

function* loadPuddleResource(resourceId) {
  const keys = yield select(getContentKeys);
  const puddleRoot = yield select(getPuddleRoot);
  const resourceManager = new ResourceManager(keys, puddleRoot, "", true);
  const resource = yield resourceManager.loadResource(resourceId);
  return URL.createObjectURL(resource);
}

function* loadResource(uri) {
  const parts = uri.split(":");
  if (parts[1] === "puddle" || parts[1] === "puddle_unencrypted") {
    return yield loadPuddleResource(parts[2]);
  }

  return uri;
}

function* startSRE(action) {
  const { interaction } = action.payload;

  if (interaction.prompt.soundUri) {
    const url = yield loadResource(interaction.prompt.soundUri);
    yield put(rstvSoundPromptLoaded(url));
  }
}

function detectAudioEndEventChannel(audioPlayer) {
  return eventChannel(emit => {
    if (!audioPlayer) {
      emit(END);
    }
    // Emit an action object so we can put/dispatch it
    audioPlayer.addEventListener("ended", () => {
      emit({ type: "END_AUDIO" }); // payload here is probably abitrary, since we don't use it
    });
    // This `subscribe` function must return an unsubscribe function.
    return () => {
      audioPlayer.removeEventListener("ended");
    };
  });
}

// eslint-disable-next-line no-unused-vars
function* playSrePrompt(action) {
  const isPlaying = yield select(isPromptPlaying);
  if (!isPlaying) return;

  const interaction = yield select(getSREInteraction);
  if (interaction.soundUrl) {
    const sound = new Audio(interaction.soundUrl);

    try {
      yield sound.play();
    } catch (e) {
      console.error("Could not play audio");
      return;
    }

    const completionChannel = detectAudioEndEventChannel(sound);

    const r = yield race({
      completed: take(completionChannel),
      canceled: take(RSTV_PLAY_SRE_PROMPT)
    });
    console.info("Race winner", r);
    sound.pause();
  }
  yield delay(400);

  yield put(rstvSREPromptCompeleted());
}

// eslint-disable-next-line no-unused-vars
function* listenSre(action) {
  const interaction = yield select(getSREInteraction);
  if (!interaction) return;
  yield put(sreActions.listenForPhrases([interaction.response.sre], "rstv" + new Date().getTime()));

  const r = yield race({
    result: take(sreActions.SRE_LISTEN_FOR_PHRASE_RESULT),
    abort: take(sreActions.SRE_ABORT_LISTEN)
  });

  if (r.result) {
    yield put(rstvResultsReceived(r.result.payload));
  } else {
    yield put(rstvCloseSRE());
  }
}

function* onVoiceTypeSelected(action) {
  const lcpServerApiUrl = yield select(getLcpAPIUrl);
  const preferences = new Preferences(lcpServerApiUrl);

  const language = null;

  if (action.payload.voiceType === "child") {
    yield preferences.setPreferences(
      {
        universal: {
          considered_child_for_speech: {
            value: true
          }
        }
      },
      language
    );
  } else {
    yield preferences.setPreferences(
      {
        universal: {
          speech_voice_type: {
            value: action.payload.voiceType
          },
          considered_child_for_speech: {
            value: false
          }
        }
      },
      language
    );
  }
}

// eslint-disable-next-line require-yield
function* onCalibrationCompleted() {
  logSegment("watch-video", "calibration-completed", {}, false);
}

export function* rstvSagas() {
  yield takeEvery(sreActions.SRE_CALIBRATE_COMPLETE, onCalibrationCompleted);
  yield takeEvery(sreActions.SRE_SKIP_MIC_CALIBRATION, disableSRE);
  yield takeEvery(RSTV_INIT_APP, initRSTV);
  yield takeLatest(RSTV_OD_EXIT_VIDEO_RATING, onRSTVExitVideoRating);
  yield takeEvery(RSTV_OD_INIT_SRE, initSRE);

  yield takeEvery(RSTV_START_SRE_INTERACTION, startSRE);
  yield takeLatest(RSTV_PLAY_SRE_PROMPT, playSrePrompt);
  yield takeLatest(RSTV_BEGIN_SRE_LISTEN, listenSre);
  yield takeLatest(RSTV_VOICE_TYPE_SELECTED, onVoiceTypeSelected);
}
