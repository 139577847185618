import React from "react";
import { endInteraction } from "@rosetta/eve-client";

// This component sends an end-interaction when it mounts. Useful for putting into places
// where you want to record where the user actually sees the UI.
// <EndInteraction interaction="application-startup" />

class EndInteraction extends React.PureComponent {
  componentDidMount() {
    this.props.interaction && endInteraction(this.props.interaction);
  }
  render() {
    return null;
  }
}

export default EndInteraction;
