import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router";
import { ConnectedRouter } from "connected-react-router";

// import main components to map to routes
import App from "./modules/app/components/App";
import DebugPage from "./modules/debug/components/DebugPage";
import StandAloneSystemCheck from "./modules/learner/presession/StandAloneSystemCheck";
import { isProduction } from "./modules/util";
import LearnerPostSession from "./modules/learner/postsession/LearnerPostSession";

import LoadingMessage from "./modules/app/components/LoadingMessage";

const LazySchedulerPage = lazy(() => import("./modules/learner/scheduler/SchedulerPage"));
const LazyCoachPage = lazy(() => import("./modules/coach/CoachPage"));
const LazyLearnerPage = lazy(() => import("./modules/learner/attend/LearnerPage"));
const LazySupportPage = lazy(() => import("./modules/support/SupportPage"));
const LazyContentReviewPage = lazy(() => import("./modules/contentreview/ContentReviewPage"));
const LazyTrainerPage = lazy(() => import("./modules/coach/TrainerPage"));
const LazyRSTVPage = lazy(() => import("./modules/rstv/RSTVPage"));

export const SCHEDULE_ROUTE = "/schedule";
export const RSTV_ROUTE = "/rstv";
export const END_SESSION_ROUTE = "/learner/sessioncompleted";

const RSTVPage = props => (
  <Suspense fallback={<LoadingMessage />}>
    <LazyRSTVPage {...props} />
  </Suspense>
);
const TrainerPage = props => (
  <Suspense fallback={<LoadingMessage />}>
    <LazyTrainerPage {...props} />
  </Suspense>
);
const SchedulerPage = props => (
  <Suspense fallback={<LoadingMessage />}>
    <LazySchedulerPage {...props} />
  </Suspense>
);
const CoachPage = props => (
  <Suspense fallback={<LoadingMessage />}>
    <LazyCoachPage {...props} />
  </Suspense>
);
const LearnerPage = props => (
  <Suspense fallback={<LoadingMessage />}>
    <LazyLearnerPage {...props} />
  </Suspense>
);
const SupportPage = props => (
  <Suspense fallback={<LoadingMessage />}>
    <LazySupportPage {...props} />
  </Suspense>
);
const ContentReviewPage = props => (
  <Suspense fallback={<div>Loading....</div>}>
    <LazyContentReviewPage {...props} />
  </Suspense>
);

/* Valid Routes:

 Debug Routes:
  /debug/
  /debug/schedule/
  /debug/learner/session/:sessionId
  /debug/presession/:sessionId
  /debug/learner/sessioncompleted/:sessionId
  /coach/
  /coach/:sessionId/:sessionToken
  /content/


 Production Routes:

  /syscheck/
  /syscheck/:sessionId
  /presession/:sessionId
  /content/:welcomePacket

  /rstv/[optional:  ?welcome=:welcomePacket]
  /rstv/debugindex[optional:  ?welcome=:welcomePacket]
  /rstv/watch/:videoId[optional:  ?welcome=:welcomePacket]
  /rstv/tutordetail/:tutorId[optional:  ?welcome=:welcomePacket]
  /rstv/videodetail/:videoGuid[optional:  ?welcome=:welcomePacket]


  /schedule/:welcomePacket
  /schedule/
  /learner/session/:sessionId
  /learner/:welcomePacket
  /learner/
  /learner/sessioncompleted/:sessionId


  /trainer/:welcomePacket
  /trainer/
  /coach/:welcomePacket
  /coach/
  /support/:welcomePacket
  /support/
  /tutormanager/:welcomePacket
  /tutormanager/
  /invisible/:welcomePacket
  /invisible/

 */

export const Router = ({ history }) => {
  const production = isProduction();
  return (
    <ConnectedRouter history={history}>
      <App>
        <Switch>
          {/* Can't do production || <Route> here because Switch will complain about invalid children */}
          {production ? null : <Route exact path="/debug" component={DebugPage} />}
          {production ? null : <Route exact path="/coach" component={CoachPage} />}
          {production ? null : <Route exact path="/coach/:sessionId/:sessionToken" component={CoachPage} />}

          {production ? null : (
            <Route
              exact
              path={"/debug" + SCHEDULE_ROUTE}
              render={props => <SchedulerPage {...props} stubCredentials={true} />}
            />
          )}

          {production ? null : (
            <Route
              exact
              path="/debug/learner/session/:sessionId"
              render={props => <LearnerPage {...props} stubCredentials={true} />}
            />
          )}

          {production ? null : (
            <Route
              exact
              path="/debug/presession/:sessionId"
              render={props => <StandAloneSystemCheck {...props} stubCredentials={true} />}
            />
          )}

          {production ? null : <Route exact path="/content/" component={ContentReviewPage} />}

          <Route exact path="/syscheck" render={props => <StandAloneSystemCheck syscheck={true} {...props} />} />
          <Route
            exact
            path="/syscheck/:sessionId"
            render={props => <StandAloneSystemCheck syscheck={true} {...props} />}
          />

          <Route exact path="/presession/:sessionId" component={StandAloneSystemCheck} />

          {/* The content path is used by content-dev and tutors to review content outside of a session */}
          <Route exact path="/content/:welcomePacket" component={ContentReviewPage} />
          <Route exact path="/content" component={ContentReviewPage} />

          {/* RSTV Page */}
          <Route path={RSTV_ROUTE} component={RSTVPage} />

          {/* The learner's main page for a session */}

          <Route exact path={SCHEDULE_ROUTE + "/:welcomePacket"} component={SchedulerPage} />
          <Route exact path={SCHEDULE_ROUTE} component={SchedulerPage} />

          {/* The learner's main page for a session */}

          <Route exact path="/learner/session/:sessionId" component={LearnerPage} />

          <Route exact path="/learner/:welcomePacket" component={StandAloneSystemCheck} />
          <Route exact path="/learner" component={StandAloneSystemCheck} />

          {production ? null : (
            <Route
              exact
              path={"/debug" + END_SESSION_ROUTE + "/:sessionId"}
              render={props => <LearnerPostSession {...props} stubCredentials={true} />}
            />
          )}
          <Route exact path={END_SESSION_ROUTE + "/:sessionId"} component={LearnerPostSession} />

          {/* Tutors use this as a pretend learner during training */}
          <Route exact path="/trainer/:welcomePacket" component={TrainerPage} />
          <Route exact path="/trainer" component={TrainerPage} />

          {/* The Tutor's main page for a session */}
          <Route exact path="/coach/:welcomePacket" component={CoachPage} />
          <Route exact path="/coach" component={CoachPage} />

          {/* Review mode, for coaching staff to watch replays in */}
          <Route
            exact
            path="/review/:welcomePacket"
            render={props => <CoachPage {...props} key="review" forceReviewMode={true} />}
          />
          <Route exact path="/review" render={props => <CoachPage {...props} key="review" forceReviewMode={true} />} />

          {/* Page for support to hop into a session and help out. Also named tutormanager, so managers can do the same (that second role
              functionality might change a bit in the future) */}
          <Route exact path="/support/:welcomePacket" component={SupportPage} />
          <Route exact path="/support" component={SupportPage} />

          <Route exact path="/tutormanager/:welcomePacket" component={SupportPage} />
          <Route exact path="/tutormanager" component={SupportPage} />

          {/* Invisible mode, just like support, but can't chat or broadcast audio */}
          <Route exact path="/invisible/:welcomePacket" render={props => <SupportPage {...props} invisible={true} />} />
          <Route exact path="/invisible" render={props => <SupportPage {...props} invisible={true} />} />
        </Switch>
      </App>
    </ConnectedRouter>
  );
};
