import { getESchoolApiKey, getESchoolAPIBase, appError, getPuddleRoot } from "../app/reducer/reducer";
import { getDiscussionTopics } from "../app/services/eschool";
import convert from "xml-js";
import { TopicService } from "./topic-service";
import { put, select, takeEvery } from "redux-saga/effects";
import { parseNonCompactXML } from "./xmlutil.js";
import {
  topicLoaded,
  topicFailedLoad,
  getTopicResourceId,
  getContentKeys,
  BEGIN_TOPIC_REVIEW,
  TOPIC_SELECTED,
  topicListLoaded,
  isStudioTopic,
  TOPIC_STUDIO_SELECT,
  getCurrentlyLoadedResourceId
} from "./topic-reducer";
import { imageResourceId, sortKey } from "./topic-util";
import { logToEschool, logMinorError } from "../errorlogging/errorlogging-reducer";

export /* istanbul ignore next */ const resourceToText = asset => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.addEventListener("loadend", function() {
      resolve(this.result);
    });

    reader.addEventListener("error", () => {
      reject();
    });

    reader.readAsText(asset);
  });
};

export function* loadTopic() {
  const resourceId = yield select(getTopicResourceId);
  if (!resourceId) {
    console.log("No topic specified yet.");
    return;
  }

  const currentTopic = yield select(getCurrentlyLoadedResourceId);

  if (currentTopic === resourceId) {
    // No need to load it, it's already there.
    return;
  }

  console.log("Loading topic from", resourceId);
  yield put(logToEschool(`Loading Topic ${resourceId}`));
  const isStudio = yield select(isStudioTopic);
  const keys = yield select(getContentKeys);
  const puddleRoot = yield select(getPuddleRoot);

  const topicResource = yield TopicService.loadTopic(resourceId, keys, puddleRoot);
  const topicStr = yield resourceToText(topicResource);

  if (isStudio) {
    yield loadStudioTopic(resourceId, topicStr, keys, puddleRoot);
  } else {
    yield loadTopicalTopic(resourceId, topicStr, keys, puddleRoot);
  }
}

export const getClassId = topicData => {
  if (!topicData || !topicData.metadata) return "eschool_unknown";
  const lang = topicData.metadata.language.text;
  const unit = topicData.metadata.unit.text;
  const lesson = topicData.metadata.lesson.text;
  if (!lang || !unit || !lesson) return "eschool_unknown";
  return `eschool_${lang}_U_${unit}_L_${lesson}`;
};

function* loadStudioTopic(resourceId, topicStr, keys, puddleRoot) {
  try {
    const images = {};

    const xml = convert.xml2js(topicStr, { compact: false, nativeType: true, addParent: false, alwaysArray: true });
    const topicData = parseNonCompactXML(xml, ["script", "layout", "module", "imageSlide", "image", "term", "slot"], [])
      .class;

    const classId = getClassId(topicData);

    for (const module of topicData.modules.module) {
      const slideTypes = Object.keys(module.slides).filter(key => key !== "imageSlide" && key !== "activitySlide");
      if (slideTypes.length > 0) {
        yield put(logMinorError(null, "Unknown slide type " + slideTypes.join(", ") + " in " + resourceId));
      }
      for (const slide of module.slides.imageSlide || []) {
        // Note: not every module has an imageSlide property, it might just have an activitySlide
        for (const image of slide.images.image) {
          try {
            const imageResource = yield TopicService.loadImage(image.resource, keys, puddleRoot);
            image.uri = URL.createObjectURL(imageResource);
          } catch (e) {
            yield put(logMinorError(e, "Could not parse a studio slide from" + resourceId));
            yield put(appError(e, "err_topic_fail"));
          }
        }
      }
    }
    // Flatten the slides array
    topicData.slides = topicData.modules.module.reduce((acc, curr) => {
      return acc.concat(curr.slides.imageSlide || []);
    }, []);

    yield put(topicLoaded(topicData, images, resourceId, classId));
  } catch (e) {
    console.error(e);
    yield put(topicFailedLoad());
    yield put(appError(e, "err_topic_fail"));
  }
}
function* loadTopicalTopic(resourceId, topicStr, keys, puddleRoot) {
  const topicData = JSON.parse(topicStr);
  try {
    const images = {};
    const coverImageResourceId = imageResourceId(topicData.cover_images.main.image);
    const coverImageResource = yield TopicService.loadImage(coverImageResourceId, keys, puddleRoot);
    const coverUri = URL.createObjectURL(coverImageResource);
    topicData.cover_images.main.uri = coverUri;

    for (const slide of topicData.slides) {
      try {
        /* This isn't the final schema for the topic data, so here's a quick & dirty
         parsing of it. */
        if (slide.content) {
          for (const content of slide.content) {
            const resourceId = imageResourceId(content.image);
            const imageResource = yield TopicService.loadImage(resourceId, keys, puddleRoot);
            const uri = URL.createObjectURL(imageResource);
            content.uri = uri;
          }
        }
      } catch (e) {
        console.warn("Could not parse a slide in the topic data", JSON.stringify(slide));
        yield put(appError(e, "err_topic_fail"));
      }
    }
    yield put(topicLoaded(topicData, images, resourceId, `eschool-${resourceId}`));
  } catch (e) {
    yield put(topicFailedLoad());
    yield put(appError(e, "err_topic_fail"));
  }
}

function* loadTopicList() {
  const baseUrl = yield select(getESchoolAPIBase);
  const apiKey = yield select(getESchoolApiKey);
  try {
    const result = yield getDiscussionTopics(baseUrl, apiKey);
    const topics = result.topics.map(topic => ({ ...topic, lastDate: sortKey(topic.scheduled_dates) }));

    const sortedTopics = topics.sort((a, b) => {
      const ak = a.lastDate;
      const bk = b.lastDate;
      if (ak < bk) return 1;
      if (ak > bk) return -1;
      return 0;
    });
    yield put(topicListLoaded(sortedTopics));
  } catch (e) {
    console.log("Could not load topic list.");
  }
}

export function* topicSagas() {
  yield takeEvery(BEGIN_TOPIC_REVIEW, loadTopicList);
  yield takeEvery(TOPIC_SELECTED, loadTopic); // Learner and Content review load
  yield takeEvery(TOPIC_STUDIO_SELECT, loadTopic);
}
