import styled from "@emotion/styled";

const Select = styled.select({
  background: "#FFFFFF",
  border: "0.5px solid rgba(151, 151, 151, 0.487347)",
  boxSizing: "border-box",
  borderRadius: "3px",
  width: 355,
  height: 40,
  fontFamily: "Effra",
  fontSize: "14px",
  lineHeight: "20px",
  color: "#4A4A4A",
  position: "relative"
});

export default Select;
