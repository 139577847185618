import React from "react";
import { connect } from "react-redux";
import { lionSelectors } from "@rosetta/react-lion";
import {
  learnerInitApp,
  getAppError,
  appError,
  clearAppError,
  isAppInitialized,
  hasLearnerAuthenticated
} from "../app/reducer/reducer";
import { decodeWelcomePacket } from "../coach/coach-service";

import * as DebugConstants from "./debug-constants";
import { ErrorDialog } from "../coach/ErrorDialog";
import { languageProductSelected } from "./scheduler/scheduler-reducer";
import LoadingMessage from "../app/components/LoadingMessage";
import { isProduction } from "../util";

const getWelcomePacket = (params, search) => {
  if (search && search.length > 0) {
    const urlParams = new URLSearchParams(search);
    const packet = urlParams.get("welcome");
    if (packet && packet.length > 0) return packet;
  }

  if (params.welcomePacket && params.welcomePacket.length > 0) {
    // Strip off malformed param at the end of url.
    const welcomePacket = params.welcomePacket.replace(/&.*/, "");

    if (welcomePacket && welcomePacket.length > 0) return welcomePacket;
  }
  return null;
};

/**
 * Handles logging in the scheduler and learner routes to LCP/SQRL
 */
export class LearnerAuth extends React.PureComponent {
  componentDidMount() {
    if (this.props.previouslyAuthenticated) {
      return;
    }

    const welcomePacket = getWelcomePacket(this.props.match.params, this.props.location.search);
    const urlParams = new URLSearchParams(this.props.location.search);

    if (welcomePacket && welcomePacket.length > 0) {
      try {
        const wp = decodeWelcomePacket(welcomePacket);
        if (wp.product_identifier) {
          this.props.languageProductSelected(wp.product_identifier);
        }

        const token = wp.token.token || wp.token;

        // Sample packet:
        // {
        //   "eschool_session_id": 222566,
        //   "token": "d78aa75f-17d9-4f8d-9097-7975351832f8",
        //   "token_type": "tully",
        //   "product_identifier": null
        // }

        if (!token) {
          this.props.appError(null, "error_launch_token");
          return;
        }

        this.props.learnerInitApp(
          {
            username: "",
            password: "", // For some weird reason, sqrl requires empty strings on these.
            auth_token: token,
            auth_token_type: wp.token_type
          },
          wp.lcp_url || DebugConstants.lcpUrl,
          urlParams.get("locale"),
          this.props.match.path
        );
      } catch (e) {
        this.props.appError("err_bad_welcome");
        this.props.learnerInitApp(null, null, null, this.props.match.path);
      }
    } else if (!isProduction()) {
      // Debug Mode, yay
      const locale = urlParams.get("locale") || localStorage.getItem("tutor.debug.locale");
      const authInfo = {
        ...DebugConstants.authInfo,
        username: localStorage.getItem("tutor.debug.username") || DebugConstants.authInfo.username,
        password: localStorage.getItem("tutor.debug.password") || DebugConstants.authInfo.password
      };
      this.props.languageProductSelected(
        localStorage.getItem("tutor.debug.product") || DebugConstants.authInfo.product
      );
      this.props.learnerInitApp(authInfo, DebugConstants.lcpUrl, locale, this.props.match.path);
    }
  }

  render() {
    if (!this.props.isAppInitialized) {
      return (
        <div>
          {this.props.currentAppError && this.props.lionLoaded && (
            <ErrorDialog error={this.props.currentAppError} clearAppError={this.props.clearAppError} />
          )}
          <LoadingMessage />
        </div>
      );
    }

    return (
      <React.Fragment>
        {this.props.currentAppError && this.props.lionLoaded && (
          <ErrorDialog error={this.props.currentAppError} clearAppError={this.props.clearAppError} />
        )}
        {this.props.children}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  isAppInitialized: isAppInitialized(state),
  currentAppError: getAppError(state),
  lionLoaded: lionSelectors.getLionAppResourcesLoaded(state),
  previouslyAuthenticated: hasLearnerAuthenticated(state)
});

export default connect(
  mapStateToProps,
  { learnerInitApp: learnerInitApp, clearAppError, appError, languageProductSelected }
)(LearnerAuth);
