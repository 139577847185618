/*
  This file contains a bunch of fake logic to use during trainer mode to fake out learner-side API calls
  that we can't actually call in that mode. It mostly re-uses data we already have from tutor-side API calls
  to mimic the results we would have gotten.
*/
import { put, select } from "redux-saga/effects";
import { getContentKeys } from "../../topic/topic-reducer";
import { getUpdateServiceUrl, getPuddleRoot, getErrbitUrl, getErrbitApi, loadTimeZones } from "../reducer/reducer";

export function* trainerModeLearnerSessionStart() {
  // For the trainer mode, we're going to fake out the results of a session start call
  // with info from the tutor API side

  const static_decryption_keys = yield select(getContentKeys);
  const update_service_url = yield select(getUpdateServiceUrl);
  const puddle_root = yield select(getPuddleRoot);
  const web_service_base_url = yield select(getErrbitUrl);
  const web_service_access_key = yield select(getErrbitApi);
  const now = Math.round(new Date().getTime() / 1000);
  return {
    heartbeat_interval_seconds: 180,
    server_timestamp: now,
    welcome_packet: {
      static_decryption_keys,
      client_config: {
        heartbeat_interval_seconds: "60",
        puddle_root,
        launchpad_url: "https://login-dev.dev.rosettastone.com/",
        fluency_builder_url: "https://learn-dev.dev.rosettastone.com/",
        update_service_url
      },
      product_language_data: [
        {
          identifier: "ESP",
          product_rights: [
            {
              product_identifier: "ESP",
              product_version: "3",
              product_family: "application",
              guid: "2affa134-9e1b-4307-82d5-fa3b646cc3c9",
              starts_at: 1507815674,
              ends_at: now + 260000,
              units_allowed: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]
            }
          ],
          resource_links: [],
          available_features: ["studio"],
          feature_metadata: {}
        }
      ],
      user_context: {
        guid: "trainer-" + new Date().getTime(),
        is_homeschool_user: false,
        vertical: "Consumer"
      },
      web_access_credentials: [
        {
          web_service_name: "errbit",
          web_service_base_url,
          web_service_access_key
        }
      ],
      extra_data: []
    },
    support_links: {}
  };
}

// eslint-disable-next-line require-yield
export function* trainerLoadStudioPreferences() {
  return null;
}

// eslint-disable-next-line require-yield
export function* trainerLoadGlobalPreferences() {
  return null;
}

export function* trainerLoadTimezones() {
  yield put(
    loadTimeZones([
      {
        name: "International Date Line West",
        offset_in_minutes: -660,
        localized_name: "International Date Line West (GMT-11:00)",
        identifier: "Pacific/Midway"
      }
    ])
  );
}
