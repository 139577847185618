import amplitude from "amplitude-js";
import appboy from "appboy-web-sdk"; // aka Braze

import {
  LEARNER_AMPLITUDE_KEY,
  FLUBU_BRAZE_KEY,
  STUDIO_BRAZE_KEY,
  PRODUCTS,
  LEARNER_AMPLITUDE_ZOOM_KEY
} from "../app/constants";
import { recordAmplitudeEvent, recordBrazeEvent } from "./common-analytics";
import { isProduction } from "../util";

let brazeInitialized = false;
let l1Language = "Unknown";
let l2Language = "Unknown";

export const initLearnerAnalytics = (product, userl1Language = "Unknown", userl2Language = "Unknown") => {
  l1Language = userl1Language;
  l2Language = userl2Language;
  try {
    if (product === PRODUCTS.PRODUCT_FB) {
      amplitude.getInstance().init(LEARNER_AMPLITUDE_KEY);
      appboy.initialize(FLUBU_BRAZE_KEY, { enableLogging: !isProduction() });
      brazeInitialized = true;
      appboy.display.automaticallyShowNewInAppMessages();
    }
    if (product === PRODUCTS.PRODUCT_STUDIO) {
      amplitude.getInstance().init(LEARNER_AMPLITUDE_ZOOM_KEY);
      appboy.initialize(STUDIO_BRAZE_KEY, { enableLogging: !isProduction() });
      brazeInitialized = true;
      appboy.display.automaticallyShowNewInAppMessages();
    }
  } catch (e) {
    console.warn("Could not init analytics", e);
  }
};

const recordBothLearnerEvents = eventName => {
  const amplitude = recordAmplitudeEvent(eventName);
  const braze = recordBrazeEvent(eventName);

  return props => {
    try {
      amplitude({
        "Interface Language (L1)": l1Language,
        "Active Learning Language (L2)": l2Language,
        ...props
      });
      if (brazeInitialized) {
        braze({
          "Interface Language (L1)": l1Language,
          "Active Learning Language (L2)": l2Language,
          ...props
        });
      }
    } catch (e) {
      console.warn(e);
      console.warn("Could not log event", eventName);
    }
  };
};

const recordLearnerAmplitudeEvent = eventName => {
  const record = recordAmplitudeEvent(eventName);
  return props =>
    record({
      "Interface Language (L1)": l1Language,
      "Active Learning Language (L2)": l2Language,
      ...props
    });
};

export const recordTechFeedbackViewed = recordLearnerAmplitudeEvent("Technical Feedback Screen Viewed");
export const recordTechFeedbackSubmitted = recordLearnerAmplitudeEvent("Technical Feedback Submitted");
export const recordSessionFeedbackViewed = recordLearnerAmplitudeEvent("Session Feedback Screen Viewed");
export const recordSessionFeedbackSubmitted = recordLearnerAmplitudeEvent("Session Feedback Submitted");

export const recordTutoringLaunched = recordBothLearnerEvents("Tutoring Launched");
export const recordTutoringDashboardViewed = recordLearnerAmplitudeEvent("Tutoring Dashboard Viewed");
export const recordPurchaseSessionStarted = recordLearnerAmplitudeEvent("Purchase Session Started");
export const recordSessionSchedulingStarted = recordLearnerAmplitudeEvent("Session Scheduling Started");
export const recordSessionDateTimeSelected = recordLearnerAmplitudeEvent("Session Date Time Selected");
export const recordSessionTopicSelected = recordLearnerAmplitudeEvent("Session Topic Selected");
export const recordTutorSelected = recordLearnerAmplitudeEvent("Tutor Selected");
export const recordSessionDateTimeNotFound = recordLearnerAmplitudeEvent("Session Date/Time Not Found");
export const recordSessionDateTimeRequested = recordLearnerAmplitudeEvent("Session Date/Time Requested");
export const recordSessionConfirmationViewed = recordLearnerAmplitudeEvent("Session Confirmation");
export const recordSessionCancelled = recordLearnerAmplitudeEvent("Session Cancelled");
export const recordSessionScheduled = recordBothLearnerEvents("Session Scheduled");

export const recordSessionStarted = recordLearnerAmplitudeEvent("Session Started");
export const recordSessionExited = recordLearnerAmplitudeEvent("Session Exited");

// RSTV Events:
export const recordRSTVOnDemandHomeScreenViewed = recordLearnerAmplitudeEvent("On-Demand Home Screen Viewed");
export const recordRSTVOnDemandVideoDetailsScreenViewed = recordLearnerAmplitudeEvent(
  "On-Demand Video Details Screen Viewed"
);
export const recordRSTVOnDemandTutorDetailsScreenViewed = recordLearnerAmplitudeEvent(
  "On-Demand Tutor Details Screen Viewed"
);
export const recordRSTVOnDemandVideoStarted = recordLearnerAmplitudeEvent("On-Demand Video Started");
export const recordRSTVOnDemandVideoExited = recordLearnerAmplitudeEvent("On-Demand Video Exited");
export const recordRSTVOnDemandRatingScreenViewed = recordLearnerAmplitudeEvent("On-Demand Rating Screen Viewed");
export const recordRSTVOnDemandRatingSubmitted = recordLearnerAmplitudeEvent("On-Demand Rating Submitted");
export const recordRSTVOnDemandFeedbackScreenViewed = recordLearnerAmplitudeEvent("On-Demand Feedback Screen Viewed");
export const recordRSTVOnDemandChallengeStarted = recordLearnerAmplitudeEvent("On-Demand Challenge Started");
export const recordRSTVOnDemandChallengeSkipped = recordLearnerAmplitudeEvent("On-Demand Challenge Skipped");
export const recordRSTVOnDemandChallengeAudioPlayed = recordLearnerAmplitudeEvent("On-Demand Challenge Audio Played");
export const recordRSTVOnDemandChallengeRepeated = recordLearnerAmplitudeEvent("On-Demand Challenge Repeated");
export const recordRSTVOnDemandChallengeCompleted = recordLearnerAmplitudeEvent("On-Demand Challenge Completed");

// Session completed is now sent by the coach via the amplitude HTTP api in coach-sagas.js
// export const recordSessionCompleted = recordLearnerEvent("Session Completed")
